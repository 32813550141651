import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  circlePlaceholder: {
    alignItems: 'center',
    backgroundColor: colors.neutral.charcoal,
    borderRadius: scale(20),
    height: scale(20),
    justifyContent: 'center',
    marginTop: scale(2),
    width: scale(20),
  },
  numericText: { color: colors.neutral.cotton },
  termsIconContainer: {
    alignItems: 'center',
    height: '100%',
    paddingTop: scale(2),
    width: '10%',
  },
  termsRow: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: scale(10),
    paddingVertical: scale(8),
  },
  termsTextContainer: { width: '90%' },
  termsTextStyle: {
    paddingLeft: scale(8),
  },
});

export default styles;
