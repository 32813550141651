import { useNavigation, useRoute } from '@react-navigation/native';
import id from '@root/i18n/id';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Button, Text } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, FlatList, ScrollView } from 'react-native';
import styles from './style';

export const keyExtract = (item, index) => index.toString();

export function onButtonPressHandler(setLoanTerms, navigation) {
  return () => {
    setLoanTerms(true);
    navigation.goBack();
  };
}

const LoanTerms = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { t } = useTranslation('common');

  const { setLoanTerms } = getStore();

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.Loans.loan_tnc_view_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.loan_product_code]: route?.params?.loanProductCode,
    });
  }, []);

  const renderItem = useCallback(
    ({ index }) => (
      <View style={styles.contentCardContainer}>
        <Text variant="sm-normal" style={styles.cardContentSrnStyle}>
          {index + 1}.{' '}
        </Text>
        <Text variant="sm-normal" style={styles.cardContentStyle}>
          {t(`loan.LOAN_TERMS.${index}`)}
        </Text>
      </View>
    ),
    [],
  );

  return (
    <ScrollView style={styles.scrollableContainerStyle} contentContainerStyle={styles.mainContentStyle}>
      <FlatList
        testID="loanTerms"
        data={id.loan.LOAN_TERMS}
        keyExtractor={keyExtract}
        renderItem={renderItem}
        contentContainerStyle={styles.contentScrollStyle}
      />

      <Button
        testId="cekReward"
        textTransform="none"
        onPress={onButtonPressHandler(setLoanTerms, navigation)}
        disabled={false}
        textStyle={styles.loginButtonTextStyle}
        style={styles.buttonStyle}>
        <Text variant="md-bold" color={colors.neutral.cotton}>
          {t('loan.saya')}
        </Text>
      </Button>
    </ScrollView>
  );
};

export default LoanTerms;
