import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  assetOneStyling: { bottom: scale(-110), position: 'absolute', zIndex: -2 },
  assetTwoStyling: {
    alignSelf: 'center',
    position: 'absolute',
    right: scale(42),
    top: scale(28),
  },
  buttonStyle: { marginBottom: scale(16), marginHorizontal: scale(16) },
  cardContentSrnStyle: { color: colors.neutral.charcoal, fontSize: scale(12), marginTop: scale(2) },
  cardContentStyle: { color: colors.neutral.charcoal, width: '90%' },
  cardTitleStyle: { color: colors.neutral.charcoal, fontSize: scale(14), marginVertical: scale(7) },
  closeStyle: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: scale(15),
    marginTop: scale(20),
  },
  contentCardContainer: { flexDirection: 'row', paddingVertical: scale(10) },
  contentScrollStyle: {
    flex: 1,
    height: 0,
    paddingBottom: scale(40),
    paddingHorizontal: scale(16),
  },
  loginButtonStyle: {
    alignSelf: 'center',
    backgroundColor: colors.primary.carnation,
    borderRadius: scale(30),
    bottom: scale(14),
    color: colors.neutral.charcoal,
    elevation: 0,
    justifyContent: 'flex-end',
    paddingVertical: scale(10),
    position: 'absolute',
    width: '85%',
  },
  loginButtonTextStyle: { textTransform: 'none' },
  mainContainer: { backgroundColor: colors.secondary.orchid, flex: 1 },
  mainContentStyle: {
    backgroundColor: colors.neutral.cotton,
    flex: 1,
    // paddingBottom: scale(50),
  },
  scrollableContainerStyle: { paddingTop: scale(10) },
  textContainer: { marginLeft: scale(15), marginTop: scale(20), width: '50%' },
  textStyling: { color: colors.neutral.cotton, fontSize: scale(15) },
  titleStyle: { color: colors.neutral.cotton, marginLeft: scale(10), marginTop: scale(4) },
  upperContainer: { height: '10.5%', paddingTop: scale(15) },
});

export default styles;
