import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const listingItemStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  disabledStyle: {
    color: colors.disabled.smoke,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: scale(30),
  },
  points: {
    color: colors.warning.sunflower,
  },
  text: {
    color: colors.neutral.charcoal,
  },
});

export default listingItemStyles;
