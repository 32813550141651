import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  amountInputStyles: errors => ({
    flexDirection: 'row',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: errors && errors.amount ? colors.error.rose : colors.neutral.darkMist,
    alignItems: 'center',
  }),
});

export default styles;
