import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bulletStyle: {
    color: colors.neutral_darkMist,

    marginStart: 5,
  },
  bulletStyleTwo: {
    color: colors.neutral_darkMist,
    marginStart: 5,
  },
  outerRow: {
    flexDirection: 'row',
    marginTop: scale(8),
    paddingHorizontal: scale(20),
  },
});

export default styles;
