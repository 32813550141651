import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ReferralCode(props) {
  const { t } = useTranslation('common');
  const { referralCode } = props;
  return (
    <View style={appStyles.marginVertical(16)}>
      <Text variant="md-bold">{t('referral.txtAchievePrize')}</Text>
      <View
        backgroundColor={colors.secondary.softOrchid}
        alignItems="center"
        style={[
          appStyles.marginTop(4),
          appStyles.borderRadius(12),
          appStyles.paddingTop(12),
          appStyles.paddingBottom(10),
        ]}>
        <Text variant="sm-normal">{t('referral.txtGGReferralCode')}</Text>
        <Text variant="xl-bold">{referralCode}</Text>
      </View>
    </View>
  );
}

ReferralCode.propTypes = {
  referralCode: PropTypes.string,
};
ReferralCode.defaultProps = {
  referralCode: '',
};
export default ReferralCode;
