import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export default function logTilePress(productConfig, source, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.userClickedProduct;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.transaction_category_id]: productConfig.transaction_category_id,
    [attributes.source]: source,
    [attributes.referral_code]: validatedReferralCode,
  });
}
