import colors from '@theme/colors';
import { boldFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral.cotton,
    flexGrow: 1,
    paddingBottom: scale(50),
    paddingTop: 0,
  },
  detailDescStyle: { marginTop: scale(8), paddingHorizontal: scale(16) },
  disabledButton: { backgroundColor: colors.gainsboro },
  headerBackContainer: { marginTop: scale(60), position: 'absolute', zIndex: 10 },
  headerBackLabelStyle: {
    color: colors.neutral.cotton,
    fontFamily: boldFont,
    fontSize: scale(16),
    paddingLeft: scale(12),
    width: scale(150),
  },
  headerBackStyle: { height: scale(25), marginLeft: scale(10), paddingRight: scale(29), width: scale(20) },
  hrBadgeContainer: {
    alignItems: 'center',
    backgroundColor: colors.secondary.darkOrchid,
    borderRadius: scale(12),
    justifyContent: 'center',
    marginLeft: scale(10),
    paddingHorizontal: scale(8),
    paddingVertical: scale(5),
  },

  iconStyle: {
    alignSelf: 'center',
    color: colors.secondary_darkOrchid,
    fontSize: scale(18),
    marginLeft: scale(4),
  },
  imagePicBackground: { backgroundColor: colors.secondary.softOrchid },
  imagePlaceholder: {
    alignItems: 'center',
    backgroundColor: colors.error.softRose,
    borderColor: colors.neutral.cotton,
    borderRadius: scale(31),
    borderWidth: scale(4),
    bottom: scale(-32),
    height: scale(62),
    justifyContent: 'center',
    marginLeft: scale(14),
    position: 'absolute',
    width: scale(62),
  },
  imageStyle: {
    height: scale(130),
    marginTop: 0,
    width: '100%',
  },
  lihatButtonContainer: {
    alignItems: 'center',
    borderColor: colors.secondary.softOrchid,
    borderRadius: scale(12),
    borderWidth: scale(1),
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: scale(16),
    marginTop: scale(20),
    paddingVertical: scale(8),
  },

  loadingButton: {
    borderRadius: scale(30),
    justifyContent: 'center',
    marginHorizontal: scale(16),
    marginTop: scale(30),
    marginVertical: scale(15),
    paddingVertical: scale(12),
  },
  middleImageStyle: { height: '70%', width: '70%' },
  noteStyle: {
    alignItems: 'center',
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    justifyContent: 'center',
    marginHorizontal: scale(16),
    marginTop: scale(17),
    paddingVertical: scale(4),
  },
  noteTextStyle: { color: colors.neutral.darkMist },
  titleContainer: {
    alignItems: 'center',

    flexDirection: 'row',
    marginTop: scale(35),
    paddingHorizontal: scale(16),
  },
  titleNameStyle: { fontSize: scale(20) },
});

export default styles;
