import config from '@config';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { getReleaseName } from '@utils/utils';
import { SentryHelper, beforeBreadCrumbEvent } from './SentryHelper';

function initializePerformanceTool() {
  Sentry.init({
    dsn: config.SENTRY_WEB_KEY,
    debug: false,
    maxBreadcrumbs: 150, // Extend from default 100 breadcrumbs.
    environment: config.ENVIRONMENT,
    // Release Health
    release: getReleaseName(),
    enableAutoSessionTracking: true,
    // For testing, session close when 5 seconds (instead of the default 30) in the background.
    sessionTrackingIntervalMillis: 5000,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enableTracing: true,
    tracePropagationTargets: ['localhost', config.API_URL],
    integrations: [
      new CaptureConsole({ levels: ['error'] }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      }),
      new Sentry.Replay(),
    ],
    beforeBreadcrumb: beforeBreadCrumbEvent(),
  });
}

export function setUserProperties(employeeId, employerId, name) {
  SentryHelper.setUser(employeeId, employerId, name);
}

export default initializePerformanceTool;
