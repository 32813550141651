import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerAdj: { marginLeft: scale(18) },
  installAmtTxt: {
    marginTop: scale(3),
    width: scale(90),
  },
  metaContainer: {
    alignItems: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: scale(12),
    justifyContent: 'center',
    marginRight: scale(12),
    paddingHorizontal: scale(8),
  },
  metaInfoStyle: {
    marginTop: scale(3),
    width: scale(87),
  },
  metaRow: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginHorizontal: scale(10),
    marginTop: scale(2),
    marginVertical: scale(8),
    paddingVertical: scale(8),
  },
  metaTagStyle: {
    color: colors.warning.darkSunflower,
    fontSize: scale(10.5),
  },
  metaTitleStyle: { marginBottom: scale(5) },
  outerContainer: {
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.neutral.mist,
    borderRadius: scale(12),
    borderWidth: scale(2),
    elevation: 2,
    marginHorizontal: scale(12),
    marginVertical: scale(10),
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: scale(2),
    },
    shadowOpacity: 0.25,
    shadowRadius: scale(3.84),
  },
  principleAmtStyle: {
    color: colors.success.cactus,
    marginLeft: scale(6),
  },
  principleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: scale(12),
    paddingVertical: scale(10),
  },
  row: { alignItems: 'center', flexDirection: 'row' },
  tenureBubble: {
    alignItems: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: scale(38),
    height: scale(20),
    justifyContent: 'center',
    width: scale(20),
  },
  tenureTxtStyle: {
    color: colors.neutral.charcoal,
  },
});

export default styles;
