import { numberWithDots } from '@utils/utils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

export const Row = ({ title, value }) => (
  <View style={styles.rowStyle}>
    <Text variant="md-normal">{title}</Text>
    <Text variant="md-bold" style={styles.textAdj}>
      {value}
    </Text>
  </View>
);

const TransaksiDetails = ({ fee, bankName, benificiaryName, bankAccountNo, show, t }) => {
  if (!show) return null;

  return (
    <View style={styles.outerContainer}>
      <Text variant="md-bold" style={styles.mainTitleStyle}>
        {t('loan.transaksiDetail')}
      </Text>

      <View style={styles.innerContainer}>
        <Row title={t('loan.transaksiDetail2')} value={`Rp${numberWithDots(fee)}`} />
        <Row title={t('loan.transaksiDetail3')} value={bankName} />
        <Row title={t('loan.transaksiDetail4')} value={bankAccountNo} />
        <Row title={t('loan.transaksiDetail5')} value={benificiaryName} />
      </View>
    </View>
  );
};

Row.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

TransaksiDetails.propTypes = {
  fee: PropTypes.number.isRequired,
  bankName: PropTypes.string.isRequired,
  benificiaryName: PropTypes.string.isRequired,
  bankAccountNo: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default TransaksiDetails;
