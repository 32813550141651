import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  accountSelectorContainer: (item, selectedEligibleAccount, showChecked) => {
    if (item === selectedEligibleAccount && showChecked) {
      return {
        flexDirection: 'row',
        paddingHorizontal: scale(16),
        paddingVertical: verticalScale(16),
        backgroundColor: colors.secondary.softOrchid,
      };
    }
    return {
      flexDirection: 'row',
      paddingHorizontal: scale(16),
      paddingVertical: verticalScale(16),
    };
  },
  accountSelectorRow: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,

    // marginVertical: verticalScale(16),
  },
  txtAccountBalance: (item, selectedEligibleAccount) => ({
    flexDirection: 'row',
    alignSelf: 'center',
    // flex: 1,
    marginRight: item === selectedEligibleAccount ? 0 : 24,
  }),
  txtAccountName: {
    alignSelf: 'stretch',
    marginLeft: scale(8),
  },
});
export default styles;
