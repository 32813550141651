import Ionicons from '@expo/vector-icons/Ionicons';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Pressable } from 'react-native';
import styles from './style';

const PhoneNumberReveal = ({ revealEye, number, onEyePress }) => (
  <View style={styles.container}>
    <Pressable testID={revealEye ? 'eye-icon' : 'eye-off-icon'} onPress={onEyePress}>
      <Ionicons name={revealEye ? 'eye' : 'eye-off'} style={styles.eyeIcon} />
    </Pressable>

    <Text variant="md-normal" style={styles.phoneNumberText} testID="phone-number-text">
      {number}
    </Text>
  </View>
);

PhoneNumberReveal.propTypes = {
  revealEye: PropTypes.bool,
  number: PropTypes.string,
  onEyePress: PropTypes.func,
};

PhoneNumberReveal.defaultProps = {
  revealEye: false,
  number: '',
  onEyePress: () => {},
};

export default PhoneNumberReveal;
