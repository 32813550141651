import ToolTipPopOver from '@atoms/ToolTipPopOver';
import { Tooltip } from '@rneui/base';
import { scale } from '@utils/normalize';
import PropTypes from 'prop-types';
import React from 'react';
import { Dimensions } from 'react-native';
import logCustomTooltip from './analyticsCustomTooltip';
import styles from './styles';

const ControlledTooltip = props => {
  const [open, setOpen] = React.useState(false);
  const { translateString, children, logParams } = props;

  return (
    <Tooltip
      containerStyle={styles.tooltipContainerStyle}
      width={Dimensions.get('screen').width - scale(24)}
      popover={<ToolTipPopOver setOpen={setOpen} translateString={translateString} />}
      skipAndroidStatusBar
      withPointer={false}
      visible={open}
      closeOnlyOnBackdropPress
      onOpen={() => {
        if (open) return;
        logCustomTooltip(logParams);
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      {...props}>
      {children}
    </Tooltip>
  );
};

ControlledTooltip.propTypes = {
  translateString: PropTypes.string,
  children: PropTypes.element,
  logParams: PropTypes.shape({}),
};
ControlledTooltip.defaultProps = {
  translateString: '',
  children: null,
  logParams: {},
};

export default React.memo(ControlledTooltip);
