import Info from '@expo/vector-icons/MaterialIcons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './style';

const InfoTextComponent = ({
  infoText,
  iconColor,
  containerStyle,
  customTextStyle,
  iconContainer,
  size = 20,
  iconName = 'info-outline',
  show,
  textBucket,
  variant,
}) => {
  function getText(txtArr) {
    return (
      <View flex={1}>
        {txtArr.map((t, i) => (
          <View style={appStyles.flexRow}>
            <Text variant={variant} style={styles.textContainer}>{`${i + 1}.`}</Text>
            <Text variant={variant} style={[styles.txtAdj, customTextStyle]}>{`${t}`}</Text>
          </View>
        ))}
      </View>
    );
  }

  function shouldShowText(txt, txtArr) {
    if (txtArr?.length > 0) return getText(txtArr);

    return (
      <Text testID="txtAtm" numberOfLines={3} variant={variant} style={customTextStyle}>
        {txt}
      </Text>
    );
  }
  if (show)
    return (
      <View style={[styles.mainContainer, containerStyle]}>
        <View style={iconContainer}>
          <Info name={iconName} color={iconColor || colors.secondary.orchid} size={size} />
        </View>

        {shouldShowText(infoText, textBucket)}
      </View>
    );

  return null;
};

InfoTextComponent.propTypes = {
  infoText: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  containerStyle: PropTypes.shape({}),
  customTextStyle: PropTypes.shape({}),
  iconContainer: PropTypes.shape({}),
  size: PropTypes.number,
  iconName: PropTypes.string,
  show: PropTypes.bool,
  textBucket: PropTypes.arrayOf([]),
  variant: PropTypes.string,
};

InfoTextComponent.defaultProps = {
  containerStyle: {},
  customTextStyle: {},
  iconContainer: {},
  size: 20,
  iconName: 'info-outline',
  show: false,
  textBucket: [],
  variant: 'xsm-bold',
};

export default InfoTextComponent;
