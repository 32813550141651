import { fetchTxnSummary } from '@api/useQueryCalls';
import ControlledTooltip from '@atoms/CustomToolTip';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import { MaterialIcons } from '@expo/vector-icons';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { isNotPoints } from '@utils/utils';
import { Text, useToast } from 'native-base';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styles from './styles';

const TransactionSummary = () => {
  const { selectedTxnAccount, selectedPCId, setInitialData } = getStore();
  const IS_NOT_POINTS = isNotPoints(selectedTxnAccount);
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  const {
    isError,
    error,
    isLoading,
    refetch: getSummary,
    data: txnSummaryData,
  } = fetchTxnSummary({ accountNumber: selectedTxnAccount?.id ?? 0, paymentCycleId: selectedPCId });

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) getSummary();
  }, [isFocused, selectedPCId, selectedTxnAccount]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  if (isError || isLoading || !selectedTxnAccount) return null;

  const e = AMPLITUDE_CONSTANTS.TransactionPage.tooltip_clicked;

  return (
    <View style={styles.container}>
      <View style={styles.innerRow}>
        <RemoteImage height={scale(15)} width={scale(15)} uri={selectedTxnAccount.icon} />
        <Text testID="accountType" variant="sm-normal" style={styles.displayName}>
          {selectedTxnAccount.displayName}
        </Text>

        <ControlledTooltip
          translateString={`transactionSummary.total.${selectedTxnAccount?.accountTypeId}`}
          logParams={{
            [e.attributes.source]: 'balance_transaction_page',
            [e.attributes.section]: AMPLITUDE_EVENT_SOURCES.transaction_page,
          }}>
          <MaterialIcons style={styles.helpIcon} name="help" size={scale(18)} color={colors.secondary.orchid} />
        </ControlledTooltip>
      </View>

      <View style={styles.centeredRow}>
        <TextCurrency
          testID="balanceText"
          showCurrencySymbol={IS_NOT_POINTS}
          amountCurrency={txnSummaryData.balance}
          variant="xxl-bold"
          style={styles.balanceText}
          roundOff
        />
      </View>

      <View style={styles.transactionRow}>
        <View style={styles.flexContainer}>
          <View style={styles.centeredContainer}>
            <TextCurrency
              testID="incomeText"
              showCurrencySymbol={IS_NOT_POINTS}
              amountCurrency={txnSummaryData.totalCredit}
              variant="md-bold"
              style={styles.transactionText}
              color={colors.neutral.darkMist}
              roundOff
            />
          </View>
          <View style={styles.rowWithIcons}>
            <MaterialIcons
              name="arrow-right-alt"
              style={styles.arrowIcon}
              size={scale(17)}
              color={colors.success.cactus}
            />
            <Text
              testID="income"
              variant="xsm-normal"
              style={styles.transactionDescription}
              color={colors.neutral.darkMist}>
              {t('transactionSummary.totalIncome')}
            </Text>
            <ControlledTooltip
              logParams={{
                [e.attributes.source]: 'total_income_transaction_page',
                [e.attributes.section]: 'transaction_page',
              }}
              translateString={`transactionSummary.income.${selectedTxnAccount.accountTypeId}`}>
              <MaterialIcons name="help" size={scale(18)} color={colors.secondary.orchid} />
            </ControlledTooltip>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.flexContainer}>
          <View style={styles.centeredContainer}>
            <TextCurrency
              testID="expenseText"
              showCurrencySymbol={IS_NOT_POINTS}
              amountCurrency={txnSummaryData.totalDebit}
              variant="md-bold"
              style={styles.transactionText}
              color={colors.neutral.darkMist}
              roundOff
            />
          </View>
          <View style={styles.rowWithIcons}>
            <MaterialIcons
              name="arrow-right-alt"
              style={[styles.arrowIcon, styles.flipArrow]}
              size={scale(17)}
              color={colors.error.rose}
            />
            <Text
              testID="expense"
              variant="xsm-normal"
              style={styles.transactionDescription}
              color={colors.neutral.darkMist}>
              {t('transactionSummary.totalExpenses')}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default TransactionSummary;
