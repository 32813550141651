import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { View, Icon } from 'native-base';
import React from 'react';
import styles from './styles';

function ArrowDown() {
  return (
    <View style={styles.arrowStyle}>
      <Icon as={AntDesign} name="down" color={colors.neutral.charcoal} />
    </View>
  );
}

ArrowDown.propTypes = {};
ArrowDown.defaultProps = {};
export default ArrowDown;
