import CustomReasonInput from '@molecules/CustomReasonInput';
import React from 'react';

export default function shouldShowCustomReasons(isEditReasons, setCustomReasonText, source) {
  if (!isEditReasons) return <CustomReasonInput setCustomReasonText={setCustomReasonText} source={source} />;
  return null;
}

// export default function renderReasonsList(
//   reasons,
//   setReasonsDisplay,
//   setDisableContinue,
//   isEditReasons,
//   setCustomReasonText,
// ) {
//   return ({ item }) => (
//     <View behavior="position">
//       <View style={styles.reasonGrid}>
//         {item.map(reasonItem => (
//           <ReasonsSwitch
//             key={reasonItem.id}
//             reason={reasonItem}
//             allReasons={reasons}
//             setSelectedReason={setReasonsDisplay}
//             setDisableContinue={setDisableContinue}
//           />
//         ))}
//       </View>
//       {shouldShowCustomReasons(isEditReasons, setCustomReasonText)}
//     </View>
//   );
// }
