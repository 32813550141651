import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  searchContainer: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    borderRadius: 8,
    borderWidth: 1,
    marginHorizontal: 16,
    marginVertical: 16,
  },
  searchIcon: {
    marginLeft: 8,
    marginRight: 16,
  },
  txtInputSearch: {
    flex: 1,
    fontFamily: regularFont,
    fontSize: scale(14),
    lineHeight: scale(21),
    marginLeft: 16,
    outlineStyle: 'none',
    paddingHorizontal: 16,
    paddingVertical: 13,
  },
});

export default styles;
