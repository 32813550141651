import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  iconStyle: {
    alignSelf: 'center',
    color: colors.secondary.orchid,
    fontSize: scale(18),
    marginLeft: scale(4),
  },
  lihatButtonContainer: {
    alignItems: 'center',
    borderColor: colors.secondary.softOrchid,
    borderRadius: scale(12),
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: scale(16),
    marginTop: scale(20),
    paddingVertical: scale(8),
  },
  lihatTextStyle: { color: colors.secondary.orchid },
});

export default styles;
