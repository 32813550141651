import colors from '@theme/colors';
import { maskPhoneNumber } from '@utils/utils';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function MaskedPhoneNumber(props) {
  const { mobileNumber, commonColor } = props;
  return (
    <View marginLeft="11px" marginTop="4px">
      <Text testID="txtMaskedPhone" variant="sm-bold" color={commonColor}>
        {maskPhoneNumber(mobileNumber)}
      </Text>
    </View>
  );
}

MaskedPhoneNumber.propTypes = {
  mobileNumber: PropTypes.string,
  commonColor: PropTypes.string,
};
MaskedPhoneNumber.defaultProps = {
  mobileNumber: '',
  commonColor: colors.neutral.charcoal,
};

export default MaskedPhoneNumber;
