import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-native-modal';
import styles from './styles';

const MyModal = ({
  isVisible,
  onPress,
  children,
  containerStyle,
  hasBackdrop,
  // eslint-disable-next-line react/prop-types
  customBackdrop,
  backdropOpacity,
  onModalHide,
  ...props
}) => (
  <Modal
    {...props}
    testID="modalBackdrop"
    onSwipeComplete={onPress}
    onModalHide={onModalHide}
    hasBackdrop={hasBackdrop}
    useNativeDriver
    animationIn="fadeIn"
    animationOut="fadeOut"
    hideModalContentWhileAnimating
    onBackButtonPress={onPress}
    customBackdrop={customBackdrop}
    backdropOpacity={backdropOpacity}
    backdropTransitionOutTiming={0}
    onBackdropPress={onPress}
    isVisible={isVisible}
    swipeDirection="down"
    style={styles.modalParent(containerStyle)}>
    {children}
  </Modal>
);

MyModal.propTypes = {
  isVisible: PropTypes.bool,
  onPress: PropTypes.func,
  children: PropTypes.element,
  containerStyle: PropTypes.shape({}),
  hasBackdrop: PropTypes.bool,

  backdropOpacity: PropTypes.number,
  onModalHide: PropTypes.func,
};
MyModal.defaultProps = {
  isVisible: false,
  onPress: () => {},
  children: {},
  containerStyle: {},
  hasBackdrop: true,

  backdropOpacity: 0.7,
  onModalHide: () => {},
};

export default MyModal;
