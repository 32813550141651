import ReasonsSwitch from '@atoms/ReasonsSwtich';
import ScrollLeft from '@atoms/ScrollLeft';
import ScrollRight from '@atoms/ScrollRight';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { chunk } from 'lodash';
import { View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Dimensions } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import shouldShowCustomReasons from './reasonsListUtils';
import styles from './styles';

const { width } = Dimensions.get('window');

export function onUserSwipeHandler(source, validatedReferralCode, amplitudeAccountName, setActivePage) {
  return index => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.referral_code]: validatedReferralCode,
      dynamic_account_name: amplitudeAccountName,
    });
    setActivePage(index);
  };
}

function ReasonsList(props) {
  const {
    reasons,
    setReasonsDisplay,
    setDisableContinue,
    setCustomReasonText,
    isEditReasons,
    source,
    transaction_category_id,
  } = props;
  const { selectedAccount, validatedReferralCode } = getStore();
  const { amplitudeAccountName } = selectedAccount;
  const chunkedReasons = chunk(reasons, 12);
  const [activePage, setActivePage] = useState(0);
  const reasonsCarousel = useRef();

  return (
    <View>
      <View style={styles.scrollButtons}>
        <ScrollLeft
          activePage={activePage}
          chunkedReasons={chunkedReasons}
          reasonsCarousel={reasonsCarousel}
          setActivePage={setActivePage}
        />
        <View />
        <ScrollRight
          activePage={activePage}
          chunkedReasons={chunkedReasons}
          reasonsCarousel={reasonsCarousel}
          setActivePage={setActivePage}
        />
      </View>

      <Carousel
        testID="reasonsCarousel"
        data={chunkedReasons}
        ref={reasonsCarousel}
        itemWidth={width}
        sliderWidth={width}
        contentContainerStyle={styles.carouselContainer}
        onSnapToItem={onUserSwipeHandler(source, validatedReferralCode, amplitudeAccountName, setActivePage)}
        enableSnap
        enableMomentum
        renderItem={({ item }) => (
          <View behavior="position">
            <View style={styles.reasonGrid}>
              {item.map(reasonItem => (
                <ReasonsSwitch
                  key={reasonItem.id}
                  reason={reasonItem}
                  allReasons={reasons}
                  setSelectedReason={setReasonsDisplay}
                  setDisableContinue={setDisableContinue}
                  source={source}
                  transaction_category_id={transaction_category_id}
                />
              ))}
            </View>
            {shouldShowCustomReasons(isEditReasons, setCustomReasonText, source)}
          </View>
        )}
      />

      <Pagination
        dotColor={colors.secondary.orchid}
        inactiveDotColor={colors.disabled.smoke}
        dotsLength={chunkedReasons.length}
        activeDotIndex={activePage}
        containerStyle={[appStyles.paddingBottom(0), appStyles.paddingTop(0), appStyles.marginVertical(16)]}
        tappableDots
      />
    </View>
  );
}

ReasonsList.propTypes = {
  source: PropTypes.string,
  transaction_category_id: PropTypes.string,
  reasons: PropTypes.arrayOf({}),
  setReasonsDisplay: PropTypes.func,
  setDisableContinue: PropTypes.func,
  setCustomReasonText: PropTypes.func,
  isEditReasons: PropTypes.bool,
};
ReasonsList.defaultProps = {
  source: '',
  transaction_category_id: '',
  reasons: [],
  setReasonsDisplay: () => {},
  setDisableContinue: () => {},
  setCustomReasonText: () => {},
  isEditReasons: false,
};
export default ReasonsList;
