import SkeletonComponent from '@atoms/SkeletonComponent';
import appStyles from '@root/appStyles';
import React from 'react';
import { View } from 'react-native';

function ShimmerMultiColumnNominal() {
  return (
    <View>
      <View flexDirection="row">
        <SkeletonComponent style={appStyles.flex1} />
        <SkeletonComponent style={appStyles.flex1} />
      </View>
      <View flexDirection="row">
        <SkeletonComponent style={appStyles.flex1} />
        <SkeletonComponent style={appStyles.flex1} />
      </View>
      <View flexDirection="row">
        <SkeletonComponent style={appStyles.flex1} />
        <SkeletonComponent style={appStyles.flex1} />
      </View>
      <View flexDirection="row">
        <SkeletonComponent style={appStyles.flex1} />
        <SkeletonComponent style={appStyles.flex1} />
      </View>
    </View>
  );
}

ShimmerMultiColumnNominal.propTypes = {};
ShimmerMultiColumnNominal.defaultProps = {};
export default ShimmerMultiColumnNominal;
