import { refreshTokenHandler } from '@api/networkUtils';
import { forceLogout } from '@organisms/ProfileTab/profileUtils';
import { useNavigation } from '@react-navigation/native';
import * as Sentry from '@sentry/react';
import { getStore } from '@store/storeUtils';
import { useQueryClient } from '@tanstack/react-query';
import secureStore from '@utils/secureStore';
// eslint-disable-next-line import/no-extraneous-dependencies
import { decode } from 'base-64';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

global.atob = decode;

function RefeshSystem({ children }) {
  const [showLoader, setShowLoader] = useState(true);
  const { setInitialData, shouldForceLogout } = getStore();
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const handleRefreshMechanism = async () => {
    try {
      const accessToken = await secureStore.getItemAsync('accessToken');

      const refreshToken = await secureStore.getItemAsync('refreshToken');

      if (accessToken) {
        const decodedAccessToken = jwtDecode(accessToken);
        const decodedRefreshToken = jwtDecode(refreshToken);

        const accessTokenExpiryAt = dayjs(decodedAccessToken.exp * 1000);

        const refreshTokenExpiryAt = dayjs(decodedRefreshToken.exp * 1000);

        const accessExpired = accessTokenExpiryAt.isBefore(dayjs());
        const refreshExpired = refreshTokenExpiryAt.isBefore(dayjs());

        if (accessExpired) {
          Sentry.addBreadcrumb({ category: 'info', message: `Refresh System - Access Token Expired`, level: 'info' });
          if (refreshExpired) {
            Sentry.addBreadcrumb({
              category: 'info',
              message: `Refresh System - Refresh Token Expired`,
              level: 'info',
            });
            await forceLogout(true, {}, navigation, setInitialData, queryClient);
          } else {
            Sentry.addBreadcrumb({
              category: 'info',
              message: `Refresh System - Refreshing User`,
              level: 'info',
            });
            await refreshTokenHandler();
          }
        }
      }
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'info',
        message: `Refresh System - Force Log out User`,
        level: 'info',
      });
      await forceLogout(true, {}, navigation, setInitialData, queryClient);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    handleRefreshMechanism();
  }, []);
  useEffect(() => {
    if (shouldForceLogout) forceLogout(true, {}, navigation, setInitialData, queryClient);
  }, [shouldForceLogout]);

  if (showLoader) return <View testID="loading" />;
  return children;
}

RefeshSystem.propTypes = {
  children: PropTypes.node.isRequired,
};

RefeshSystem.defaultProps = {};

export default React.memo(RefeshSystem);
