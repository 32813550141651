import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const justifyContentTxt = 'space-between';

const styles = StyleSheet.create({
  absoluteAdj: { marginTop: scale(25) },
  absoluteContainer: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(12),
    elevation: 5,
    marginHorizontal: scale(15),
  },

  bannerTextStyle: {
    marginLeft: scale(8),
  },
  collapsibleContainer: {
    borderBottomWidth: 1,
    borderColor: colors.neutral.mist,
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: justifyContentTxt,
    marginTop: scale(11),
    padding: scale(15),
  },
  collapsibleSubValueRow: { flexDirection: 'row' },
  container: {
    backgroundColor: colors.secondary.orchid,
    flex: 1,
  },
  containerStyle: { backgroundColor: colors.transparent },

  ewaBalance: {
    alignSelf: 'center',
    color: colors.success.cactus,
    fontSize: scale(23),
    marginVertical: scale(7),
  },
  iconStyle: { alignSelf: 'center', marginRight: scale(6) },
  innerRowStyle: {
    backgroundColor: colors.neutral.mist,
    flexDirection: 'row',
    justifyContent: justifyContentTxt,
    paddingHorizontal: scale(23),
    paddingVertical: scale(15),
  },
  innterRowValue: { fontSize: scale(12), marginRight: scale(11) },

  lockPeriodBannerIconContainerStyle: { justifyContent: 'center' },
  lockPeriodBannerTextStyle: { paddingLeft: scale(8), paddingRight: scale(8) },
  lockperiodBannerContainerStyle: {
    alignItems: 'center',
    backgroundColor: colors.error.softRose,
    borderRadius: scale(8),
    marginHorizontal: scale(15),
    marginTop: scale(11),
    paddingHorizontal: scale(12),
  },
  modalIconStyle: { alignSelf: 'center', marginRight: scale(6) },
  parentContainer: {
    backgroundColor: colors.neutral.cotton,
    flexGrow: 1,
    paddingTop: 0,
  },
  parentStyle: {
    backgroundColor: colors.neutral.cotton,
    height: '100%',
  },
  periodContainer: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: scale(8),
    marginHorizontal: scale(15),
    marginTop: scale(11),
    paddingHorizontal: scale(12),
  },
  tooltipBannerContainerStyle: {
    backgroundColor: colors.neutral.mist,
    marginHorizontal: scale(15),
    marginTop: scale(11),
  },

  tooltipBannerIconContainerStyle: { justifyContent: 'center' },

  tooltipBannerTextStyle: { fontSize: scale(11.5), marginLeft: scale(10) },
  topCotainerRow: { alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: scale(12) },
  totalExpenseValue: { fontSize: scale(12), marginRight: scale(11) },
  totalExpsenseRowContainer: {
    flexDirection: 'row',
    justifyContent: justifyContentTxt,
    paddingHorizontal: scale(15),
    paddingRight: scale(23),
    paddingVertical: scale(15),
  },
});

export default styles;
