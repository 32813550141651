import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase } from '@utils/utils';

export function logCloseHandler() {
  const e = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pin_failed_modal_bankaccount',
  });
}

export function logThreeTry() {
  const e = AMPLITUDE_CONSTANTS.securityPin.reset_pin_later;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pin_failed_modal_bankaccount',
  });
}

export function logThreeTryVerify() {
  const e = AMPLITUDE_CONSTANTS.securityPin.reset_pin_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pin_failed_modal_bankaccount',
  });
}

export function logResetPinLater() {
  const e = AMPLITUDE_CONSTANTS.securityPin.reset_pin_later;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pin_failed_modal_phonenumber',
  });
}
export function logXClick() {
  const e = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pin_failed_modal_phonenumber',
  });
}

export function logEventErrorCallBack(pageSource, errMessage) {
  const e = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_error;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: pageSource,
    [e.attributes.error_message]: errMessage,
  });
}

export function logBankNameClicked(source) {
  const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_name_clicked;
  const { name } = e;
  AmplitudeHelper.logEvent(name, { source });
}

export function logNickNameFilled(source) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.bankRegistration.nickname_filled.name, { source });
  };
}

export function logNickNameClicked(source) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.bankRegistration.nickname_clicked.name, { source });
  };
}

export function logBeneficiaryNameFilled(source) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.bankRegistration.beneficiary_name_filled.name, { source });
  };
}

export function logBeneficiaryNameClicked(source) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.bankRegistration.beneficiary_name_clicked.name, { source });
  };
}

export function logAccountNumberFilled(source) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.bankRegistration.account_number_filled.name, { source });
  };
}

export function logAccountNumberClicked(source) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.bankRegistration.account_number_clicked.name, { source });
  };
}

export function logBankAccountAddPress(source, values) {
  const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_saved;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.source]: source,
    [attributes.account_number]: values?.accountNumber,
    [attributes.bank_name]: formatUnderScoreLowerCase(values?.selectedBank?.bankName),
    [attributes.beneficiary_name]: formatUnderScoreLowerCase(values?.accountName),
    [attributes.nickname]: formatUnderScoreLowerCase(values?.accountNickName),
  });
}
