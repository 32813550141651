import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bottomView: { alignSelf: 'stretch', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, marginHorizontal: 0 },
  successImage: { alignSelf: 'center', marginTop: 44 },
  successReportParent: { alignItems: 'center' },
  txtSuccess: status => ({
    color: status === 'PENDING' ? colors.warning.sunflower : colors.success.cactus,
    marginTop: 24,
  }),
  txtSuccessDetails: { color: colors.neutral.charcoal, marginTop: 11 },
  txtTarikGaji: { color: colors.neutral.darkMist, marginTop: 10 },
  txtTransferAmount: { color: colors.secondary.orchid, marginTop: 10 },
});
export default styles;
