import { LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@root/constants';
import { returnBottomSheetContent } from '@utils/utils';
import { logCloseModal, logLogoutClicked } from './analyticsLogout';

export default function bottomSheetHandlers(setInfoModal) {
  const onOpenLogoutModal = () => {
    logLogoutClicked();
    return setInfoModal(prevState => ({
      ...prevState,
      visible: true,
      fromLogout: false,
      data: returnBottomSheetContent(
        LOGIN_INFO_SHEET_KEYS.LOGOUT,
        LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.LOGOUT],
      ),
      errorCode: LOGIN_INFO_SHEET_KEYS.LOGOUT,
    }));
  };

  const onPressPrimaryButton = () => setInfoModal(prevState => ({ ...prevState, fromLogout: true, visible: false }));

  const onCloseModalHandler = type => {
    setInfoModal(prevState => ({ ...prevState, fromLogout: false, visible: false }));
    logCloseModal(type);
  };

  const onPressSecondaryBottomSheet = () => {
    onCloseModalHandler('cancel');
  };

  return { onOpenLogoutModal, onPressPrimaryButton, onPressSecondaryBottomSheet, onCloseModalHandler };
}
