import useFeatureFlag from '@customHooks/useFeatureFlag';
import { AntDesign } from '@expo/vector-icons';
import BottomSheet from '@molecules/LoginInfoSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import {
  BOTTONSHEET_PHONE_CONTENT,
  BOTTONSHEET_PHONE_ENROLLMENT,
  FEATURE_FLAGS,
  addPhoneTemplate1,
  addPhoneTemplate2,
  addPhoneTemplate3,
} from '@root/constants';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { filterRejected, returnBottomSheetContent } from '@utils/utils';
import { Button, View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import phoneWithPinHandlers from './pinWithAddPhoneUtils';
import styles from './styles';

function PinWithAddPhone(props) {
  const { type, listData, isTransact, transactionCategoryId } = props;
  const { t } = useTranslation('common');
  const navigation = useNavigation();
  const [isFeatureAvailale] = useFeatureFlag({ featureName: [FEATURE_FLAGS.SECURITY_PIN] });
  const { pinRegister } = getStore();
  const initalState = {
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
      BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE],
    ),
    errorCode: BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
    currentId: 0,
    selectedNumber: 0,
    selectedName: '',
    buttonPosition: '',
  };
  const [infoModal, setInfoModal] = useState(initalState);
  const addPhoneText = t('phoneTransfer.phoneAddBtnText');

  const eventState = filterRejected(listData, isTransact).length === 0 ? 'empty' : 'registered';

  const eventSource = isTransact ? AMPLITUDE_EVENT_SOURCES.select_phone_page : AMPLITUDE_EVENT_SOURCES.phone_list_page;

  const { onPressAddPhoneNo, onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onBottomSheetCloseHandler } =
    phoneWithPinHandlers({
      listData,
      navigation,
      setInfoModal,
      isFeatureAvailale,
      pinRegister,
      infoModal,
      initalState,
      eventState,
      eventSource,
      transactionCategoryId,
    });

  if (type === addPhoneTemplate1) {
    return (
      <>
        <Button
          variant="unstyled"
          testID="btnAddPhoneScreen"
          onPress={() => onPressAddPhoneNo('bottom')}
          style={styles.btnAddPhoneStyles}>
          <View style={styles.btnAddPhoneTextContainer}>
            <Text variant="xsm-bold" style={appStyles.marginRight(8)}>
              {addPhoneText}
            </Text>
            <AntDesign size={16} name="plus" />
          </View>
        </Button>
        <BottomSheet
          onPressPrimary={onPressPrimaryBottomSHeet}
          onPressSecondary={onPressSecondaryBottomSheet}
          loginInfo={infoModal}
          hasTwoButtons={infoModal.data.hasTwoButtons}
          onClose={onBottomSheetCloseHandler}
        />
      </>
    );
  }
  if (type === addPhoneTemplate2) {
    return (
      <>
        <Button
          variant="solid"
          testID="btnNavigateToAddPhone"
          onPress={() => onPressAddPhoneNo('bottom')}
          style={styles.btnAddScreenStyles}>
          <View style={styles.addPhoneTextContainer}>
            <Text variant="md-bold" style={styles.addPhoneText}>
              {addPhoneText}
            </Text>
          </View>
        </Button>
        <BottomSheet
          onPressPrimary={onPressPrimaryBottomSHeet}
          onPressSecondary={onPressSecondaryBottomSheet}
          loginInfo={infoModal}
          hasTwoButtons={infoModal.data.hasTwoButtons}
          onClose={onBottomSheetCloseHandler}
        />
      </>
    );
  }
  if (type === addPhoneTemplate3) {
    return (
      <>
        <Text
          variant="sm-bold"
          color={colors.secondary.orchid}
          underline
          onPress={() => onPressAddPhoneNo('top_right')}>
          {addPhoneText}
        </Text>
        <BottomSheet
          onPressPrimary={onPressPrimaryBottomSHeet}
          onPressSecondary={onPressSecondaryBottomSheet}
          loginInfo={infoModal}
          hasTwoButtons={infoModal.data.hasTwoButtons}
          onClose={onBottomSheetCloseHandler}
        />
      </>
    );
  }
}

PinWithAddPhone.propTypes = {
  type: PropTypes.string,
  listData: PropTypes.arrayOf({}),
  isTransact: PropTypes.bool,
  transactionCategoryId: PropTypes.string,
};
PinWithAddPhone.defaultProps = {
  type: 'template1',
  listData: [],
  isTransact: false,
  transactionCategoryId: '',
};
export default PinWithAddPhone;
