import { Feather } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

function ScrollRight(props) {
  const { activePage, chunkedReasons, reasonsCarousel, setActivePage } = props;
  const { t } = useTranslation('common');
  if (activePage >= 0 && chunkedReasons.length > 0 && activePage < chunkedReasons.length - 1) {
    return (
      <TouchableOpacity
        testID="btnScrollRight"
        style={styles.rightStyles}
        onPress={() => {
          reasonsCarousel.current.snapToNext();
          setActivePage(activePage + 1);
        }}>
        <Text variant="sm-bold" color={colors.secondary.orchid}>
          {t('reasonsModal.next')}
        </Text>
        <Feather name="chevrons-right" size={24} color={colors.secondary.orchid} />
      </TouchableOpacity>
    );
  }
  return null;
}

ScrollRight.propTypes = {
  activePage: PropTypes.number,
  chunkedReasons: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  reasonsCarousel: PropTypes.shape({
    current: PropTypes.shape({ snapToNext: PropTypes.func }),
  }),
  setActivePage: PropTypes.func,
};
ScrollRight.defaultProps = {
  activePage: 0,
  chunkedReasons: [[]],
  reasonsCarousel: {
    current: {
      snapToNext: () => {},
    },
  },
  setActivePage: () => {},
};
export default ScrollRight;
