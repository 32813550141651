import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  hrBadgeContainer: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: scale(12),
    justifyContent: 'center',
    marginLeft: scale(10),
    paddingHorizontal: scale(8),
    paddingVertical: scale(5),
  },
  hrTitleStyle: {
    color: colors.secondary.darkOrchid,
  },
});

export default styles;
