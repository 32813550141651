import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  infoLeftSubContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '34%',
  },
  infoOuterContainer: {
    flexDirection: 'row',
  },
  infoRightSubContainer: {
    justifyContent: 'center',
    paddingVertical: scale(18),
    width: '66%',
  },
  infoTextStyle: { paddingRight: scale(12) },
  linkStyle: { marginTop: scale(5) },
  linkTextStyle: {
    color: colors.secondary.orchid,
    textDecorationLine: 'underline',
  },
  logoImageStyle: { height: scale(90), width: scale(130) },
});

export default styles;
