import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase, getAmplitudeKeyValue } from '@utils/utils';

export function logPageBack(productConfig, source, validatedReferralCode) {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.BillPayment.userClickedBack.name, {
    [AMPLITUDE_CONSTANTS.BillPayment.userClickedBack.attributes.transaction_category_id]:
      productConfig.transaction_category_id,
    [AMPLITUDE_CONSTANTS.BillPayment.userClickedBack.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.BillPayment.userClickedBack.attributes.referral_code]: validatedReferralCode,
  });
}

export function logPageLaunch(productConfig, source, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.ProductPage;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.transaction_category_id]: productConfig.transaction_category_id,
    [attributes.source]: source,
    [attributes.referral_code]: validatedReferralCode,
  });
}

export function logNominalsPress(selectedAccountNumber, item, productConfig, source, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
  const { attributes, name } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.account_number]: selectedAccountNumber,
    [attributes.product_price]: item?.nominal?.value ?? 0,
    [attributes.product_code]: item.code,
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
    [attributes.source]: source,
    [attributes.reason]: productConfig.reason,
    [attributes.reason_id]: productConfig.reasonId,
    [attributes.referral_code]: validatedReferralCode,
  });
}

export function logRequestBP(
  selectedAccountNumber,
  productConfig,
  item,
  source,
  validatedReferralCode,
  meAmplitudeContext,
  amplitudeAccountName,
) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.verifyingBillPaymentTransaction;

  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.account_number]: selectedAccountNumber,
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
    [attributes.product_code]: item?.code,
    [attributes.reason]: productConfig?.reason,
    [attributes.reason_id]: productConfig?.reasonId,
    [attributes.source]: source,
    [attributes.referral_code]: validatedReferralCode,
    [attributes.dynamic_account_name]: amplitudeAccountName,
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}

export function logErrorOnAnalytics(
  isBPReqError,
  selectedAccountNumber,
  productConfig,
  apiPayload,
  t,
  bpError,
  source,
  validatedReferralCode,
) {
  if (isBPReqError && bpError) {
    const e = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionFailed;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.account_number]: selectedAccountNumber,
      [attributes.transaction_category_id]: productConfig.transaction_category_id,
      [attributes.product_code]: apiPayload.productCode,
      [attributes.reason]: productConfig.reason,
      [attributes.reason_id]: productConfig.reasonId,
      [attributes.failure_message]: formatUnderScoreLowerCase(t(`errorCodes.${bpError[0]}`)),
      [attributes.source]: source,
      [attributes.referral_code]: validatedReferralCode,
    });
  }
}
