import colors from '@theme/colors';
import { boldFont, regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';

import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  closeStyle: { marginLeft: -20 },
  inActiveTabbarStyle: { color: colors.neutral.darkMist },
  indicatorContainerStyle: { paddingEnd: 20 },
  indicatorStyle: {
    backgroundColor: colors.primary.carnation,
    borderRadius: scale(7),
    height: '80%',
    marginBottom: scale(4),
    marginEnd: scale(30),
    marginStart: scale(5),
    width: '50%',
  },
  inputSearch: {
    color: colors.neutral.charcoal,
    fontFamily: regularFont,
    fontSize: scale(12),
    outlineStyle: 'none',
    paddingVertical: scale(5),
    width: '90%',
  },
  inputTextContainer: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.neutral.darkMist,
    borderRadius: scale(10),
    borderWidth: scale(1),
    flexDirection: 'row',

    justifyContent: 'space between',
    marginHorizontal: scale(16),
    paddingLeft: scale(8),
    paddingRight: scale(12),
  },
  labelStyle: {
    fontFamily: boldFont,
    fontSize: scale(13.5),
    margin: 0,
    padding: scale(10),
    textTransform: 'none',
  },
  linkStyle: { color: colors.secondary.orchid, textDecorationLine: 'underline' },
  mainContentStyle: {
    backgroundColor: colors.neutral.cotton,
    paddingTop: scale(16),
  },
  scrollableContainerStyle: { backgroundColor: colors.neutral.cotton, flexGrow: 1, paddingBottom: scale(150) },
  tabBarStyle: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(5),
    elevation: 0,
    justifyContent: 'center',
    marginBottom: scale(10),
    marginHorizontal: scale(13),
    paddingVertical: 0,
  },
  tabStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: scale(45),
    padding: 0,
  },
  tabViewContainer: { height: '100%', marginTop: scale(16) },

  termsParentLink: {
    color: colors.neutral.charcoal,
    marginVertical: scale(16),
    textAlign: 'center',
  },
});

export default styles;
