import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  adjTooltip: { marginVertical: 4 },
  popOverContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 'auto',
    paddingHorizontal: scale(10),
    paddingVertical: scale(4),
  },
  textAndCloseParent: {
    flexDirection: 'row',
    flex: 1,
  },
});
export default styles;
