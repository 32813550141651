import { maskPhoneNumber } from '@utils/utils';

export function getMaskedOrUnmaskedData(maskData, bankAccountNo) {
  return maskData ? maskPhoneNumber(bankAccountNo) : bankAccountNo;
}

export function setMaskIconValue(maskData) {
  return maskData ? 'eye' : 'eye-off';
}

export function setIconValue(icon) {
  return icon === 'up' ? 'down' : 'up';
}

export function getIconState(expanded) {
  return expanded ? 'up' : 'down';
}

export function maskIconInit(maskData) {
  return maskData ? 'eye-off' : 'eye';
}
