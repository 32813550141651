import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import colors from '@theme/colors';
import { verticalScale } from '@utils/normalize';
import { Modal, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function ModalInactiveWAS(props) {
  const { closeModalWDStatus, showWDModal, modalHeader, imgStatusFile, wdModalText } = props;
  return (
    <Modal
      closeOnOverlayClick
      testID="modalWDStatus"
      sentry-label="modalWDStatus"
      onClose={closeModalWDStatus}
      isOpen={showWDModal}
      size="full">
      <Modal.Content
        marginTop="auto"
        bottom={0}
        position="absolute"
        backgroundColor={colors.neutral.cotton}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}>
        <ModalHeader headerTranslationString={modalHeader} />
        <ModalClose testID="modalClose" sentry-label="modalClose" onPress={closeModalWDStatus} />
        <View alignContent="center" alignItems="center" marginX="5" marginY={verticalScale(8)}>
          <View alignItems="center" alignContent="center">
            {imgStatusFile}
          </View>
          <Text variant="md-normal">{wdModalText}</Text>
        </View>
      </Modal.Content>
    </Modal>
  );
}

ModalInactiveWAS.propTypes = {
  closeModalWDStatus: PropTypes.func,
  showWDModal: PropTypes.bool,
  modalHeader: PropTypes.string,
  imgStatusFile: PropTypes.node,
  wdModalText: PropTypes.string,
};

ModalInactiveWAS.defaultProps = {
  closeModalWDStatus: () => {},
  showWDModal: false,
  modalHeader: '',
  imgStatusFile: null,
  wdModalText: '',
};

export default ModalInactiveWAS;
