import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logLihatPress(isPIC) {
  const e4 = AMPLITUDE_CONSTANTS.UserProfile.user_info_lihat_button_clicked;
  AmplitudeHelper.logEvent(e4.name, {
    [e4.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e4.attributes.influencer_role]: isPIC ? 'pic' : 'normal_user',
  });
}

export default logLihatPress;
