import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types'; // Import PropTypes
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const DescriptionRow = ({ desc }) => (
  <View style={styles.outerRow}>
    <Text variant="md-bold" color={colors.neutral.darkMist} onPress={() => {}} style={styles.bulletStyle}>
      {'\u2022'}
    </Text>
    <Text variant="md-normal" color={colors.neutral.darkMist} onPress={() => {}} style={styles.bulletStyleTwo}>
      {desc}
    </Text>
  </View>
);

DescriptionRow.propTypes = {
  desc: PropTypes.string.isRequired, // Specify the prop type and mark it as required
};

export default DescriptionRow;
