import { verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  categoryContainer: { flex: 1, marginBottom: verticalScale(24) },

  productContainer: {
    columnGap: verticalScale(24),
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
export default styles;
