import GGTxnReferralBanner from '@atoms/GGTxnReferralBanner';
import TextCurrency from '@atoms/TextCurrency';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { SVG_ICONS } from '@utils/SvgIcons';
import { goBackToHome } from '@utils/utils';
import { View, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native';
import styles from './styles';

function GGBPSuccess(props) {
  const navigation = useNavigation();
  const { setValidatedReferralCode, setReferralValidated, headerText } = getStore();

  const { t } = useTranslation('common');
  const { route } = props;
  const { params } = route;
  const { productApiData, bpReqData } = params;
  const { screenName } = productApiData;
  const { subtotal, productName } = bpReqData;

  useEffect(() => {
    // TODO replace with headerText
    navigation.setOptions({ title: headerText, headerBackVisible: false });
  }, []);
  const e = AMPLITUDE_CONSTANTS.interactionBanner.banner_clicked;
  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <View style={[appStyles.safeAreaView, { marginHorizontal: scale(16), backgroundColor: colors.neutral.cotton }]}>
        <View style={styles.successParent}>
          {SVG_ICONS.Clock}
          <Text
            testID="txnInProcess"
            variant="xl-bold"
            color={colors.warning.sunflower}
            style={appStyles.marginTop(16)}>
            {t('successPage.txInProcess')}
          </Text>
          <Text testID="txtPurchased" variant="lg-normal" style={appStyles.marginTop(12)}>
            {t('successPage.purchased')} {screenName}
          </Text>
          <TextCurrency
            style={appStyles.marginTop(12)}
            amountCurrency={subtotal}
            showCurrencySymbol
            color={colors.secondary.orchid}
            variant="xxl-bold"
            testID="txtCurrencyFormat"
          />
          <Text
            testID="txtProductName"
            variant="md-bold"
            color={colors.neutral.darkMist}
            style={appStyles.marginTop(12)}>
            {productName}
          </Text>
          <View testID="txtSuccess" style={styles.successInfo}>
            <Feather testID="txtInfoIcon" name="info" size={scale(24)} color={colors.secondary.orchid} />
            <Text
              testID="txTimeTaken"
              variant="xsm-bold"
              color={colors.neutral.charcoal}
              style={{ marginLeft: scale(8) }}>
              {t('successPage.txTimeTaken')}
            </Text>
          </View>
        </View>

        <GGTxnReferralBanner
          customStyles={styles.txnBannerStyle}
          logParams={{
            [e.attributes.transaction_category_id]: route?.params?.productConfig?.transaction_category_id,
            [e.attributes.reason]: route?.params?.productConfig?.reason,
            [e.attributes.reason_id]: route?.params?.productConfig?.reasonId,
            [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.success_page,
            [e.attributes.dynamic_account_name]: route?.params?.selectedBPAccountForConfirm?.amplitudeAccountName,
          }}
        />
        <Button
          testID="btnHome"
          style={appStyles.marginVertical(16)}
          onPress={() => {
            setValidatedReferralCode(null);
            setReferralValidated(false);
            goBackToHome(navigation);
          }}>
          {t('common.ok')}
        </Button>
      </View>
    </SafeAreaView>
  );
}

GGBPSuccess.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      productConfig: PropTypes.shape({
        transaction_category_id: PropTypes.string,
        reason: PropTypes.string,
        reasonId: PropTypes.string,
      }),
      selectedBPAccountForConfirm: PropTypes.shape({
        amplitudeAccountName: PropTypes.string,
      }),
      source: PropTypes.string,
      bpReqData: PropTypes.shape({
        subtotal: PropTypes.number,
        productName: PropTypes.string,
        productCode: PropTypes.string,
        fee: PropTypes.number,
      }),
      productApiData: PropTypes.shape({
        screenName: PropTypes.string,
      }),
    }),
  }),
};
GGBPSuccess.defaultProps = {
  route: {
    params: {
      productConfig: {
        transaction_category_id: '',
        reason: '',
        reasonId: '',
      },
      selectedBPAccountForConfirm: {
        amplitudeAccountName: '',
      },
      bpReqData: {
        subtotal: 0,
        productName: '',
        productCode: '',
        fee: 0,
      },
      productApiData: {
        screenName: '',
      },
    },
  },
};

export default GGBPSuccess;
