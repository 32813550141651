import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export function logRoleChoosen(employeeId, employerId) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.role_option_chosen;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.employee_id]: employeeId,
    [e.attributes.employer_id]: employerId,
  });
}

export function logisDeparmentChoose(employeeId, employerId) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.department_option_chosen;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.employee_id]: employeeId,
    [e.attributes.employer_id]: employerId,
  });
}
