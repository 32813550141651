import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  carouselContainer: { alignContent: 'center', marginHorizontal: 16 },

  reasonGrid: {
    alignContent: 'center',
    alignSelf: 'stretch',
    columnGap: 14,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    rowGap: 16,
  },
  scrollButtons: { flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 16, marginVertical: 16 },
});
export default styles;
