import navigationConstants from '@navigation/navigationConstants';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export default function profilePinActiveBottomSheetHandler(setInfoModal, infoModal, navigation) {
  const onPrimaryPress = () => {
    setInfoModal({
      ...infoModal,
      visible: false,
    });

    if (infoModal.errorCode !== 'GG_01_FALLBACK') {
      navigation.navigate(navigationConstants.GG_ADD_PHONE_SCREEN, {
        isTransact: false,
        source: AMPLITUDE_EVENT_SOURCES.others_page,
      });
    }
  };
  const onPressSecondaryHandler = () => {
    setInfoModal({
      ...infoModal,
      visible: false,
    });
    navigation.navigate(navigationConstants.GG_ADD_BANK_SCREEN, {
      fromTransfer: false,
      source: AMPLITUDE_EVENT_SOURCES.others_page,
    });
  };
  const onCloseModal = () => {
    setInfoModal({
      ...infoModal,
      visible: false,
    });
  };
  return { onPrimaryPress, onPressSecondaryHandler, onCloseModal };
}
