import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.secondary.softOrchid,
    marginTop: scale(16),
    paddingVertical: scale(5),
    width: '100%',
  },
  titleText: {
    color: colors.neutral.darkMist,
    paddingLeft: scale(16),
  },
});

export default styles;
