import { AntDesign } from '@expo/vector-icons';
import React from 'react';

function RightArrow() {
  return <AntDesign testID="btnAccordianImage" name="right" size={16} color="black" />;
}

RightArrow.propTypes = {};
RightArrow.defaultProps = {};

export default RightArrow;
