export const getReferralEncryption = code =>
  code
    .split('')
    .map(item => item.charCodeAt(0))
    .join('');

export const calculateTotalPoints = data => {
  let totalPoints = 0;

  data.employeesReferred.forEach(employee => {
    if (employee.tnxPoint !== null) {
      totalPoints += employee.tnxPoint;
    }

    if (employee.loginPoint !== null) {
      totalPoints += employee.loginPoint;
    }
  });

  return totalPoints;
};
