import AccountSelector from '@molecules/AccountSelector';
import Header from '@molecules/Header';
import PaymentCycleSelector from '@molecules/PaymentCycleSelector';
import TransactionList from '@molecules/TransactionList';
import TransactionSummary from '@molecules/TransactionSummary';
import { useIsFocused } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { getSelectedAccounts } from '@utils/utils';
import React, { useEffect } from 'react';
import { SafeAreaView, View } from 'react-native';
import styles from './style';

function TransactionsTab() {
  const { accounts, selectedTxnAccount, setSelectedTxnAccount, meAmplitudeContext } = getStore();

  const isFocused = useIsFocused();
  useEffect(() => {
    getSelectedAccounts(selectedTxnAccount, accounts, setSelectedTxnAccount);
  }, []);

  useEffect(() => {
    if (isFocused) {
      const e = AMPLITUDE_CONSTANTS.TransactionPage.transaction_page;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.navbar,
      });
    }
  }, [isFocused]);

  const accountSelectorBP = {
    selectedAccount: selectedTxnAccount,
    withdrawlAccounts: accounts,
    setSelectedAccount: setSelectedTxnAccount,
    homePage: false,
    txnPage: true,
  };

  return (
    <SafeAreaView style={styles.containerStyle}>
      <Header />
      <PaymentCycleSelector />
      <View style={styles.subContainerStyle}>
        <TransactionSummary />
        <AccountSelector
          {...accountSelectorBP}
          source={AMPLITUDE_EVENT_SOURCES.transaction_page}
          meAmplitudeContext={meAmplitudeContext}
          excludeNewTag
        />
        <TransactionList />
      </View>
    </SafeAreaView>
  );
}

export default TransactionsTab;
