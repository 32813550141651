import LoginInfoSheet from '@molecules/LoginInfoSheet';
import navigationConstants from '@navigation/navigationConstants';
import { showErrors } from '@screens/GGOTP/otpUtils';
import { showErrorBottomSheet } from '@utils/utils';
import React from 'react';

export function shouldShowLoginInfoSheet(onCloseModal, infoModal) {
  if (infoModal.visible) {
    return <LoginInfoSheet onPressPrimary={onCloseModal} loginInfo={infoModal} onClose={onCloseModal} />;
  }
  return null;
}

export function shouldRenderError(errorProps) {
  const {
    isError,
    error,
    setIsLoadingSMS,
    setIsLoadingWhatsapp,
    setLoading,
    setErrTxtMsg,
    t,
    mobileNumber,
    setInfoModal,
    setDisableOTP,
  } = errorProps;
  if (isError && error) {
    setIsLoadingSMS(false);
    setIsLoadingWhatsapp(false);
    // const errorPromise = await error.json();
    if (error[0] === 'GG-OTP-08') {
      showErrors(true, error, setLoading, setErrTxtMsg, t, mobileNumber, false, setDisableOTP);
    } else {
      showErrorBottomSheet(setIsLoadingSMS, setIsLoadingWhatsapp, mobileNumber, error[0], setInfoModal);
    }
  }
}

export function shouldRenderOnSuccess(successProps) {
  const {
    LoginData,
    isSuccess,
    setLoginData,
    setIsLoadingSMS,
    setIsLoadingWhatsapp,
    fromLogin,
    navigation,
    setInfoModal,
  } = successProps;
  if (LoginData && isSuccess) {
    setLoginData(LoginData);
    setIsLoadingSMS(false);
    setIsLoadingWhatsapp(false);
    if (fromLogin) {
      navigation.navigate(navigationConstants.OTP);
    } else {
      setInfoModal(prevState => ({ ...prevState, visible: true }));
    }
  }
}
