import { analyticsTimeStampFormat } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getNowInFormat } from '@utils/dateUtils';
import { formatUnderScoreLowerCase } from '@utils/utils';

export function logSheetOpened(selectedAccountNumber, productConfig, bpReqData, source, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionSuccess;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.account_number]: selectedAccountNumber,
    [e.attributes.transaction_category_id]: productConfig?.transaction_category_id,
    [e.attributes.product_code]: bpReqData?.productCode,
    [e.attributes.reason]: productConfig?.reason,
    [e.attributes.reason_id]: productConfig?.reasonId,
    [e.attributes.source]: source,
    [e.attributes.referral_code]: validatedReferralCode,
  });
}

export function logTnCClosed(productConfig, source) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.termsPageClosed;
  const { attributes } = e;
  AmplitudeHelper.logEvent(e.name, {
    [attributes.transaction_category_id]: productConfig.transaction_category_id,
    [attributes.source]: source,
    [attributes.timestamp]: getNowInFormat(analyticsTimeStampFormat),
  });
}

export function logTxClosedWithSource(productConfig, source, button) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.userClosedBillPaymentModal;
  const { attributes } = e;
  AmplitudeHelper.logEvent(e.name, {
    [attributes.transaction_category_id]: productConfig.transaction_category_id,
    [attributes.button]: button,
    [attributes.source]: source,
  });
}

export function logErrorAnalytics(productConfig, bpReqData, t, error, selectedBPAccountForConfirm, source) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentFailed;
  const { attributes, name } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
    [attributes.product_price]: bpReqData?.subTotal,
    [attributes.reason]: productConfig.reason,
    [attributes.reason_id]: productConfig.reasonId,
    [attributes.failure_message]: formatUnderScoreLowerCase(t(`errorCodes.${error[0]}`)),
    [attributes.payment_method]: selectedBPAccountForConfirm?.amplitudeAccountName,
    [attributes.source]: source,
    [attributes.fee_charged]: bpReqData?.fee,
    [attributes.product_code]: bpReqData?.productCode,
  });
}
