import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TextInput, View } from 'react-native';
import styles from './style';

const TextInputComponent = ({
  title,
  isMandatory,
  textInputProps,
  containerStyle,
  inputContainerStyle,
  isError,
  errorMessage,
  textInputStyle,
}) => (
  <View style={[styles.mainContainer, containerStyle]}>
    <Text variant="sm-normal" style={styles.titleText}>
      {title}
      <Text style={styles.star}>{isMandatory ? '*' : ''}</Text>
    </Text>
    <View style={[styles.inputContainer, isError && styles.errorContainerStyle, inputContainerStyle]}>
      <TextInput style={[styles.textInput, textInputStyle]} {...textInputProps} />
    </View>
    {isError && (
      <Text variant="sm-normal" style={styles.errorText}>
        {errorMessage}
      </Text>
    )}
  </View>
);

TextInputComponent.defaultProps = {
  textInputProps: {},
  title: '',
  isMandatory: false,
  containerStyle: {},
  inputContainerStyle: {},
  isError: false,
  errorMessage: '',
  textInputStyle: {},
};

TextInputComponent.propTypes = {
  textInputProps: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  isMandatory: PropTypes.bool,
  containerStyle: PropTypes.instanceOf(Object),
  inputContainerStyle: PropTypes.instanceOf(Object),
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  textInputStyle: PropTypes.instanceOf(Object),
};

export default TextInputComponent;
