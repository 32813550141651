import { apiLogin } from '@api/apiCall';
import BtnSMS from '@atoms/BtnSMS';
import BtnWhatsapp from '@atoms/BtnWhatsapp';
import ChatIcon from '@atoms/ChatIcon';
import LoginPhoneTxt from '@atoms/LoginPhoneTxt';
import LoginWelcomeTxt from '@atoms/LoginWelcomeTxt';
import TextWithValue from '@atoms/TextWithValue';
import { shouldHideSplashScreen } from '@atoms/ValidateToken/validateTokenUtils';
import useKeyboardStatus from '@customHooks/useKeyboardStatus';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import LoginPhoneInput from '@molecules/LoginPhoneInput';
import WelcomCards from '@molecules/Welcome';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { logoutFromMoengage } from '@utils/engagement/engagementUtils';
import secureStore from '@utils/secureStore';
import { formatPhoneNumber, formatUnderScoreLowerCase, goBackToHome, returnBottomSheetContent } from '@utils/utils';
import { Box } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, SafeAreaView, View } from 'react-native';
import {
  onCloseFunction,
  onPressPrimaryFunction,
  onPressSecondaryFunction,
  phoneChangeHandler,
  shouldNavigateOnSuccess,
  showLoginErrors,
} from './loginUtils';
import styles from './styles';
import { LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '../../constants';

const authAmplitude = AMPLITUDE_CONSTANTS.authentication;

function LoginButtons(props) {
  const {
    isDisabled,
    isLoadingSMS,
    phoneNumber,
    isLoadingWhatsapp,
    performLogin,
    setIsLoadingSMS,
    setIsLoadingWhatsapp,
  } = props;
  return (
    <Box marginTop="32px" flexDirection="row">
      <BtnWhatsapp
        isDisabled={isDisabled}
        isLoadingWhatsapp={isLoadingWhatsapp}
        phoneNumber={phoneNumber}
        performLogin={performLogin}
        setIsLoadingSMS={setIsLoadingSMS}
        setIsLoadingWhatsapp={setIsLoadingWhatsapp}
      />
      <BtnSMS
        isDisabled={isDisabled}
        isLoadingSMS={isLoadingSMS}
        phoneNumber={phoneNumber}
        performLogin={performLogin}
        setIsLoadingSMS={setIsLoadingSMS}
        setIsLoadingWhatsapp={setIsLoadingWhatsapp}
      />
    </Box>
  );
}

LoginButtons.propTypes = {
  isDisabled: PropTypes.bool,
  isLoadingSMS: PropTypes.bool,
  phoneNumber: PropTypes.string,
  isLoadingWhatsapp: PropTypes.bool,
  performLogin: PropTypes.func,
  setIsLoadingSMS: PropTypes.func,
  setIsLoadingWhatsapp: PropTypes.func,
};
LoginButtons.defaultProps = {
  isDisabled: false,
  isLoadingSMS: false,
  phoneNumber: '',
  isLoadingWhatsapp: false,
  performLogin: () => {},
  setIsLoadingSMS: () => {},
  setIsLoadingWhatsapp: () => {},
};

function GGLogin() {
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingSMS, setIsLoadingSMS] = useState(false);
  const [isLoadingWhatsapp, setIsLoadingWhatsapp] = useState(false);
  const [sError, setError] = useState('');
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.RESEND_OTP],
      phoneNumber,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
  });
  const { tenantSettings, setLoginData } = getStore();
  const { countryCode } = tenantSettings;
  const {
    mutate: performLogin,
    isSuccess,
    data: LoginData,
    isError,
    error,
  } = useMutation({ mutationFn: payload => apiLogin(payload) });

  const isKeyboardOpen = useKeyboardStatus();

  useEffect(() => {
    AmplitudeHelper.clearUserProperties();
    logoutFromMoengage();
    shouldHideSplashScreen();
    (async () => {
      if (Platform.OS === 'web') {
        const token = await secureStore.getItemAsync('accessToken');
        if (token && token.length > 0) {
          goBackToHome(navigation, true);
        }
      }
      // This will work, in case user comes to login page particularly from the partner url for PWA.
    })();
  }, []);

  useEffect(() => {
    showLoginErrors(isError, error, setIsLoadingSMS, setIsLoadingWhatsapp, phoneNumber, setInfoModal);
  }, [isError, error]);

  useEffect(() => {
    shouldNavigateOnSuccess(
      LoginData,
      isSuccess,
      setLoginData,
      setIsLoadingSMS,
      setIsLoadingWhatsapp,
      navigation,
      phoneNumber,
    );
  }, [LoginData, isSuccess]);

  const onChangePhoneNumber = phoneChangeHandler(sError, setError, setPhoneNumber, setIsDisabled, t, countryCode);

  const onPressPrimaryModalButton = () => {
    onPressPrimaryFunction(infoModal, phoneNumber, setInfoModal);
  };

  const onPressSecondaryModalButton = () => {
    onPressSecondaryFunction(infoModal, phoneNumber, setInfoModal, setPhoneNumber, navigation);
  };

  const onCloseModal = () => {
    onCloseFunction(infoModal, phoneNumber, setInfoModal);
  };

  useEffect(() => {
    AmplitudeHelper.logEvent(authAmplitude.login_page.name);
  }, []);

  const loginHandler = payload => {
    const e = AMPLITUDE_CONSTANTS.authentication.otp_requested;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(payload.mobileNumber),
      [e.attributes.source]: formatUnderScoreLowerCase(payload.otpType),
    });
    performLogin(payload);
  };

  const onTermsHandler = () => {
    const e = AMPLITUDE_CONSTANTS.authentication.terms_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });

    navigation.navigate('TermsAndCondition');
  };

  const navigateToSignup = () => {
    const e = AMPLITUDE_CONSTANTS.authentication.registration_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });
    navigation.navigate('Signup');
  };

  return (
    <SafeAreaView style={styles.loginParent}>
      <KeyboardAvoidingView style={styles.loginParent} behavior="height" keyboardVerticalOffset={30}>
        <WelcomCards
          isKeyboardOpen={isKeyboardOpen}
          item={{ cardColor: colors.secondary.orchid, textContext: t('login.loginHelpText') }}
        />
        <View style={styles.loginInputContainer}>
          <View style={styles.loginInputParent}>
            <LoginWelcomeTxt />
            <LoginPhoneTxt />
            <LoginPhoneInput sError={sError} phoneNumber={phoneNumber} setPhoneNumber={onChangePhoneNumber} />
            <LoginButtons
              isLoadingSMS={isLoadingSMS}
              isLoadingWhatsapp={isLoadingWhatsapp}
              isDisabled={isDisabled}
              phoneNumber={phoneNumber}
              performLogin={loginHandler}
              setIsDisabled={setIsDisabled}
              setIsLoadingSMS={setIsLoadingSMS}
              setIsLoadingWhatsapp={setIsLoadingWhatsapp}
            />
            <LoginInfoSheet
              loginInfo={infoModal}
              onPressPrimary={onPressPrimaryModalButton}
              onPressSecondary={onPressSecondaryModalButton}
              onClose={onCloseModal}
            />
            <Box testID="txtNoAccount" style={styles.loginSignupView}>
              <TextWithValue
                textToShow="login.noAccount"
                valueText="login.signUpNow"
                onValuePress={navigateToSignup}
                textSize="sm-bold"
                valueSize="sm-bold"
                textWeight="700"
                valueWeight="700"
                textAlign="center"
                isValueHyperLink
              />
            </Box>
            {/* <BtnWhatsappContact t={t} /> */}
            <Box testID="txtTermsAndConditions" style={styles.tncParent}>
              <TextWithValue
                textToShow="login.tncText"
                valueText="login.tncHyperLink"
                onValuePress={onTermsHandler}
                isValueHyperLink
                color={colors.neutral.darkMist}
                textSize="xsm-bold"
                valueSize="xsm-bold"
                textWeight="700"
                valueWeight="700"
                textAlign="center"
              />
            </Box>
          </View>
        </View>
      </KeyboardAvoidingView>
      <ChatIcon />
    </SafeAreaView>
  );
}

export default GGLogin;
