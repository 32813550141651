import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export function logDetailPage(detailsData) {
  const e = AMPLITUDE_CONSTANTS.TransactionPage.detail_transaction_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
    [e.attributes.transaction_id]: detailsData?.transactionId,
  });
}

export function logFocus() {
  const e = AMPLITUDE_CONSTANTS.TransactionPage.field_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
    [e.attributes.field_name]: 'description',
  });
}
