import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Input, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function EmployeeOrKTPInput(props) {
  const { signUpValidation, validationType, t, setId, onKTPInputFocus, onKTPInputBlur } = props;
  return (
    <View marginTop="24px">
      <Text testID="txtSignUpValidationPlaceHolder" variant="sm-bold" color={colors.disabled.smoke}>
        {t(`signup.${signUpValidation[validationType].placeHolder}`)}
        <Text testID="txtMandatoryAsteriks" color={colors.error.rose}>
          *
        </Text>
      </Text>
      <Input
        testID="employeeOrKTPInput"
        sentry-label="employeeOrKTPInput"
        placeholder={t(`signup.${signUpValidation[validationType].title}`)}
        placeholderTextColor={colors.neutral.charcoal}
        style={[appStyles.paddingLeft(16), appStyles.paddingRight(16)]}
        fontWeight="100"
        size="md"
        variant="md-normal"
        borderColor={colors.disabled.smoke}
        onChangeText={val => setId(val)}
        _focus={{
          borderColor: colors.secondary.orchid,
        }}
        onFocus={onKTPInputFocus}
        onBlur={onKTPInputBlur}
      />
    </View>
  );
}

EmployeeOrKTPInput.propTypes = {
  signUpValidation: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, placeHolder: PropTypes.string })),
  validationType: PropTypes.number,
  t: PropTypes.func,
  setId: PropTypes.func,
  onKTPInputFocus: PropTypes.func,
  onKTPInputBlur: PropTypes.func,
};
EmployeeOrKTPInput.defaultProps = {
  signUpValidation: [{ title: '', placeHolder: '' }],
  validationType: 0,
  t: () => {},
  setId: () => {},
  onKTPInputFocus: () => {},
  onKTPInputBlur: () => {},
};

export default EmployeeOrKTPInput;
