import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  flatListParent: {
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginVertical: scale(5),
    paddingBottom: verticalScale(6),
    paddingLeft: scale(16),
    paddingTop: verticalScale(4),
  },
  noDataFoundResult: { padding: scale(16) },
});
export default styles;
