import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase } from '@utils/utils';

export default function logReasonTap(selectedReason, source, amplitudeAccountName, validatedReferralCode) {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.name, {
    [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.reason]: formatUnderScoreLowerCase(selectedReason.reason),
    [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.reason_id]: selectedReason?.id,
    [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.dynamic_account_name]: amplitudeAccountName,
    [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.referral_code]: validatedReferralCode,
  });
}
