import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerStyle: { justifyContent: 'flex-end', margin: 0 },
  crossPressable: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingRight: scale(10),
    paddingVertical: scale(5),
  },
  iconContainer: {
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: scale(12),
    marginLeft: scale(20),
    padding: scale(2),
  },
  innerContainer: {
    backgroundColor: colors.neutral.cotton,
    borderTopEndRadius: scale(20),
    borderTopStartRadius: scale(20),
    paddingVertical: scale(10),
    width: '100%',
  },
  limitStyle: { marginLeft: scale(7) },
  modalIconStyle: { alignSelf: 'center', marginRight: scale(6) },
  rowStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: scale(2),
  },
  subRow: { alignItems: 'center', flexDirection: 'row' },
  tooltipBannerContainerStyle: {
    backgroundColor: colors.neutral.mist,
    marginHorizontal: scale(15),
    marginTop: scale(11),
  },
  tooltipBannerIconContainerStyle: { justifyContent: 'center' },
  tooltipBannerTextStyle: { flex: 1, paddingLeft: scale(10) },
  witdrawLimitStyle: {
    marginLeft: scale(22),
    marginTop: scale(16),
  },
});

export default styles;
