import { PWA_REFFERAL_LINK } from '@root/constants';
import colors from '@theme/colors';
import { numberWithDots } from '@utils/utils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Linking } from 'react-native';
import styles from './style';

const HYPERLINK_TEXT = 'Baca syarat dan ketentuan di sini';

const displayText = (item, referrerAmt, refereeAmt) => {
  let text = item.replace('$$referrerRewardAmount$$', numberWithDots(referrerAmt));
  text = text.replace('$$refereeRewardAmount$$', numberWithDots(refereeAmt));
  return text;
};

export function shouldRenderText(item, referrerAmt, refereeAmt, component) {
  if (component) return component;

  return (
    <View style={styles.termsTextContainer}>
      <Text variant="md-normal" style={styles.termsTextStyle}>
        {displayText(item, referrerAmt, refereeAmt)}
        {item === HYPERLINK_TEXT && (
          <Text
            variant="md-normal"
            testID="hyperlink"
            style={{ color: colors.secondary.orchid }}
            onPress={() => Linking.openURL(PWA_REFFERAL_LINK)}>
            {' '}
            {PWA_REFFERAL_LINK}
          </Text>
        )}
      </Text>
    </View>
  );
}

const TNCitem = ({ item, index, refereeAmt, referrerAmt, component }) => (
  <View style={styles.termsRow}>
    <View style={styles.termsIconContainer}>
      <View style={styles.circlePlaceholder}>
        <Text style={styles.numericText} variant="xsm-normal">
          {index + 1}
        </Text>
      </View>
    </View>
    {shouldRenderText(item, referrerAmt, refereeAmt, component)}
  </View>
);

TNCitem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  refereeAmt: PropTypes.number.isRequired,
  referrerAmt: PropTypes.number.isRequired,
  component: PropTypes.func,
};

TNCitem.defaultProps = {
  component: null,
};
export default TNCitem;
