import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const DetailRow = ({ title, text, customComponent, show }) => {
  function shouldRenderText(textCopy, cComponent) {
    if (textCopy) {
      return (
        <Text variant="md-normal" style={styles.textStyle}>
          {textCopy}
        </Text>
      );
    }

    return cComponent;
  }

  if (show) {
    return (
      <>
        <View style={styles.rowStyle}>
          <Text variant="md-normal" style={styles.titleStyle}>
            {title}
          </Text>
          {shouldRenderText(text, customComponent)}
        </View>
        <View style={styles.lowerBorderStyle} />
      </>
    );
  }

  return null;
};

DetailRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  customComponent: PropTypes.node,
  show: PropTypes.bool,
};

DetailRow.defaultProps = {
  text: '',
  customComponent: null,
  show: true,
};
export default DetailRow;
