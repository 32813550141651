import ScreenNames from '@navigation/navigationConstants';
import { BOTTOMSHEET_CONTENT, BOTTONSHEET_KEYS, FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { logBankClicked } from '@screens/GGCreatePin/analyticsCreatePiin';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { returnBottomSheetContent } from '@utils/utils';

const beforeCreatePin = 'BEFORE_CREATE_PIN';

export default function bottomSheetHandlersForBankWithPin(bottomSheetParams) {
  const { setInfoModal, infoModal, navigation, userBanks, initialState, isFeatureAvailale, pinRegister } =
    bottomSheetParams;
  const onPressSecondaryBottomSheet = () => {
    setInfoModal({ ...infoModal, visible: false });
    if (infoModal.errorCode === beforeCreatePin) {
      const e = AMPLITUDE_CONSTANTS.securityPin.create_pin_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_bank_account_page,
      });
      navigation.navigate(ScreenNames.GG_CREATE_PIN, { type: 'create', source: 'add_bank_account' });
    }
  };

  const onCloseModalHandler = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === beforeCreatePin) {
      const e = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_bank_account_page,
      });
    }
  };

  const legacyCreateBank = () => {
    if (userBanks.length < 10) {
      const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
      });
      navigation.navigate(ScreenNames.GG_ADD_BANK_SCREEN, { source: AMPLITUDE_EVENT_SOURCES.bank_page, pop: false });
      return;
    }
    const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_failed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
      [e.attributes.failure_message]:
        AMPLITUDE_EVENT_SOURCES.bankRegistration.failureMessage.bank_account_limit_exceeded,
    });
    setInfoModal({
      visible: true,
      data: returnBottomSheetContent(BOTTONSHEET_KEYS.MAX_LIMIT, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.MAX_LIMIT]),
      errorCode: BOTTONSHEET_KEYS.MAX_LIMIT,
    });
  };

  const onPressPrimaryBottomSHeet = () => {
    if (infoModal.errorCode === beforeCreatePin) {
      setInfoModal(initialState);

      const e = AMPLITUDE_CONSTANTS.securityPin.create_pin_later;
      AmplitudeHelper.logEvent(
        e.name,
        {
          [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_bank_account_page,
        },
        'LOG_CHECK',
      );

      legacyCreateBank();
    }
  };

  const checkPinAndCreateBank = (fromTransfer, source) => {
    logBankClicked(source);
    if (isFeatureAvailale[FEATURE_FLAGS.SECURITY_PIN]?.show && !pinRegister.isActive) {
      return setInfoModal({
        data: returnBottomSheetContent(
          SECURITY_PIN_INFO_MODAL.BEFORE_CREATE_PIN,
          SECURITY_PIN_INFO_MODAL.BEFORE_CREATE_PIN,
        ),
        errorCode: beforeCreatePin,
        visible: true,
      });
    }
    navigation.navigate(ScreenNames.GG_ADD_BANK_SCREEN, { fromTransfer, source });
    // return null;
  };
  return { checkPinAndCreateBank, onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onCloseModalHandler };
}
