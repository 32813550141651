import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  listStyle: { marginHorizontal: scale(16), marginVertical: verticalScale(14) },
  selectedNominalStyles: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginRight: scale(16),
    marginTop: verticalScale(8),
  },
});
export default styles;
