import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Icon, Text } from 'native-base';
import React from 'react';

export function getDisabledColorForText(isDisabled) {
  return isDisabled ? colors.neutral.darkMist : colors.neutral.charcoal;
}

export function getBorderColorForInput(sError, isFocused) {
  let borderResult = colors.disabled.smoke;
  if (sError && sError.length > 0 && isFocused) {
    borderResult = colors.error.rose;
  } else if (isFocused) {
    borderResult = colors.secondary.orchid;
  }
  return borderResult;
}

export function shouldShowError(sErrorMsg) {
  if (sErrorMsg && sErrorMsg.length > 0) {
    return (
      <Text
        testID="txtLoginPhoneError"
        variant="sm-normal"
        marginTop="4px"
        alignSelf="stretch"
        color={colors.error.rose}
        textAlign="left">
        {sErrorMsg}
      </Text>
    );
  }
  return null;
}

export function shouldShowCloseIcon(phoneNumber, setPhoneNumber) {
  if (phoneNumber && phoneNumber.length > 0) {
    return (
      <Icon
        testID="btnClearTxtFld"
        sentry-label="btnClearTxtFld"
        size="24px"
        as={AntDesign}
        name="closecircleo"
        color={colors.neutral.charcoal}
        marginRight="16px"
        onPress={() => setPhoneNumber('')}
      />
    );
  }
  return null;
}

export function shouldGetCloseIcon(isDisabled, phoneNumber, setPhoneNumber) {
  return !isDisabled ? shouldShowCloseIcon(phoneNumber, setPhoneNumber) : null;
}

export function getBackgroundColor(isDisabled) {
  return isDisabled ? colors.disabled.smoke : colors.neutral.cotton;
}
