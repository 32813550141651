import BankRowStatusApproved from '@atoms/BankRowStatusApproved';
import BankRowStatusPending from '@atoms/BankRowStatusPending';
import BankRowStatusRejected from '@atoms/BankRowStatusRejected';
import { maskIconInit } from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function BankRowStatus(props) {
  const { item } = props;
  if (item?.status?.toUpperCase() === 'PENDING') {
    return <BankRowStatusPending {...props} />;
  }
  if (item?.status?.toUpperCase() === 'APPROVED') {
    return <BankRowStatusApproved {...props} />;
  }
  if (item?.status?.toUpperCase() === 'REJECTED') {
    return <BankRowStatusRejected {...props} />;
  }
  return null;
}

BankRowStatus.propTypes = {
  onPressDelete: PropTypes.func,
  maskData: PropTypes.bool,
  maskIcon: PropTypes.string,
  setMaskIcon: PropTypes.func,
  setMaskData: PropTypes.func,
  fromTransfer: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    accountNumber: PropTypes.string,
    beneficiaryName: PropTypes.string,
    nickname: PropTypes.string,
    status: PropTypes.string,
    bank: PropTypes.shape({
      bankName: PropTypes.string,
    }),
  }),
};

BankRowStatus.defaultProps = {
  onPressDelete: () => {},
  maskData: false,
  maskIcon: '',
  setMaskIcon: () => {},
  setMaskData: () => {},
  fromTransfer: false,
  item: {
    id: 0,
    accountNumber: '',
    beneficiaryName: '',
    nickname: '',
    status: '',
    bank: {
      bankName: '',
    },
  },
};

function BankRow(props) {
  const { item, onPressDelete, fromTransfer, setBankForTransfer, source } = props;
  const [maskData, setMaskData] = useState(true);
  const [maskIcon, setMaskIcon] = useState(maskIconInit(maskData));

  if (fromTransfer && item?.status?.toUpperCase() === 'REJECTED') {
    return null;
  }
  return (
    <BankRowStatus
      item={item}
      onPressDelete={onPressDelete}
      maskData={maskData}
      setMaskData={setMaskData}
      maskIcon={maskIcon}
      setMaskIcon={setMaskIcon}
      fromTransfer={fromTransfer}
      setBankForTransfer={setBankForTransfer}
      source={source}
    />
  );
}

BankRow.propTypes = {
  onPressDelete: PropTypes.func,
  setBankForTransfer: PropTypes.func,
  fromTransfer: PropTypes.bool,
  source: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    accountNumber: PropTypes.string,
    beneficiaryName: PropTypes.string,
    nickname: PropTypes.string,
    status: PropTypes.string,
    bank: PropTypes.shape({
      bankName: PropTypes.string,
    }),
  }),
};

BankRow.defaultProps = {
  onPressDelete: () => {},
  setBankForTransfer: () => {},
  fromTransfer: true,
  source: '',
  item: {
    id: 0,
    accountNumber: '',
    beneficiaryName: '',
    nickname: '',
    status: '',
    bank: {
      bankName: '',
    },
  },
};

export default BankRow;
