import ControlledTooltip from '@atoms/CustomToolTip';
import Info from '@expo/vector-icons/MaterialIcons';
import colors from '@theme/colors';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { numberWithDots } from '@utils/utils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import poinEarnedStyles from './style';

const e = AMPLITUDE_CONSTANTS.UserProfile;
const PointEarned = ({ accumulatedTotalPoin, t, isPIC }) => (
  <View style={poinEarnedStyles.container}>
    <View style={poinEarnedStyles.infoContainer}>
      <Text variant="md-normal" style={poinEarnedStyles.infoText}>
        {t('userProfile.poinEarned')}
      </Text>
      <ControlledTooltip
        logParams={{
          [e.tooltip_clicked.attributes.source]: 'pic_page',
          [e.tooltip_clicked.attributes.influencer_role]: isPIC ? 'pic' : 'normal_user',
        }}
        translateString={t('userProfile.tooltipBanner')}>
        <Info name="info-outline" color={colors.neutral.darkMist} size={15} />
      </ControlledTooltip>
    </View>

    <View style={poinEarnedStyles.coinContainer}>
      {SVG_ICONS.coin5}
      <Text style={poinEarnedStyles.coinIcon}>{`${numberWithDots(accumulatedTotalPoin)} Poin`}</Text>
    </View>

    <View pointerEvents="none" style={poinEarnedStyles.absoluteContainer}>
      {SVG_ICONS.userProfile.confettiLeft}
      {SVG_ICONS.userProfile.confettiRight}
    </View>
  </View>
);

PointEarned.propTypes = {
  accumulatedTotalPoin: PropTypes.number,
  t: PropTypes.func.isRequired,
  isPIC: PropTypes.bool.isRequired,
};

PointEarned.defaultProps = {
  accumulatedTotalPoin: 0,
};

export default PointEarned;
