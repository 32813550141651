import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  maskIconInit,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './styles';

const accountName = 'a/n';

function WithdrawalBankDetails(props) {
  const { bank } = props;
  const { bankName, accountNumber } = bank;
  const [maskData, setMaskData] = useState(true);
  const [maskIcon, setMaskIcon] = useState(maskIconInit(maskData));
  return (
    <View style={styles.bankParent}>
      <View style={styles.bankContainer}>
        <MaterialCommunityIcons name="bank" size={24} color={colors.neutral.cotton} style={styles.bankIconStyles} />
      </View>
      <View style={appStyles.marginLeft(12)}>
        <Text testID="lblBankName" variant="md-normal">
          {bankName}
        </Text>
        <View style={styles.maskUnmaskContainer}>
          <Text testID="lblMaskUnmaskAcNo" variant="lg-bold">
            {getMaskedOrUnmaskedData(maskData, accountNumber)}
          </Text>
          <Ionicons
            name={maskIcon}
            size={16}
            color="black"
            testID="btnMaskUnmaskImage"
            style={appStyles.marginLeft(8)}
            onPress={() => {
              setMaskIcon(setMaskIconValue(maskData));
              setMaskData(!maskData);
            }}
          />
        </View>
        <Text testID="lblBeneficiaryName" variant="sm-normal">
          {accountName} {bank.beneficiaryName}
        </Text>
      </View>
    </View>
  );
}

WithdrawalBankDetails.propTypes = {
  bank: PropTypes.shape({
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    beneficiaryName: PropTypes.string,
  }),
};
WithdrawalBankDetails.defaultProps = {
  bank: {
    bankName: '',
    accountNumber: '',
    beneficiaryName: '',
  },
};
export default WithdrawalBankDetails;
