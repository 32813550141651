import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import statusRowStyles from './style';

export const StatusRow = ({ title, value, show }) => {
  if (show) {
    return (
      <View style={statusRowStyles.container}>
        <Text variant="md-normal" style={statusRowStyles.title}>
          {title}
        </Text>
        <Text variant="md-normal" style={statusRowStyles.value}>
          {value}
        </Text>
      </View>
    );
  }

  return null;
};

StatusRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  show: PropTypes.bool,
};

StatusRow.defaultProps = {
  title: '',
  value: '',
  show: false,
};

export default StatusRow;
