import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  dataSelectionStyles: {
    borderColor: colors.disabled.smoke,
    borderRadius: 10,
    borderWidth: 1,
    height: 48,
    justifyContent: 'center',
    paddingHorizontal: scale(16),
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  signUpValidateTextStyles: { flex: 1, textAlign: 'left' },
});
export default styles;
