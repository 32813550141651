import { FEATURE_FLAGS } from '@api/queries/featureFlag';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react-native';
import { getStore } from '@store/storeUtils';
import AmplitudeHelper from '@utils/analytics';
import { isFeatureEnabled } from '@utils/utils';
import { useEffect, useMemo } from 'react';
import { featureFlagOnComplete, featureFlagOnError } from './customHookUtils';

const useFeatureFlag = ({ featureName, onCompleted, onCompleteWithDynamicFeature }) => {
  const { featureFlags, setFeatureFlags } = getStore();

  const [getFeatureFlags] = useLazyQuery(FEATURE_FLAGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      featureFlagOnComplete(setFeatureFlags, data, onCompleted, featureName, onCompleteWithDynamicFeature);
    },
    onError: e => {
      Sentry.addBreadcrumb({ category: 'info', message: `Error in gql feature flag ${e?.message}`, level: 'info' });
      AmplitudeHelper.logEvent('gql_error_featureflag', { message: e?.message });
      featureFlagOnError();
    },
  });

  useEffect(() => {
    getFeatureFlags();
  }, [getFeatureFlags]);

  const isFeatureAvailable = useMemo(() => isFeatureEnabled(featureFlags, featureName), [featureFlags, featureName]);

  return featureName ? [isFeatureAvailable, getFeatureFlags] : [getFeatureFlags];
};

export default useFeatureFlag;
