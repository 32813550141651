import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  modal2: {
    alignSelf: 'center',
    backgroundColor: colors.neutral.cotton,
    borderRadius: 15,
  },
  subView: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 25,
  },
  text: {
    color: colors.neutral.cotton,
    fontSize: 20,
    fontWeight: '200',
  },
  textStyle: {
    marginRight: scale(15.5),
  },
});

export default styles;
