import config from '@config';
import useStore from '@store/index';
// import { SentryHelper } from '@utils/performance/SentryHelper';
import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';
import axios from 'axios';
import { Platform } from 'react-native';
import URI from 'urijs';

function getXAPIKey() {
  return Platform.OS === 'web' ? '87654321' : '12345678';
}

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': getXAPIKey(),
  'x-api-version': 1,
};

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: defaultHeaders,
});

export function shouldAddCustomHeaders(customHeaders) {
  if (customHeaders) {
    return {
      ...defaultHeaders,
      ...customHeaders,
    };
  }
  return {
    ...defaultHeaders,
  };
}

function getExpandedURL(sUrl, data) {
  const uriObj = URI.expand(sUrl, data.queryProps);
  const { _parts } = uriObj;
  const { protocol, hostname, path } = _parts;
  return `${protocol}://${hostname}${path}`;
}
export function shouldAddTokenForAuth(options) {
  if (options.isAuthenticated) {
    const accessToken = tokenManager.getAccessToken();
    if (!accessToken) {
      throw new Error('Token does not exist');
    }
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return {};
}

export function getURLForApiCall(options, sUrl, data) {
  let result = sUrl;
  if (options?.hasQueryProps && options?.hasNamedProps) {
    result += URI.buildQuery(data.queryProps);
  } else if (options?.hasQueryProps && !options?.hasNamedProps) {
    result = getExpandedURL(sUrl, data);
  } else if (options?.hasMixedProps) {
    // This scenario is for api(s) like txn/reasons. Since it contains a mix of /param?key1={val1}&key2={val2}
    const mixedUriObj = URI.expand(sUrl, data.mixedProps);
    const { _parts } = mixedUriObj;
    const { protocol, hostname, path } = _parts;
    result = `${protocol}://${hostname}${path}?`;
    result += URI.buildQuery(data.queryProps);
  }
  return result;
}

export function getInvokeOptionsForHTTPMethod(postData, options, invokeOption, urlEncodedData) {
  let result = { ...invokeOption }; // When post body is null - result get returned as null . therefore losing header . So default properties should be retained
  if (postData) {
    switch (options.httpMethod) {
      case 'POST':
        result = urlEncodedData
          ? { ...invokeOption, data: urlEncodedData }
          : {
              ...invokeOption,
              data: options.stringifyRequest ? JSON.stringify(postData) : postData,
            };
        break;
      case 'PUT':
      case 'PATCH':
        result = urlEncodedData
          ? { ...invokeOption, body: urlEncodedData }
          : {
              ...invokeOption,
              data: postData,
            };
        break;
      case 'DELETE':
        result = { ...invokeOption, body: JSON.stringify(postData) };
        break;
      default:
    }
  }
  return result;
}

export async function refreshTokenCall() {
  const refreshToken = await secureStore.getItemAsync('refreshToken');
  return axios.post(`${config.API_URL}auth/refresh`, undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
}

export async function refreshTokenHandler() {
  const result = await refreshTokenCall();
  const { data } = result.data;
  await secureStore.setItemAsync('accessToken', data.accessToken);
  await secureStore.setItemAsync('refreshToken', data.refreshToken);
  tokenManager.setAccessToken(data.accessToken);
  tokenManager.setRefreshToken(data.refreshToken);
}

function successData(response) {
  // SentryHelper.captureMessage(`response for:${response.config.url.replace(config.API_URL, '')}`);
  return response.data.data;
}

export function axiosResponseInterceptor() {
  return async response => {
    if (response.status === 200) {
      // this is the httpstatus
      if (response.data.success) {
        return successData(response);
      }
      if (
        response.data.error &&
        response.data.error.length > 0 &&
        (response.data.error[0] === 'GG-GEN-17' ||
          response.data.error[0] === 'GG-GEN-05' ||
          response.data.error[0] === 'GG-REF-TKN-04')
      ) {
        useStore.getState().setForceLogout();
        throw response.data.error;
      }
      // SentryHelper.captureException(response.data.error);
      throw response.data.error;
    }
    return null;
  };
}

axiosInstance.interceptors.response.use(
  axiosResponseInterceptor(),
  // error => {},
);
