import { useState, useEffect } from 'react';
import { Keyboard } from 'react-native';

function useKeyboardStatus() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => setIsOpen(true));
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => setIsOpen(false));

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return isOpen;
}

export default useKeyboardStatus;
