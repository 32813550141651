import 'setimmediate';
import { gqClient, queryClient } from '@api/clientProvider';
import { ApolloProvider } from '@apollo/client';
import config from '@config';
import * as Sentry from '@sentry/react-native';
import { QueryClientProvider } from '@tanstack/react-query';
import { theme } from '@theme/theme';
import { useFonts } from 'expo-font';
import i18next from 'i18next';
import { NativeBaseProvider } from 'native-base';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

import { Platform } from 'react-native';
import GGApp from './GGApp';
import { en, id } from './src/i18n/supportedLanguages';
import { getStore } from './src/store/storeUtils';
import tenantConfig from './src/tenantConfig';
import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';

i18next.init({
  compatibilityJSON: 'v3',
  interpolation: { escapeValue: false }, // React already does escaping
  lng: tenantConfig[config.COUNTRY_CODE].lang, // language to use
  resources: {
    en: {
      common: en,
    },
    id: { common: id },
  },
});

function App() {
  const { setTenantSettings } = getStore();

  useEffect(() => {
    const initializeToken = async () => {
      const accessToken = await secureStore.getItemAsync('accessToken');
      const refreshToken = await secureStore.getItemAsync('refreshToken');
      if (accessToken) {
        tokenManager.setAccessToken(accessToken);
        tokenManager.setRefreshToken(refreshToken);
      }
    };

    initializeToken();
    setTenantSettings();
  }, []);

  useFonts({
    'Nunito-Bold': require('@assets/fonts/Nunito-Bold.ttf'),
    'Nunito-Regular': require('@assets/fonts/Nunito-Regular.ttf'),
  });

  return (
    <I18nextProvider i18n={i18next}>
      <ApolloProvider client={gqClient}>
        <NativeBaseProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <GGApp />
          </QueryClientProvider>
        </NativeBaseProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
}

export default Platform.OS === 'web' ? Sentry.wrap(() => App) : Sentry.wrap(App);
