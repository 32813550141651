import { deleteImage, uploadImages } from '@api/useQueryCalls';
import ImageDisplay from '@atoms/ImageDisplay';
import MyModal from '@atoms/MyModal';
import UploadMessage from '@atoms/UploadMessage';
import ImagePickerItem from '@molecules/ImagePickerItem';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { onImagePick } from '@utils/ImagePicker';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style';

export const shouldRenderContent = (onCloseModal, onImagePickHandler, imageUri, t, setImageObj, transactionId) => {
  if (!imageUri?.uri) {
    return (
      <UploadMessage
        onImagePick={() => onImagePickHandler(onCloseModal, setImageObj, transactionId)}
        onCloseModal={onCloseModal}
        t={t}
      />
    );
  }
  return <ImageDisplay imageUri={imageUri} onCloseModal={onCloseModal} transactionId={transactionId} />;
};

function ImageUPloadComponent(props) {
  const { imageUrl, documentId, transactionId, show, onUpload, onDelete } = props;
  const { t } = useTranslation('common');

  const [imageObj, setImageObj] = useState({
    uploaded: !!imageUrl || false,
    uploading: false,
    failed: false,
    uri: imageUrl ?? null,
    documentId: documentId ?? null,
    file: null,
  });

  const [modalObj, setModal] = useState({ isVisible: false, type: null });

  const { isLoading: imageLoading, mutate: uploadCall } = uploadImages({ file: imageObj.file, transactionId }, data => {
    setImageObj(prevState => ({
      ...prevState,
      uploaded: true,
      failed: false,
      documentId: data?.uploadedFiles?.[0]?.documentId,
      uploading: false,
    }));
    onUpload();
  });

  const { isLoading: deleteLoading, mutate: deleteCall } = deleteImage({ documentId: imageObj.documentId }, () => {
    setImageObj(prevState => ({
      ...prevState,
      uploaded: false,
      failed: false,
      uri: null,
      file: null,
      documentId: null,
      uploading: false,
    }));
    onDelete();
  });

  useEffect(() => {
    setImageObj(prevState => ({ ...prevState, uploading: imageLoading || deleteLoading }));
  }, [imageLoading, deleteLoading]);

  const openModal = () => {
    setModal({ isVisible: true });
  };
  const onCloseModal = () => {
    setModal({ visible: false });
  };

  useEffect(() => {
    if (imageObj.file) uploadCall();
  }, [imageObj.file]);

  const onDeleteHandler = () => {
    const e = AMPLITUDE_CONSTANTS.workAllowance.image_deleted;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.transaction_id]: transactionId ?? null,
      [e.attributes.image_id]: imageObj.documentId,
    });

    deleteCall();
  };

  if (show) {
    return (
      <>
        <ImagePickerItem onPressCancel={onDeleteHandler} imageUri={imageObj} title="Bukti.png" onPress={openModal} />

        <MyModal onPress={onCloseModal} containerStyle={styles.modalContainer} isVisible={modalObj.isVisible}>
          {shouldRenderContent(onCloseModal, onImagePick, imageObj, t, setImageObj, transactionId)}
        </MyModal>
      </>
    );
  }
  return null;
}

ImageUPloadComponent.propTypes = {
  imageUrl: PropTypes.shape([]),
  documentId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ImageUPloadComponent.defaultProps = {
  imageUrl: {},
  show: false,
};

export default ImageUPloadComponent;
