import { BOTTOMSHEET_CONTENT, BOTTONSHEET_KEYS } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { returnBottomSheetContent } from '@utils/utils';

export default function bankListBottomSheetHandlers(
  infoModal,
  setInfoModal,
  beneficiaryDeleteMutate,
  setBankRowPressed,
) {
  const onPressPrimaryBottomSHeet = bankRowPressed => {
    if (infoModal.errorCode === BOTTONSHEET_KEYS.BANK_DELETE) {
      setInfoModal({ ...infoModal, visible: false });
      if (infoModal.errorCode === BOTTONSHEET_KEYS.BANK_DELETE) {
        // delete bank here
        beneficiaryDeleteMutate(bankRowPressed.id);
      }
    } else {
      setInfoModal({ ...infoModal, visible: false });
    }
  };
  const onPressSecondaryBottomSheet = () => {
    setInfoModal({ ...infoModal, visible: false });
  };
  const onCloseModalHandler = () => {
    setInfoModal({ ...infoModal, visible: false });
  };
  const onPressDelete = item => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_account_delete_clicked;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
    });
    setBankRowPressed(item);
    if (item.status === 'REJECTED') {
      // onClickSecondaryBottomSheet(setInfoModal, infoModal, deleteUserBank, item.id);
      // delete bank here

      beneficiaryDeleteMutate(item.id);
      return;
    }
    setInfoModal({
      data: returnBottomSheetContent(BOTTONSHEET_KEYS.BANK_DELETE, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.BANK_DELETE]),
      errorCode: BOTTONSHEET_KEYS.BANK_DELETE,
      visible: true,
    });
  };
  return { onPressDelete, onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onCloseModalHandler };
}
