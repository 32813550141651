import { useEffect } from 'react';
import { Platform } from 'react-native';

const useBeforeUnload = callback => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (typeof callback === 'function') callback();
      return null;
    };

    if (Platform.OS === 'web') {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [callback]);
};

export default useBeforeUnload;
