import { Button, View } from 'native-base';
import React from 'react';
import styles from './style';

export function shouldRenderButtons(loginInfo, onPressPrimary, buttonText, onPressSecondary, buttonText2) {
  return loginInfo?.data?.hasTwoButtons ? (
    <View style={styles.buttonContainer}>
      <Button testID="btnPrimary" flex={1} variant="ghost" onPress={onPressPrimary} marginRight={5}>
        {buttonText}
      </Button>
      <Button testID="btnSecondary" flex={1} variant="solid" onPress={onPressSecondary}>
        {buttonText2}
      </Button>
    </View>
  ) : (
    <Button
      // size="lg"
      testID="btnOK"
      bottom={0}
      marginX={4}
      marginTop="16px"
      marginBottom="40px"
      variant="solid"
      onPress={onPressPrimary}>
      {buttonText}
    </Button>
  );
}

export function test() {}
