import colors from '@theme/colors';
import { verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  artwork: { alignSelf: 'center', height: verticalScale(200), paddingTop: 2, width: verticalScale(200) },
  cardContainer: {
    alignSelf: 'stretch',
    flex: 1,
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  languageBtnStyles: {
    flex: 1,
    flexDirection: 'row',
    position: 'absolute',
    right: 5,
    top: 5,
  },
  logoStyle: { alignItems: 'center', paddingTop: 20 },
  textContainer: { paddingVertical: 12 },
  textStyle: {
    color: colors.neutral.cotton,
    textAlign: 'center',
  },
});

export default styles;
