import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: { marginTop: scale(5), paddingHorizontal: scale(15) },
  descriptText: {
    color: colors.neutral.darkMist,
    fontSize: scale(12),
    marginTop: scale(3),
  },
  dividerStyle: { height: scale(1), marginTop: scale(10) },
  keyStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: scale(8),
    paddingRight: scale(12),
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: scale(5),
    paddingRight: scale(12),
  },
});

export default styles;
