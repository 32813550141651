import colors from '@theme/colors';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VersionCheck from 'react-native-version-check-expo';

function ProfileAppVersion() {
  const currentAppVersion = VersionCheck.getCurrentVersion();
  const { t } = useTranslation('common');
  return (
    <View flexDirection="row" justifyContent="center" marginTop="16px" marginBottom="8px">
      <Text testID="txtAppVersion" variant="xsm-bold" color={colors.neutral.darkMist}>
        {t('profile.appVersion')}:{currentAppVersion}{' '}
      </Text>
    </View>
  );
}

ProfileAppVersion.propTypes = {};

ProfileAppVersion.defaultProps = {};

export default ProfileAppVersion;
