import CommonTextInput from '@atoms/CommonTextInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function ReferralValidatedInput(props) {
  const { validatedReferralCode } = props;
  const { t } = useTranslation('common');
  return (
    <CommonTextInput
      testID="txtInputReferral"
      editable={false}
      size="md"
      style={styles.referralInputStyle}
      customBorderStyle={styles.customBorderStyle}
      value={validatedReferralCode}
      keyboardType="number-pad"
      placeholder={t('referral.inputPlaceHolder')}
    />
  );
}

ReferralValidatedInput.propTypes = {
  validatedReferralCode: PropTypes.string,
};
ReferralValidatedInput.defaultProps = {
  validatedReferralCode: '',
};

export default ReferralValidatedInput;
