import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase } from '@utils/utils';

export default function logSubProdNominalFailure(selectedAccountNumber, productConfig, apiPayload, t, bpError, source) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionFailed;
  const { attributes } = e;
  AmplitudeHelper.logEvent(e.name, {
    [attributes.account_number]: selectedAccountNumber,
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
    [attributes.product_code]: apiPayload?.productCode,
    [attributes.reason]: productConfig?.reason,
    [attributes.reason_id]: productConfig?.reasonId,
    [attributes.failure_message]: formatUnderScoreLowerCase(t(`errorCodes.${bpError[0]}`)),
    [attributes.source]: source,
  });
}
