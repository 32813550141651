import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  descStyle: { color: colors.neutral.darkMist },
  dueTextStyle: {
    color: colors.neutral.darkMist,
    marginLeft: scale(5),
  },
  installTextSyle: { color: colors.neutral.darkMist },
  installmentRow: {
    borderColor: colors.disabled.smoke,
    borderWidth: scale(1),
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: scale(30),
    paddingVertical: scale(6),
  },
  ongoingHighlighter: {
    backgroundColor: colors.warning.softSunflower,
    borderBottomColor: colors.transparent,
    borderColor: colors.warning.sunflower,
    borderLeftWidth: scale(4),
    borderRightColor: colors.transparent,
    borderRightWidth: 0,
    borderTopColor: colors.transparent,
  },
  outerContainer: {
    marginBottom: scale(10),
    marginTop: scale(10),
    paddingHorizontal: scale(15),
  },
  paidHighter: {
    backgroundColor: colors.success.softCactus,
    borderBottomColor: colors.transparent,
    borderColor: colors.success.darkCactus,
    borderLeftWidth: scale(4),
    borderRightColor: colors.transparent,
    borderRightWidth: 0,
    borderTopColor: colors.transparent,
  },
  secondPart: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: scale(123),
  },

  srStyles: { color: colors.neutral.darkMist, marginVertical: scale(4) },
  titleContainer: {
    borderColor: colors.disabled.smoke,
    borderRadius: scale(12),
    borderWidth: 1,
    marginHorizontal: scale(15),
    overflow: 'hidden',
  },
  titleRow: {
    backgroundColor: colors.neutral.mist,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: scale(30),
    paddingVertical: scale(12),
  },

  totalTextColor: { color: colors.neutral.darkMist },
  txtStyle: {
    color: colors.neutral.charcoal,
  },
});

export default styles;
