import { SECURITY_PIN } from '@api/queries/securityPin';
import { useLazyQuery } from '@apollo/client';
import IconRightArrow from '@atoms/IconRightArrow';
import SecurityPinBanner from '@atoms/SecurityPinBanner';
import SkeletonComponent from '@atoms/SkeletonComponent';
import ProfileActivePinRow from '@molecules/ProfileActivePinRow';
import ProfileRejectedPinRow from '@molecules/ProfileRejectedPinRow';
import navigationConstants from '@navigation/navigationConstants';
import { onCheckSecurityErrorCallBack, onCheckSecurityPinCallBack } from '@organisms/ProfileTab/profileUtils';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logSecurityPin from './analyticsProfileSecurityPin';

export function onPressSecurityPin(navigation) {
  return () => {
    logSecurityPin();
    navigation.navigate(navigationConstants.GG_CREATE_PIN, { type: 'create', source: 'pin_creation' });
  };
}

export function getSecurityPinStatusView(pinRegister, childRef) {
  if (pinRegister.isActive) {
    return <ProfileActivePinRow childRef={childRef} />;
  }

  return <ProfileRejectedPinRow childRef={childRef} onPressSecurityPin={onPressSecurityPin} />;
}

export function onParentPressHandler(childRef) {
  return () => {
    if (childRef?.current) {
      // // This is for web
      if (childRef?.current?.click) {
        childRef.current.click();
      }
      // This is for mobile
      if (childRef?.current?.props?.onPress) {
        childRef.current.props.onPress();
      }
    }
  };
}

function ProfileSecurityPin(props) {
  const { showSecurityPin, route } = props;
  const { t } = useTranslation('common');
  const { pinRegister, setShowPinRegister } = getStore();
  const childRef = useRef();
  const PadLock = SVG_ICONS.securityPin.padLock;
  const [checkSecurityPin, { loading }] = useLazyQuery(SECURITY_PIN, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onCheckSecurityPinCallBack(setShowPinRegister),
    onError: onCheckSecurityErrorCallBack(setShowPinRegister),
  });

  useEffect(() => {
    checkSecurityPin();
  }, []);

  if (loading) {
    return <SkeletonComponent />;
  }

  const onParentPress = onParentPressHandler(childRef);

  if (showSecurityPin) {
    if (pinRegister.showPinRegister && route === 'Home') {
      return <SecurityPinBanner />;
    }
    if (route !== 'Home') {
      return (
        <>
          <View style={appStyles.marginLeft(16)} marginY="16px">
            <Text testID="txtProfileInformation" variant="md-bold">
              {t('profile.securityHeader')}
            </Text>

            <TouchableOpacity onPress={onParentPress}>
              <View marginY="8px" flexDirection="row" alignItems="center">
                <View paddingX="4px" paddingY="4px" background={colors.secondary.softOrchid} borderRadius="12px">
                  <PadLock />
                </View>
                <Text testID="txtPinStatus" marginLeft="8px" variant="md-normal" flex={1}>
                  {t('profile.pinsText')}
                </Text>
                <View flexDirection="row" alignItems="center">
                  {getSecurityPinStatusView(pinRegister, childRef)}
                  <IconRightArrow />
                </View>
              </View>
            </TouchableOpacity>
          </View>
          <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
        </>
      );
    }
    return null;
  }
  return null;
}

ProfileSecurityPin.propTypes = {
  showSecurityPin: PropTypes.bool,
  route: PropTypes.string,
  pinRegister: PropTypes.shape({
    isActive: PropTypes.bool,
  }),
};
ProfileSecurityPin.defaultProps = {
  showSecurityPin: false,
  route: '',
  pinRegister: {
    isActive: false,
  },
};

export default ProfileSecurityPin;
