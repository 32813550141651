import CommonTextInput from '@atoms/CommonTextInput';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function ReferralUserInput(props) {
  const { setReferralCode, pageSource } = props;
  const { t } = useTranslation('common');
  return (
    <CommonTextInput
      style={styles.referralInputStyle}
      customBorderStyle={{ borderRadius: scale(8), borderColor: colors.disabled.smoke }}
      testID="txtInputReferral"
      keyboardType="number-pad"
      placeholder={t('referral.inputPlaceHolder')}
      onChangeText={val => setReferralCode(val)}
      amplitudeCallBackOnBlur={val => {
        const textValue = val?.target?.value ?? '';
        const e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_inputted;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: textValue,
          [e.attributes.source]: pageSource,
        });
      }}
    />
  );
}

ReferralUserInput.propTypes = { setReferralCode: PropTypes.func, pageSource: PropTypes.string };
ReferralUserInput.defaultProps = { setReferralCode: () => {}, pageSource: '' };

export default ReferralUserInput;
