import BPReqAccountWithBalance from '@atoms/BPReqAccountWithBalance';
import React from 'react';
import { logAccountChange } from './analyticsBPAccountSelector';

export function dropDownRenderItem(selectedEligibleAccount) {
  return item => <BPReqAccountWithBalance selectedEligibleAccount={selectedEligibleAccount} item={item} showChecked />;
}

export function dropDownRenderLeftIcon(selectedEligibleAccount) {
  return () => (
    <BPReqAccountWithBalance
      selectedEligibleAccount={selectedEligibleAccount}
      item={selectedEligibleAccount}
      showChecked={false}
    />
  );
}

export function dropDownOnChange(
  setSelectedEligibleAccount,
  setSelectedBPAccount,
  employeeId,
  tcid,
  pCode,
  source,
  bpReqData,
  validatedReferralCode,
) {
  return item => {
    if (!item.isDisabled) {
      logAccountChange(employeeId, tcid, pCode, source, bpReqData, item, validatedReferralCode);
      setSelectedEligibleAccount(item);
      setSelectedBPAccount(item);
    }
  };
}
