import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 5,
    flexDirection: 'row',
    marginBottom: 12,
    paddingHorizontal: scale(16),
    paddingVertical: scale(8),
  },
  text: {
    marginStart: 10,
    width: '90%',
  },
  textContainer: { paddingLeft: scale(10) },
  textStyle: { fontSize: scale(10.5), marginLeft: scale(3) },
  txtAdj: { paddingLeft: scale(4) },
});

export default styles;
