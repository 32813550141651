import { MaterialIcons } from '@expo/vector-icons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

export const INFO_APP_STATUS = {
  APPROVED: {
    backgroundColor: colors.success.softCactus,
    textDesc: 'INFO_STATUS_DESC_1',
    iconName: 'check-circle-outline',
    iconColor: colors.success.darkCactus,
    showLink: true,
  },
  DISBURSED: {
    backgroundColor: colors.success.softCactus,
    textDesc: 'INFO_STATUS_DESC_1',
    iconName: 'check-circle-outline',
    iconColor: colors.success.darkCactus,
    showLink: true,
  },
  PENDING_APPROVAL: {
    backgroundColor: colors.warning.softSunflower,
    textDesc: 'INFO_STATUS_DESC_2',
    iconName: 'query-builder',
    iconColor: colors.warning.darkSunflower,
  },
  default: {
    backgroundColor: colors.secondary.softOrchid,
    textDesc: 'INFO_STATUS_DESC_3',
    iconName: 'info-outline',
    iconColor: colors.secondary.orchid,
  },
};

const InfoBar = ({ appStatus, show = true, onPressLink, t }) => {
  if (!show) return null;

  function shouldShowLink(showLink) {
    if (showLink) {
      return (
        <Text testID="linkText" onPress={onPressLink} variant="sm-bold" style={styles.linkTextStyle}>
          {`\n${t('loan.riwayat')}`}
        </Text>
      );
    }
    return null;
  }

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: INFO_APP_STATUS[appStatus ?? 'default'].backgroundColor,
        },
      ]}>
      <MaterialIcons
        size={scale(24)}
        style={[styles.iconContainer, { color: INFO_APP_STATUS[appStatus ?? 'default'].iconColor }]}
        name={INFO_APP_STATUS[appStatus ?? 'default'].iconName}
      />
      <View style={styles.appStatusStyle}>
        <Text variant="xsm-bold" style={styles.textStyle}>
          {t(`loan.${INFO_APP_STATUS[appStatus ?? 'default'].textDesc}`)}
          {shouldShowLink(INFO_APP_STATUS[appStatus ?? 'default'].showLink)}
        </Text>
      </View>
    </View>
  );
};

InfoBar.propTypes = {
  appStatus: PropTypes.string,
  show: PropTypes.bool,
  onPressLink: PropTypes.func,
  t: PropTypes.func.isRequired,
};

InfoBar.defaultProps = {
  show: true,
  appStatus: null,
  onPressLink: () => {},
};

export default InfoBar;
