import { fetchMeUseQuery } from '@api/useQueryCalls';
import { getStore } from '@store/storeUtils';
import { useEffect } from 'react';
import { autoLoginIfCorrectTokens, renderHomePageOnAutoLogin } from './accessFromPartnerUtils';

const AccessFromPartner = () => {
  const {
    setAccounts,
    setCanShowReferral,
    setEmployeeId,
    setIsInLockPeriod,
    setReferralApplicable,
    setStatus,
    setUserId,
    setSelectedAccount,
    setEmployerId,
    setBPAccounts,
    setSelectedBPAccounts,
    setLoginData,
    setBPPhone,
    setIsSuspended,
    setOtpData,
    setHeaderText,
    setReferralHeaderText,
    setAppliedReferral,
    setTxnSuccessReferral,
    setMeAmplitudeContext,
    setAutoDisburseData,
  } = getStore();

  const { isSuccess, data: meApiData, refetch } = fetchMeUseQuery();
  async function checkToken() {
    const { search } = window.location;
    await autoLoginIfCorrectTokens(search, refetch);
  }

  useEffect(() => {
    checkToken();
  }, []);
  useEffect(() => {
    renderHomePageOnAutoLogin(
      isSuccess,
      meApiData,
      setLoginData,
      setAccounts,
      setSelectedAccount,
      setCanShowReferral,
      setEmployeeId,
      setIsInLockPeriod,
      setReferralApplicable,
      setStatus,
      setUserId,
      setEmployerId,
      setBPAccounts,
      setSelectedBPAccounts,
      setBPPhone,
      setIsSuspended,
      setOtpData,
      setHeaderText,
      setReferralHeaderText,
      setAppliedReferral,
      setTxnSuccessReferral,
      setMeAmplitudeContext,
      setAutoDisburseData,
    );
  }, [meApiData, isSuccess]);
};

export default AccessFromPartner;
