import IconRightArrow from '@atoms/IconRightArrow';
import { FontAwesome } from '@expo/vector-icons';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Divider, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

function ProfileViewRegisteredBankDetails(props) {
  const { employeeDefaultBank, source } = props;
  const { t } = useTranslation('common');
  const navigation = useNavigation();
  return (
    <View marginTop="12px">
      <TouchableOpacity
        testID="btnBanksListShow"
        onPress={() => {
          const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_page_clicked;
          const { name, attributes } = e;
          AmplitudeHelper.logEvent(name, {
            [attributes.source]: source,
          });
          navigation.navigate(navigationConstants.BANK_LIST, {
            defaultBank: employeeDefaultBank,
            source,
          });
        }}>
        <View
          marginX="16px"
          borderColor={colors.disabled.smoke}
          borderWidth={1}
          borderRadius="12"
          justifyContent="center">
          <View marginLeft="12px" marginY="17px" flexDirection="row" alignItems="center">
            <FontAwesome name="bank" size={18} color="black" />
            <Text
              testID="txtRegisteredBanks"
              marginLeft="8px"
              variant="sm-bold"
              flex={1}
              color={colors.neutral.charcoal}>
              {t('profile.registeredBanks')}
            </Text>
            <View marginRight="16px">
              <IconRightArrow />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <Divider marginTop="12px" height="4px" style={{ backgroundColor: colors.neutral.mist }} />
    </View>
  );
}

ProfileViewRegisteredBankDetails.propTypes = {
  source: PropTypes.string,
  employeeDefaultBank: PropTypes.shape({
    bankName: PropTypes.string,
    bankAccountName: PropTypes.string,
    number: PropTypes.string,
  }),
};

ProfileViewRegisteredBankDetails.defaultProps = {
  source: '',
  employeeDefaultBank: { bankName: '', bankAccountNumber: '', number: '' },
};

export default ProfileViewRegisteredBankDetails;
