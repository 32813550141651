import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  arrowIcon: {
    marginLeft: scale(4),
  },
  balanceText: {
    alignSelf: 'center',
    marginBottom: scale(4),
    textAlign: 'center',
  },
  centeredContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: scale(2.5),
  },
  centeredRow: {
    alignItems: 'center',
    height: scale(36),
    justifyContent: 'center',
  },
  container: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: 12,
    elevation: 6,
    marginHorizontal: scale(16),
    paddingVertical: 12,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  displayName: {
    color: colors.neutral.charcoal,
    marginLeft: scale(4),
  },
  divider: {
    backgroundColor: colors.disabled.smoke,
    height: 24,
    width: 4,
  },
  flexContainer: {
    flex: 1,
  },
  flipArrow: {
    transform: [{ scaleX: -1 }],
  },
  helpIcon: {
    marginLeft: scale(4),
  },
  iconImage: {
    height: 15,
    width: 15,
  },
  innerRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: scale(4),
  },
  rowWithIcons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  transactionDescription: {
    marginHorizontal: scale(4),
  },
  transactionRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  transactionText: {
    marginBottom: scale(2.5),
  },
});

export default styles;
