import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  arrowStyle: {
    height: scale(18),
    justifyContent: 'center',
    width: scale(18),
  },
});
export default styles;
