import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text } from 'native-base';
import React from 'react';

export function shouldShowBeneficiaryNameWhenBankSelected(bankForTransfer) {
  return bankForTransfer === null ? null : (
    <Text variant="sm-normal" color={colors.neutral.darkMist}>
      {bankForTransfer?.beneficiaryName}
    </Text>
  );
}

export function variantForSelectedBank(bankForTransfer) {
  return bankForTransfer === null ? 'md-normal' : 'md-bold';
}

export function styleForSelectedBank(bankForTransfer) {
  return bankForTransfer === null ? appStyles.flex1 : null;
}

export function colorForSelectedBank(bankForTransfer) {
  return bankForTransfer === null ? colors.neutral.darkMist : colors.neutral.charcoal;
}

export function shouldShowBankNameWhenBankSelected(bankForTransfer) {
  if (bankForTransfer === null) {
    return 'Pilih Rekening';
  }
  return bankForTransfer.bank.bankName.length > 10
    ? `${bankForTransfer.bank.bankName.slice(0, 10)}...`
    : bankForTransfer.bank.bankName;
}

export function shouldShowSeperatorWhenBankSelected(bankForTransfer) {
  return bankForTransfer === null ? null : (
    <Text variant="sm-normal" style={appStyles.marginHorizontal(4)}>
      |
    </Text>
  );
}

export function shouldShowAccountNumberWhenBankSelected(bankForTransfer) {
  return bankForTransfer === null ? null : (
    <Text variant="md-normal" style={appStyles.flex1}>
      {bankForTransfer?.accountNumber}
    </Text>
  );
}

export function shouldShowErrorForAmounts(errors) {
  return errors.amount ? (
    <Text variant="md-normal" color={colors.error.rose}>
      {errors.amount}
    </Text>
  ) : null;
}
