import { Ionicons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  maskIconInit,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import appStyles from '@root/appStyles';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { View } from 'react-native';
import styles from './style';

function BankEyeComponent(props) {
  const { value } = props;

  const [maskData, setMaskData] = useState(true);
  const [maskIcon, setMaskIcon] = useState(maskIconInit(maskData));

  if (!value) return null;

  return (
    <View style={styles.row}>
      <Ionicons
        name={maskIcon}
        size={16}
        color="black"
        testID="btnMaskUnmaskImage"
        onPress={() => {
          setMaskIcon(setMaskIconValue(maskData));
          setMaskData(!maskData);
        }}
      />
      <Text variant="md-bold" textAlign="right" style={appStyles.marginLeft(8)}>
        {getMaskedOrUnmaskedData(maskData, value)}
      </Text>
    </View>
  );
}

BankEyeComponent.propTypes = {
  value: PropTypes.string, // Define the prop type for 'value'
};

BankEyeComponent.defaultProps = {
  value: '',
};

export default BankEyeComponent;
