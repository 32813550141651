import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  informationParent: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 5,
    marginTop: 15,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
});
export default styles;
