import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonStyle: { marginRight: scale(16), paddingVertical: scale(5), width: scale(78) },
  reasonHeader: { marginLeft: scale(16) },
  reasonsContainer: {
    alignItems: 'center',
    borderColor: colors.neutral.darkMist,
    borderRadius: 12,
    borderWidth: 1,
    flexDirection: 'row',
    marginVertical: 16,
  },
  reasonsSelected: { marginBottom: 6, marginLeft: 16, marginTop: 4 },
});
export default styles;
