import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  checkBoxContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: verticalScale(150),
    marginVertical: scale(16),
  },
  tncText: { marginLeft: scale(8), textDecorationLine: 'underline' },
});
export default styles;
