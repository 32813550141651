import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  successInfo: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 8,
    flexDirection: 'row',
    marginHorizontal: scale(16),
    marginTop: verticalScale(37),
    paddingHorizontal: scale(12),
    paddingVertical: verticalScale(8),
  },
  successParent: { alignItems: 'center', flex: 1, marginTop: verticalScale(24) },
  txnBannerStyle: { marginBottom: 0, marginHorizontal: 0 },
});
export default styles;
