import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: colors.disabled.smoke,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: scale(8),
    paddingHorizontal: scale(15),
  },
  containerAdj: { marginLeft: scale(18) },
  installAmtTxt: {
    marginTop: scale(3),
    width: scale(100),
  },
  metaInfoStyle: {
    marginTop: scale(3),
    width: scale(87),
  },
  metaRow: { flexDirection: 'row', marginTop: scale(4) },
  metaTitleStyle: { marginBottom: scale(5) },
  principleAmtStyle: { color: colors.success.cactus, marginLeft: scale(4) },
  principleRow: { alignItems: 'center', flexDirection: 'row', marginTop: scale(12) },
  rightArrowContainer: { alignItems: 'center', justifyContent: 'center', marginTop: scale(8) },
  tenureBubble: {
    alignItems: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: scale(38),
    height: scale(24),
    justifyContent: 'center',
    width: scale(26),
  },
  tenureTxtStyle: {
    color: colors.warning.darkSunflower,
  },
});

export default styles;
