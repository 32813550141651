import SkeletonComponent from '@atoms/SkeletonComponent';
import { View } from 'native-base';
import React from 'react';
import ShimmerBPTile from '../ShimmerBPTile';

export const ShimmerBPCategory = () => (
  <View flexDirection="row">
    <ShimmerBPTile />
    <ShimmerBPTile />
    <ShimmerBPTile />
    <ShimmerBPTile />
  </View>
);

function ShimmerBPList() {
  return (
    <View>
      <SkeletonComponent />
      <ShimmerBPCategory />
      <ShimmerBPCategory />
      <ShimmerBPCategory />
      <ShimmerBPCategory />
    </View>
  );
}

ShimmerBPList.propTypes = {};
ShimmerBPList.defaultProps = {};
export default ShimmerBPList;
