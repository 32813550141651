import GGFloatingReferral from '@atoms/GGFloatingReferral';
import { shouldHideSplashScreen } from '@atoms/ValidateToken/validateTokenUtils';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import ChatBot from '@molecules/ChatBot';
import Header from '@molecules/Header';
// import MarketingBanner from '@molecules/MarketingBanner';
import MeComponent from '@molecules/MeComponent';
// import PermissionsModal from '@molecules/PermissionsModal';
import ProfileSecurityPin from '@molecules/ProfileSecurityPin';
import TopProducts from '@molecules/TopProducts';
import { useIsFocused } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { FEATURE_FLAGS } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { ScrollView } from 'native-base';
import React, { useEffect } from 'react';
import {
  // Platform,
  SafeAreaView,
} from 'react-native';

function HomeTab() {
  const [isFeatureAvailale, getFeatureFlags] = useFeatureFlag({
    featureName: [
      FEATURE_FLAGS.REFERRAL,
      FEATURE_FLAGS.MUL_BANK_ACC,
      FEATURE_FLAGS.BANK_SEL_TRNSF,
      FEATURE_FLAGS.PHONE_ENROLL,
      FEATURE_FLAGS.SALARY_INFO,
      FEATURE_FLAGS.SECURITY_PIN,
      FEATURE_FLAGS.PIC_REFERRAL,
    ],
  });

  // const props = { eventSource: 'home_page' };
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getFeatureFlags();
    }
  }, [isFocused]);

  useEffect(() => {
    shouldHideSplashScreen();
  }, []);

  useEffect(() => {
    if (isFocused) AmplitudeHelper.logEvent(AMPLITUDE_EVENT_SOURCES.home_page, {});
  }, [isFocused]);

  return (
    <SafeAreaView style={appStyles.tabParent}>
      <Header />
      <ScrollView
        contentContainerStyle={appStyles.flex1}
        // refreshControl={<RefreshControl refreshing={isLoading} onRefresh={() => refetch()} />}
      >
        <MeComponent />
        <ProfileSecurityPin showSecurityPin={isFeatureAvailale[FEATURE_FLAGS.SECURITY_PIN]?.show} route="Home" />
        <TopProducts />
      </ScrollView>
      <GGFloatingReferral />
      <ChatBot />
    </SafeAreaView>
  );
}

export default HomeTab;
