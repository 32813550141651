import * as Device from 'expo-device';
import { Dimensions, PixelRatio } from 'react-native';

// Constants
const DEVICE_WIDTH = 360;
const DEVICE_HEIGHT = 800;

// You can use Device.isTabletAsync() which is a promise, but for synchronous checks, we use this:
const isTablet = Device.deviceType === Device.DeviceType.TABLET;

const basicScale = (size, deviceSize) => (size * Dimensions.get('window').width) / deviceSize;

const moderateScale = (size, factor = 0.3) => size + (basicScale(size, DEVICE_WIDTH) - size) * factor;

const scaleDimension = (size, deviceSize, dimension) => {
  if (isTablet) {
    return moderateScale(size);
  }

  return (size * dimension) / deviceSize;
};

const scale = size =>
  Math.round(PixelRatio.roundToNearestPixel(scaleDimension(size, DEVICE_WIDTH, Dimensions.get('window').width)));

const verticalScale = size =>
  Math.round(PixelRatio.roundToNearestPixel(scaleDimension(size, DEVICE_HEIGHT, Dimensions.get('window').height)));

export { scale, verticalScale };
