import { gql } from '@apollo/client';

/**
 *  Fetch query for feature flag, gets features list with hide/show config,
 *  Excludes feature set config for employers specified in app_feature_blacklists table
 */
const FEATURE_FLAGS = gql`
  query getFeatures {
    app_feature {
      code
      isGG
      name
      show
      startAt
      endAt
    }
  }
`;

export { FEATURE_FLAGS };

export function test() {}
