import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

function LoginWelcomeTxt() {
  const { t } = useTranslation('common');
  return (
    <Text testID="txtLoginWelcome" alignSelf="stretch" textAlign="left" variant="lg-bold">
      {t('login.welcome')}
    </Text>
  );
}

export default LoginWelcomeTxt;
