import IconRightArrow from '@atoms/IconRightArrow';
import TextCurrency from '@atoms/TextCurrency';
// import { useNavigation } from '@react-navigation/native';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logPointsRow from './analyticsProfilePointsRow';
import styles from './styles';

export function onPresPointsRowHandler(pointsAccount, setSelectedBPAccounts, navigation, employeeId) {
  return () => {
    if (pointsAccount?.accountTypeId) setSelectedBPAccounts(pointsAccount);
    logPointsRow(employeeId, pointsAccount);
    navigation.navigate(navigationConstants.PembayaranTab);
  };
}

function ProfilePointsRow() {
  const { accounts, setSelectedBPAccounts, employeeId } = getStore();
  const { t } = useTranslation('common');
  const navigation = useNavigation();
  let pointsAccount = { balance: 0 };
  if (accounts && accounts.length > 0) {
    pointsAccount = accounts.filter(item => item.accountTypeId === 1);
    pointsAccount = pointsAccount.length > 0 ? pointsAccount[0] : { balance: 0 };
  }

  return (
    <TouchableOpacity
      testID="btnPointsRow"
      onPress={onPresPointsRowHandler(pointsAccount, setSelectedBPAccounts, navigation, employeeId)}>
      <View flexDirection="row" marginY="8px" alignItems="center">
        <View
          backgroundColor={colors.warning.softSunflower}
          paddingX="4px"
          paddingBottom="4.5px"
          paddingTop="3.5px"
          borderRadius="12px"
          justifyContent="center">
          {SVG_ICONS.coin}
        </View>
        <Text
          testID="txtProfilePoints"
          marginLeft="8px"
          flex={1}
          variant="md-normal"
          fontWeight="400"
          alignSelf="center">
          {t('profile.myPoints')}
        </Text>

        <View style={styles.coinContainer}>
          {SVG_ICONS.coin}
          <TextCurrency
            showCurrencySymbol={false}
            amountCurrency={pointsAccount.balance}
            variant="xsm-bold"
            style={styles.textStyle}
          />
        </View>

        <IconRightArrow />
      </View>
    </TouchableOpacity>
  );
}

ProfilePointsRow.propTypes = {};
ProfilePointsRow.defaultProps = {};

export default ProfilePointsRow;
