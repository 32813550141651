import config from '@config';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import React from 'react';
import getKeyFromEmpIdOrUUID from './chatScreenUtils';

function ChatScreenUI() {
  const { employeeId } = getStore();
  return (
    <iframe
      src={`${config.AI_SUPPORT_UI_URL}${getKeyFromEmpIdOrUUID(employeeId)}`}
      width="100%"
      height="100%"
      style={appStyles.flex1}
      // TODO replace with headerText
      title="Gajigesa Finance Chat"
    />
  );
}

export default ChatScreenUI;
