import { getStore } from '@store/storeUtils';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Pressable, View } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withSequence,
  interpolate,
  withTiming,
} from 'react-native-reanimated';
import styles from './style';

const ReferralButton = ({ onRefferalCta, referralButtonText }) => {
  const animation = useSharedValue(0);

  const { tenantSettings } = getStore();
  const { lang } = tenantSettings;

  useEffect(() => {
    animation.value = withRepeat(withSequence(withTiming(1), withTiming(0), withTiming(1)), 0, true);
  }, [animation]);

  const animatedStyle = useAnimatedStyle(() => {
    const rotate = interpolate(animation.value, [0, 1], [-30, 30]);
    return {
      transform: [{ rotate: `${rotate}deg` }],
      marginTop: 5,
    };
  });

  return (
    <View testID="referral-container" sentry-label="referral-container" style={styles.pressableContainer}>
      <Pressable testID="referral-button" style={styles.referralPressable} onPress={onRefferalCta}>
        <View style={styles.iconView}>
          <Animated.View style={[styles.animatedButtonStyle, animatedStyle]}>{SVG_ICONS.giftNew}</Animated.View>
        </View>
        <Text testID="txtReferralInvite" variant="sm-bold" style={styles.textStyle}>
          {referralButtonText[lang]}
        </Text>
      </Pressable>
    </View>
  );
};

ReferralButton.propTypes = {
  onRefferalCta: PropTypes.func,
  referralButtonText: PropTypes.shape({}),
};

ReferralButton.defaultProps = {
  onRefferalCta: () => {},
  referralButtonText: {},
};

export default ReferralButton;
