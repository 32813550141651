import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { getPicRole } from '../PicListing/analyticsPIcListing';

export function logRoleAppear(employeeId, employerId) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.role_options_appear;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.employee_id]: employeeId,
    [e.attributes.employer_id]: employerId,
  });
}

export function logDepartmentAppear(employeeId, employerId) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.department_options_appear;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.employee_id]: employeeId,
    [e.attributes.employer_id]: employerId,
  });
}

export function logUserInfoPage(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.user_info_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}

export function logDepartmentSaved(employeeId, employerId) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.department_role_saved;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.employee_id]: employeeId,
    [e.attributes.employer_id]: employerId,
  });
}

export function logRoleSubmitted(employeeId, employerId) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.department_role_submitted;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.employee_id]: employeeId,
    [e.attributes.employer_id]: employerId,
  });
}
