import { MaterialIcons } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import PropTypes from 'prop-types';
import React from 'react';

function IconRightArrow(props) {
  return (
    <MaterialIcons name="keyboard-arrow-right" size={24} color="black" style={appStyles.marginRight(16)} {...props} />
  );
}

IconRightArrow.propTypes = {
  props: PropTypes.shape({}),
};
IconRightArrow.defaultProps = {
  props: {},
};
export default IconRightArrow;
