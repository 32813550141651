import AntDesign from '@expo/vector-icons/AntDesign';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import listingItemStyles from './style';

const ListingItem = ({ text, points, customContainer, show, disabled }) => {
  if (show) {
    return (
      <View style={[listingItemStyles.container, customContainer]}>
        <View style={listingItemStyles.iconContainer}>
          <AntDesign
            name="checkcircle"
            color={disabled ? colors.disabled.smoke : colors.success.cactus}
            size={scale(18)}
          />
        </View>
        <View style={appStyles.marginLeft(5)}>
          <Text variant="sm-normal" style={listingItemStyles.text}>
            {text}
          </Text>
          <Text variant="xsm-normal" style={[listingItemStyles.points, disabled && listingItemStyles.disabledStyle]}>
            {`+${points} Poin`}
          </Text>
        </View>
      </View>
    );
  }
  return null;
};

ListingItem.propTypes = {
  text: PropTypes.string,
  points: PropTypes.number,
  customContainer: PropTypes.shape({}),
  show: PropTypes.bool,
  disabled: PropTypes.bool,
};

ListingItem.defaultProps = {
  text: '',
  points: 0,
  customContainer: {},
  show: false,
  disabled: false,
};

export default ListingItem;
