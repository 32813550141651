import { SET_OPT_IN } from '@api/queries/query';
import { useMutation } from '@apollo/client';
import { artWithdrawAll } from '@assets/png/imagesPng';
import DescriptionRow from '@atoms/DescriptionRow';
import ToastSuccess from '@atoms/ToastSuccess';
import { useNavigation } from '@react-navigation/native';
import { id } from '@root/i18n/supportedLanguages';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { Button, Checkbox, Divider, Text, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ScrollView, View } from 'react-native';
import { logAutoDTerms, logBackButtonTerms, logChangeBox, logContinue } from './analyticsTerms';
import styles from './style';

export function onSucccessHandler(navigation, toast, t) {
  return () => {
    navigation.goBack();
    toast.show({
      render: () => <ToastSuccess data={t('autoDisburse.successOptIn')} />,
      placement: 'top',
    });
  };
}

const GGAutoDisburseTerms = () => {
  const { employeeId } = getStore();
  const [acceptTemrs, setAcceptTerms] = useState(false);
  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const toast = useToast();

  const [setOptIn, { loading }] = useMutation(SET_OPT_IN, {
    fetchPolicy: 'no-cache',
    variables: {
      employeeId,
      autoDisburse: true,
    },
    onCompleted: onSucccessHandler(navigation, toast, t),
  });

  const onSetInHandler = () => {
    logContinue();
    setOptIn();
  };

  const shouldRenderDescription = (title, srNo, style) => (
    <View style={[styles.descContainerAdj, style]}>
      <Text variant="md-normal" color={colors.neutral.darkMist} style={styles.srnoStyle}>
        {srNo}.
      </Text>
      <Text variant="md-normal" color={colors.neutral.darkMist} style={styles.descTitleStyle}>
        {title}
      </Text>
    </View>
  );

  useEffect(() => {
    logAutoDTerms();
    return () => {
      logBackButtonTerms();
    };
  }, []);

  return (
    <>
      <ScrollView style={styles.containerStyle} contentContainerStyle={styles.contentContainerStyle}>
        <View style={styles.imageContainer}>
          <Image source={artWithdrawAll} style={styles.imageStyle} resizeMode="contain" />
        </View>
        <Text variant="lg-bold" style={styles.titleStyle}>
          {t('autoDisburse.mainTitle')}
        </Text>

        {shouldRenderDescription(t('autoDisburse.descOne'), 1, styles.firstDescStyle)}
        {shouldRenderDescription(t('autoDisburse.descTwo'), 2)}

        <Divider style={styles.dividerStyle} />

        <Text variant="lg-bold" style={styles.bulletTitle}>
          {t('autoDisburse.bulletOne')}
        </Text>

        {id.autoDisburse.terms.map((d, i) => (
          <DescriptionRow key={d.substring(0, 5)} desc={t(`autoDisburse.terms.${i}`)} />
        ))}
      </ScrollView>

      <View style={styles.footerContainer}>
        <View style={styles.checkBoxRow}>
          <Checkbox
            testID="autoDisburseCheckbox"
            aria-label="autoDisburseCheckbox"
            isChecked={acceptTemrs}
            _checked={{
              backgroundColor: colors.primary.carnation,
              borderColor: colors.primary.carnation,
            }}
            onChange={() => {
              logChangeBox();
              setAcceptTerms(prevState => !prevState);
            }}
          />

          <Text testID="checkboxTitle" variant="sm-bold" style={styles.checkBoxTextStyle}>
            {t('autoDisburse.termsTitle')}
          </Text>
        </View>

        <Button
          isLoading={loading}
          isDisabled={!acceptTemrs || loading}
          textAlign="center"
          testID="btnAutoDisburse"
          sentry-label="btnOTP"
          onPress={onSetInHandler}>
          {t('autoDisburse.continue')}
        </Button>
      </View>
    </>
  );
};

export default GGAutoDisburseTerms;
