import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
  },
  container: {
    borderRadius: 10,
    elevation: 5,
    justifyContent: 'center',
    marginBottom: scale(4),
    marginHorizontal: scale(16),
    marginTop: scale(17),
    overflow: 'hidden',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: -10,
  },
  iconContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  textReason: {
    marginHorizontal: scale(8),
  },
});

export default styles;
