import IconRightArrow from '@atoms/IconRightArrow';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logSalaryInformation from './analyticsProfileInformation';

function navigateToSalaryInfo(navigation) {
  return () => {
    logSalaryInformation();
    navigation.navigate(navigationConstants.SALARY_INFORMATION);
  };
}

function ProfileSalaryInfo(props) {
  const { t } = useTranslation('common');
  const navigation = useNavigation();
  const { showSalaryInfo } = props;
  if (showSalaryInfo) {
    return (
      <TouchableOpacity testID="btnSalaryInfo" onPress={navigateToSalaryInfo(navigation)}>
        <View flexDirection="row" marginTop="16px" alignItems="center">
          <View
            backgroundColor={colors.secondary.softOrchid}
            paddingX="4px"
            paddingBottom="4.5px"
            paddingTop="3.5px"
            borderRadius="12px"
            justifyContent="center">
            <MaterialCommunityIcons name="wallet" size={24} color={colors.secondary.orchid} />
          </View>
          <Text testID="txtSalaryInfo" marginLeft="8px" flex={1} variant="md-normal" alignSelf="center">
            {t('profile.salaryInfo')}
          </Text>
          <IconRightArrow />
        </View>
      </TouchableOpacity>
    );
  }
  return null;
}

ProfileSalaryInfo.propTypes = {
  showSalaryInfo: PropTypes.bool,
};
ProfileSalaryInfo.defaultProps = {
  showSalaryInfo: false,
};

export default ProfileSalaryInfo;
