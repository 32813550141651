import config from '@config';
import { camelToSnake, spaceToSnake } from '@utils/utils';
import { Platform } from 'react-native';

export function initialiseUserAttributes(userData) {
  const { userId, phoneNumber, is_gajigea, employeeId, userName } = userData;
  if (employeeId && employeeId !== null && employeeId !== undefined) {
    Moengage?.add_unique_user_id(userId);
    Moengage?.add_mobile(`+${phoneNumber}`);
    Moengage?.add_user_attribute('is_gajigesa', is_gajigea);
    Moengage?.add_user_attribute('employee_id', employeeId.toString());
    Moengage?.add_user_attribute('full_name', userName);
    Moengage?.add_user_attribute('platform', Platform.OS);
    Moengage?.add_user_attribute('from_rewrite', true);
    Moengage?.add_user_attribute('environment', config.ENVIRONMENT);
  }
}

export function logoutFromMoengage() {
  Moengage?.destroy_session();
}

export function trackEngagement(data, eventName) {
  const objectProperties = {};
  if (data) {
    Object.keys(data).forEach(key => {
      if (data[key]) {
        const objKey = spaceToSnake(camelToSnake(key));
        const objValue = data[key].toString();
        objectProperties[objKey] = objValue;
      }
    });
  }
  Moengage?.track_event(eventName, objectProperties);
}
