import { artPhoneBook } from '@assets/png/imagesPng';
import PinWithAddPhone from '@molecules/PinWithAddPhone';
import appStyles from '@root/appStyles';
import { addPhoneTemplate1 } from '@root/constants';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-native';
import styles from './styles';

function PhoneEmptyView({ isTransact, transactionCategoryId }) {
  const { t } = useTranslation('common');
  return (
    <View style={appStyles.marginTop(16)}>
      <Image
        source={artPhoneBook}
        width={100}
        height={50}
        // style={{ marginTop: 42 }}
        style={styles.phoneBookStyles}
      />
      <Text variant="lg-bold" textAlign="center" style={appStyles.marginTop(8)}>
        {t('phoneTransfer.noRegisteredText')}
      </Text>
      <Text variant="md-normal" color={colors.neutral.darkMist} textAlign="center" style={appStyles.marginTop(4)}>
        {t('phoneTransfer.phoneAddPurpose')}
      </Text>
      <PinWithAddPhone type={addPhoneTemplate1} isTransact={isTransact} transactionCategoryId={transactionCategoryId} />
    </View>
  );
}

PhoneEmptyView.propTypes = {
  isTransact: PropTypes.bool,
  transactionCategoryId: PropTypes.string,
};

PhoneEmptyView.defaultProps = {
  isTransact: false,
  transactionCategoryId: '',
};

export default PhoneEmptyView;
