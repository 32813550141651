import SkeletonComponent from '@atoms/SkeletonComponent';
import AccountSelector from '@molecules/AccountSelector';
import AccountSlider from '@molecules/AccountSlider';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styles from './styles';

function WithdrawlSlider(props) {
  const { isLoading } = props;
  const { t } = useTranslation('common');
  const { selectedAccount, withdrawlAccounts, setSelectedAccount, meAmplitudeContext } = getStore();

  const accounSelectorProps = {
    selectedAccount,
    withdrawlAccounts,
    setSelectedAccount,
    homePage: true,
  };
  const elevation = { elevation: 8 };
  if (isLoading) {
    return <SkeletonComponent />;
  }
  return (
    <View style={[appStyles.borderWithShadow, elevation]}>
      <View style={styles.withdrawlHeader}>
        <Text testID="txtWithdrawlSliderHeading" variant="sm-normal" flex={1}>
          {t('withdrawlSlider.heading')}
        </Text>
        <AccountSelector
          {...accounSelectorProps}
          source={AMPLITUDE_EVENT_SOURCES.home_page}
          meAmplitudeContext={meAmplitudeContext}
        />
      </View>
      <AccountSlider />
    </View>
  );
}

WithdrawlSlider.propTypes = {
  isLoading: PropTypes.bool,
};
WithdrawlSlider.defaultProps = {
  isLoading: false,
};
export default WithdrawlSlider;
