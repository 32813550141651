import IconRightArrow from '@atoms/IconRightArrow';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { getAmplitudeKeyValue } from '@utils/utils';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

export function logEventForPoinClick(meAmplitudeContext) {
  const e = AMPLITUDE_CONSTANTS.poin_card_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}

function shouldShowRightArrowIcon(account) {
  const { enableAccountCard } = account;
  if (enableAccountCard) {
    return <IconRightArrow size={18} />;
  }
  return null;
}

export function onCardPressHandler(account, setSelectedBPAccounts, navigation, meAmplitudeContext) {
  return () => {
    if (account.accountTypeId === 1) {
      logEventForPoinClick(meAmplitudeContext);
      setSelectedBPAccounts(account);
      navigation.navigate(navigationConstants.PembayaranTab, {
        source: AMPLITUDE_EVENT_SOURCES.poin_card_clicked,
      });
    }

    if (account.accountTypeId === 0) {
      navigation.navigate(navigationConstants.SALARY_INFORMATION);
    }
  };
}

function AccountCard(props) {
  const { account } = props;
  const navigation = useNavigation();
  const { setSelectedBPAccounts, meAmplitudeContext } = getStore();

  return (
    <TouchableOpacity
      style={styles.accountCardContainer}
      sentry-label={account.displayName}
      testID={`account_${account?.accountTypeId?.toString()}`}
      onPress={onCardPressHandler(account, setSelectedBPAccounts, navigation, meAmplitudeContext)}>
      <View style={styles.iconAndAccountName}>
        <View style={styles.iconStyles}>
          <RemoteImage uri={account.icon} width={scale(15)} height={scale(15)} />
        </View>
        <Text
          alignSelf="stretch"
          flex={1}
          testID="txtAccountDisplayName"
          variant="sm-normal"
          color={colors.neutral.darkMist}>
          {account.displayName}
        </Text>
      </View>
      <View flexDirection="row" alignItems="center" marginTop="4px" marginBottom="8px">
        <TextCurrency
          testID={`txt_account_${account?.accountTypeId?.toString()}`}
          variant="md-bold"
          style={styles.accountAmount}
          showCurrencySymbol={account.accountTypeId !== 1}
          amountCurrency={account?.balance}
        />
        {shouldShowRightArrowIcon(account)}
      </View>
    </TouchableOpacity>
  );
}

AccountCard.propTypes = {
  account: PropTypes.shape({
    balance: PropTypes.number,
    icon: PropTypes.string,
    displayName: PropTypes.string,
    color: PropTypes.string,
    accountTypeId: PropTypes.number,
  }),
};
AccountCard.defaultProps = {
  account: {
    icon: '',
    balance: 0,
    displayName: '',
    color: '',
    id: 0,
  },
};

export default React.memo(AccountCard);
