import RemoteImage from '@atoms/RemoteImage';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { formatUnderScoreLowerCase } from '@utils/utils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styles from './style';

const ImageDisplay = ({ imageUri, onCloseModal, transactionId }) => {
  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.workAllowance.image_viewed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.image_id]: imageUri?.documentId,
      [e.attributes.transaction_id]: formatUnderScoreLowerCase(transactionId),
    });
  }, []);

  return (
    <View style={styles.container}>
      <TouchableOpacity testID="close-button" onPress={onCloseModal} style={styles.closeButton}>
        <MaterialIcons name="close" size={scale(20)} color={colors.neutral.cotton} />
      </TouchableOpacity>
      <RemoteImage testID="displayed-image" resizeMode="contain" isFullSize uri={imageUri.uri} />
    </View>
  );
};

ImageDisplay.propTypes = {
  imageUri: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
};

export default ImageDisplay;
