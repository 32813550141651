import { numberWithDots } from '@utils/utils';
import * as yup from 'yup';

export const bankTransferSchema = (minWithdrawalAmount, balance, t, currencySymbol) =>
  yup.object().shape({
    bankAccountNumber: yup.object().required('Please select bank'),
    amount: yup
      .number()
      .typeError('Amount exceeds number validation')
      .required('Amount is required for transfer')
      .lessThan(balance + 1, t('bankTransfer.notEnoughBalance'))
      .moreThan(minWithdrawalAmount - 1, `Minimum ${currencySymbol}${numberWithDots(minWithdrawalAmount)}`),
  });

export const bankAddSchema = t =>
  yup.object().shape({
    selectedBank: yup.object().required('Please select bank'),
    accountNumber: yup
      .string()
      .matches(/^\d+$/, 'Account number must be a numeric string')
      .required('Account number is required'),
    accountName: yup
      .string()
      .matches(/^[a-zA-Z\s]*[a-zA-Z]+$/, t('addBank.accountNameError'))
      .required('Account name is required'),
    // accountNickName: yup.string().matches(/^[a-zA-Z]+$/, 'Account name must only contain alphabetic characters'),
  });
