import appStyles from '@root/appStyles';
import React from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import ChatScreenUI from './ChatScreenUI';

function ChatScreen() {
  return (
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: 'position', android: 'padding' })}
      enabled
      contentContainerStyle={appStyles.flex1}
      keyboardVerticalOffset={Platform.select({ android: 100 })}
      style={appStyles.flex1}>
      <ChatScreenUI />
    </KeyboardAvoidingView>
  );
}

export default ChatScreen;
