import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function shouldShowMandatory(isMandatory) {
  return isMandatory ? (
    <Text testID="txtMandatoryAsteriks" variant="xsm-bold" color={colors.error.rose}>
      *
    </Text>
  ) : null;
}

function LoginPhoneTxt(props) {
  const { isMandatory } = props;
  const { t } = useTranslation('common');
  return (
    <Text
      testID="txtLoginPhoneNumber"
      marginTop="12px"
      alignSelf="stretch"
      variant="sm-normal"
      color={colors.neutral.darkMist}
      {...props}>
      {t('login.phoneNumber')}
      {shouldShowMandatory(isMandatory)}
    </Text>
  );
}

LoginPhoneTxt.propTypes = {
  isMandatory: PropTypes.bool,
};

LoginPhoneTxt.defaultProps = {
  isMandatory: false,
};

export default LoginPhoneTxt;
