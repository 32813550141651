import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerParent: {
    alignItems: 'center',
    backgroundColor: colors.secondary.orchid,
    flexDirection: 'row',
    paddingBottom: scale(16),
    paddingLeft: scale(16),
    paddingRight: scale(16),
    paddingTop: scale(16),
    width: '100%',
  },
  referralContainer: { alignItems: 'center', flexDirection: 'row' },
});
export default styles;
