import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { BOTTONSHEET_PHONE_CONTENT, BOTTONSHEET_PHONE_ENROLLMENT, COMMON_CONSTANTS } from '@root/constants';
import { logResetPinLater, logXClick } from '@screens/GGAddBankScreen/analyticsBankScreen';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { goBackToHome, returnBottomSheetContent } from '@utils/utils';
import { Linking } from 'react-native';

export function bottomSheetHandlers(setInfoModal, infoModal, navigation, addPhoneNumber, variables) {
  const onPressPrimary = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      addPhoneNumber({ ...variables, securityPin: null });
      logResetPinLater();
    }
  };

  const resetPinHandler = () => Linking.openURL(COMMON_CONSTANTS.support);

  const onPressSecondaryHandler = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      goBackToHome(navigation);
      resetPinHandler();
      const e = AMPLITUDE_CONSTANTS.securityPin.reset_pin_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'pin_failed_modal_phonenumber',
      });
    }
  };

  const onCloseHandler = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));

    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      addPhoneNumber({ ...variables, securityPin: null });
      logXClick();
    }
  };
  return { onPressPrimary, onPressSecondaryHandler, onCloseHandler };
}

export function errorHandler(isError, error, navigation, setInfoModal, toast, t, setInitialData) {
  if (isError && error) {
    navigation.goBack();
    if (error[0] === 'GG-PHONE-02') {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_error;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.error_message]: 'number_duplicated',
      });
      return setInfoModal(prevState => ({
        ...prevState,
        data: returnBottomSheetContent(
          BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
          BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY],
        ),
        errorCode: BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
        visible: true,
      }));
    }

    if (error[0] === 'GG-PHONE-03') {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_error;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_phonenumber_page,
        [e.attributes.error_message]: 'max_reached',
      });
      return setInfoModal(prevState => ({
        ...prevState,
        data: returnBottomSheetContent(
          BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
          BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT],
        ),
        errorCode: BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
        visible: true,
      }));
    }

    return shouldShowError(isError, error, toast, t, navigation, setInitialData);
  }
}
