import CommonTextInput from '@atoms/CommonTextInput';
import RightArrow from '@atoms/RightArrow';
import ToastSuccess from '@atoms/ToastSuccess';
import ToastWarning from '@atoms/ToastWarning';
import BottomSheet from '@molecules/LoginInfoSheet';
import navigationConstants from '@navigation/navigationConstants';
import { CommonActions } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { COMMON_CONSTANTS } from '@root/constants';
import colors from '@theme/colors';
import { removeNonNumeric, resetStackToCustomScreen } from '@utils/utils';
import { View, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Linking, TouchableOpacity } from 'react-native';
import {
  logAccountNumberClicked,
  logAccountNumberFilled,
  logBankNameClicked,
  logBeneficiaryNameClicked,
  logBeneficiaryNameFilled,
  logNickNameClicked,
  logNickNameFilled,
  logThreeTry,
  logThreeTryVerify,
} from './analyticsBankScreen';
import styles from './styles';

function disableButtonForValues(values, errors) {
  let result = false;
  if (values?.accountName?.trim() === '' || errors?.accountName?.length > 0) {
    result = true;
  }
  if (values?.accountNumber?.trim() === '' || errors?.accountNumber?.length > 0) {
    result = true;
  }
  if (values && values?.selectedBank?.id === -1) {
    result = true;
  }
  return result;
}

function shouldShowBankNameOrPlaceholder(selectedBank, t) {
  return selectedBank === null ? t('addBank.bankTitlePlaceholder') : selectedBank.bankName;
}

export function shouldRenderSuccessfulBankAdd(isSuccess, data, toast, t, navigation, params) {
  if (isSuccess && data) {
    if (data.status === 'PENDING') {
      toast.show({
        render: () => <ToastWarning data={t('addBank.addPending')} />,
        placement: 'top',
        duration: 5000,
      });
    }
    if (data.status === 'APPROVED') {
      toast.show({
        render: () => <ToastSuccess data={t('addBank.addApproved')} />,
        placement: 'top',
        duration: 5000,
      });
    }

    const { routes } = navigation.getState();
    const enrolledBankExists = routes.filter(item => item.name === navigationConstants.BANK_LIST);
    if (enrolledBankExists.length > 0) {
      return resetStackToCustomScreen(navigation, params, navigationConstants.BANK_LIST);
    }
    if (params?.fromTransfer) {
      return navigation.dispatch(
        CommonActions.reset({
          index: 2,
          routes: [
            routes[0],
            { name: navigationConstants.GG_BANK_TRANSFER, params },
            { name: navigationConstants.BANK_LIST, params: { fromTransfer: false } },
          ],
        }),
      );
    }
    return navigation.dispatch(
      CommonActions.reset({
        index: 2,
        routes: [routes[0], { name: navigationConstants.BANK_LIST, params: { fromTransfer: false } }],
      }),
    );
  }
}

export function renderFormContentFunction(
  t,
  openBankList,
  selectedBank,
  setInfoModal,
  infoModal,
  addBankMutate,
  onPressSecondaryHandler,
  onCloseHandler,
  source,
) {
  const RenderFormContent = ({ handleChange, values, errors, handleSubmit }) => (
    <View style={[appStyles.marginHorizontal(16), appStyles.marginVertical(16)]}>
      <View>
        <Text variant="sm-normal" color={colors.neutral.darkMist}>
          {t('addBank.bankNameTitle')}
        </Text>
        <TouchableOpacity testID="btnSelectBank" style={styles.bankSelectButton} onPress={openBankList}>
          <Text variant="md-normal" color={selectedBank ? colors.neutral.charcoal : colors.neutral.darkMist} flex={1}>
            {shouldShowBankNameOrPlaceholder(selectedBank, t)}
          </Text>
          <RightArrow />
        </TouchableOpacity>
      </View>
      <View style={appStyles.marginTop(16)}>
        <Text variant="sm-normal" color={colors.neutral.darkMist}>
          {t('addBank.bankAccontNumTitle')}
        </Text>
        <CommonTextInput
          testID="txtInputAccountNumber"
          onChangeText={val => {
            const accountNumber = removeNonNumeric(val);
            handleChange('accountNumber')(accountNumber);
          }}
          placeholderTextColor={colors.neutral.darkMist}
          value={values.accountNumber}
          style={styles.inputStyles}
          placeholder={t('addBank.bankNameTitlePlaceholder')}
          keyboardType="numeric"
          amplitudeCallBackOnFocus={logAccountNumberClicked(source)}
          amplitudeCallBackOnBlur={logAccountNumberFilled(source)}
        />
        {errors?.accountNumber && errors?.accountNumber.length > 0 ? (
          <Text variant="sm-normal" color={colors.error.rose}>
            {errors.accountNumber}
          </Text>
        ) : null}
      </View>
      <View style={appStyles.marginTop(16)}>
        <Text color={colors.neutral.darkMist}>{t('addBank.bankAccountName')}</Text>
        <CommonTextInput
          testID="txtInputAccountName"
          onChangeText={handleChange('accountName')}
          value={values.accountName}
          style={styles.inputStyles}
          placeholderTextColor={colors.neutral.darkMist}
          placeholder={t('addBank.bankAccountNamePlaceholder')}
          amplitudeCallBackOnFocus={logBeneficiaryNameClicked(source)}
          amplitudeCallBackOnBlur={logBeneficiaryNameFilled(source)}
        />
        {values?.accountName?.length > 0 && errors?.accountName && errors?.accountName.length > 0 ? (
          <Text variant="sm-normal" color={colors.error.rose}>
            {errors.accountName}
          </Text>
        ) : null}
      </View>
      <View style={appStyles.marginTop(16)}>
        <Text color={colors.neutral.darkMist}>{t('addBank.bankAccountNickName')}</Text>
        <CommonTextInput
          testID="txtInputAccountNickName"
          onChangeText={handleChange('accountNickName')}
          value={values.accountNickName}
          style={styles.inputStyles}
          placeholderTextColor={colors.neutral.darkMist}
          placeholder={t('addBank.bankAccountNickNamePlaceholder')}
          amplitudeCallBackOnFocus={logNickNameClicked(source)}
          amplitudeCallBackOnBlur={logNickNameFilled(source)}
        />
      </View>
      <Button
        testID="btnAddBankSubmitForm"
        isDisabled={disableButtonForValues(values, errors)}
        style={appStyles.marginVertical(16)}
        onPress={handleSubmit}>
        {t('addBank.bankAccountAdd')}
      </Button>
      <BottomSheet
        onPressPrimary={() => {
          setInfoModal(prevState => ({ ...prevState, visible: false }));
          if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
            logThreeTry();
            addBankMutate({
              accountNumber: values.accountNumber,
              bankId: values.selectedBank.id,
              beneficiaryName: values.accountName,
              nickName: values.accountNickName,
            });
          }
        }}
        loginInfo={infoModal}
        onPressSecondary={onPressSecondaryHandler}
        onClose={() => {
          onCloseHandler(values);
        }}
      />
    </View>
  );
  RenderFormContent.propTypes = {
    handleChange: PropTypes.func,
    errors: PropTypes.shape({
      beneficiaryName: PropTypes.string,
      accountName: PropTypes.string,
      accountNickName: PropTypes.string,
      accountNumber: PropTypes.string,
    }),
    values: PropTypes.shape({
      accountNumber: PropTypes.string,
      selectedBank: PropTypes.shape({ id: PropTypes.string }),
      beneficiaryName: PropTypes.string,
      accountName: PropTypes.string,
      accountNickName: PropTypes.string,
    }),
    handleSubmit: PropTypes.func,
  };
  RenderFormContent.defaultProps = {
    handleChange: () => {},
    values: {
      accountNumber: '',
      selectedBank: { id: '0' },
      beneficiaryName: '',
      accountName: '',
      accountNickName: '',
    },
    errors: { accountNumber: '', beneficiaryName: '', accountName: '', accountNickName: '' },
    handleSubmit: () => {},
  };
  return RenderFormContent;
}

export function addBankHandlers(bankHandlerParams) {
  const { setInfoModal, infoModal, navigation, setSelectedBank, source } = bankHandlerParams;

  const resetPinHandler = () => Linking.openURL(COMMON_CONSTANTS.support);

  const onPressSecondaryHandler = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      resetPinHandler();
      logThreeTryVerify();
    }
  };

  const openBankList = () => {
    logBankNameClicked(source);
    navigation.navigate(navigationConstants.GG_ALL_BANKS, { setSelectedBank, source });
  };
  return { openBankList, onPressSecondaryHandler };
}
