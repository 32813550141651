import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  collapseContainerStyle: {
    backgroundColor: colors.neutral.cotton,
    marginBottom: scale(10),
  },
  container: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    borderBottomWidth: scale(4),
    borderColor: colors.neutral.mist,
    borderTopWidth: scale(4),
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: scale(-2),
    padding: scale(15),
  },
  iconContainerStyle: { flexDirection: 'row' },
});

export default styles;
