import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  descStyles: {
    color: colors.neutral.charcoal,
    marginBottom: scale(7),
    marginTop: scale(4),
  },
  iconStyle: { marginTop: scale(7) },
  listContainer: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    marginBottom: scale(10),
    marginTop: scale(5),
    paddingHorizontal: scale(5),
    paddingTop: scale(4),
  },
  outerContainer: {
    marginBottom: scale(10),
    marginTop: scale(15),
    paddingHorizontal: scale(15),
  },
  row: { flexDirection: 'row' },
  textContainer: { marginLeft: scale(10), width: '85%' },
});

export default styles;
