import IconRightArrow from '@atoms/IconRightArrow';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logPhoneListClicked from './analyticsPhoneEnroll';

function onPhoneNavigation(navigation) {
  return () => {
    logPhoneListClicked();
    navigation.navigate(navigationConstants.ENROLLED_PHONES, {
      isTransact: false,
      eventSource: AMPLITUDE_EVENT_SOURCES.others_page,
    });
  };
}

function ProfilePhoneEnroll(props) {
  const { showPhoneEnroll } = props;
  const { t } = useTranslation('common');
  const navigation = useNavigation();
  const PhoneImage = SVG_ICONS.phoneEnroll.phoneIcon;
  if (showPhoneEnroll) {
    return (
      <TouchableOpacity testID="btnEnrollPhone" onPress={onPhoneNavigation(navigation)}>
        <View flexDirection="row" marginTop="8px" alignItems="center">
          <View
            backgroundColor={colors.secondary.softOrchid}
            paddingX="4px"
            paddingBottom="4.5px"
            paddingTop="3.5px"
            borderRadius="12px"
            justifyContent="center">
            <PhoneImage />
          </View>
          <Text testID="txtPhoneEnroll" marginLeft="8px" flex={1} variant="md-normal" alignSelf="center">
            {t('profile.phoneEnroll')}
          </Text>
          <IconRightArrow />
        </View>
      </TouchableOpacity>
    );
  }
  return null;
}

ProfilePhoneEnroll.propTypes = {
  showPhoneEnroll: PropTypes.bool,
};
ProfilePhoneEnroll.defaultProps = {
  showPhoneEnroll: false,
};

export default ProfilePhoneEnroll;
