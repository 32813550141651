import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Icon, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

function SignUpDataSelect(props) {
  const { setOpenModalForSelection, signUpValidation, validationType, t } = props;
  return (
    <View marginTop="24px">
      <Text testID="txtDataTypeForChecking" variant="sm-bold" color={colors.disabled.smoke}>
        {t('signup.dataTypeForChecking')} <Text color={colors.error.rose}>*</Text>
      </Text>
      <TouchableOpacity
        testID="selectDataType"
        sentry-label="selectDataType"
        style={styles.dataSelectionStyles}
        onPress={setOpenModalForSelection}>
        <View style={styles.rowCenter}>
          <Text
            testID="txtSignUpValidationTitle"
            variant="md-normal"
            color={colors.neutral.charcoal}
            style={styles.signUpValidateTextStyles}>
            {t(`signup.${signUpValidation[validationType].title}`)}
          </Text>
          <Icon as={AntDesign} name="down" color={colors.neutral.charcoal} />
        </View>
      </TouchableOpacity>
    </View>
  );
}

SignUpDataSelect.propTypes = {
  setOpenModalForSelection: PropTypes.func,
  signUpValidation: PropTypes.arrayOf({
    title: PropTypes.string,
  }),
  validationType: PropTypes.number,
  t: PropTypes.func,
};

SignUpDataSelect.defaultProps = {
  setOpenModalForSelection: () => {},
  signUpValidation: [{ title: '' }],
  validationType: 0,
  t: () => {},
};

export default SignUpDataSelect;
