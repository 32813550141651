import { gql } from '@apollo/client';

const SECURITY_PIN = gql`
  query checkPin {
    employee_security_pin(where: { securityPin: { _is_null: false } }) {
      invalidAttempts
    }
  }
`;

export { SECURITY_PIN };

export function test() {}
