import ProfilePICBonusRow from '@atoms/ProfilePICBonusRow';
import ProfilePointsRow from '@atoms/ProfilePointsRow';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { Divider, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function shouldShowProfilePICBonusRow(showBonus) {
  if (showBonus) {
    return <ProfilePICBonusRow />;
  }
  return null;
}
function ProfileBonus(props) {
  const { showBonus } = props;
  const { employeeProfile } = getStore();
  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  const { t } = useTranslation('common');
  return (
    <>
      <View style={appStyles.marginLeft(16)} marginY="16px">
        <Text testID="txtProfileBonusHeader" variant="md-bold">
          {t('profile.bonusHeader')}
        </Text>
        <ProfilePointsRow />
        {shouldShowProfilePICBonusRow(showBonus && isPIC)}
      </View>
      <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
    </>
  );
}

ProfileBonus.propTypes = {
  showBonus: PropTypes.bool,
};
ProfileBonus.defaultProps = {
  showBonus: false,
};
export default ProfileBonus;
