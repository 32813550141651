import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  infoContainer: {
    alignItems: 'center',
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(8),
    marginTop: scale(16),
    paddingVertical: 8,
  },
});
export default styles;
