import { getStore } from '@store/storeUtils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function TextCurrency(props) {
  const { amountCurrency, showCurrencySymbol, roundOff, suffix } = props;

  let numberValue = isNaN(amountCurrency) ? parseInt(amountCurrency, 10) : amountCurrency;

  if (roundOff) numberValue = Math.floor(numberValue);

  const { tenantSettings } = getStore();
  const { currencySymbol, numberFormatting, currencyShortName } = tenantSettings;

  return (
    <Text testID="txtCurrency" sentry-label="txtCurrency" {...props}>
      {showCurrencySymbol ? currencySymbol : null}
      {numberValue.toLocaleString(numberFormatting, { currency: currencyShortName, string: 'currency' })}
      {suffix}
    </Text>
  );
}

TextCurrency.propTypes = {
  testID: PropTypes.string,
  amountCurrency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showCurrencySymbol: PropTypes.bool,
  roundOff: PropTypes.bool,
  suffix: PropTypes.string,
};
TextCurrency.defaultProps = {
  testID: 'txtCurrency',
  amountCurrency: 0,
  showCurrencySymbol: true,
  roundOff: false,
  suffix: '',
};
export default TextCurrency;
