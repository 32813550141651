import { apiBPRequest } from '@api/apiCall';
import { useNavigation } from '@react-navigation/native';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'native-base';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Custom Hook
const useBPMutation = ({ setOpenRTS, setBpReqData }) => {
  const { setLoading, setInitialData } = getStore();
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  const [apiPayload, setApiPayload] = useState(null);
  const {
    isLoading: isBPReqLoading,
    isError: isBPReqError,
    error: bpError,
    isSuccess: isBPSuccess,
    data: bpData,
    mutate: requestBP,
  } = useMutation({
    mutationFn: payload => {
      setApiPayload(payload);
      return apiBPRequest(payload);
    },
  });

  useEffect(() => {
    setLoading(isBPReqLoading);
  }, [isBPReqLoading]);

  useEffect(() => {
    if (isBPSuccess && bpData) {
      setBpReqData(bpData);
      setOpenRTS(true);
    }
  }, [isBPSuccess, bpData]);

  useEffect(() => {
    showErrorOnAPI(isBPReqError, bpError, toast, t, navigation, setInitialData);
  }, [isBPReqError, bpError]);

  return { requestBP, isBPReqLoading, isBPReqError, bpError, isBPSuccess, bpData, apiPayload };
};

export default useBPMutation;
