import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export function getPicRole(isPIC) {
  return isPIC ? 'pic' : 'normal_user';
}

export function logInputChange(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.type_search_bar;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pic_page',
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}

export function logTermsPress(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.pic_tnc_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pic_page',
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}

export function logPicPage(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.pic_zone_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}

export function logInviteStatusClicked(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.invite_status_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pic_page',
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}

export function logEmployeeList(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.employee_list_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pic_page',
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}
