import { BOTTONSHEET_PHONE_ENROLLMENT } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { onClickSecondaryPhonNoBottomSheet } from '@utils/utils';

export function onDeletePhoneCompleteCallBack(infoModal, getPhoneNumbers, setInfoModal, initalState) {
  const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_confirmed;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.number]: infoModal.selectedNumber,
    [e.attributes.name]: infoModal.selectedName,
  });
  getPhoneNumbers();
  setInfoModal(initalState);
}

export function getBottomSheetHandlers(infoModal, setInfoModal, deletePhoneNumber, initalState) {
  const onPressPrimaryBottomSHeet = () => {
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      onClickSecondaryPhonNoBottomSheet(
        setInfoModal,
        infoModal,
        deletePhoneNumber,
        infoModal.currentId,
        BOTTONSHEET_PHONE_ENROLLMENT,
      );
    } else {
      setInfoModal(initalState);
    }
  };

  const onPressSecondaryBottomSheet = () => {
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_cancelled;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.number]: infoModal.selectedNumber,
        [e.attributes.name]: infoModal.selectedName,
        [e.attributes.button]: 'cancel',
      });
    }
    setInfoModal(initalState);
  };

  const onBottomSheetCloseHandler = () => {
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_cancelled;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.number]: infoModal.selectedNumber,
        [e.attributes.name]: infoModal.selectedName,
        [e.attributes.button]: 'close',
      });
    }
    setInfoModal(initalState);
  };
  return { onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onBottomSheetCloseHandler };
}

export function shouldShowFooterComponent(isTransact, footerComponent) {
  return isTransact ? null : footerComponent();
}
