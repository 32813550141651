import { apiLogin } from '@api/apiCall';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { formatPhoneNumber, formatUnderScoreLowerCase, returnBottomSheetContent } from '@utils/utils';
import { Button, Divider, Icon, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shouldRenderError, shouldRenderOnSuccess, shouldShowLoginInfoSheet } from './loginButtonsUtils';
import styles from './styles';

function LoginButtons(props) {
  const { fromLogin, setErrTxtMsg, setOtp, disableOTP, setDisableOTP } = props;
  const navigation = useNavigation();
  const { tenantSettings, loginData, setLoginData, setLoading } = getStore();
  const [, setIsLoadingSMS] = useState(false);
  const [, setIsLoadingWhatsapp] = useState(false);
  const { countryCode } = tenantSettings;
  const { mobileNumber } = loginData;
  const { t } = useTranslation('common');
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.RESEND_OTP],
      mobileNumber,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
  });
  const {
    isLoading,
    mutate: performLogin,
    isSuccess,
    data: LoginData,
    isError,
    error,
  } = useMutation({ mutationFn: payload => apiLogin(payload) });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsLoadingSMS(false);
    setIsLoadingWhatsapp(false);
    // (async () => {
    shouldRenderError({
      isError,
      error,
      setIsLoadingSMS,
      setIsLoadingWhatsapp,
      setLoading,
      setErrTxtMsg,
      t,
      mobileNumber,
      setInfoModal,
      setDisableOTP,
    });
    // })();
    setOtp('');
  }, [isError, error]);

  useEffect(() => {
    setIsLoadingSMS(false);
    setIsLoadingWhatsapp(false);
    shouldRenderOnSuccess({
      LoginData,
      isSuccess,
      setLoginData,
      setIsLoadingSMS,
      setIsLoadingWhatsapp,
      fromLogin,
      navigation,
      setInfoModal,
    });
    setOtp('');
  }, [LoginData, isSuccess]);

  const onCloseModal = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
  };

  return (
    <>
      <View style={styles.loginButtonContainer}>
        <Button
          testID="whatsapp-button"
          sentry-label="whatsapp-button"
          alignContent="center"
          justifyContent="center"
          flex={1}
          alignSelf="stretch"
          alignItems="center"
          isDisabled={disableOTP || isLoading}
          _disabled={{ _text: colors.disabled.smoke, backgroundColor: colors.neutral.cotton }}
          android_ripple={colors.disabled.smoke}
          isLoading={false}
          onPress={() => {
            setLoading(true);
            setIsLoadingSMS(false);
            setIsLoadingWhatsapp(true);
            const payload = {
              otpType: 'WHATSAPP',
              mobileNumber: formatPhoneNumber(mobileNumber, countryCode),
            };
            const e = AMPLITUDE_CONSTANTS.authentication.otp_resend_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.phone_number]: payload.mobileNumber,
              [e.attributes.source]: formatUnderScoreLowerCase(payload.otpType),
            });
            performLogin(payload);
          }}
          size="sm"
          leftIcon={
            <Icon
              fontSize={scale(18)}
              marginBottom="3px"
              as={FontAwesome}
              name="whatsapp"
              color={disableOTP ? colors.disabled.smoke : colors.neutral.charcoal}
            />
          }
          backgroundColor={colors.neutral.cotton}
          _pressed={{ backgroundColor: colors.disabled.smoke }}
          style={styles.btnLeftStyle}>
          <Text
            testID="txtWhatsapp"
            variant="sm-bold"
            color={disableOTP ? colors.disabled.smoke : colors.neutral.charcoal}>
            {t('login.whatsapp')}
          </Text>
        </Button>
        <Divider width="1px" orientation="vertical" />
        <Button
          testID="sms-button"
          sentry-label="sms-button"
          alignContent="center"
          flex={1}
          alignItems="center"
          justifyContent="center"
          alignSelf="stretch"
          _disabled={{ _text: colors.disabled.smoke, backgroundColor: colors.neutral.cotton }}
          isDisabled={disableOTP || isLoading}
          size="sm"
          style={styles.btnRightStyle}
          onPress={() => {
            setIsLoadingSMS(true);
            setIsLoadingWhatsapp(false);
            setLoading(true);
            const payload = {
              otpType: 'SMS',
              mobileNumber: formatPhoneNumber(mobileNumber, countryCode),
            };
            const e = AMPLITUDE_CONSTANTS.authentication.otp_resend_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.phone_number]: payload.mobileNumber,
              [e.attributes.source]: formatUnderScoreLowerCase(payload.otpType),
            });
            performLogin(payload);
          }}
          isLoading={false}
          leftIcon={
            <Icon
              fontSize={scale(18)}
              as={MaterialIcons}
              marginBottom="2px"
              name="message"
              color={disableOTP ? colors.disabled.smoke : colors.neutral.charcoal}
            />
          }
          android_ripple={colors.disabled.smoke}
          _pressed={{ backgroundColor: colors.disabled.smoke }}
          backgroundColor={colors.neutral.cotton}>
          <Text testID="txtSms" variant="sm-bold" color={disableOTP ? colors.disabled.smoke : colors.neutral.charcoal}>
            {t('login.sms')}
          </Text>
        </Button>
      </View>
      {shouldShowLoginInfoSheet(onCloseModal, infoModal)}
    </>
  );
}

LoginButtons.propTypes = {
  fromLogin: PropTypes.bool,
  disableOTP: PropTypes.bool,
  setErrTxtMsg: PropTypes.func,
  setOtp: PropTypes.func,
  setDisableOTP: PropTypes.func,
};
LoginButtons.defaultProps = {
  fromLogin: true,
  disableOTP: false,
  setErrTxtMsg: () => {},
  setOtp: () => {},
  setDisableOTP: () => {},
};

export default LoginButtons;
