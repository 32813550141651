import { apiBillPayment } from '@api/apiCall';
import ShimmerBPList from '@atoms/Shimmers/ShimmerBPList';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, ScrollView } from 'react-native';
import { shouldRenderError, shouldSetBPListOnSuccess, shouldShowProductCategoryList } from './bpListUtils';

function BPList(props) {
  const { source } = props;
  const [bpProducts, setBPProducts] = useState([]);
  const { selectedBPAccount, setInitialData } = getStore();
  const {
    isLoading,
    mutate: fetchBP,
    isSuccess,
    data,
    isError,
    error,
  } = useMutation({
    mutationFn: payload => apiBillPayment(payload),
  });

  const toast = useToast();
  const { t } = useTranslation('common');
  const navigation = useNavigation();

  useEffect(() => {
    fetchBP(selectedBPAccount);
  }, [selectedBPAccount]);

  useEffect(() => {
    shouldRenderError(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    shouldSetBPListOnSuccess(isSuccess, data, setBPProducts);
  }, [isSuccess, data]);
  return (
    <ScrollView
      style={[appStyles.marginTop(16), appStyles.safeAreaView]}
      refreshControl={<RefreshControl refreshing={isLoading} onRefresh={() => fetchBP(selectedBPAccount)} />}>
      {isLoading ? <ShimmerBPList /> : shouldShowProductCategoryList(bpProducts, source)}
    </ScrollView>
  );
}

BPList.propTypes = {
  source: PropTypes.string,
};
BPList.defaultProps = {
  source: '',
};
export default BPList;
