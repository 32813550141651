import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { serverError } from '@utils/utils';
import { Divider, Icon } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

export function getItemSeperatorComponent() {
  return () => <Divider />;
}

export function selectDataAndCheckBox(selectValidationType, index, dataType, setDataType, setOpenModalForSelection) {
  selectValidationType(index);
  const changedData = dataType.map((dt, dtIndex) => {
    const localCopyOfData = dt;
    if (index === dtIndex) {
      localCopyOfData.selected = true;
      return { ...localCopyOfData };
    }
    localCopyOfData.selected = false;
    return { ...localCopyOfData };
  });

  setDataType(changedData);
  setOpenModalForSelection(false);
}

export function shouldShowSelectedCheck(selected) {
  if (selected) {
    return <Icon as={AntDesign} name="check" color={colors.error.rose} marginRight={5} size="24px" />;
  }
  return null;
}

export function shouldValidateForm(formData) {
  let result = true;
  if (formData.employerName === undefined || formData.employerName === '') {
    result = false;
  }
  if (formData.userName === undefined || formData.userName === '') {
    result = false;
  }
  if (formData.phoneNumber === undefined || formData.phoneNumber === '') {
    result = false;
  }
  if (formData.identityType === undefined || formData.identityType === '') {
    result = false;
  }
  if (formData.identityId === undefined || formData.identityId === '') {
    result = false;
  }
  return result;
}

export function shouldSetMarginTop() {
  return Platform.OS === 'web' ? 0 : '-18px';
}

export function shouldCheckForEditable(route) {
  return !(route?.params?.phoneNumber && route?.params?.phoneNumber.length > 0);
}

export function shouldCheckForDisabled(route) {
  return route?.params?.phoneNumber && route?.params?.phoneNumber.length > 0;
}

export function shouldRenderSignUpSuccess(isSuccess, signUpData, setInfoModal, infoModal) {
  if (isSuccess && signUpData) {
    setInfoModal({ ...infoModal, visible: true });
  }
}

export function shouldRenderSignUpError(isError, error, toast, t, navigation, setInitialData) {
  if (isError && error) {
    serverError(error, toast, t, navigation, setInitialData);
  }
}
