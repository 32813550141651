import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logPhoneListClicked() {
  const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phone_list_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
  });
}

export default logPhoneListClicked;
