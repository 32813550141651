import * as ImagePicker from 'expo-image-picker';
import { Platform } from 'react-native';
import AmplitudeHelper from './analytics';
import { AMPLITUDE_CONSTANTS } from './analytics/constants';
import { formatUnderScoreLowerCase } from './utils';

const openImagePicker = () =>
  ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    base64: false,
    allowsEditing: false,
    aspect: [4, 3],
    quality: 1,
  });

const fetchImageAsBinary = async imageUri => {
  try {
    const response = await fetch(imageUri);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blobData = await response.blob();
    return new File([blobData], 'Bukti.png', { type: 'image/png' });
  } catch (error) {
    return null;
  }
};

const onImagePick = async (onCloseModal, setImageObj, transactionId) => {
  onCloseModal();
  let failReason = 'user_cancelled';
  const e = AMPLITUDE_CONSTANTS.workAllowance.image_chosen;
  try {
    const e1 = AMPLITUDE_CONSTANTS.workAllowance.upload_clicked;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.transaction_id]: formatUnderScoreLowerCase(transactionId),
    });
    const response = await openImagePicker();
    if (!response.canceled) {
      let fileData = null;
      if (Platform.OS === 'web') fileData = await fetchImageAsBinary(response.assets?.[0]?.uri);
      else fileData = response;

      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.status]: 'success',
        [e.attributes.failure_message]: null,
        [e.attributes.transaction_id]: formatUnderScoreLowerCase(transactionId),
      });
      setImageObj(prevState => ({ ...prevState, file: fileData, uri: response.assets?.[0]?.uri }));
    } else {
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.status]: 'failed',
        [e.attributes.failure_message]: failReason,
        [e.attributes.transaction_id]: formatUnderScoreLowerCase(transactionId),
      });
    }
  } catch (err) {
    // eerror

    failReason = 'others';
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.status]: 'failed',
      [e.attributes.failure_message]: failReason,
      [e.attributes.transaction_id]: formatUnderScoreLowerCase(transactionId),
    });
  }
};

export { openImagePicker, fetchImageAsBinary, onImagePick };
