import colors from '@theme/colors';
import { getDateInFormatLocale } from '@utils/dateUtils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function OpenPayPeriodText(props) {
  const { t } = useTranslation('common');
  const { openPeriod } = props;
  const { start, end, payDateWithdrawal } = openPeriod;
  if (start !== '' && end !== '') {
    return (
      <Text
        testID="txtOpenPayPeriod"
        marginBottom="16px"
        textAlign="center"
        variant="xsm-bold"
        color={colors.neutral.darkMist}
        flex={1}>
        {t('withdrawlSlider.openPeriodText')} {getDateInFormatLocale(start, 'DD MMM YYYY')} -{' '}
        {getDateInFormatLocale(end, 'DD MMM YYYY')} {payDateWithdrawal ? t('withdrawlSlider.payDateWithdrawal') : ''}
      </Text>
    );
  }
  return null;
}

OpenPayPeriodText.propTypes = {
  openPeriod: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    payDateWithdrawal: PropTypes.bool,
  }),
};
OpenPayPeriodText.defaultProps = {
  openPeriod: {
    start: '',
    end: '',
    payDateWithdrawal: false,
  },
};
export default OpenPayPeriodText;
