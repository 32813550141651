import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export function logLogoutClicked() {
  const e = AMPLITUDE_CONSTANTS.ProfilePage.logout_clicked;
  AmplitudeHelper.logEvent(e.name, { [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page });
}

export function logoutConfirmedClicked() {
  const e = AMPLITUDE_CONSTANTS.ProfilePage.logout_confirm_clicked;
  AmplitudeHelper.logEvent(e.name, { [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page });
}

export function logCloseModal(type) {
  const e = AMPLITUDE_CONSTANTS.ProfilePage.logout_cancelled_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    [e.attributes.button]: type,
  });
}
