import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase, getAmplitudeKeyValue } from '@utils/utils';

export function logWithdrawalRequest(
  amplitudeAccountName,
  withdrawalAmount,
  reasonData,
  validatedReferralCode,
  transaction_category_id,
  source,
  meAmplitudeContext,
) {
  const e = AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.transaction_category_id]: transaction_category_id,
    [attributes.dynamic_account_name]: amplitudeAccountName,
    [attributes.requested_amount]: Math.floor(withdrawalAmount),
    [attributes.reason_id]: reasonData?.id,
    [attributes.reason]: formatUnderScoreLowerCase(reasonData.reason),
    [attributes.source]: source,
    [attributes.referral_code]: validatedReferralCode,
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}

export function logHideReasonsModal(source, amplitudeAccountName, validatedReferralCode) {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.name, {
    [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.attributes.dynamic_account_name]: amplitudeAccountName,
    [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.attributes.referral_code]: validatedReferralCode,
  });
}

export function logShowReasonsModal(source, amplitudeAccountName, validatedReferralCode) {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.name, {
    [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.source]: source,
    [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.dynamic_account_name]: amplitudeAccountName,
    [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.referral_code]: validatedReferralCode,
  });
}
