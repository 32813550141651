import CommonTextInput from '@atoms/CommonTextInput';
import BPDropdownWithAccounts from '@molecules/BPDropdownWithAccounts';
import BPDropdownWithRegions from '@molecules/BPDropdownWithRegions';
import { ButtonGroup } from '@rneui/themed';
import appStyles from '@root/appStyles';
import { shouldShowErrorForAccountInput } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { logAccountNumberBlur, logAccountNumberFocus } from './analyticsAccountNumber';
import styles from './styles';

function shouldReturnViewForSelectedIndex(selectedIndex, t, props, validatedReferralCode) {
  const { sAccountInputError, source, productConfig } = props;
  if (selectedIndex === 0) {
    return (
      <View>
        <Text variant="sm-normal" style={styles.customerIdTextStyles}>
          {t('bp.customerId')}
        </Text>
        <CommonTextInput
          placeholder={t('bp.customerIdInput')}
          onChangeText={val => props.setSelectedAccountNumber(val)}
          amplitudeCallBackOnBlur={logAccountNumberBlur(source, productConfig, validatedReferralCode)}
          amplitudeCallBackOnFocus={logAccountNumberFocus(source, productConfig, validatedReferralCode)}
        />
        {shouldShowErrorForAccountInput(sAccountInputError)}
        <BPDropdownWithRegions {...props} />
      </View>
    );
  }
  return (
    <View>
      <BPDropdownWithAccounts {...props} />
      <BPDropdownWithRegions {...props} />
    </View>
  );
}

const analyticsIndex = [
  AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked,
  AMPLITUDE_CONSTANTS.BillPayment.AccountInput.registeredIdTabClicked,
];

function AccountNumberInput(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { validatedReferralCode } = getStore();

  const { t } = useTranslation('common');
  const { productConfig, source } = props;
  useEffect(() => {
    const event = analyticsIndex[selectedIndex];
    AmplitudeHelper.logEvent(event?.name, {
      [event?.attributes?.transaction_category_id]: productConfig.transaction_category_id,
      [event?.attributes?.source]: source,
      [event?.attributes?.referral_code]: validatedReferralCode,
    });
  }, [selectedIndex]);
  return (
    <View>
      <View style={appStyles.marginHorizontal(16)}>
        <ButtonGroup
          containerStyle={styles.btnGrpContainer}
          selectedButtonStyle={styles.btnSelectedStyle}
          textStyle={styles.btnTxtStyle}
          selectedTextStyle={styles.selectedTextStyles}
          buttons={[t('bp.new'), t('bp.registeredID')]}
          selectedIndex={selectedIndex}
          onPress={value => {
            setSelectedIndex(value);
          }}
        />
        {shouldReturnViewForSelectedIndex(selectedIndex, t, props, validatedReferralCode)}
      </View>
      <Divider style={styles.dividerStyles} color={colors.neutral.mist} />
    </View>
  );
}

AccountNumberInput.propTypes = {
  source: PropTypes.string,
  productConfig: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    noOfColumns: PropTypes.number,
    hasAccountInput: PropTypes.bool,
    transaction_category_id: PropTypes.string,
  }),
};
AccountNumberInput.defaultProps = {
  source: '',
  productConfig: {
    productId: '',
    productName: '',
    noOfColumns: 1,
    hasAccountInput: false,
    transaction_category_id: '',
  },
};
export default AccountNumberInput;
