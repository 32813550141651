import PinWithAddPhone from '@molecules/PinWithAddPhone';
import { addPhoneTemplate3 } from '@root/constants';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { filterRejected } from '@utils/utils';
import React from 'react';
import logPhonePage from './analyticsPhoneEnrolled';

export function onCompleteGetPhoneCallBack(data, setPhoneList, isTransact, eventSource, transactionCategoryId) {
  // return data => {
  setPhoneList(filterRejected(data, isTransact) ?? []);
  logPhonePage(eventSource, filterRejected(data, isTransact), isTransact, transactionCategoryId);
  // };
}

export function shouldShowLinkToAddPhone(isTransact, transactionCategoryId) {
  return isTransact ? (
    <PinWithAddPhone
      type={addPhoneTemplate3}
      pageSource={AMPLITUDE_EVENT_SOURCES.select_phone_page}
      isTransact
      transactionCategoryId={transactionCategoryId}
    />
  ) : null;
}
