import TextCurrency from '@atoms/TextCurrency';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ReferralEarnPoins(props) {
  const { amount } = props;
  const { t } = useTranslation('common');
  return (
    <View flexDirection="row" justifyContent="center">
      {SVG_ICONS.coin}
      <TextCurrency
        amountCurrency={amount}
        variant="lg-bold"
        showCurrencySymbol={false}
        color={colors.warning.darkSunflower}
      />
      <Text variant="lg-bold" color={colors.warning.darkSunflower}>
        {t('referral.txtPoin')}
      </Text>
    </View>
  );
}

ReferralEarnPoins.propTypes = {
  amount: PropTypes.number,
};
ReferralEarnPoins.defaultProps = {
  amount: 0,
};

export default ReferralEarnPoins;
