import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tncContainer: {
    flexDirection: 'row',
    paddingLeft: 12,
    paddingTop: 16,
  },
  tncNumberStyle: {
    backgroundColor: colors.neutral.charcoal,
    borderRadius: 24,
    color: colors.neutral.cotton,
    height: 24,
    paddingHorizontal: 8,
    textAlign: 'center',
    width: 24,
  },
});
export default styles;
