import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function sliderCompleteAnalytics(validatedReferralCode) {
  return value => {
    const e = AMPLITUDE_CONSTANTS.Withdrawal.withdrawSliderEvent;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.requested_amount]: value?.toString() ?? null,
      [attributes.source]: 'home_page',
      [attributes.referral_code]: validatedReferralCode || null,
    });
  };
}

export function onManualEditingFinished(validatedReferralCode) {
  return e => {
    const event = AMPLITUDE_CONSTANTS.Withdrawal.manualWithdrawal;
    const { attributes, name } = event;
    AmplitudeHelper.logEvent(name, {
      [attributes.requested_amount]: e?.target?.value?.toString() ?? null,
      [attributes.source]: 'home_page',
      [attributes.referral_code]: validatedReferralCode,
    });
  };
}
