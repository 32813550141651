import { Entypo } from '@expo/vector-icons';
import colors from '@theme/colors';
import i18next from 'i18next';
import { Button, Icon } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getChangedLanguage } from './btnLanguageUtils';

async function changeLanguage() {
  const selectedLanguage = i18next.language;
  const changeLanguageTo = getChangedLanguage(selectedLanguage);
  await i18next.changeLanguage(changeLanguageTo);
}

function BtnLanguage() {
  const { t } = useTranslation('common');
  return (
    <Button
      shadow={1}
      variant="ghost"
      flex={1}
      testID="btnLanguageChange"
      backgroundColor={colors.transparent}
      borderColor={colors.transparent}
      sentry-label="btnLanguageChange"
      _text={{
        testID: 'Language',
        color: colors.neutral.cotton,
        size: 'sm',
        fontWeight: '200',
      }}
      onPress={async () => {
        await changeLanguage();
      }}
      leftIcon={<Icon as={Entypo} name="language" color={colors.neutral.cotton} />}>
      {t('common.Language')}
    </Button>
  );
}

BtnLanguage.propTypes = {};
BtnLanguage.defaultProps = {};
export default BtnLanguage;
