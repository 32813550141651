import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  imageContainer: { height: 32, width: 32 },
  imageStyle: { height: '100%', width: '100%' },
  outerContainer: {
    flexDirection: 'row',
    marginTop: 24,
    paddingHorizontal: 14,
    width: '100%',
  },
  subContainer: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 12,
    height: 42,
    justifyContent: 'center',
    marginTop: 4,
    width: 42,
  },
  viewRightContainer: {
    paddingHorizontal: 10,
    paddingRight: 40,
    width: '90%',
  },
});
export default styles;
