import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  subProductTile: {
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.neutral.cotton,
    borderRadius: 8,
    borderWidth: 1,
    elevation: 6,
    marginHorizontal: 0,
    marginTop: verticalScale(12),
    overflow: 'hidden',
    paddingHorizontal: scale(16),
    paddingVertical: verticalScale(14),
  },
});
export default styles;
