import AppliedReferral from '@atoms/AppliedReferral';
import BPTnc from '@atoms/BPTnc';
import BPTncCheckBox from '@atoms/BPTncCheckBox';
import BPRequestAccountSelector from '@molecules/BPRequestAccountSelector';
import { logTxClosedWithSource } from '@molecules/RequestTransactionSheet/analyticsRequestTxSheet';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { View, Text, Divider, Modal, Button } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { logTermsPage, logTxConfirmation } from './analyticsTransaction';
import { showProdDetailsFromConfigAndApi } from './bpTnCAndBillDetaillsUtils';
import styles from './styles';

function getCode(props, validatedReferralCode) {
  if (props.isGold) return props.promocode;
  return validatedReferralCode;
}

function BPTncAndBillDetails({
  isLoading,
  validatedReferralCode,
  tncOpen,
  productConfig,
  props,
  bpReqData,
  tncChecked,
  confirmBP,
  setTncOpen,
  setTncChecked,
  setOpenRTS,
  selectedBPAccount,
  setSelectedBPAccount,
  source,
  meAmplitudeContext,
}) {
  const { t } = useTranslation('common');
  if (tncOpen) {
    logTermsPage(productConfig, source, validatedReferralCode);
    return (
      <BPTnc
        setTncChecked={setTncChecked}
        setTncOpen={setTncOpen}
        transactionCategoryId={productConfig.transaction_category_id}
        source={source}
      />
    );
  }
  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false} style={{ marginHorizontal: scale(16) }}>
        {showProdDetailsFromConfigAndApi(productConfig.requestInfo.header, t, props)}
        <Divider height="4px" color={colors.neutral.mist} style={appStyles.marginVertical(12)} />
        <Text variant="md-bold">{t('bp.bpDetails')}</Text>
        {showProdDetailsFromConfigAndApi(productConfig.requestInfo.detail, t, props)}
        <Divider height="1px" color={colors.neutral.mist} style={appStyles.marginTop(12)} />
        {showProdDetailsFromConfigAndApi(productConfig.requestInfo.summary, t, props, true)}
        <Text variant="md-bold" style={appStyles.marginVertical(12)}>
          {t('reqTxSheet.bpDetails')}
        </Text>
        <BPRequestAccountSelector
          paymentMethods={bpReqData.paymentMethods}
          setSelectedBPAccount={setSelectedBPAccount}
          source={source}
          bpReqData={bpReqData}
          productConfig={productConfig}
        />
        <AppliedReferral />
        <BPTncCheckBox
          tncChecked={tncChecked}
          setTncOpen={setTncOpen}
          setTncChecked={setTncChecked}
          transaction_category_id={productConfig.transaction_category_id}
          source={source}
        />
      </ScrollView>
      <Modal.Footer>
        <View style={styles.rtsFooter}>
          <Button
            testID="btnCloseRTS"
            variant="ghost"
            flex={1}
            style={appStyles.marginRight(8)}
            onPress={() => {
              logTxClosedWithSource(productConfig, source, 'Batal', validatedReferralCode);
              setOpenRTS(false);
            }}>
            {t('common.cancelled')}
          </Button>
          <Button
            testID="btnOpenRTS"
            variant="solid"
            flex={1}
            isDisabled={!tncChecked}
            isLoading={isLoading}
            onPress={() => {
              if (!isLoading) {
                logTxConfirmation(
                  productConfig,
                  bpReqData,
                  source,
                  validatedReferralCode,
                  selectedBPAccount,
                  meAmplitudeContext,
                );
                confirmBP({
                  providerId: bpReqData.id,
                  employeeAccountNumber: selectedBPAccount.id,
                  description: '',
                  referralCode: getCode(props, validatedReferralCode),
                  donatorCount: null,
                  donatorName: null,
                  transactionId: bpReqData.id, // For Gold
                });
              }
            }}>
            {t('common.confirmed')}
          </Button>
        </View>
      </Modal.Footer>
    </>
  );
}

BPTncAndBillDetails.propTypes = {
  isLoading: PropTypes.bool,
  source: PropTypes.string,
  validatedReferralCode: PropTypes.string,
  tncOpen: PropTypes.bool,
  productConfig: PropTypes.shape({
    transaction_category_id: PropTypes.string,
    requestInfo: {
      header: PropTypes.shape([]),
      detail: PropTypes.shape([]),
    },
  }),
  props: PropTypes.shape({
    isGold: PropTypes.bool,
    promocode: PropTypes.string,
  }),
  bpReqData: PropTypes.shape({
    id: PropTypes.string,
    paymentMethods: PropTypes.shape([]),
  }),
  tncChecked: PropTypes.bool,
  confirmBP: PropTypes.func,
  setTncOpen: PropTypes.func,
  setTncChecked: PropTypes.func,
  setOpenRTS: PropTypes.func,
  selectedBPAccount: PropTypes.shape({
    id: PropTypes.number,
  }),
  setSelectedBPAccount: PropTypes.func,
  meAmplitudeContext: PropTypes.func,
};
BPTncAndBillDetails.defaultProps = {
  source: '',
  validatedReferralCode: '',
  tncOpen: false,
  productConfig: {
    transaction_category_id: '',
  },
  props: {
    isGold: false,
    promocode: null,
  },
  bpReqData: {},
  tncChecked: false,
  confirmBP: () => {},
  setTncOpen: () => {},
  setTncChecked: () => {},
  setOpenRTS: () => {},
  selectedBPAccount: {},
  setSelectedBPAccount: () => {},
  isLoading: false,
  meAmplitudeContext: [],
};

export default BPTncAndBillDetails;
