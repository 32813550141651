import TextCurrency from '@atoms/TextCurrency';
import { Divider, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const LoanMetaDetail = ({ upFrontFee, show, t }) => {
  if (!show) return null;

  return (
    <View style={styles.container}>
      <Text variant="md-bold">{t('loan.loanMetaText3')}</Text>
      <Text variant="sm-normal" style={styles.descriptText}>
        {t('loan.loanMetaText1')}
      </Text>
      <View style={styles.rowContainer}>
        <Text variant="sm-normal" style={styles.keyStyle}>
          {t('loan.loanMetaText2')}
        </Text>
        <TextCurrency
          variant="sm-bold"
          style={styles.keyStyle}
          amountCurrency={parseInt(upFrontFee, 10)}
          showCurrencySymbol
        />
      </View>
      <Divider style={styles.dividerStyle} />
    </View>
  );
};

LoanMetaDetail.propTypes = {
  upFrontFee: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default LoanMetaDetail;
