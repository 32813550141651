import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bankSelectButton: {
    alignItems: 'center',
    borderColor: colors.neutral.darkMist,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 13,
  },
  infoContainer: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(8),
    marginHorizontal: scale(16),
    paddingLeft: scale(12),
  },
  inputStyles: {
    borderColor: colors.neutral.darkMist,
    borderRadius: 8,
    borderWidth: 1,
    color: colors.neutral.charcoal,
    fontFamily: regularFont,
    fontSize: scale(14),
    lineHeight: scale(21),
    marginTop: 4,
    paddingHorizontal: 16,
    paddingVertical: 13,
  },
  textStyle: { paddingLeft: scale(8), paddingRight: scale(12) },
});
export default styles;
