const colors = {
  transparent: 'transparent',
  black: 'black',
  primary: {
    carnation: '#F56B57',
    darkCarnation: '#A14335',
    softCarnation: '#FEF0EE',
  },
  secondary: {
    softOrchid: '#EBEFFE',
    orchid: '#3863F0',
    darkOrchid: '#223B90',
  },
  success: {
    softCactus: '#E6EFE3',
    cactus: '#56893E',
    darkCactus: '#2E6930',
  },
  warning: {
    softSunflower: '#FEF0DC',
    sunflower: '#F8B24F',
    darkSunflower: '#A17230',
  },
  error: {
    softRose: '#FEEAE8',
    rose: '#F23015',
    darkRose: '#911D0D',
  },
  neutral: {
    cotton: '#FFFFFF',
    mist: '#F4F4F4',
    darkMist: '#909090',
    charcoal: '#454545',
  },
  variants: {
    softClover: '#D5F2EA',
    clover: '#2CBE96',
    darkClover: '#1A725A',
  },
  disabled: {
    smoke: '#C9C9C9',
  },
};

export default colors;
