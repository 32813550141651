import { fetchReasonsUseQuery } from '@api/useQueryCalls';
import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import SkeletonComponent from '@atoms/SkeletonComponent';
import ReasonsList from '@molecules/ReasonsList';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { Button, Modal } from 'native-base';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  // , useRef
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { logHideReasonsModal, logShowReasonsModal, logWithdrawalRequest } from './analyticsReasonsModal';

/**
 *
 * @param {array} reasons
 * @returns JSX
 */
function ReasonsForSelection(props) {
  const { reasons } = props;
  if (reasons && reasons.length > 0) {
    return <ReasonsList {...props} />;
  }
  return null;
}

ReasonsForSelection.propTypes = {
  reasons: PropTypes.arrayOf({}),
};
ReasonsForSelection.defaultProps = {
  reasons: [{}],
};

function ReasonsModal(props) {
  const {
    showReasonSelector,
    setShowReasonSelector,
    withdrawalAmount,
    withdrawRequest,
    reasonData,
    setReason,
    position,
    account,
    isEditReasons,
    source,
    transaction_category_id,
  } = props;
  const { t } = useTranslation('common');
  const [reasonsDisplay, setReasonsDisplay] = useState(null);
  const [disableContinue, setDisableContinue] = useState(true);
  const { validatedReferralCode, bankForTransfer, setCustomReasonText, selectedAccount, meAmplitudeContext } =
    getStore();
  const { amplitudeAccountName } = selectedAccount;
  const [customReasonText, setCustomReasonTextLocal] = useState('');
  // const { withdrawRequest } = useWithdrawalRequest();
  const { data, isSuccess, refetch } = fetchReasonsUseQuery({
    accountNumber: account.id,
    reasonType: position === 'INCREASE' ? 'INCOME' : 'EXPENSE',
  });

  useEffect(() => {
    setReasonsDisplay(data);
  }, [isSuccess, data]);

  useEffect(() => {
    setCustomReasonText('');
    setReason(reasonData);

    refetch();
  }, []);

  useEffect(() => {
    if (showReasonSelector) {
      logShowReasonsModal(source, amplitudeAccountName, validatedReferralCode);
    }
  }, [showReasonSelector]);

  const closeReasonSelectorModal = () => {
    logHideReasonsModal(source, amplitudeAccountName, validatedReferralCode);
    setShowReasonSelector(false);
  };
  return (
    <Modal
      testID="modalReasonSelector"
      sentry-label="modalReasonSelector"
      isOpen={showReasonSelector}
      closeOnOverlayClick
      avoidKeyboard
      onClose={closeReasonSelectorModal}>
      <Modal.Content style={[appStyles.modalContainer, appStyles.flex1]}>
        <ModalHeader headerTranslationString={t('withdrawlSlider.withdrawalReason')} />
        <ModalClose
          testID="modalClose"
          sentry-label="modalClose"
          onPress={() => {
            logHideReasonsModal(source, amplitudeAccountName, validatedReferralCode);
            setShowReasonSelector(false);
            setReason(reasonData);
          }}
        />
        <Modal.Body style={appStyles.paddingLeft(0)}>
          {data && data.length > 0 ? (
            <ReasonsForSelection
              reasons={reasonsDisplay}
              setReasonsDisplay={setReasonsDisplay}
              setDisableContinue={setDisableContinue}
              setCustomReasonText={setCustomReasonTextLocal}
              isEditReasons={isEditReasons}
              source={source}
              transaction_category_id={transaction_category_id}
            />
          ) : (
            <SkeletonComponent />
          )}
        </Modal.Body>
      </Modal.Content>
      <Modal.Footer alignSelf="stretch">
        <Button
          flex={1}
          testID="btnCommonContinue"
          alignSelf="stretch"
          marginBottom={5}
          variant="solid"
          isDisabled={disableContinue}
          onPress={() => {
            if (withdrawRequest) {
              setCustomReasonText(customReasonText);
              logWithdrawalRequest(
                amplitudeAccountName,
                withdrawalAmount,
                reasonData,
                validatedReferralCode,
                transaction_category_id,
                source,
                meAmplitudeContext,
              );
              withdrawRequest({
                bankAccountNumber: bankForTransfer?.accountNumber,
                requestedAmount: Math.floor(withdrawalAmount),
                reasonId: reasonData.id,
                accountNumber: account.id,
                referralCode: validatedReferralCode,
                reasonDetail: customReasonText,
              });
            }
            setShowReasonSelector(false);
          }}>
          {t('common.continue')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ReasonsModal.propTypes = {
  source: PropTypes.string,
  transaction_category_id: PropTypes.string,
  withdrawalAmount: PropTypes.number,
  showReasonSelector: PropTypes.bool,
  setShowReasonSelector: PropTypes.func,
  withdrawRequest: PropTypes.func,
  setReason: PropTypes.func,
  reasonData: PropTypes.shape({
    id: PropTypes.number,
  }),
  position: PropTypes.string,
  account: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isEditReasons: PropTypes.bool,
};
ReasonsModal.defaultProps = {
  source: '',
  transaction_category_id: '',
  withdrawalAmount: 0,
  showReasonSelector: false,
  setShowReasonSelector: () => {},
  withdrawRequest: () => {},
  setReason: () => {},
  reasonData: {
    id: 0,
  },
  position: 'DECREASE',
  isEditReasons: false,
};
export default ReasonsModal;
