import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bulletTitle: {
    marginBottom: scale(12),
    marginTop: scale(15),
    paddingHorizontal: scale(16),
    paddingRight: scale(40),
  },
  buttonContainerStyle: {
    marginBottom: scale(16),
  },
  checkBoxRow: { flexDirection: 'row', marginVertical: scale(12), width: '90%' },
  checkBoxTextStyle: {
    marginStart: scale(8),
  },
  containerStyle: { backgroundColor: colors.neutral.cotton, flex: 1, paddingTop: scale(10) },
  contentContainerStyle: { paddingBottom: scale(150) },
  descContainerAdj: { flexDirection: 'row', marginTop: scale(5) },
  descTitleStyle: {
    color: colors.neutral.darkMist,
    paddingLeft: scale(6),
    paddingRight: scale(40),
  },
  dividerStyle: {
    backgroundColor: colors.black,
    height: scale(4),
    marginTop: scale(15),
    opacity: 0.1,
  },
  firstDescStyle: { marginTop: scale(12) },
  footerContainer: {
    backgroundColor: colors.neutral.cotton,
    bottom: scale(2),
    elevation: 4,
    justifyContent: 'flex-end',
    paddingBottom: scale(15),
    paddingHorizontal: scale(15),

    position: 'absolute',
    width: '100%',
  },
  imageContainer: { alignItems: 'center', height: 200, justifyContent: 'center' },
  imageStyle: { height: '100%', width: '100%' },
  srnoStyle: {
    color: colors.neutral.darkMist,
    paddingLeft: scale(18),
  },
  titleStyle: { paddingHorizontal: scale(50), textAlign: 'center' },
});

export default styles;
