import BPDropdownWithAccountsAndAPI from '@molecules/BPDropdownWithAccountsAndAPI';
import PropTypes from 'prop-types';
import React from 'react';

function BPDropdownWithAccounts(props) {
  const { productConfig } = props;
  const { accountInput } = productConfig;
  const { fetchAccounts } = accountInput;
  if (fetchAccounts) {
    return <BPDropdownWithAccountsAndAPI {...props} />;
  }
  return null;
}

BPDropdownWithAccounts.propTypes = {
  productConfig: PropTypes.shape({
    accountInput: PropTypes.shape({
      fetchAccounts: PropTypes.bool,
    }),
  }),
};

BPDropdownWithAccounts.defaultProps = {
  productConfig: {
    accountInput: {
      fetchAccounts: true,
    },
  },
};

export default React.memo(BPDropdownWithAccounts);
