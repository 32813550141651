import appStyles from '@root/appStyles';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { restartWebOnError } from '@utils/helper.web';
import { SentryHelper } from '@utils/performance/SentryHelper';
import { formatUnderScoreLowerCase } from '@utils/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ErrorComponent from './ErrorComponent';

class ErrorFallBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      // errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true,
    };
  }

  componentDidCatch(error) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      // errorInfo,
      hasError: true,
    });
    // You can also log error messages to an error reporting service here
    SentryHelper.captureException(error);

    const errorEvent = AMPLITUDE_CONSTANTS.errorPage.app_error_page;
    AmplitudeHelper.logEvent(errorEvent.name, {
      [errorEvent.attributes.event_triggers]: formatUnderScoreLowerCase(error?.message || error?.stack),
    });
  }

  render() {
    const { children, t } = this.props;
    const { hasError, error } = this.state;
    const buttonStyles = { ...appStyles.marginHorizontal(16), ...appStyles.marginBottom(16) };
    if (hasError) {
      // Error path
      return <ErrorComponent t={t} buttonStyles={buttonStyles} error={error} restartAppOnError={restartWebOnError} />;
    }
    // Normally, just render children
    return children;
  }
}

ErrorFallBack.propTypes = {
  children: PropTypes.element,
  t: PropTypes.func,
};

ErrorFallBack.defaultProps = {
  children: null,
  t: () => {},
};

export default React.memo(withTranslation('common')(ErrorFallBack));
