import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  appStatusStyle: { width: '85%' },
  container: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: scale(7),
    flexDirection: 'row',
    marginHorizontal: scale(14),
    marginTop: scale(10),
    paddingHorizontal: scale(10),
    paddingVertical: scale(10),
  },
  iconContainer: { color: colors.secondary.orchid },
  linkTextStyle: {
    color: colors.secondary.orchid,
    textDecorationLine: 'underline',
  },
  textStyle: { marginLeft: scale(10), marginRight: scale(4) },
});

export default styles;
