// import { fetchBanksEnrolled } from '@api/useQueryCalls';
import SkeletonComponent from '@atoms/SkeletonComponent';
import useFetchBanks from '@customHooks/useFetchBanks';
import ProfileDefaultUserBank from '@molecules/ProfileDefaultUserBank';
import ProfileViewRegisteredBankDetails from '@molecules/ProfileViewRegisteredBankDetails';
// import { onGetUserBankCallBank } from '@organisms/ProfileTab/profileUtils';
import {
  useIsFocused,
  // , useNavigation
} from '@react-navigation/native';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
// import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
// import { useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

function ProfileBankDetails(props) {
  const { showBankDetails, defaultBank: employeeDefaultBank } = props;
  const [enrolledBankList, setEnrolledBankList] = useState([]);
  // const {
  // setLoading,
  // setInitialData,
  // } = getStore();
  // const [employeeBank, setEmployeeBank] = useState(employeeDefaultBank);
  // const toast = useToast();
  // const navigation = useNavigation();
  // const { t } = useTranslation('common');
  const isFocused = useIsFocused();
  // const { userBanks } = employeeBank || {
  //   userBanks: [],
  // };
  const { bankName, bankAccountName, number } = employeeDefaultBank || {
    bankName: '',
    bankAccountName: '',
    number: '',
  };

  const { isLoading, isSuccess, bankList, refetch } = useFetchBanks();
  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);
  // useEffect(() => {
  //   setLoading(isLoading);
  // }, [isLoading]);

  useEffect(() => {
    if (isSuccess && bankList) {
      // onGetUserBankCallBank(bankList, setEmployeeBank);
      setEnrolledBankList(bankList);
    }
  }, [isSuccess, bankList]);
  // useEffect(() => {
  // showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  // }, [isError, error]);

  if (isLoading) {
    return <SkeletonComponent />;
  }
  if (showBankDetails) {
    if (enrolledBankList && enrolledBankList.length > 0) {
      return (
        <ProfileViewRegisteredBankDetails
          employeeDefaultBank={employeeDefaultBank}
          source={AMPLITUDE_EVENT_SOURCES.others_page}
        />
      );
    }
    if (employeeDefaultBank && employeeDefaultBank !== null) {
      return <ProfileDefaultUserBank bankName={bankName} beneficiaryName={bankAccountName} bankAccountNo={number} />;
    }
    return <SkeletonComponent />;
  }
  return null;
}

ProfileBankDetails.propTypes = {
  showBankDetails: PropTypes.bool,
  defaultBank: PropTypes.shape({
    bankName: PropTypes.string,
    bankAccountName: PropTypes.string,
    number: PropTypes.string,
  }),
};
ProfileBankDetails.defaultProps = {
  showBankDetails: false,
  defaultBank: {
    bankName: '',
    bankAccountNumber: '',
    number: '',
  },
};
export default ProfileBankDetails;
