import OpenPayPeriodText from '@atoms/OpenPayPeriodText';
import TextCurrency from '@atoms/TextCurrency';
import WithdrawError from '@atoms/WithdrawError';
import SliderView from '@molecules/SliderView';
// import { disableCheck } from '@molecules/SliderView/sliderUtils';
import WithdrawButton from '@molecules/WithdrawButton';
import { getStore } from '@store/storeUtils';
import { View } from 'native-base';
import React, { useState } from 'react';

function AccountSlider() {
  const { selectedAccount } = getStore();

  const { slider, balance, openPeriod, withdrawalButton } = selectedAccount;
  const [editable, setEditable] = useState(false);
  const [focusInputField, setFocusInputField] = useState(false);

  if (slider && slider?.enabled) {
    return (
      <SliderView
        editable={editable}
        setEditable={setEditable}
        focusInputField={focusInputField}
        setFocusInputField={setFocusInputField}
      />
    );
  }
  return (
    <View>
      <TextCurrency amountCurrency={balance} variant="md-bold" />
      <WithdrawButton
        isDisabled={!withdrawalButton?.enabled}
        withdrawalAmount={balance}
        source="home_page"
        transaction_category_id="wd-01"
      />
      <WithdrawError />
      <OpenPayPeriodText openPeriod={openPeriod} />
    </View>
  );
}

export default AccountSlider;
