const BP_CONFIG = {
  'BP-10': {
    // PULSA - done
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'phoneNumber', value: 'phoneNumber' },
      { labelKey: 'provider', value: 'biller' },
      { labelKey: 'nominalPulsa', value: 'productNominal', isCurrency: true, dontShowCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-05': {
    // Token PLN - Flag
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'customerId', value: 'customerId' },
      { labelKey: 'customerName', value: 'customerName' },
      { labelKey: 'nominalToken', value: 'productNominal', isCurrency: true, dontShowCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-13': {
    // Voucher - done
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'nominalVoucher', value: 'productNominal', isCurrency: true, dontShowCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-15': {
    // E-Money - done
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'emoneyPhoneNumber', value: 'phoneNumber' },
      { labelKey: 'emoneyProductName', value: 'biller' },
      { labelKey: 'emoneyNominal', value: 'productNominal', isCurrency: true, dontShowCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-07': {
    // Packet Data - done
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'packetProductName', value: 'productDisplayName' },
      { labelKey: 'phoneNumber', value: 'phoneNumber' },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-08': {
    // Pasabayar -  done
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'phoneNumber', value: 'phoneNumber' },
      { labelKey: 'provider', value: 'productDisplayName' },
      { labelKey: 'tagihanPascabayar', value: 'billAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transAdminFee', isCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-17': {
    // Listrik PLN
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'customerId', value: 'customerId' },
      { labelKey: 'customerName', value: 'customerName' },
      { labelKey: 'nominalPLN', value: 'billAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transAdminFee', isCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  'BP-06': {
    // PDAM WaterBill
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'customerId', value: 'customerId' },
      { labelKey: 'pdamNama', value: 'biller' },
      { labelKey: 'customerName', value: 'customerName' },
      { labelKey: 'pdamCustomerName', value: 'billAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transFee', isCurrency: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
};
const WITHDRAWAL_CONFIG = {
  WITHDRAWAL_0: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'bankName', value: 'bankName' },
      { labelKey: 'beneficiaryName', value: 'beneficiaryName' },
      { labelKey: 'bankAccountNumber', value: 'bankAccountNumber', isBankAccountNumber: true },
      { labelKey: 'jumlahPenarikan', value: 'transAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transFee', isCurrency: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  WITHDRAWAL_3: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'bankName', value: 'bankName' },
      { labelKey: 'beneficiaryName', value: 'beneficiaryName' },
      { labelKey: 'bankAccountNumber', value: 'bankAccountNumber', isBankAccountNumber: true },
      { labelKey: 'jumlahPenarikan', value: 'transAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transFee', isCurrency: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  WITHDRAWAL_4: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'bankName', value: 'bankName' },
      { labelKey: 'beneficiaryName', value: 'beneficiaryName' },
      { labelKey: 'bankAccountNumber', value: 'bankAccountNumber', isBankAccountNumber: true },
      { labelKey: 'jumlahPenarikan', value: 'transAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transFee', isCurrency: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  WITHDRAWAL_5: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'bankName', value: 'bankName' },
      { labelKey: 'beneficiaryName', value: 'beneficiaryName' },
      { labelKey: 'bankAccountNumber', value: 'bankAccountNumber', isBankAccountNumber: true },
      { labelKey: 'jumlahPenarikan', value: 'transAmount', isCurrency: true },
      { labelKey: 'adminFee', value: 'transFee', isCurrency: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
};
const CREDIT_ADJUSTMENT_CONFIG = {
  GG_0_INCREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_0_DECREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'jumlahPenyesusian', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_3_INCREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_3_DECREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'jumlahPenyesusian', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_4_INCREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'noOfReferensi', value: 'referenceNumber' },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_4_DECREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'jumlahPenyesusian', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_5_INCREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_5_DECREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'jumlahPenyesusian', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_1_INCREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'noOfReferensi', value: 'referenceNumber' },
      { labelKey: 'transReceivedAmount', value: 'transReceivedAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
  GG_1_DECREASE: {
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'jumlahPenyesusian', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
};
const GOLD_CONFIG = {
  'GD-01': {
    // Gold
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'hargaBelis', value: 'goldBuyingPrice', isGoldPrice: true, isCurrency: true },
      { labelKey: 'jumlahBerat', value: 'totalWeightOfGold', totalWeightOfGold: true },
      { labelKey: 'totalPembayaran', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
};

const LOAN_CONFIG = {
  'LOAN-01': {
    // LOAN
    details: [
      { labelKey: 'status', value: 'transStatus', isStatus: true },
      { labelKey: 'transDate', value: 'transDate', isDate: true },
      { labelKey: 'transaksi', value: 'displayName' },
      { labelKey: 'loanBankName', value: 'bankName' },
      { labelKey: 'loanBankAccountNo', value: 'bankAccountNumber', isBankAccountNumber: true },
      { labelKey: 'beneficiaryName', value: 'beneficiaryName' },
      { labelKey: 'jumlahPenarikan', value: 'transAmount', isCurrency: true },
      { labelKey: 'kantongDana', value: 'accountName' },
    ],
  },
};

export default { ...BP_CONFIG, ...WITHDRAWAL_CONFIG, ...CREDIT_ADJUSTMENT_CONFIG, ...GOLD_CONFIG, ...LOAN_CONFIG };
