import InfoTextComponent from '@atoms/InfoTextComponent';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import { useNavigation } from '@react-navigation/native';
import { FEATURE_FLAGS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { scale } from '@utils/normalize';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { AUTO_DISBURSE_KEYS, AUTO_DISBURSE_UI, showBanner } from './autoDisburseUtils';
import styles from './styles';

/*  Auto withdrwal componnet is used on homepage with only suspension and lock period showcase  */

export function useMemoHandler(isSuspended, isInLockPeriod, autoDisburseData) {
  return () => {
    if (isSuspended) return AUTO_DISBURSE_KEYS.SUSPENDED;
    if (isInLockPeriod) return AUTO_DISBURSE_KEYS.LOCKPERIOD;
    if (autoDisburseData?.allowEwaAutoDisburse) return AUTO_DISBURSE_KEYS.OPT_OUT;
    return AUTO_DISBURSE_KEYS.OPT_IN;
  };
}

const AutoWithdrawal = ({ isBillPayment }) => {
  const { isInLockPeriod, isSuspended, autoDisburseData } = getStore();
  const navigation = useNavigation();
  // const toast = useToast();
  const { t } = useTranslation('common');

  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.AUTODISBURSE],
  });

  const AUTO_DISBURSE_TITLE = useMemo(useMemoHandler(isSuspended, isInLockPeriod, autoDisburseData), [
    isInLockPeriod,
    autoDisburseData,
    isSuspended,
  ]);

  if (autoDisburseData.length === 0) return null;

  return (
    <InfoTextComponent
      show={showBanner(
        isInLockPeriod,
        isSuspended,
        isBillPayment,
        autoDisburseData,
        isFeatureAvailale[FEATURE_FLAGS.AUTODISBURSE]?.show,
      )}
      customTextStyle={styles.lockPeriodBannerTextStyle}
      containerStyle={{
        ...styles.lockperiodBannerContainerStyle,
        backgroundColor: AUTO_DISBURSE_UI[AUTO_DISBURSE_TITLE].containerBackground,
      }}
      iconContainer={styles.lockPeriodBannerIconContainerStyle}
      iconName={AUTO_DISBURSE_UI[AUTO_DISBURSE_TITLE].iconName}
      infoText={AUTO_DISBURSE_UI[AUTO_DISBURSE_TITLE].infoText(t, autoDisburseData, navigation, Linking)}
      size={scale(24)}
      iconColor={AUTO_DISBURSE_UI[AUTO_DISBURSE_TITLE].iconColor}
      variant="sm-bold"
    />
  );
};

AutoWithdrawal.propTypes = {
  isBillPayment: PropTypes.bool,
};

AutoWithdrawal.defaultProps = {
  isBillPayment: false,
};

export default AutoWithdrawal;
