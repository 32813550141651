import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { Dimensions, StyleSheet } from 'react-native';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

const styles = StyleSheet.create({
  accountImgLeftPadding: {
    alignItems: 'center',
  },
  accountSelectorContainer: {
    alignItems: 'center',
    borderRadius: scale(12),
    flexDirection: 'row',
    paddingHorizontal: scale(8),
    paddingVertical: scale(4),
  },
  accountSelectorRow: { flexDirection: 'row' },
  arrowIcon: {
    color: colors.neutral.charcoal,
    fontSize: scale(18),
  },
  arrowStyle: {
    height: scale(18),
    justifyContent: 'center',
    width: scale(18),
  },
  baruStyle: { left: 15, top: verticalScale(9) },
  container: {
    alignItems: 'center',
    borderColor: colors.disabled.smoke,
    borderRadius: scale(12),
    borderWidth: 1,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: scale(16),
    marginVertical: scale(12),
    paddingVertical: scale(8),
  },
  homePageBaru: { bottom: scale(20), left: 0, position: 'absolute' },
  iconContainer: {
    backgroundColor: colors.error.rose,
    borderRadius: scale(5),
    height: scale(8),
    marginHorizontal: scale(4),
    width: scale(8),
  },
  modalParent: {
    backgroundColor: colors.neutral.cotton,
    bottom: 0,
    // height: '30%',
    margin: 0,
    position: 'absolute',
    width: SCREEN_WIDTH,
  },
  toStyles: accountBGColor => ({
    marginHorizontal: scale(16),
    marginVertical: scale(16),
    paddingLeft: scale(16),
    borderRadius: scale(12),
    flexGrow: 1,
    backgroundColor: accountBGColor,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: scale(7),
  }),
});

export default styles;
