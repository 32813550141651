import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const spaceBetween = 'space-between';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 10,
    borderStyle: 'dashed',
    borderWidth: 1,
    flexDirection: 'row',
    flex: 1,
    justifyContent: spaceBetween,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  cancel: { marginStart: 10 },
  completedButton: {
    alignItems: 'center',
    backgroundColor: colors.neutral.charcoal,
    borderRadius: 10,
    flexDirection: 'row',
    flex: 1,
    justifyContent: spaceBetween,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  container: {
    alignItems: 'center',
    borderColor: colors.neutral.darkMist,
    flexDirection: 'row',
    justifyContent: spaceBetween,
    marginHorizontal: scale(16),
    overflow: 'hidden',
  },
  failedButton: {
    alignItems: 'center',
    borderColor: colors.error.rose,
    borderRadius: 10,
    borderStyle: 'dashed',
    borderWidth: 1,
    flexDirection: 'row',
    flex: 1,
    justifyContent: spaceBetween,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  failedText: {
    color: colors.error.rose,
  },
  name: {
    color: colors.neutral.cotton,
  },
});

export default styles;
