import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase } from '@utils/utils';

export default function logSubProdClick(productItem, productConfig) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.providerSelected;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.provider]: formatUnderScoreLowerCase(productItem),
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
  });
}
