import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerStyle: { backgroundColor: colors.secondary.orchid, flex: 1 },
  subContainerStyle: {
    backgroundColor: colors.neutral.cotton,
    borderBottomEndRadius: 0,
    borderBottomStartRadius: 0,
    borderRadius: scale(12),
    flex: 1,
    marginTop: -2,
    paddingTop: scale(12),
  },
});

export default styles;
