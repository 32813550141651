import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const poinEarnedStyles = StyleSheet.create({
  absoluteContainer: {
    bottom: 0,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  coinContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: scale(6),
  },
  coinIcon: {
    marginLeft: scale(5),
  },
  container: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(12),
    elevation: scale(5),
    marginHorizontal: scale(16),
    overflow: 'hidden',
    paddingVertical: scale(12),
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  infoContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  infoText: {
    color: colors.neutral.charcoal,
    marginRight: scale(5),
  },
});

export default poinEarnedStyles;
