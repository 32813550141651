import AccountRow from '@atoms/AccountRow';
import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Divider, Modal } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { FlatList } from 'react-native';
import styles from './styles';

function getItemSeperatorComponent() {
  return () => <Divider />;
}

function ModalAccounts(props) {
  const {
    withdrawlAccounts,
    showAccountSelector,
    setSelectedAccount,
    setShowAccountSelector,
    selectedAccount,
    source,
    excludeNewTag,
  } = props;
  const closeAccountSelector = () => {
    const e = AMPLITUDE_CONSTANTS.Withdrawal.CancelButtonClicked;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
    });
    setShowAccountSelector(false);
  };
  return (
    <Modal
      closeOnOverlayClick
      testID="modalAccountSelector"
      sentry-label="modalAccountSelector"
      onClose={closeAccountSelector}
      isOpen={showAccountSelector}
      size="full">
      <Modal.Content
        marginTop="auto"
        bottom={0}
        position="absolute"
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}>
        <ModalHeader headerTranslationString="withdrawlSlider.selectData" />
        <ModalClose testID="modalClose" sentry-label="modalClose" onPress={closeAccountSelector} />
        <FlatList
          testID="fltLstAccounts"
          sentry-label="fltLstAccounts"
          data={withdrawlAccounts}
          style={styles.accountListStyle}
          scrollEnabled
          contentContainerStyle={styles.modalContainer}
          ItemSeparatorComponent={getItemSeperatorComponent()}
          renderItem={({ item, index }) => (
            <AccountRow
              setShowAccountSelector={setShowAccountSelector}
              setSelectedAccount={setSelectedAccount}
              item={item}
              index={index}
              isSelected={selectedAccount.id === item.id}
              excludeNewTag={excludeNewTag}
            />
          )}
        />
      </Modal.Content>
    </Modal>
  );
}

ModalAccounts.propTypes = {
  withdrawlAccounts: PropTypes.arrayOf({
    icon: PropTypes.string,
    displayName: PropTypes.string,
  }),
  showAccountSelector: PropTypes.bool,
  setSelectedAccount: PropTypes.func,
  setShowAccountSelector: PropTypes.func,
  source: PropTypes.string,
  selectedAccount: PropTypes.shape({
    id: PropTypes.number,
  }),
  excludeNewTag: PropTypes.bool,
};
ModalAccounts.defaultProps = {
  withdrawlAccounts: [],
  showAccountSelector: false,
  setSelectedAccount: () => {},
  setShowAccountSelector: () => {},
  source: '',
  selectedAccount: {},
  excludeNewTag: false,
};
export default ModalAccounts;
