import { applyForLoan, fetchLoanDetails, updateUserNotified } from '@api/useQueryCalls';
import AttentionBoard from '@atoms/Loan/AttentionBoard';
import InfoBar from '@atoms/Loan/InfoBar';
import LoanDetailCard from '@atoms/Loan/LoanDetailCard';
import LoanInstallMents from '@atoms/Loan/LoanInstallments';
import LoanMetaDetail from '@atoms/Loan/LoanMetaDetail';
import TransaksiDetails from '@atoms/Loan/TransaksiDetails';
import MaterialIcon from '@expo/vector-icons/MaterialIcons';

import BottomSheet from '@molecules/LoginInfoSheet';
import navigationConstants from '@navigation/navigationConstants';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { LOAN_APPLICATION_STATUS, LOAN_ATTENTION_TEXT } from '@root/constants';
import { LOAN_STATUS_TYPES } from '@screens/Loan/GGLoan/ggLoanUtils';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { Text, Checkbox, Button, Skeleton, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, View } from 'react-native';
import { logAcceptedTerms, logLoanSchedulePage, logLoanVerification } from './analyticsLoanDetails';
import resetNavigationStack from './loanDetailsUtils';
import styles from './styles';

export function applyLoanHandler(refetchLoanDetails, setInfoModalSheet) {
  return () => {
    refetchLoanDetails();

    setInfoModalSheet(prevState => ({
      ...prevState,
      visible: true,
    }));
  };
}

export function shouleRenderButtonContainer(
  onPress,
  show,
  onPressLinkTNC,
  checkedValue,
  disabledButton,
  submitLoading,
  isLoanTermsAccepted,
  t,
) {
  if (show) {
    const disabledCheck = !(checkedValue || isLoanTermsAccepted) || disabledButton;

    return (
      <View style={styles.bottomButtonContainer}>
        <Pressable testID="buttonContainer" onPress={onPressLinkTNC} style={styles.pressableLinkContainer}>
          <Checkbox
            testID="autoDisburseCheckbox"
            aria-label="autoDisburseCheckbox"
            isChecked={checkedValue || isLoanTermsAccepted}
            _checked={{
              backgroundColor: colors.primary.carnation,
              borderColor: colors.primary.carnation,
            }}
            onChange={onPressLinkTNC}
          />

          <Text variant="sm-normal" style={styles.pressableTitle}>
            {t('loan.loanTermsText')}
          </Text>
        </Pressable>

        <Button
          isLoading={submitLoading}
          _disabled={{ _text: colors.neutral.cotton, backgroundColor: colors.disabled.smoke }}
          isDisabled={disabledCheck}
          textAlign="center"
          backgroundColor={colors.primary.carnation}
          testID="salinKode"
          onPress={onPress}
          textStyle={{ color: colors.neutral_cotton }}
          style={styles.copyButtonStyle}
          leftIcon={<MaterialIcon name="privacy-tip" color={colors.neutral.cotton} size={20} />}>
          <Text color={colors.neutral.cotton}>{t('loan.loanButton')}</Text>
        </Button>
      </View>
    );
  }

  return null;
}

export function onMounthHandler(loanDetails, navigation) {
  return () => {
    if (
      loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
      loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
    ) {
      logLoanSchedulePage(loanDetails);
    }

    if (loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL) {
      resetNavigationStack(CommonActions, navigation);
      logLoanVerification(loanDetails);
    }
  };
}

export function onSubmitPress(loanDetails, setDisabled, submitLoan) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.Loans.loan_requested;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.loan_product_code]: loanDetails?.loanCode,
    });
    setDisabled(true);
    submitLoan({ loanCode: loanDetails?.loanCode });
  };
}

export function onPressTNC(isLoanTermsAccepted, setLoanTerms, loanDetails, navigation) {
  return () => {
    if (isLoanTermsAccepted) return setLoanTerms(false);

    return navigation.navigate(navigationConstants.GG_LOANTERMS, { loanProductCode: loanDetails?.loanCode });
  };
}

export function onPressPrimaryInfoModal(setInfoModalSheet, updateLoanStatus, loanDetails) {
  return () => {
    setInfoModalSheet(prevState => ({ ...prevState, visible: false }));
    updateLoanStatus({ applicationId: loanDetails?.id });
  };
}

export function onPressSecondary(setInfoModalSheet) {
  return () => {
    setInfoModalSheet(prevState => ({ ...prevState, visible: false }));
  };
}

export function onPressHistory(navigation) {
  return () => {
    navigation.navigate(navigationConstants.TransactionsTab);
  };
}

const LoanDetails = () => {
  const { t } = useTranslation('common');

  const route = useRoute();

  const { loanData } = route.params;

  const [loanDetails, setLoanDetails] = useState(loanData);

  const navigation = useNavigation();

  const toast = useToast();

  const { isLoanTermsAccepted, setLoanTerms, setInitialData, setLoading, loading } = getStore();

  const [showInfoModalSheet, setInfoModalSheet] = useState({
    visible: false,
    data: LOAN_STATUS_TYPES[LOAN_APPLICATION_STATUS.PENDING_APPROVAL],
    type: 'loan',
  });

  const {
    isLoading: loadingLoanDetails,
    isError: isLoanDetailsError,
    refetch: refetchLoanDetails,
    error: loanError,
  } = fetchLoanDetails(data => {
    setLoanDetails(data);
  });

  useEffect(() => {
    shouldShowError(isLoanDetailsError, loanError, toast, t, navigation, setInitialData);
  }, [isLoanDetailsError, loanError]);

  const {
    isLoading: loadSubmitLoan,
    isError: isSubmitLoanError,
    mutate: submitLoan,
    error: submitLoanError,
  } = applyForLoan(applyLoanHandler(refetchLoanDetails, setInfoModalSheet));

  useEffect(() => {
    shouldShowError(isSubmitLoanError, submitLoanError, toast, t, navigation, setInitialData);
  }, [isSubmitLoanError, submitLoanError]);

  const { isError: isUserNotifiedError, error: userNotifiedError, mutate: updateLoanStatus } = updateUserNotified();

  useEffect(() => {
    shouldShowError(isUserNotifiedError, userNotifiedError, toast, t, navigation, setInitialData);
  }, [isUserNotifiedError, userNotifiedError]);

  useEffect(() => {
    if (!loanData) refetchLoanDetails();

    return () => {
      setLoanTerms(false);
    };
  }, [loanData]);

  const isNewApply = !!loanData;

  const [disabledButton, setDisabled] = useState(false);

  useEffect(onMounthHandler(loanDetails, navigation), [loanDetails]);

  const onPressLinkTNC = onPressTNC(isLoanTermsAccepted, setLoanTerms, loanDetails, navigation);

  const onPressHandler = onSubmitPress(loanDetails, setDisabled, submitLoan);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (isLoanTermsAccepted) {
      logAcceptedTerms(loanDetails);
    }
  }, [isLoanTermsAccepted]);

  if (!loanDetails || loadingLoanDetails) return <Skeleton />;

  return (
    <>
      <ScrollView style={appStyles.flex1} contentContainerStyle={styles.contentStyle}>
        <View style={{ marginVertical: scale(10) }}>
          <InfoBar
            show={
              loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
              loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED ||
              loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL
            }
            appStatus={loanDetails?.applicationStatus}
            onPressLink={onPressHistory(navigation)}
            t={t}
          />
        </View>

        <LoanDetailCard
          principalAmt={loanDetails.principal}
          recievable={loanDetails.principal - loanDetails.upfrontFee}
          tenure={loanDetails?.tenure}
          installAmt={loanDetails?.installmentValue}
          showTag={loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL}
          t={t}
        />

        <LoanMetaDetail
          upFrontFee={loanDetails?.upfrontFee}
          show={loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL || isNewApply}
          t={t}
        />

        <AttentionBoard
          dataList={LOAN_ATTENTION_TEXT}
          show={loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL || isNewApply}
          t={t}
        />

        <LoanInstallMents
          show={
            loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
            loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
          }
          installMentsData={loanDetails?.loan_app_installments}
          t={t}
        />

        <TransaksiDetails
          metainfo={loanDetails.bankDetails}
          fee={loanDetails?.upfrontFee}
          bankName={loanDetails?.bankDetails?.bankName}
          benificiaryName={loanDetails?.bankDetails?.beneficiaryName}
          bankAccountNo={loanDetails?.bankDetails?.bankAccountNo}
          show={
            loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
            loanDetails?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
          }
          t={t}
        />

        <BottomSheet
          onPressPrimary={onPressPrimaryInfoModal(setInfoModalSheet, updateLoanStatus, loanDetails)}
          onPressSecondary={onPressSecondary(setInfoModalSheet)}
          loginInfo={showInfoModalSheet}
          hasTwoButtons={showInfoModalSheet.data.hasTwoButtons}
          onClose={onPressSecondary(setInfoModalSheet)}
        />
      </ScrollView>
      {shouleRenderButtonContainer(
        onPressHandler,
        isNewApply && !loanDetails?.applicationStatus,
        onPressLinkTNC,
        isLoanTermsAccepted,
        disabledButton,
        loadSubmitLoan,
        isLoanTermsAccepted,
        t,
      )}
    </>
  );
};

export default LoanDetails;
