import { GET_USER_PROPERTIES } from '@api/queries/query';
import { UPDATE_DEPARTMENT_DESIGNATION } from '@api/queries/UserProfile';
import { useLazyQuery, useMutation } from '@apollo/client';
import { blueNormal, redPic } from '@assets/png/imagesPng';
import ToastSuccess from '@atoms/ToastSuccess';
import DepartmentPicker from '@atoms/UserProfile/DepartmentPicker';
import DetailRow from '@atoms/UserProfile/DetailRow';
import LihatPICGateway from '@atoms/UserProfile/LihatPICGateway';
import PhoneNumberReveal from '@atoms/UserProfile/PhoneNumberReveal';
import SectionTitle from '@atoms/UserProfile/SectionTitle';
import TagBadge from '@atoms/UserProfile/TagBadge';
import { onGetUserPropertiesCallBack } from '@organisms/ProfileTab/profileUtils';
import { HeaderBackButton } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { SkeletonLoader } from '@screens/GGSalaryInformation';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Button, ScrollView, Text, useToast } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Image } from 'react-native';
import {
  logDepartmentAppear,
  logDepartmentSaved,
  logRoleAppear,
  logRoleSubmitted,
  logUserInfoPage,
} from './analyticsUserProfile';
import styles from './style';
import { getSelectedNames, masker } from './userProfileUtils';

export function shouldShowNoteText(show, t) {
  if (show)
    return (
      <Text variant="sm-regular" style={styles.detailDescStyle}>
        {t('userProfile.noteText')}
      </Text>
    );
  return null;
}

export function shouldShowUntukText(show, t) {
  if (show)
    return (
      <View style={styles.noteStyle}>
        <Text variant="sm-normal" style={styles.noteTextStyle}>
          {t('userProfile.unTukText')}
        </Text>
      </View>
    );
  return null;
}

export function onSumbitDetailsHandler(submitDetails, employeeId, employerId) {
  return () => {
    submitDetails();
    logRoleSubmitted(employeeId, employerId);
  };
}

export function headerBackNavigation(navigation) {
  return () => navigation.goBack();
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const UserProfile = () => {
  const navigation = useNavigation();
  const { t } = useTranslation('common');

  const { employeeProfile, employeeId, employerId, setEmployeeProfile } = getStore();

  const toast = useToast();

  const { mobileNumber, firstName, lastName } = employeeProfile || {};

  const [numberReveal, setNumberReveal] = useState(false);

  const onLihatBenifitHandler = () => {
    navigation.navigate('PicListing');
  };

  const maskedPHoneNo = useMemo(() => masker(mobileNumber), [mobileNumber]);

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  let imageLogo = SVG_ICONS.userProfile.happyFacesx;
  if (isPIC) imageLogo = SVG_ICONS.userProfile.profilePicture;

  const [submitDetails, { loading: submitLoading }] = useMutation(UPDATE_DEPARTMENT_DESIGNATION, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const payload = data?.update_gg_employee?.returning?.[0];
      if (payload) {
        setEmployeeProfile({ ...employeeProfile, ...payload });
        toast.show({
          render: () => <ToastSuccess data={t('userProfile.toastSuccess')} />,
          placement: 'top',
        });
        logDepartmentSaved(employeeId, employerId);
      }
    },
    variables: {
      departmentName: employeeProfile?.selectedDepartmentName || employeeProfile?.departmentName,
      designationName: employeeProfile?.selectedDesignationName || employeeProfile?.designationName,
      employeeId,
    },
  });

  const handleReveal = () => {
    const e = AMPLITUDE_CONSTANTS.UserProfile.eye_icon_phonenumber_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
      [e.attributes.influencer_role]: isPIC ? 'pic' : 'normal_user',
    });
    setNumberReveal(prevState => !prevState);
  };

  useEffect(() => {
    logUserInfoPage(isPIC);

    // return () => {
    //   setEmployeeProfile(null);
    // };
  }, []);

  const [getUserProperties, { loading }] = useLazyQuery(GET_USER_PROPERTIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onGetUserPropertiesCallBack(setEmployeeProfile),
  });

  useEffect(() => {
    if (!employeeProfile) getUserProperties();
  }, []);

  const onDepartmentDesignationHandler = isDepartment => {
    if (isDepartment) {
      logDepartmentAppear(employeeId, employerId);
    } else {
      logRoleAppear(employeeId, employerId);
    }

    navigation.navigate('DeptDesignList', { isDepartment });
  };

  function shouldShowSubmitSelectables(show) {
    if (show)
      return (
        <Button
          style={styles.loadingButton}
          testID="submitBtn"
          isDisabled={
            !getSelectedNames(
              employeeProfile?.departmentName,
              employeeProfile?.designationName,
              employeeProfile?.selectedDepartmentName,
              employeeProfile?.selectedDesignationName,
            ) || submitLoading
          }
          onPress={onSumbitDetailsHandler(submitDetails, employeeId, employerId)}
          isLoading={submitLoading}>
          <Text textTransform="none" variant="md-bold" style={styles.loadingButtonText} color={colors.neutral.cotton}>
            {t('userProfile.selectableText')}
          </Text>
        </Button>
      );
    return null;
  }

  if (!employeeProfile || loading) return <SkeletonLoader />;

  return (
    <ScrollView style={styles.container}>
      <StatusBar translucent backgroundColor="transparent" />
      <View>
        <Image
          testID={`imagesource-${isPIC ? 'redPic' : 'blueNormal'}`}
          source={isPIC ? redPic : blueNormal}
          style={styles.imageStyle}
        />
        <View style={[styles.imagePlaceholder, !isPIC && styles.imagePicBackground]}>{imageLogo}</View>
      </View>
      <View style={styles.headerBackContainer}>
        <HeaderBackButton
          tintColor={colors.neutral.cotton}
          labelVisible
          testID="backBtnHeaderBack"
          label={t('userProfile.headerBack')}
          labelStyle={styles.headerBackLabelStyle}
          style={styles.headerBackStyle}
          onPress={headerBackNavigation(navigation)}
        />
      </View>

      <View style={styles.titleContainer}>
        <Text variant="xl-bold" style={styles.titleNameStyle}>{`${t('userProfile.textEight')} ${firstName}!`}</Text>
        <TagBadge title={t('userProfile.tag')} show={isPIC} />
      </View>
      {shouldShowNoteText(isPIC, t)}

      <LihatPICGateway
        title={t('userProfile.lihatGateway')}
        show={isPIC}
        onLihatBenifitHandler={onLihatBenifitHandler}
      />

      <SectionTitle title={t('userProfile.textNine')} show>
        <DetailRow title={t('userProfile.texttwo')} text={`${firstName} ${lastName}`} show />
        <DetailRow
          title={t('userProfile.textThree')}
          customComponent={
            <PhoneNumberReveal
              revealEye={numberReveal}
              number={numberReveal ? mobileNumber : maskedPHoneNo}
              onEyePress={handleReveal}
            />
          }
          show={mobileNumber}
        />
      </SectionTitle>

      {shouldShowUntukText((!employeeProfile?.departmentName || !employeeProfile?.designationName) && !isPIC, t)}

      <SectionTitle
        title={t('userProfile.textFour')}
        show={employeeProfile?.departmentName || employeeProfile?.designationName || !isPIC}>
        <DetailRow
          title={t('userProfile.textSix')}
          text={employeeProfile?.departmentName}
          show={employeeProfile?.departmentName?.length > 0}
        />
        <DetailRow
          title={t('userProfile.textSeven')}
          text={employeeProfile?.designationName}
          show={employeeProfile?.designationName?.length > 0}
        />
        <DepartmentPicker
          show={!employeeProfile?.departmentName && !isPIC}
          title={t('userProfile.textSix')}
          valueHolder={employeeProfile?.selectedDepartmentName || t('userProfile.departmentEmpty')}
          isSelected={employeeProfile?.selectedDepartmentName}
          onPress={() => onDepartmentDesignationHandler(true)}
        />

        <DepartmentPicker
          show={!employeeProfile?.designationName && !isPIC}
          title={t('userProfile.jabatan')}
          valueHolder={employeeProfile?.selectedDesignationName || t('userProfile.desginationEmpty')}
          isSelected={employeeProfile?.selectedDesignationName}
          onPress={() => onDepartmentDesignationHandler(false)}
        />
      </SectionTitle>

      {shouldShowUntukText((employeeProfile?.departmentName && employeeProfile?.designationName) || isPIC, t)}

      {shouldShowSubmitSelectables((!employeeProfile?.departmentName || !employeeProfile?.designationName) && !isPIC)}
    </ScrollView>
  );
};

export default UserProfile;
