// Import dayjs and the Indonesian locale
const dayjs = require('dayjs');
const idLocale = require('dayjs/locale/id');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const localizedFormat = require('dayjs/plugin/localizedFormat');

// Load the Indonesian locale
dayjs.locale(idLocale);

// Optionally, you can also add any plugins you need
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

// Now you can use dayjs with the Indonesian locale

export function getDayFromDate(dayjsDate) {
  const dayjsDateObj = dayjs(dayjsDate);
  return dayjsDateObj.format('d');
}

export function getDateInFormatLocale(dateString, format) {
  const now = dayjs(dateString);
  if (!now.isValid()) return '-';
  return now.format(format);
}

export function getDateInFormatLocaleSubtract(dateString, format, noOfDay = 1) {
  const now = dayjs(dateString).subtract(noOfDay, 'day');
  return now.format(format);
}

export function getDateInFormatLocaleWithInputFormat(dateString, format, inputFormat) {
  const now = dayjs(dateString, { format: inputFormat });
  return now.format(format);
}

export function getNowInFormat(format) {
  return dayjs().format(format);
}
