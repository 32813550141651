import colors from '@theme/colors';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextInput } from 'react-native';
import styles from './styles';

function getStylesFromProps(style, borderColor) {
  let result = {};
  if (isArray(style)) {
    style.push(borderColor);
    result = style;
  } else {
    result = { ...style, borderColor };
  }
  return result;
}

const CommonTextInput = props => {
  const [isFocused, setIsFocused] = useState(false);
  const { style, customBorderStyle, amplitudeCallBackOnBlur, amplitudeCallBackOnFocus } = props;
  const handleFocus = e => {
    setIsFocused(true);
    amplitudeCallBackOnFocus(e);
  };

  const handleBlur = e => {
    setIsFocused(false);
    amplitudeCallBackOnBlur(e);
  };

  const customBorderColor = isFocused ? colors.secondary.orchid : colors.neutral.darkMist;

  return (
    <TextInput
      testID="txtInputCommon"
      blurOnSubmit
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
      style={[styles.inputStyles, getStylesFromProps(style, customBorderColor), customBorderStyle]}
      placeholderTextColor={colors.neutral.darkMist}
    />
  );
};

CommonTextInput.propTypes = {
  style: PropTypes.shape({}),
  customBorderStyle: PropTypes.shape({}),
  amplitudeCallBackOnBlur: PropTypes.func,
  amplitudeCallBackOnFocus: PropTypes.func,
};
CommonTextInput.defaultProps = {
  style: {},
  customBorderStyle: {},
  amplitudeCallBackOnBlur: () => {},
  amplitudeCallBackOnFocus: () => {},
};

export default CommonTextInput;
