import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logAccountChange(employeeId, tcid, pCode, source, bpReqData, item, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.transactionSummary.pickPaymentMethod;
  const { attributes, name } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.employee_id]: employeeId,
    [attributes.transaction_category_id]: tcid,
    [attributes.product_code]: pCode,
    [attributes.source]: source,
    [attributes.point_used]: bpReqData?.subtotal,
    [attributes.point_balance]: item.balance,
    [attributes.referral_code]: validatedReferralCode,
    [attributes.payment_method]: item?.amplitudeAccountName,
  });
}

export function logDropDownPressed(source, employeeId, productConfig, bpReqData, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.transactionSummary.paymentMethod;
  const { attributes } = e;
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.BillPayment.transactionSummary.paymentMethod.name, {
    [attributes.source]: source,
    [attributes.employee_id]: employeeId,
    [attributes.transaction_category_id]: productConfig.transaction_category_id,
    [attributes.product_code]: bpReqData.productCode,
    [attributes.referral_code]: validatedReferralCode,
  });
}
