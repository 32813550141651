import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  loginInputContainer: {
    backgroundColor: colors.neutral.cotton,
    borderTopLeftRadius: scale(15),
    borderTopRightRadius: scale(15),
    flex: 1,
    marginTop: -12,
  },
  loginInputParent: {
    flex: 1,
    marginBottom: scale(20),
    marginLeft: scale(16),
    marginRight: scale(16),
    marginTop: scale(24),
  },
  loginParent: {
    backgroundColor: colors.neutral.cotton,
    flex: 1,
  },
  loginScrollContainer: { backgroundColor: colors.neutral.cotton, flexGrow: 1 },
  loginSignupView: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: scale(16),
  },
  tncParent: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 8,
  },
});

export default styles;
