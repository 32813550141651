import ProfileSalaryInfo from '@atoms/ProfileInformation';
import ProfilePhoneEnroll from '@atoms/ProfilePhoneEnroll';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ProfileInformation(props) {
  const { showPhoneEnroll, showSalaryInfo } = props;
  const { t } = useTranslation('common');
  return (
    <>
      <View style={appStyles.marginLeft(16)} marginY="16px">
        <Text testID="txtProfileInformation" variant="md-bold">
          {t('profile.information')}
        </Text>
        <ProfilePhoneEnroll showPhoneEnroll={showPhoneEnroll} />
        <ProfileSalaryInfo showSalaryInfo={showSalaryInfo} />
      </View>
      <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
    </>
  );
}

ProfileInformation.propTypes = {
  showPhoneEnroll: PropTypes.bool,
  showSalaryInfo: PropTypes.bool,
};
ProfileInformation.defaultProps = {
  showPhoneEnroll: false,
  showSalaryInfo: false,
};
export default ProfileInformation;
