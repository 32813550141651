import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const TagBadge = ({ title, show }) => {
  if (show) {
    return (
      <View style={styles.hrBadgeContainer}>
        <Text variant="xsm-bold" style={styles.hrTitleStyle}>
          {title}
        </Text>
      </View>
    );
  }
  return null;
};

TagBadge.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
};

TagBadge.defaultProps = {
  title: '',
  show: false,
};

export default TagBadge;
