import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export default function logEyeIconClicked(source, maskData, item) {
  const e = AMPLITUDE_CONSTANTS.bankRegistration.eye_icon_clicked;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
    [attributes.action]: maskData ? 'to_show' : 'to_hide',
    [attributes.bank_account_id]: item?.accountNumber,
  });
}
