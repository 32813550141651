import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Input, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function CompanyNameInput(props) {
  const { setCompanyName, t, onCompanyNameFocus, onCompanyNameBlur } = props;
  return (
    <View marginTop="24px">
      <Text testID="txtCompanyName" variant="sm-bold" color={colors.disabled.smoke}>
        {t('signup.comapnyName')}
        <Text testID="txtMandatory" color={colors.error.rose}>
          *
        </Text>
      </Text>
      <Input
        testID="companyNameInput"
        sentry-label="companyNameInput"
        placeholder={t('signup.placeHolderCompanyName')}
        placeholderTextColor={colors.neutral.charcoal}
        fontWeight="100"
        style={[appStyles.paddingLeft(16), appStyles.paddingRight(16)]}
        size="md"
        variant="md-normal"
        borderColor={colors.disabled.smoke}
        onChangeText={val => setCompanyName(val)}
        _focus={{
          borderColor: colors.secondary.orchid,
        }}
        onFocus={onCompanyNameFocus}
        onBlur={onCompanyNameBlur}
      />
    </View>
  );
}

CompanyNameInput.propTypes = {
  setCompanyName: PropTypes.func,
  t: PropTypes.func,
  onCompanyNameFocus: PropTypes.func,
  onCompanyNameBlur: PropTypes.func,
};
CompanyNameInput.defaultProps = {
  setCompanyName: () => {},
  t: () => {},
  onCompanyNameFocus: () => {},
  onCompanyNameBlur: () => {},
};

export default CompanyNameInput;
