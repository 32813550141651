import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Platform, SafeAreaView, StatusBar } from 'react-native';
import styles from './styles';

function ErrorComponent(props) {
  const { t, buttonStyles, restartAppOnError, error } = props;
  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <StatusBar translucent backgroundColor={colors.secondary.orchid} />
      <View style={styles.errorContainer}>
        <View style={[appStyles.marginHorizontal(16), styles.errorData]}>
          <View alignSelf="center">{SVG_ICONS.fixingImage}</View>
          <Text testID="txtOopsError" textAlign="center" variant="xl-bold">
            {t('common.oopsError')}
          </Text>
          <Text testID="txtOopsDescription" style={appStyles.marginTop(8)} textAlign="center" variant="lg-normal">
            {t('common.oopsErrorDescription')}
          </Text>
        </View>
      </View>
      <Button testID="btnRestartApp" onPress={() => restartAppOnError(error)} style={buttonStyles}>
        {Platform.OS === 'web' ? t('common.oopsRetryWeb') : t('common.oopsRetryApp')}
      </Button>
    </SafeAreaView>
  );
}

ErrorComponent.propTypes = {
  t: PropTypes.func,
  buttonStyles: PropTypes.shape({}),
  error: PropTypes.shape({}),
  restartAppOnError: PropTypes.func,
};
ErrorComponent.defaultProps = {
  t: () => {},
  buttonStyles: {},
  error: {
    message: 'Generic Error',
    stack: 'Generic Stack',
  },
  restartAppOnError: () => {},
};

export default ErrorComponent;
