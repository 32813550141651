import { verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  voucherTncContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  voucherValidation: { flexDirection: 'row', marginTop: verticalScale(6) },
});
export default styles;
