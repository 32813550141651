import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const webStyles = Platform.OS === 'web' ? { justifyContent: 'flex-end', alignSelf: 'stretch' } : {};

const styles = StyleSheet.create({
  artContainer: {
    marginLeft: 20,
    marginTop: 22,
  },
  bottomButtonView: {
    flex: 1,
    marginHorizontal: 16,
    paddingBottom: 16,
    ...webStyles,
  },
  errorContainer: { marginHorizontal: 18, marginVertical: 10 },
  errorStyles: { marginHorizontal: 16, marginTop: 24 },
  errorTextStyle: { color: colors.error.rose, fontSize: 12, textAlign: 'center' },
  otpDefault: {
    borderColor: colors.disabled.smoke,
    borderRadius: scale(12),
    borderWidth: 1,
    color: colors.neutral.charcoal,
    fontFamily: 'Nunito-Bold',
    fontSize: scale(24),
    height: scale(62),
    lineHeight: verticalScale(36),
    paddingTop: 8,
    width: scale(62),
  },
  otpDisabled: { backgroundColor: colors.disabled.smoke, borderColor: colors.disabled.smoke },
  otpFailed: { borderColor: colors.error.rose },
  otpGroup: {
    height: scale(62),
    marginHorizontal: 16,
    marginTop: 24,
    marginVertical: 10,
  },
  otpHightLight: { borderColor: colors.secondary.orchid },
  otpParent: { backgroundColor: colors.neutral.cotton, flex: 1 },

  otpTextStyles: {
    fontSize: scale(30),
    marginHorizontal: scale(16),
    marginTop: scale(12),
  },
  resendText: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  subPhoneNumberStyle: { fontFamily: 'Nunito-Bold', lineHeight: scale(24) },
});

export default styles;
