import appStyles from '@root/appStyles';
import tncData from '@root/tnc';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, SectionList, View } from 'react-native';
import styles from './style';

const GGTermsAndConditions = () => {
  const [data, setData] = useState();
  const { t } = useTranslation('common');
  const fetchTermsAndConditions = () => {
    setData(tncData);
  };

  useEffect(() => {
    const { login_terms_page: loginTermsPage } = AMPLITUDE_CONSTANTS.authentication;
    AmplitudeHelper.logEvent(loginTermsPage.name, {
      [loginTermsPage.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });

    fetchTermsAndConditions();

    return () => {
      const { back_arrow_clicked: backArrowClicked } = AMPLITUDE_CONSTANTS.authentication;
      AmplitudeHelper.logEvent(backArrowClicked.name, {
        [backArrowClicked.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_terms_page,
      });
    };
  }, []);

  const header = () => (
    <Text testID="txtHeaderTnC" selectable variant="md-normal">
      {t(`tnc.${data?.description}`)}
    </Text>
  );

  const renderItem = ({ item, section }) => (
    <View style={styles.tncItem}>
      <Text testID="txtItemText" variant="md-normal" selectable>
        {t(`tnc.${item.text}`)}
      </Text>
      {item?.list?.length > 0 &&
        item.list.map(e => (
          <Text testID="txtDetails" selectable variant="md-normal" key={e} style={styles.item}>
            {t(`tnc.${e}`)}
          </Text>
        ))}
      {section?.title?.footerDescription && section?.data?.length !== 0 && (
        <Text testID="txtFooterDescription" variant="md-normal" selectable style={styles.headerDescription}>
          {t(`tnc.${section.title.footerDescription}`)}
        </Text>
      )}
    </View>
  );

  const renderSectionHeader = ({ section }) => (
    <View>
      <Text testID="txtSubtitle" fontWeight="200" selectable style={styles.headerTitle}>
        {t(`tnc.${section.title?.subTitle}`)}
      </Text>

      {section?.title?.footerDescription && section?.data?.length === 0 && (
        <Text testID="txtFooterDescription" fontWeight="200" selectable style={styles.headerDescription}>
          {t(`tnc.${section.title.footerDescription}`)}
        </Text>
      )}
    </View>
  );

  const keyExtractor = item => item.text;

  return (
    <SafeAreaView style={[appStyles.flexRow, styles.safeAreaParent]}>
      <View style={styles.textContainer}>
        <Text testID="txtLastUpdated" variant="xsm-normal" color={colors.neutral.darkMist} style={styles.lastUpdate}>
          {t('tnc.tncLastUpdated')} {data?.lastupdatedDate}
        </Text>
        <SectionList
          keyboardShouldPersistTaps="never"
          keyExtractor={keyExtractor}
          sections={data?.data ?? []}
          renderItem={renderItem}
          ListHeaderComponent={header}
          renderSectionHeader={renderSectionHeader}
          contentContainerStyle={styles.sectionList}
        />
      </View>
    </SafeAreaView>
  );
};

export default GGTermsAndConditions;
