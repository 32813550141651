import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  subProductContainer: {
    columnGap: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 16,
  },
});
export default styles;
