import ErrorFallBack from '@atoms/ErrorFallBack';
import RefreshSystem from '@atoms/RefreshSystem';
import config from '@config';
import navigationConstants from '@navigation/navigationConstants';
import StackNavigation from '@navigation/StackNavigation';
import { NavigationContainer } from '@react-navigation/native';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AmplitudeHelper from '@utils/analytics';
import initializePerformanceTool from '@utils/performance';
import * as Linking from 'expo-linking';
import React, { useEffect } from 'react';
import { Alert } from 'react-native';
import ReactPWAInstallProvider from 'react-pwa-install';
import * as Sentry from 'sentry-expo';
import * as serviceWorkerRegistration from './web/serviceWorkerRegistration';

const prefix = Linking.createURL('/');
AmplitudeHelper.init();
initializePerformanceTool();

function GGApp() {
  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        [navigationConstants.HOME]: 'home',
        [navigationConstants.PembayaranTab]: 'billPayment',
        [navigationConstants.TransactionsTab]: 'Transactions',
        [navigationConstants.ProfileTab]: 'profile',
      },
    },
  };



  return Sentry.Browser.wrap(() => (
    <>
      {config.ENVIRONMENT === 'staging' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      <ErrorFallBack>
        <ReactPWAInstallProvider enableLogging>
          <NavigationContainer linking={linking}>
            <RefreshSystem>
              <StackNavigation />
            </RefreshSystem>
          </NavigationContainer>
        </ReactPWAInstallProvider>
      </ErrorFallBack>
    </>
  ));
}

serviceWorkerRegistration.register({
  onSuccess: () => { },
  onUpdate: reg => {
    Alert.alert(
      'Update available',
      'Gaji-Gesa updated in the back-end',
      [
        {
          text: 'Update',
          onPress: () => { },
        },
        { text: 'Cancel' },
      ],
      {
        cancelable: true,
        onDismiss: () => { },
      },
    );
  },
});

export default GGApp;
