import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  goldBanner: {
    marginTop: 8,
    overflow: 'hidden',
  },
  goldImage: { height: scale(80), width: '100%' },
});
export default styles;
