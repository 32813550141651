import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';
import { getFocusedTextForTab, getWebStyles } from './appStylesUtils';

const webStyles = getWebStyles();

const appStyles = StyleSheet.create({
  amountContainer: { flexDirection: 'row', marginTop: verticalScale(4) },
  bgColor: bgc => ({ backgroundColor: bgc }),
  borderBottomLeftRadius: val => ({ borderBottomLeftRadius: val }),
  borderBottomRightRadius: val => ({ borderBottomRightRadius: val }),
  borderRadius: val => ({ borderRadius: val }),
  borderRadius10: { borderRadius: 10 },
  borderTopLeftRadius: val => ({ borderTopLeftRadius: val }),
  borderTopRightRadius: val => ({ borderTopRightRadius: val }),
  borderWidth: val => ({ borderWidth: val }),
  borderWithShadow: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(12),
    elevation: 6,
    marginHorizontal: scale(16),
    marginTop: scale(12),
    paddingHorizontal: scale(16),
    paddingTop: scale(12),
    shadowColor: colors.black,

    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,

    // elevation: 6,
  },
  bottomView: {
    paddingBottom: 16,
    ...webStyles,
  },
  dotStyles: {
    borderRadius: 5,
    height: 5,
    marginHorizontal: 4,
    width: 5,
  },
  flex1: { flex: 1 },
  flex6: { flex: 6 },
  flexRow: { flexDirection: 'row' },
  height: val => ({ height: val }),
  marginBottom: val => ({ marginBottom: verticalScale(val) }),
  marginHorizontal: val => ({ marginHorizontal: scale(val) }),
  marginHorizontal5: { marginHorizontal: scale(5) },
  marginLeft: val => ({ marginLeft: scale(val) }),
  marginRight: val => ({ marginRight: scale(val) }),
  marginTop: val => ({ marginTop: verticalScale(val) }),
  marginVertical: val => ({ marginVertical: verticalScale(val) }),
  modalContainer: {
    alignSelf: 'stretch',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    width: '100%',
  },
  modalParent: { bottom: 0, justifyContent: 'flex-end' },
  padding: val => ({ padding: val }),
  paddingBottom: val => ({ paddingBottom: scale(val) }),
  paddingHorizontal: val => ({ paddingHorizontal: scale(val) }),
  paddingLeft: val => ({ paddingLeft: scale(val) }),
  paddingRight: val => ({ paddingRight: scale(val) }),
  paddingTop: val => ({ paddingTop: verticalScale(val) }),
  paddingVertical: val => ({ paddingVertical: verticalScale(val) }),
  pendingContainer: {
    backgroundColor: colors.warning.softSunflower,
    borderRadius: 12,
  },
  pendingText: {
    color: colors.warning.darkSunflower,
    marginHorizontal: 8,
    marginVertical: 5,
  },
  rejectedContainer: {
    flexDirection: 'row',
  },
  rejectedText: {
    color: colors.error.darkRose,
    marginHorizontal: 8,
    marginVertical: 5,
  },
  rejectedTextContainer: {
    backgroundColor: colors.error.softRose,
    borderRadius: 12,
  },
  safeAreaView: { backgroundColor: colors.neutral.cotton, flex: 1 },
  tabParent: { backgroundColor: colors.neutral.cotton, flex: 1, paddingBottom: 10 },
  tabStyles: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginVertical: 15,
  },
  tabText: focused => ({
    marginBottom: 8,
    color: getFocusedTextForTab(focused),
  }),
  width: val => ({ width: val }),
});
export default appStyles;
