import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export default function logAccountSelected(item, productConfig, source) {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar.name, {
    [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar.attributes.account_number]: item,
    [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar.attributes.transaction_category_id]:
      productConfig.transaction_category_id,
    [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar.attributes.source]: source,
  });
}
