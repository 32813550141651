import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  circleStyle: {
    borderRadius: scale(6),
    height: scale(12),
    width: scale(12),
  },
  containerStyle: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(12),
    elevation: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: scale(15),
    marginTop: scale(12),
    paddingHorizontal: scale(23),
    paddingVertical: scale(15),
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
  },
  displayText: { marginLeft: scale(11) },
  rowStyle: { alignItems: 'center', flexDirection: 'row' },
});

export default styles;
