import SkeletonComponent from '@atoms/SkeletonComponent';
import React from 'react';

function ShimmerReferralInput() {
  return <SkeletonComponent />;
}

ShimmerReferralInput.propTypes = {};
ShimmerReferralInput.defaultProps = {};
export default ShimmerReferralInput;
