import appStyles from '@root/appStyles';
import { PWA_REFFERAL_LINK } from '@root/constants';
import colors from '@theme/colors';
import { numberWithDots } from '@utils/utils';
import { View, Text, Link } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

function shouldShowLink(index) {
  return index === 5 ? (
    <Link
      _text={{
        color: colors.secondary.orchid,
        variant: 'sm-normal',
      }}
      isUnderlined={false}
      href={PWA_REFFERAL_LINK}
      isExternal>
      {PWA_REFFERAL_LINK}
    </Link>
  ) : null;
}

function ReferralTnC(props) {
  const { tncItem, index, referrerAmt, refereeAmt } = props;
  const displayText = () => {
    let text = tncItem.replace('$$referrerRewardAmount$$', numberWithDots(referrerAmt));
    text = text.replace('$$refereeRewardAmount$$', numberWithDots(refereeAmt));
    return text;
  };
  return (
    <View style={styles.tncContainer}>
      <Text style={styles.tncNumberStyle}>{index + 1}</Text>
      <Text variant="sm-normal" style={[appStyles.marginHorizontal(10), appStyles.paddingRight(20)]}>
        {displayText()}

        {shouldShowLink(index)}
      </Text>
    </View>
  );
}

ReferralTnC.propTypes = {
  tncItem: PropTypes.string,
  index: PropTypes.number,
  referrerAmt: PropTypes.number,
  refereeAmt: PropTypes.number,
};
ReferralTnC.defaultProps = {
  tncItem: '',
  index: 0,
  referrerAmt: 0,
  refereeAmt: 0,
};
export default ReferralTnC;
