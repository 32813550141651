import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    marginHorizontal: scale(16),
    marginVertical: scale(4),
    padding: scale(16),
  },
  detailRow: {
    // alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: scale(2),
  },
  styleAdj: {
    alignItems: 'flex-end',
    width: '48%',
  },
  textAdj: { textAlign: 'right' },
});

export default styles;
