import ReasonSelector from '@atoms/ReasonSelector';
import ReasonsModal from '@molecules/ReasonsModal';
import { getStore } from '@store/storeUtils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import logReasonSelected from './analyticsEditReasons';

export function shouldShowReasonsModal(
  showReasonSelector,
  setShowReasonSelector,
  reasonData,
  setWithdrawalReason,
  account,
  position,
) {
  if (showReasonSelector) {
    return (
      <ReasonsModal
        showReasonSelector={showReasonSelector}
        setShowReasonSelector={setShowReasonSelector}
        reasonData={reasonData}
        setReason={setWithdrawalReason}
        account={account}
        position={position}
        isEditReasons
      />
    );
  }
  return null;
}

function EditReasons(props) {
  const { iconUrl, transReason, position, disabled, transReasonId } = props;
  const [showReasonSelector, setShowReasonSelector] = useState(false);
  const { selectedTxnAccount, withdrawalReason, setWithdrawalReason } = getStore();
  const [selectedTxnReason, setSelectedTxnReason] = useState({ transReason, iconUrl });

  useEffect(() => {
    if (withdrawalReason)
      setSelectedTxnReason({ transReason: withdrawalReason?.reason, iconUrl: withdrawalReason.imageUrl });
    else setWithdrawalReason({ id: transReasonId, reason: transReason, imageUrl: iconUrl });
  }, [withdrawalReason]);

  const onReasonSelectorHandler = () => {
    setShowReasonSelector(true);
    logReasonSelected();
  };

  return (
    <View>
      <ReasonSelector
        disabled={disabled}
        onPress={onReasonSelectorHandler}
        iconUrl={selectedTxnReason.iconUrl}
        selectedReason={selectedTxnReason.transReason}
      />
      {shouldShowReasonsModal(
        showReasonSelector,
        setShowReasonSelector,
        withdrawalReason,
        setWithdrawalReason,
        selectedTxnAccount,
        position,
      )}
    </View>
  );
}

EditReasons.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  transReason: PropTypes.string.isRequired,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  transReasonId: PropTypes.string.isRequired,
};

EditReasons.defaultProps = {
  position: 'DECREASE',
  disabled: true,
};

export default React.memo(EditReasons);
