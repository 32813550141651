import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function WithdrawalTotal(props) {
  const { transferAmount } = props;
  const { t } = useTranslation('common');
  return (
    <View style={appStyles.amountContainer}>
      <Text testID="lblWithdrawTotal" variant="sm-bold" flex={1}>
        {t('withdrawalPage.withdrawalTotal')}
      </Text>
      <TextCurrency
        variant="sm-bold"
        amountCurrency={transferAmount}
        showCurrencySymbol
        style={{ color: colors.success.cactus }}
      />
    </View>
  );
}

WithdrawalTotal.propTypes = {
  transferAmount: PropTypes.number,
};
WithdrawalTotal.defaultProps = {
  transferAmount: 0,
};
export default WithdrawalTotal;
