import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function TextWithValue(props) {
  const { t } = useTranslation('common');
  const { textToShow, valueText, onValuePress, isValueHyperLink, textSize, valueSize, textWeight, valueWeight } = props;
  return (
    <Text variant={textSize} testID="txtPlain" sentry-label="txtPlain" fontWeight={textWeight} {...props}>
      {t(textToShow)}{' '}
      <Text
        testID="txtValue"
        sentry-label="txtValue"
        onPress={onValuePress}
        variant={valueSize}
        underline={isValueHyperLink}
        color={isValueHyperLink ? colors.secondary.orchid : colors.neutral.charcoal}
        fontWeight={valueWeight}>
        {t(valueText)}
      </Text>
    </Text>
  );
}

TextWithValue.propTypes = {
  textToShow: PropTypes.string,
  valueText: PropTypes.string,
  onValuePress: PropTypes.func,
  isValueHyperLink: PropTypes.bool,
  textSize: PropTypes.string,
  valueSize: PropTypes.string,
  textWeight: PropTypes.string,
  valueWeight: PropTypes.string,
};
TextWithValue.defaultProps = {
  textToShow: '',
  valueText: '',
  onValuePress: () => {},
  isValueHyperLink: false,
  textSize: 'md',
  valueSize: 'md',
  textWeight: '100',
  valueWeight: '100',
};

export default TextWithValue;
