import LoanMetaList from '@atoms/Loan/LoanMetaList';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

const LoanInfo = () => {
  const { t } = useTranslation('common');
  return (
    <ScrollView containerStyle={appStyles.flex1} style={{ backgroundColor: colors.neutral.cotton }}>
      <LoanMetaList t={t} />
    </ScrollView>
  );
};

export default LoanInfo;
