import useFeatureFlag from '@customHooks/useFeatureFlag';
import { AntDesign } from '@expo/vector-icons';
import BottomSheet from '@molecules/LoginInfoSheet';

import { useNavigation } from '@react-navigation/native';
import {
  BOTTOMSHEET_CONTENT,
  BOTTONSHEET_KEYS,
  FEATURE_FLAGS,
  addBankTemplate1,
  addBankTemplate2,
  addBankTemplate3,
} from '@root/constants';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { returnBottomSheetContent } from '@utils/utils';
import { Button, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import bottomSheetHandlersForBankWithPin from './pinWithAddBankUtils';

function PinWithAddBank(props) {
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const { type, userBanks, fromTransfer, source } = props;
  const { pinRegister } = getStore();
  const initialState = {
    visible: false,
    data: returnBottomSheetContent(BOTTONSHEET_KEYS.BANK_DELETE, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.BANK_DELETE]),
    errorCode: BOTTONSHEET_KEYS.BANK_DELETE,
  };
  const [infoModal, setInfoModal] = useState(initialState);
  const [isFeatureAvailale] = useFeatureFlag({ featureName: [FEATURE_FLAGS.SECURITY_PIN] });

  const { checkPinAndCreateBank, onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onCloseModalHandler } =
    bottomSheetHandlersForBankWithPin({
      setInfoModal,
      infoModal,
      navigation,
      userBanks,
      initialState,
      isFeatureAvailale,
      pinRegister,
    });
  if (type === addBankTemplate1) {
    return (
      <>
        <Text
          testID="btnAddBank"
          variant="sm-bold"
          color={colors.secondary.orchid}
          underline
          onPress={() => checkPinAndCreateBank(fromTransfer, source)}>
          {t('bankList.addBankAccount')}
        </Text>
        <BottomSheet
          onPressPrimary={onPressPrimaryBottomSHeet}
          onPressSecondary={onPressSecondaryBottomSheet}
          loginInfo={infoModal}
          hasTwoButtons={infoModal.data.hasTwoButtons}
          onClose={onCloseModalHandler}
        />
      </>
    );
  }
  if (type === addBankTemplate2) {
    return (
      <>
        <Button
          testID="btnAddBank"
          variant="ghost"
          onPress={() => checkPinAndCreateBank(fromTransfer, source)}
          borderColor={colors.disabled.smoke}
          marginY="16px"
          marginX="28px"
          rightIcon={<AntDesign name="plus" size={16} color="black" />}>
          <Text testID="btnAddBankTxt" variant="md-bold">
            {t('profile.addBankString')}
          </Text>
        </Button>
        <BottomSheet
          onPressPrimary={onPressPrimaryBottomSHeet}
          onPressSecondary={onPressSecondaryBottomSheet}
          loginInfo={infoModal}
          hasTwoButtons={infoModal.data.hasTwoButtons}
          onClose={onCloseModalHandler}
        />
      </>
    );
  }
  if (type === addBankTemplate3) {
    return (
      <>
        <Button testID="btnAddBank" flex={1} onPress={() => checkPinAndCreateBank(fromTransfer, source)}>
          <Text testID="btnAddBankTxt" color={colors.neutral.cotton} variant="md-bold">
            {t('bankList.addBankAccount')}
          </Text>
        </Button>
        <BottomSheet
          onPressPrimary={onPressPrimaryBottomSHeet}
          onPressSecondary={onPressSecondaryBottomSheet}
          loginInfo={infoModal}
          hasTwoButtons={infoModal.data.hasTwoButtons}
          onClose={onCloseModalHandler}
        />
      </>
    );
  }
  return null;
}

PinWithAddBank.propTypes = {
  type: PropTypes.string,
  userBanks: PropTypes.arrayOf({}),
  fromTransfer: PropTypes.bool,
  source: PropTypes.string,
};
PinWithAddBank.defaultProps = {
  type: 'template1',
  userBanks: [],
  fromTransfer: false,
  source: '',
};
export default PinWithAddBank;
