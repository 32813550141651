import { getDateInFormatLocale } from '@utils/dateUtils';

export function getPaymentCycleDate(selectedIdx, paymentCycles) {
  if (paymentCycles.length > 0) {
    const { startDate = '', endDate = '' } = paymentCycles[selectedIdx];
    return ` ${getDateInFormatLocale(startDate, 'DD MMM')} - ${getDateInFormatLocale(endDate, 'DD MMM YYYY')}`;
  }
  return '';
}

export function getBoundary(selectedIdx, paymentCycles) {
  return !(selectedIdx >= 0 && selectedIdx < paymentCycles.length);
}
