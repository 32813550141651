import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { Checkbox, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import logCheckUncheck from './analyticsTnCBox';
import styles from './styles';

function BPTncCheckBox(props) {
  const { tncChecked, setTncOpen, setTncChecked, amplitudeAccountName, transaction_category_id, source } = props;
  const { validatedReferralCode } = getStore();
  const { t } = useTranslation('common');
  return (
    <TouchableOpacity
      style={styles.checkBoxContainer}
      // onPress={() => {}}
    >
      <Checkbox
        testID="cbTnC"
        value="test"
        accessibilityLabel="This is a dummy checkbox"
        isChecked={tncChecked}
        _checked={{
          backgroundColor: colors.primary.carnation,
          borderColor: colors.primary.carnation,
        }}
        onChange={() => {
          logCheckUncheck(transaction_category_id, amplitudeAccountName, tncChecked, validatedReferralCode, source);
          setTncChecked(!tncChecked);
        }}
      />
      <Text
        testID="txtTncText"
        variant="sm-bold"
        style={styles.tncText}
        color={colors.secondary.orchid}
        onPress={() => {
          setTncOpen(true);
        }}>
        {t('reqTxSheet.confirmTnC')}
      </Text>
    </TouchableOpacity>
  );
}

BPTncCheckBox.propTypes = {
  tncChecked: PropTypes.bool,
  setTncOpen: PropTypes.func,
  setTncChecked: PropTypes.func,
  amplitudeAccountName: PropTypes.string,
  transaction_category_id: PropTypes.string,
  source: PropTypes.string,
};

BPTncCheckBox.defaultProps = {
  tncChecked: false,
  setTncOpen: () => {},
  setTncChecked: () => {},
  amplitudeAccountName: '',
  transaction_category_id: '',
  source: '',
};

export default BPTncCheckBox;
