import useFeatureFlag from '@customHooks/useFeatureFlag';
import { MaterialIcons } from '@expo/vector-icons';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { FEATURE_FLAGS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { formatUnderScoreLowerCase } from '@utils/utils';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

function BPPhoneSelection(props) {
  const { bpPhone } = getStore();
  const { productConfig } = props;
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(bpPhone);
  // const { txnCategory } = props;
  const { mobileNumber, name } = selectedPhoneNumber;
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: FEATURE_FLAGS.PHONE_ENROLL,
  });

  useEffect(() => {
    setSelectedPhoneNumber(bpPhone);
  }, [bpPhone]);

  if (isFeatureAvailale.show) {
    const onPressGantiButton = () => {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.change_number_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: formatUnderScoreLowerCase(productConfig?.productName),
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(productConfig?.transaction_category_id),
      });

      navigation.navigate(navigationConstants.ENROLLED_PHONES, {
        isTransact: true,
        transactionCategoryId: productConfig?.transaction_category_id,
        eventSource: formatUnderScoreLowerCase(productConfig?.productName),
      });
    };
    return (
      <View>
        <View style={styles.phoneContainer}>
          <View style={styles.bpPhoneIcon}>{SVG_ICONS.BPIcons.phoneSelector}</View>
          <View style={styles.nameAndNumber}>
            <Text testID="txtPhName" variant="sm-bold" color={colors.primary.carnation}>
              {name}
            </Text>
            <Text testID="txtMobileNo" variant="md-bold" color={colors.neutral.charcoal}>
              {mobileNumber}
            </Text>
          </View>
          <TouchableOpacity testID="btnGanti" style={styles.gantiButton} onPress={onPressGantiButton}>
            <Text marginRight="4px" variant="xsm-bold">
              {t('bp.phoneTransfer')}
            </Text>
            <MaterialIcons name="contacts" size={16} color={colors.secondary.darkOrchid} />
          </TouchableOpacity>
        </View>
        <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
      </View>
    );
  }
  return null;
}

BPPhoneSelection.propTypes = {
  productConfig: PropTypes.shape({
    productName: PropTypes.string,
    transaction_category_id: PropTypes.string,
  }),
};
BPPhoneSelection.defaultProps = {
  productConfig: {
    productName: '',
    transaction_category_id: '',
  },
};
export default BPPhoneSelection;
