import { apiSignUp } from '@api/apiCall';
import CompanyNameInput from '@atoms/CompanyNameInput';
import EmployeeOrKTPInput from '@atoms/EmployeeOrKTPInput';
import FullNameInput from '@atoms/FullNameInput';
import LoginPhoneTxt from '@atoms/LoginPhoneTxt';
import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import SignUpDataSelect from '@atoms/SignUpDataSelect';
import SignUpInformation from '@atoms/SignUpInformation';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import LoginPhoneInput from '@molecules/LoginPhoneInput';
import { useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@root/constants';
import { phoneChangeHandler } from '@screens/GGLogin/loginUtils';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatPhoneNumber, returnBottomSheetContent } from '@utils/utils';
import { Button, Modal, ScrollView, Text, useToast, View } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, SafeAreaView, TouchableOpacity } from 'react-native';
import {
  getItemSeperatorComponent,
  selectDataAndCheckBox,
  shouldCheckForDisabled,
  shouldCheckForEditable,
  shouldRenderSignUpError,
  shouldRenderSignUpSuccess,
  shouldSetMarginTop,
  shouldShowSelectedCheck,
  shouldValidateForm,
} from './signupUtils';
import styles from './styles';

/**
 * title: translates in UI from supportedLanguages
 * placeHolder: translates in UI from supportedLanguages
 * testId: Used for jest
 * id: Used to pass to API
 */
const signUpValidation = [
  {
    title: 'employeeIdHeader',
    placeHolder: 'employeeIdPlaceholder',
    testId: 'empId',
    id: 'employee-id',
    selected: true,
  },
  { title: 'ktpHeader', placeHolder: 'ktpPlaceholder', testId: 'ktpId', id: 'national-id', selected: false },
];

function shouldShowDataSelectModal(
  openModalForSelection,
  setOpenModalForSelection,
  dataType,
  selectValidationType,
  setDataType,
  t,
) {
  if (openModalForSelection) {
    return (
      <Modal testID="signUpDataModal" isOpen={openModalForSelection} borderBottomRadius={0} size="full">
        <Modal.Content
          marginTop="auto"
          bottom={0}
          position="absolute"
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}>
          <ModalHeader headerTranslationString="signup.selectData" />
          <ModalClose testID="modalClose" onPress={() => setOpenModalForSelection(false)} />
          <FlatList
            testID="fltLstTypes"
            data={dataType}
            // style={styles.accountListStyle}
            scrollEnabled
            contentContainerStyle={styles.fltLstStyles}
            ItemSeparatorComponent={getItemSeperatorComponent()}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                testID={dataType[index].testId}
                sentry-label={dataType[index].testId}
                onPress={() => {
                  selectDataAndCheckBox(selectValidationType, index, dataType, setDataType, setOpenModalForSelection);
                }}
                style={styles.idTypeStyles}>
                <Text
                  testID={`txt${signUpValidation[index].placeHolder}`}
                  marginY="16px"
                  marginLeft="16px"
                  variant="sm-normal"
                  flex={1}>
                  {t(`signup.${signUpValidation[index].title}`)}
                </Text>
                {shouldShowSelectedCheck(item.selected)}
              </TouchableOpacity>
            )}
          />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
}

function GGSignup() {
  const route = useRoute();
  const { params } = route;
  const { phoneNumber } = params || { phoneNumber: '' };
  const { t } = useTranslation('common');
  const [validationType, selectValidationType] = useState(0);
  const [openModalForSelection, setOpenModalForSelection] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(phoneNumber);
  const [dataType, setDataType] = useState(signUpValidation);
  //  const [selectedDataType] = useState(signUpValidation.filter(item => item.selected)[0]);

  const [sFullName, setFullName] = useState('');
  const [sCompanyName, setCompanyName] = useState('');
  const [sId, setId] = useState('');
  const [sPhoneError, setPhoneError] = useState('');
  const navigation = useNavigation();
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.IN_PROCESS,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.IN_PROCESS],
      mobileNumber,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.IN_PROCESS,
  });
  const toast = useToast();
  const { tenantSettings, setLoading, setInitialData } = getStore();
  const { countryCode } = tenantSettings;
  const setDisabled = () => {};

  const selectedDataType = useMemo(() => dataType.filter(item => item.selected)[0], [dataType]);

  const phoneInputProps = {
    phoneNumber: mobileNumber,
    setPhoneNumber: phoneChangeHandler(sPhoneError, setPhoneError, setMobileNumber, setDisabled, t, countryCode),
    sError: sPhoneError,
    isDisabled: shouldCheckForDisabled(route),
    isEditable: shouldCheckForEditable(route),
    marginTop: shouldSetMarginTop(),
    borderColor: colors.disabled.smoke,
  };

  const userInput = {
    employerName: sCompanyName,
    userName: sFullName,
    phoneNumber: formatPhoneNumber(mobileNumber, countryCode),
    identityType: selectedDataType.id,
    identityId: sId,
  };
  const authenticAmplitude = AMPLITUDE_CONSTANTS.authentication;
  const logParams = {
    [authenticAmplitude.check_data_attributes.phone_number]: userInput?.phoneNumber ?? null,
    [authenticAmplitude.check_data_attributes.id_type]: userInput?.identityType ?? null,
    [authenticAmplitude.check_data_attributes.id_number]: userInput?.identityId ?? null,
    [authenticAmplitude.check_data_attributes.employee_name]: userInput?.userName ?? null,
    [authenticAmplitude.check_data_attributes.employer_name]: userInput?.employerName ?? null,
  };
  const logEvent = val => AmplitudeHelper.logEvent(authenticAmplitude[val.name].name, logParams);

  useEffect(() => {
    if (dataType) {
      logEvent(AMPLITUDE_CONSTANTS.authentication.id_type_chosen);
    }
  }, [dataType]);

  const {
    isLoading,
    mutate: performSignUp,
    isSuccess,
    data: signUpData,
    isError,
    error,
  } = useMutation({ mutationFn: payload => apiSignUp(payload) });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.authentication.login_check_data_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(userInput.phoneNumber),
    });

    return () => {
      const e1 = AMPLITUDE_CONSTANTS.authentication.login_check_data_closed;
      AmplitudeHelper.logEvent(e1.name, {
        [e1.attributes.phone_number]: formatPhoneNumber(userInput.phoneNumber),
      });
    };
  }, []);

  useEffect(() => {
    shouldRenderSignUpError(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    shouldRenderSignUpSuccess(isSuccess, signUpData, setInfoModal, infoModal);
  }, [isSuccess, signUpData]);

  const onCloseModal = () => {
    setInfoModal({ ...infoModal, visible: false });
    navigation.navigate('Login');
  };

  const onDataSelectHandler = () => {
    logEvent(AMPLITUDE_CONSTANTS.authentication.id_type_clicked);
    setOpenModalForSelection(true);
  };

  return (
    <SafeAreaView style={appStyles.flex1}>
      <ScrollView contentContainerStyle={styles.scrollViewContainer} keyboardShouldPersistTaps="handled">
        <View style={styles.signUpContainer}>
          <View style={appStyles.flex6}>
            <SignUpInformation />
            <SignUpDataSelect
              setOpenModalForSelection={onDataSelectHandler}
              signUpValidation={dataType}
              validationType={validationType}
              t={t}
            />
            <EmployeeOrKTPInput
              signUpValidation={dataType}
              validationType={validationType}
              t={t}
              setId={setId}
              onKTPInputFocus={() => logEvent(AMPLITUDE_CONSTANTS.authentication.id_number_clicked)}
              onKTPInputBlur={() => logEvent(AMPLITUDE_CONSTANTS.authentication.id_number_filled)}
            />
            <FullNameInput
              t={t}
              setFullName={setFullName}
              onFullNameFocus={() => logEvent(AMPLITUDE_CONSTANTS.authentication.employee_name_clicked)}
              onFullNameBlur={() => logEvent(AMPLITUDE_CONSTANTS.authentication.employee_name_filled)}
            />
            <CompanyNameInput
              t={t}
              setCompanyName={setCompanyName}
              onCompanyNameFocus={() => logEvent(AMPLITUDE_CONSTANTS.authentication.employer_name_clicked)}
              onCompanyNameBlur={() => logEvent(AMPLITUDE_CONSTANTS.authentication.employer_name_filled)}
            />
            <LoginPhoneTxt
              color={colors.disabled.smoke}
              fontWeight="200"
              isMandatory
              marginTop="24px"
              variant="sm-bold"
            />
            <LoginPhoneInput {...phoneInputProps} logParams={logParams} />
          </View>
          <View style={appStyles.bottomView}>
            <Button
              testID="btnSignup"
              marginX="16px"
              isDisabled={!shouldValidateForm(userInput)}
              variant="solid"
              onPress={() => {
                logEvent(AMPLITUDE_CONSTANTS.authentication.login_check_data_requested);
                performSignUp(userInput);
              }}>
              {t('signup.headerText')}
            </Button>
          </View>
          {shouldShowDataSelectModal(
            openModalForSelection,
            setOpenModalForSelection,
            dataType,
            selectValidationType,
            setDataType,
            t,
          )}
          <LoginInfoSheet onPressPrimary={onCloseModal} loginInfo={infoModal} onClose={onCloseModal} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default GGSignup;
