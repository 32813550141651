import colors from '@theme/colors';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function HiUserName(props) {
  const { t, userName, commonColor } = props;
  return (
    <View marginLeft="11px" flexDirection="row">
      <Text testID="txtProfileHi" variant="sm-bold" color={commonColor}>
        {t('profile.hi')},{' '}
      </Text>
      <Text testID="txtUserName" variant="sm-bold" color={commonColor}>
        {userName}
      </Text>
    </View>
  );
}

HiUserName.propTypes = {
  t: PropTypes.func,
  userName: PropTypes.string,
  commonColor: PropTypes.string,
};

HiUserName.defaultProps = {
  t: () => {},
  userName: '',
  commonColor: colors.neutral.charcoal,
};

export default HiUserName;
