import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  animatedButtonStyle: interpolated => ({ transform: [{ rotate: interpolated }], marginTop: 5 }),
  container: { alignItems: 'center', color: colors.neutral.charcoal, flexDirection: 'row' },
  gradientContainer: {
    alignItems: 'center',
    backgroundColor: colors.transparent,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: 16,
  },
  iconView: {
    alignItems: 'center',
    backgroundColor: colors.primary.darkCarnation,
    borderRadius: 30 / 2,
    height: 30,
    justifyContent: 'center',
    width: 30,
    zIndex: 1,
  },
  pressableContainer: {
    borderRadius: 20,
    overflow: 'hidden',
  },
  referralPressable: {
    alignItems: 'center',
    backgroundColor: colors.primary.carnation,
    flexDirection: 'row',
  },
  textStyle: {
    color: colors.neutral.cotton,
    marginLeft: 10,
    marginRight: 17,
  },
});

export default styles;
