// tokenManager.js
let accessToken = null;
let refreshToken = null;

const setAccessToken = newAccessToken => {
  accessToken = newAccessToken;
};

const setRefreshToken = newRefreshToken => {
  refreshToken = newRefreshToken;
};

const getAccessToken = () => accessToken;
const getRefreshToken = () => refreshToken;

const clearAccessToken = () => {
  accessToken = null;
};

const clearRefreshToken = () => {
  refreshToken = null;
};

export default {
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
  clearAccessToken,
  clearRefreshToken,
};
