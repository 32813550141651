import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { formatPhoneNumber } from '@utils/utils';
import { Button, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function BtnWhatsapp(props) {
  const { t } = useTranslation('common');
  const { isLoadingWhatsapp, isDisabled, phoneNumber, setIsLoadingSMS, setIsLoadingWhatsapp, performLogin } = props;
  const { tenantSettings } = getStore();
  const { countryCode } = tenantSettings;
  return (
    <Button
      isLoading={isLoadingWhatsapp}
      isDisabled={isDisabled}
      flex={1}
      variant="solid"
      style={styles.btnWhatsappStyles}
      testID="btnWhatsapp"
      sentry-label="btnWhatsapp"
      onPress={() => {
        setIsLoadingSMS(false);
        setIsLoadingWhatsapp(true);
        performLogin({
          otpType: 'WHATSAPP',
          mobileNumber: formatPhoneNumber(phoneNumber, countryCode),
        });
      }}>
      <Text testID="txtWhatsapp" variant="sm-bold" color={colors.neutral.cotton}>
        {t('login.whatsapp')}
      </Text>
    </Button>
  );
}

BtnWhatsapp.propTypes = {
  isLoadingWhatsapp: PropTypes.bool,
  isDisabled: PropTypes.bool,
  phoneNumber: PropTypes.string,
  setIsLoadingSMS: PropTypes.func,
  setIsLoadingWhatsapp: PropTypes.func,
  performLogin: PropTypes.func,
};

BtnWhatsapp.defaultProps = {
  isLoadingWhatsapp: false,
  isDisabled: false,
  phoneNumber: '',
  setIsLoadingSMS: () => {},
  setIsLoadingWhatsapp: () => {},
  performLogin: () => {},
};

export default BtnWhatsapp;
