import RemoteImage from '@atoms/RemoteImage';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React from 'react';
import { View, Text as MyText } from 'react-native';
import styles from './styles';

export const getStatusAndColor = status => {
  switch (status) {
    case 'PENDING':
      return {
        bgColor: colors.warning.softSunflower,
        color: colors.warning.sunflower,
        text: 'transStatus.wait',
      };

    case 'COMPLETED':
    case 'CREDITED':
    case 'DEBITED':
      return {
        color: colors.success.cactus,
        bgColor: colors.success.softCactus,
        text: 'transStatus.success',
      };

    case 'FAILED':
      return {
        bgColor: colors.error.softRose,
        color: colors.error.rose,
        text: 'transStatus.failed',
      };
    case 'REJECTED':
      return {
        bgColor: colors.error.softRose,
        color: colors.error.rose,
        text: 'transStatus.rejected',
      };

    default:
      return {
        color: colors.neutral.cotton,
        text: '',
      };
  }
};

export function shouldShowRedeemURL(item, t) {
  let displayLabel = null;
  if (item.redeemUrl || item.token) displayLabel = t('transactionHistory.kodeLink');

  return displayLabel ? (
    <View style={styles.redeemContainer}>
      <MyText style={styles.redeemText}>{displayLabel}</MyText>
    </View>
  ) : null;
}

export function shouldShowCoinOrExpenseArrow(isCoinBased, account) {
  if (isCoinBased) {
    return (
      <View testID="coinIcon" style={styles.coinIconContainer}>
        <RemoteImage uri={account.icon} height={scale(14)} width={scale(14)} />
      </View>
    );
  }
  return null;
}

export function shouldShowDisplayName(item) {
  if (item?.displayName)
    return (
      <Text variant="xsm-normal" color={colors.neutral.darkMist}>
        {item.displayName}
      </Text>
    );
  return null;
}

export function shouldShowDescription(item) {
  if (item?.description?.length > 0)
    return (
      <Text variant="sm-normal" color={colors.neutral.darkMist}>
        {' '}
        | {item.description}
      </Text>
    );
  return null;
}

export function shouldShowItemIcon(item) {
  if (item?.iconUrl)
    return (
      <View testID="remoteImage">
        <RemoteImage uri={item.iconUrl} height={32} width={32} />
      </View>
    );
  return <View testID="itemIconContainer" style={styles.itemIconContainer} />;
}
