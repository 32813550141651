import SkeletonComponent from '@atoms/SkeletonComponent';
import useFetchBanks from '@customHooks/useFetchBanks';
import { useIsFocused } from '@react-navigation/native';
import GGEmptyBankPage from '@screens/GGBankEmptyPage';
import GGBankTransferPage from '@screens/GGBankTransferPage';

import React, { useEffect } from 'react';

function GGBankTransfer(props) {
  const { bankList, isLoading, isFetching, isFetched, refetch } = useFetchBanks();
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);
  if ((isLoading || isFetching) && bankList?.length === 0) {
    return <SkeletonComponent />;
  }
  if (bankList?.length > 0) {
    return <GGBankTransferPage {...props} />;
  }
  if (!isLoading && isFetched && bankList?.length === 0) {
    return <GGEmptyBankPage fromTransfer {...props} />;
  }

  return null;
}

GGBankTransfer.propTypes = {};
GGBankTransfer.defaultProps = {};

export default GGBankTransfer;
