import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  closeButton: {
    alignSelf: 'flex-end',
    marginRight: 20,
  },
  container: {
    backgroundColor: colors.neutral.cotton,
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: 10,
    paddingVertical: 20,
    width: '100%',
  },
  description: {
    marginVertical: scale(10),
  },
  fromGalleryButton: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: scale(10),
  },
  fromGalleryIcon: {
    marginRight: scale(10),
  },
  fromGalleryText: {
    marginLeft: scale(10),
  },
  title: {
    marginTop: -10,
  },
});

export default styles;
