import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logReasonSelected() {
  const e = AMPLITUDE_CONSTANTS.TransactionPage.field_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
    [e.attributes.field_name]: 'reason',
  });
}

export default logReasonSelected;
