import CommonTextInput from '@atoms/CommonTextInput';
import { getStore } from '@store/storeUtils';
import { verticalScale } from '@utils/normalize';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Text } from 'react-native';
import logCustomReasonInput from './analyticsReasonInput';
import styles from './styles';

function CustomReasonInput(props) {
  const { withdrawalReason, validatedReferralCode } = getStore();
  const { setCustomReasonText, source } = props;
  if (withdrawalReason?.id === 18) {
    return (
      <View style={styles.customReasons}>
        <Text style={{ marginBottom: verticalScale(12) }} variant="lg-bold">
          {withdrawalReason.reason}
        </Text>
        <CommonTextInput
          style={styles.inputStyles}
          onChangeText={val => setCustomReasonText(val)}
          amplitudeCallBackOnBlur={val => {
            const textValue = val?.target?.value ?? '';
            logCustomReasonInput(textValue, withdrawalReason, validatedReferralCode, source);
          }}
        />
      </View>
    );
  }
  return null;
}

CustomReasonInput.propTypes = {
  withdrawalReason: PropTypes.shape({
    id: PropTypes.number,
    reason: PropTypes.string,
  }),
  setCustomReasonText: PropTypes.func,
  source: PropTypes.string,
};
CustomReasonInput.defaultProps = {
  withdrawalReason: {
    id: 0,
    reason: '',
  },
  setCustomReasonText: () => {},
  source: '',
};
export default CustomReasonInput;
