import BPSubProdSelectedNominal from '@atoms/BPSubProdSelectedNominal';
import navigationConstants from '@navigation/navigationConstants';
import { logRequestBP } from '@screens/GGBillPayments/GGNominals/analyticsNominals';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

export default function setOfInlineFunctions(params) {
  const {
    setSelectedNominal,
    setShowNominalsModal,
    productId,
    navigation,
    selectedProductItem,
    productNominals,
    selectedNominal,
    selectedAccountNumber,
    selectedBPAccount,
    productConfig,
    productApiData,
    requestBP,
    validatedReferralCode,
    source,
    meAmplitudeContext,
    // toast,
    // t,
  } = params;
  const { amplitudeAccountName } = selectedBPAccount;
  const listOfNominalsForSubProducts = ({ item }) => (
    <TouchableOpacity
      testID="btnForNominals"
      onPress={() => {
        if (item.availability) {
          setSelectedNominal(item);
          setShowNominalsModal(false);
        } else {
          // return unavailableToast(toast, t);
        }
      }}
      style={styles.listStyle}>
      <BPSubProdSelectedNominal selectedNominal={item} />
    </TouchableOpacity>
  );
  const closeModalCallBack = () => setShowNominalsModal(false);
  const btnToShowNominals = () => {
    setShowNominalsModal(true);
  };
  const onPressSubProdOrNominals = () => {
    if (productId === 13) {
      // bp request for vouchers
      navigation.navigate(navigationConstants.GG_VOUCHER_DETAILS, {
        selectedVoucher: selectedProductItem,
        voucherImage: productNominals[selectedProductItem][0].iconLink,
        selectedNominal,
        selectedAccountNumber,
        selectedBPAccount,
        productConfig,
        productApiData,
        requestBP,
        selectedProductItem,
      });
    } else {
      // bp request for e-money
      logRequestBP(
        selectedAccountNumber,
        productConfig,
        selectedNominal,
        source,
        null,
        meAmplitudeContext,
        amplitudeAccountName,
      );
      const request = {
        accountNumber: selectedAccountNumber,
        employeeAccountNumber: selectedBPAccount.id,
        transCategoryPrefix: productApiData.prefix,
        productCode: selectedNominal.code,
        referralCode: validatedReferralCode,
        zoneId: null,
        month: null,
      };
      requestBP(request);
    }
  };
  return { btnToShowNominals, onPressSubProdOrNominals, closeModalCallBack, listOfNominalsForSubProducts };
}
