import { fetchPromoCode } from '@api/useQueryCalls';
import CommonTextInput from '@atoms/CommonTextInput';
import ControlledTooltip from '@atoms/CustomToolTip';
import ToastSuccess from '@atoms/ToastSuccess';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Button, Text, useToast } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styles from './style';

const Promocode = () => {
  const [tempPromo, setTempPromo] = useState('');
  const { employeeId, isValidPromocode, setIsValidPromocode, promocode, setPromocode } = getStore();

  const { data } = fetchPromoCode({ employeeId });

  const onChangeReferral = text => {
    setTempPromo(text);
  };
  const toast = useToast();
  const { t } = useTranslation('common');
  const onSubmitHandler = () => {
    setIsValidPromocode(true);
    setPromocode(tempPromo);
    toast.show({
      render: () => <ToastSuccess data={t('autoDisburse.successOptIn')} />,
      placement: 'top',
      duration: 5000,
    });
  };

  if (data?.isPromoCodeApplicable) {
    return (
      <>
        <View style={styles.row}>
          <Text variant="sm-normal" style={styles.codeReferralText}>
            {t('treasury.kodePromo')}
          </Text>

          <ControlledTooltip translateString="transactionSummary.total.0">
            <AntDesign name="questioncircle" size={16} color={colors.neutral.darkMist} />
          </ControlledTooltip>
        </View>

        <View style={styles.horizontalView}>
          <CommonTextInput
            testID="txtReferalInput"
            style={[styles.commonInputStyle, isValidPromocode && styles.appliedInput]}
            editable={!isValidPromocode}
            placeholder={t('referral.inputPlaceHolder')}
            value={promocode || tempPromo}
            onChangeText={onChangeReferral}
          />
          <Button
            // loading={promocodeLoading}
            testID="btnPromoCode"
            disabled={isValidPromocode || tempPromo?.length === 0}
            style={[styles.button, !isValidPromocode ? styles.buttonNotApplied : styles.buttonApplied]}
            onPress={onSubmitHandler}
            rightIcon={
              isValidPromocode ? (
                <AntDesign name="check" color={colors.variants.clover} size={scale(18)} />
              ) : (
                <FontAwesome name="long-arrow-right" size={scale(18)} color={colors.disabled.smoke} />
              )
            }>
            <Text
              variant="md-normal"
              style={{ color: !isValidPromocode ? colors.disabled.smoke : colors.variants.clover }}>
              {isValidPromocode ? t('referral.validReferral') : t('referral.verifyBtn')}
            </Text>
          </Button>
        </View>
      </>
    );
  }

  return null;
};

export default Promocode;
