import Constants from 'expo-constants';

const config = Constants.expoConfig.extra;

export default {
  API_URL: config.API_URL,
  GRAPHQL_URL: config.GRAPHQL_URL,
  MEDIA_STORAGE: config.MEDIA_STORAGE,
  WHATSAPP_URL: config.WHATSAPP_URL,
  AMPLITUDE_API_KEY: config.AMPLITUDE_API_KEY,
  DYNAMICLINK_API_KEY: config.DYNAMICLINK_API_KEY,
  COUNTRY_CODE: config.COUNTRY_CODE,
  ENVIRONMENT: config.ENVIRONMENT,
  AI_SUPPORT_UI_URL: config.AI_SUPPORT_UI_URL,
  MOENGAGE_API_KEY: config.MOENGAGE_API_KEY,
  SENTRY_WEB_KEY: config.SENTRY_WEB_KEY,
};
