import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  emptyViewStyles: {
    alignSelf: 'flex-start',
    flex: 0.5,
  },
  trailArrowImagesContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
  trailTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  txtYouStyles: {
    borderColor: colors.secondary.orchid,
    borderRadius: 100,
    borderStyle: 'dashed',
    borderWidth: 1.5,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  yourFriendStyles: {
    borderColor: colors.primary.carnation,
    borderRadius: 100,
    borderStyle: 'dashed',
    borderWidth: 1.5,
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
});

export default styles;
