import { fetchAllBanks } from '@api/useQueryCalls';
import ListWithFilter from '@atoms/ListWithFilter';
import { useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import { useToast, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function GGAllBanks() {
  const [allBanks, setAllBanks] = useState([]);
  const { setInitialData, setLoading } = getStore();
  const route = useRoute();
  const { setSelectedBank, source } = route.params;
  const { isError, error, isLoading, isSuccess, data } = fetchAllBanks();
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const toast = useToast();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess && data && data.list) {
      setAllBanks(data.list);
    }
  }, [isSuccess, data]);

  return (
    <View style={appStyles.safeAreaView}>
      <ListWithFilter allBanks={allBanks} setAllBanks={setAllBanks} setSelectedBank={setSelectedBank} source={source} />
    </View>
  );
}

export default GGAllBanks;
