import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bannerButtonStyle: {
    borderRadius: scale(11),
    overflow: 'hidden',
    paddingVertical: scale(14),
  },
  buttonStyle: { marginHorizontal: scale(16) },
  containerStyle: { flex: 1, paddingHorizontal: scale(15), paddingTop: 0 },
  contentcontainerStyle: { flexGrow: 1, paddingBottom: scale(100) },
  disabledButton: { backgroundColor: colors.disabled.smoke },
  imageStyle: { height: scale(120), marginVertical: scale(16), width: '100%' },
  infoTextContainer: {
    backgroundColor: colors.neutral.mist,
    marginTop: scale(10),
    paddingLeft: scale(12),
    paddingVertical: scale(8),
  },
  loadingButton: {
    borderRadius: 30,
    justifyContent: 'center',
    marginVertical: scale(15),
    paddingVertical: scale(12),
    width: '95%',
  },
  loadingButtonText: {
    fontSize: scale(14),
  },
  mainContainer: {
    backgroundColor: colors.neutral.cotton,
    flex: 1,
  },
  textStyle: { width: '90%' },
  titleTextStyle: { marginTop: scale(0) },
});

export default styles;
