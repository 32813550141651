import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  innerRowStyle: {
    backgroundColor: colors.neutral.mist,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: scale(23),
    paddingVertical: scale(15),
  },
  innterRowValue: { marginRight: scale(11) },
});

export default styles;
