import TextCurrency from '@atoms/TextCurrency';
import { getDateInFormatLocale } from '@utils/dateUtils';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getStatusAndColor from './detailsUtils';
import styles from './style';
import BankEyeComponent from '../BankEyeComponent';

function shouldReturnValue(fieldConfig, detailsData, t) {
  let displayValue = detailsData[fieldConfig.value];

  if (fieldConfig.isDate) displayValue = getDateInFormatLocale(displayValue, 'DD-MM-YYYY');

  if (fieldConfig.totalWeightOfGold) displayValue = `${displayValue} gr`;

  if (fieldConfig.isStatus && displayValue) {
    const statusData = getStatusAndColor(displayValue);
    return (
      <Text variant="md-bold" style={[{ color: statusData.color }, styles.textAdj]}>
        {t(statusData.text)}
      </Text>
    );
  }

  if (fieldConfig.isCurrency)
    return (
      <TextCurrency
        amountCurrency={displayValue}
        showCurrencySymbol={!fieldConfig.dontShowCurrency}
        variant="md-bold"
        suffix={fieldConfig.isGoldPrice ? '/gr' : null}
        style={styles.textAdj}
      />
    );

  if (fieldConfig.isBankAccountNumber && displayValue) {
    return <BankEyeComponent value={displayValue} />;
  }

  return (
    <Text variant="md-bold" style={styles.textAdj}>
      {displayValue || '-'}
    </Text>
  );
}

function DetailsComponent(props) {
  const { detailsData, detailsConfig } = props;
  const { t } = useTranslation('common');

  return (
    <View style={styles.container}>
      {detailsConfig.details.map(e => (
        <View style={styles.detailRow} key={e.labelKey}>
          <Text variant="md-normal">{t(`editTransactions.${e.labelKey}`)}</Text>
          <View style={styles.styleAdj}>{shouldReturnValue(e, detailsData, t)}</View>
        </View>
      ))}
    </View>
  );
}

DetailsComponent.propTypes = {
  detailsData: PropTypes.shape({}).isRequired,
  detailsConfig: PropTypes.shape({
    details: PropTypes.arrayOf(
      PropTypes.shape({
        labelKey: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default DetailsComponent;
