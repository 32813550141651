import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Input, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

function FullNameInput(props) {
  const { t, setFullName, onFullNameFocus, onFullNameBlur } = props;
  return (
    <View marginTop="24px">
      <Text testID="txtNameInput" variant="sm-bold" color={colors.disabled.smoke}>
        {t('signup.nameTxtInput')}
        <Text color={colors.error.rose}>*</Text>
      </Text>
      <Input
        testID="fullNameInput"
        sentry-label="fullNameInput"
        placeholder={t('signup.nameTxtInputPlaceholder')}
        placeholderTextColor={colors.neutral.charcoal}
        fontWeight="100"
        style={[appStyles.paddingLeft(16), appStyles.paddingRight(16)]}
        size="md"
        variant="md-normal"
        borderColor={colors.disabled.smoke}
        onChangeText={val => setFullName(val)}
        _focus={{
          borderColor: colors.secondary.orchid,
        }}
        onFocus={onFullNameFocus}
        onBlur={onFullNameBlur}
      />
    </View>
  );
}

FullNameInput.propTypes = {
  setFullName: PropTypes.func,
  t: PropTypes.func,
  onFullNameFocus: PropTypes.func,
  onFullNameBlur: PropTypes.func,
};
FullNameInput.defaultProps = {
  setFullName: () => {},
  t: () => {},
  onFullNameFocus: () => {},
  onFullNameBlur: () => {},
};

export default FullNameInput;
