import { Image } from 'expo-image';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

function endsWithPNGJPGOrIsBase64(url) {
  const withoutQueryString = url.split('?')[0];
  const isPNG = withoutQueryString.endsWith('.png');
  const isJPEG = withoutQueryString.endsWith('.jpeg') || withoutQueryString.endsWith('.jpg');
  const isBase64 = withoutQueryString.startsWith('data:image/') && withoutQueryString.includes('base64,');

  return isPNG || isJPEG || isBase64;
}

function RemoteImage(props) {
  const { uri, width, height, isFullSize } = props;
  if (uri && uri.length > 0) {
    const uriEndsWithPNG = endsWithPNGJPGOrIsBase64(uri);
    if (uriEndsWithPNG) {
      return (
        <Image
          contentFit="contain"
          style={[styles.imageStyles(width, height), isFullSize && styles.fullSize]}
          source={{ uri }}
          {...props}
        />
      );
    }

    return <Image style={styles.imageStyles(width, height)} source={{ uri }} {...props} />;
  }
  return null;
}

RemoteImage.propTypes = {
  uri: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isFullSize: PropTypes.bool,
};
RemoteImage.defaultProps = {
  uri: '',
  width: 0,
  height: 0,
  isFullSize: false,
};

export default RemoteImage;
