import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  referralInputStyle: {
    color: colors.neutral.darkMist,
    flex: 1,
    fontFamily: regularFont,
    fontSize: scale(14),
    height: scale(32),
    lineHeight: scale(21),
    marginRight: scale(4),
    marginTop: 0,
    paddingVertical: 0,
  },
});

export default styles;
