import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  collapsibleContainer: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    marginBottom: scale(10),
    marginHorizontal: scale(14),
    marginTop: scale(5),
    paddingHorizontal: scale(10),
  },
  containerStyle: { backgroundColor: colors.neutral.cotton, flex: 1 },
});

export default styles;
