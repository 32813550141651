import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bannerContainer: {
    alignItems: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: 8,
    flexDirection: 'row',
    marginHorizontal: 16,
    marginVertical: 16,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
});
export default styles;
