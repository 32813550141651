import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logAcceptedTerms(loanDetails) {
  const e = AMPLITUDE_CONSTANTS.Loans.loan_tnc_checkbox_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.loan_product_code]: loanDetails?.loanCode,
  });
}

export function logLoanVerification(loanDetails) {
  const e = AMPLITUDE_CONSTANTS.Loans.loan_verification;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.loan_product_code]: loanDetails?.loanCode,
  });
}

export function logLoanSchedulePage(loanDetails) {
  const e = AMPLITUDE_CONSTANTS.Loans.loan_schedule_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.loan_product_code]: loanDetails?.loanCode,
  });
}
