import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { scale } from '@utils/normalize';
import { View, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { logAcceptPress, logRejectPress } from './analyticsBPTnc';

function BPTnc(props) {
  const { setTncChecked, setTncOpen, transactionCategoryId, accountName, source } = props;
  const { validatedReferralCode } = getStore();
  const { t } = useTranslation('common');

  return (
    <View style={{ marginHorizontal: scale(16) }}>
      <View style={appStyles.marginBottom(24)}>
        <Text testID="lblTnCText" variant="sm-normal" style={appStyles.marginTop(8)}>
          {t('reqTxSheet.bpTnc')}
        </Text>
      </View>
      <View style={[appStyles.flexRow, appStyles.marginBottom(12)]}>
        <Button
          testID="btnRejectTnC"
          variant="ghost"
          flex={1}
          style={[appStyles.marginRight(8), appStyles.borderWidth(2)]}
          onPress={() => {
            logRejectPress(transactionCategoryId, accountName, validatedReferralCode, source);
            setTncChecked(false);
            setTncOpen(false);
          }}>
          {t('reqTxSheet.cancelTnC')}
        </Button>
        <Button
          testID="btnAccpetTnC"
          variant="solid"
          flex={1}
          onPress={() => {
            logAcceptPress(transactionCategoryId, accountName, validatedReferralCode, source);
            setTncChecked(true);
            setTncOpen(false);
          }}>
          {t('reqTxSheet.acceptTnC')}
        </Button>
      </View>
    </View>
  );
}

BPTnc.propTypes = {
  setTncChecked: PropTypes.func,
  setTncOpen: PropTypes.func,
  transactionCategoryId: PropTypes.string,
  accountName: PropTypes.string,
  source: PropTypes.string,
};
BPTnc.defaultProps = {
  setTncChecked: () => {},
  setTncOpen: () => {},
  transactionCategoryId: '',
  accountName: '',
  source: '',
};
export default BPTnc;
