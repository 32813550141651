import { Feather } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

function ScrollLeft(props) {
  const { activePage, chunkedReasons, reasonsCarousel, setActivePage } = props;
  const { t } = useTranslation('common');
  if (activePage > 0 && chunkedReasons.length > 0 && activePage < chunkedReasons.length) {
    return (
      <TouchableOpacity
        testID="btnScrollLeft"
        style={styles.leftStyles}
        onPress={() => {
          reasonsCarousel.current.snapToPrev();
          setActivePage(activePage - 1);
        }}>
        <Feather name="chevrons-left" size={24} color={colors.secondary.orchid} />
        <Text variant="sm-bold" color={colors.secondary.orchid}>
          {t('reasonsModal.previous')}
        </Text>
      </TouchableOpacity>
    );
  }
  return null;
}

ScrollLeft.propTypes = {
  activePage: PropTypes.number,
  chunkedReasons: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  reasonsCarousel: PropTypes.shape({
    current: PropTypes.shape({ snapToPrev: PropTypes.func }),
  }),
  setActivePage: PropTypes.func,
};
ScrollLeft.defaultProps = {
  activePage: 0,
  chunkedReasons: [[]],
  reasonsCarousel: {
    current: {
      snapToPrev: () => {},
    },
  },
  setActivePage: () => {},
};
export default ScrollLeft;
