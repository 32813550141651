import AutoWithdrawal from '@atoms/AutoWithdrawal';
import AccountSelector from '@molecules/AccountSelector';
import BPList from '@molecules/BPList';
import Header from '@molecules/Header';
import ReferralInput from '@molecules/ReferralInput';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase } from '@utils/utils';
import { View } from 'native-base';
import React, { useEffect } from 'react';
import shouldFireOnFocusBPTab from './bpTabUtils';
import styles from './styles';

export function logOthersPage(source, validatedReferralCode, amplitudeAccountName) {
  const e = AMPLITUDE_CONSTANTS.other_product_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: source,
    [e.attributes.referral_code]: validatedReferralCode,
    [e.attributes.dynamic_account_name]: formatUnderScoreLowerCase(amplitudeAccountName),
  });
}

function PembayaranTab() {
  const {
    loginData,
    setBPPhone,
    bpAccounts,
    selectedBPAccount,
    setSelectedBPAccounts,
    otpData,
    validatedReferralCode,
    meAmplitudeContext,
  } = getStore();
  const isFocused = useIsFocused();
  const { mobileNumber } = loginData;
  const { userName } = otpData;
  const route = useRoute();
  const { params } = route;
  const { source } = params || { source: AMPLITUDE_EVENT_SOURCES.other_product_page };
  useEffect(() => {
    shouldFireOnFocusBPTab(isFocused, setBPPhone, mobileNumber, userName);
  }, [isFocused]);
  useEffect(() => {
    if (isFocused) logOthersPage(source, validatedReferralCode, selectedBPAccount?.amplitudeAccountName);
  }, [isFocused]);

  const accountSelectorBP = {
    selectedAccount: selectedBPAccount,
    withdrawlAccounts: bpAccounts,
    setSelectedAccount: setSelectedBPAccounts,
    homePage: false,
  };
  return (
    <View style={styles.bpParent}>
      <Header />
      <AutoWithdrawal isBillPayment />
      <AccountSelector
        {...accountSelectorBP}
        source={AMPLITUDE_EVENT_SOURCES.other_product_page}
        meAmplitudeContext={meAmplitudeContext}
      />
      <ReferralInput
        isLoading={false}
        selectedAccount={selectedBPAccount}
        pageSource={AMPLITUDE_EVENT_SOURCES.other_product_page}
      />
      <BPList source={source} />
    </View>
  );
}

export default PembayaranTab;
