import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export default function logCustomReasonInput(textValue, withdrawalReason, validatedReferralCode, source) {
  const e = AMPLITUDE_CONSTANTS.Withdrawal.customReason;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.reason]: textValue,
    [e.attributes.reason_id]: withdrawalReason?.id,
    [e.attributes.source]: source,
    [e.attributes.referral_code]: validatedReferralCode,
  });
}
