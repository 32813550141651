// import { SentryHelper } from '@utils/performance/SentryHelper';
import * as Sentry from '@sentry/react-native';
import apiConfig from './apiConfig';
import {
  axiosInstance,
  defaultHeaders,
  getInvokeOptionsForHTTPMethod,
  getURLForApiCall,
  shouldAddCustomHeaders,
  shouldAddTokenForAuth,
} from './networkUtils';

export default async function invokeApi(options, baseUrl, data, customHeaders, postData, urlEncodedData) {
  // eslint-disable-next-line global-require, no-unused-vars
  const URITemplate = require('urijs/src/URITemplate');
  let reqHeader = {
    ...defaultHeaders,
  };

  reqHeader = { ...reqHeader, ...shouldAddCustomHeaders(customHeaders) };
  if (options?.version) {
    reqHeader['x-api-version'] = options?.version;
  }
  try {
    const authHeader = await shouldAddTokenForAuth(options);
    reqHeader = { ...reqHeader, ...authHeader };
  } catch (error) {
    // Stop the API call if the token is null
    Sentry.addBreadcrumb({ level: 'error', message: `unable to read token skipping ${options?.urlEndpoint}` });
    return;
  }
  let apiRequest = {
    method: options.httpMethod,
    headers: reqHeader,
  };
  apiRequest = getInvokeOptionsForHTTPMethod(postData, options, apiRequest, urlEncodedData);
  let sUrl = apiConfig.getApiUrl(options.name, baseUrl);
  sUrl = getURLForApiCall(options, sUrl, data);

  // SentryHelper.captureMessage(`${options.name} called`);
  return axiosInstance({
    ...apiRequest,
    apiConfig: options,
    url: sUrl,
  });
}
