import useFeatureFlag from '@customHooks/useFeatureFlag';
import ScreenNames from '@navigation/navigationConstants';
import FinansialTab from '@organisms/FinansialTab';
import HomeTab from '@organisms/HomeTab';
import PembayaranTab from '@organisms/PembayaranTab';
import ProfileTab from '@organisms/ProfileTab';
import TransactionsTab from '@organisms/TransactionsTab';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { FEATURE_FLAGS } from '@root/constants';
import { tabIconOptions } from '@theme/theme';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

const Tab = createBottomTabNavigator();

export function onHomeTabPress(e) {
  return () => {
    AmplitudeHelper.logEvent(e.home_menu_clicked.name);
  };
}

export function onProfileTabPress(e) {
  return () => {
    AmplitudeHelper.logEvent(e.others_menu_clicked.name);
  };
}

export function onPembayaranTabPress(e, navigation) {
  return () => {
    AmplitudeHelper.logEvent(e.other_product_menu_clicked.name);
    navigation.navigate(ScreenNames.PembayaranTab, { source: AMPLITUDE_EVENT_SOURCES.other_product_page });
  };
}

export function onFinansialTabPress(e) {
  return () => {
    AmplitudeHelper.logEvent(e.financial_menu_clicked.name);
  };
}

export function onTransactionTabPress(e) {
  return () => {
    AmplitudeHelper.logEvent(e.transaction_menu_clicked.name);
  };
}

function TabNavigator() {
  const { t } = useTranslation('common');

  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.GOLD_SAVINGS],
  });
  const navigation = useNavigation();
  const e = AMPLITUDE_CONSTANTS.NavBar;
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          height: 60,
          marginTop: -10,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 14,
        },
      }}>
      <Tab.Screen
        name={ScreenNames.HomeTab}
        title=""
        component={HomeTab}
        listeners={{
          tabPress: onHomeTabPress(e),
        }}
        options={{ ...tabIconOptions('home', t('common.homeTab')), tabBarTestID: 'tabHome' }}
      />
      <Tab.Screen
        name={ScreenNames.TransactionsTab}
        component={TransactionsTab}
        listeners={{
          tabPress: onTransactionTabPress(e),
        }}
        options={{ ...tabIconOptions('transaction', t('common.transactionTab')), tabBarTestID: 'tabTransactions' }}
      />
      {Platform.OS === 'android' && isFeatureAvailale[FEATURE_FLAGS.GOLD_SAVINGS]?.show && (
        <Tab.Screen
          name={ScreenNames.Finansial}
          component={FinansialTab}
          options={{ ...tabIconOptions('gold', t('common.financialTab'), true), tabBarTestID: 'tabBP' }}
          listeners={{
            tabPress: onFinansialTabPress(e),
          }}
        />
      )}
      <Tab.Screen
        name={ScreenNames.PembayaranTab}
        component={PembayaranTab}
        options={{ ...tabIconOptions('billpayment', t('common.bpTab')), tabBarTestID: 'tabBP' }}
        listeners={{
          tabPress: onPembayaranTabPress(e, navigation),
        }}
      />
      <Tab.Screen
        name={ScreenNames.ProfileTab}
        component={ProfileTab}
        options={{ ...tabIconOptions('profile', t('common.profileTab')), tabBarTestID: 'tabProfile' }}
        listeners={{
          tabPress: onProfileTabPress(e),
        }}
      />
    </Tab.Navigator>
  );
}

export default TabNavigator;
