import GGTxnReferralBanner from '@atoms/GGTxnReferralBanner';
import TextCurrency from '@atoms/TextCurrency';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { goBackToHome } from '@utils/utils';
import { View, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logPageAppear, logSuccessPageClosed } from './analyticsWithdrawSuccess';
import styles from './styles';

function GGWithdrawSuccess(props) {
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const { route } = props;
  const { params } = route;
  const {
    wdConfirm,
    withdrawalData,
    fromTransfer,
    withdrawalReason,
    validatedReferralCode,
    source,
    transaction_category_id,
    logParams,
  } = params;
  const { setWithdrawalReason, selectedAccount, headerText } = getStore();
  const { amplitudeAccountName } = selectedAccount;
  useEffect(() => {
    logPageAppear(
      fromTransfer,
      withdrawalData,
      withdrawalReason,
      amplitudeAccountName,
      validatedReferralCode,
      source,
      transaction_category_id,
      logParams,
    );
    navigation.setOptions({ title: headerText, headerBackVisible: false });
    setWithdrawalReason(null);
  }, []);
  const e = AMPLITUDE_CONSTANTS.interactionBanner.banner_clicked;

  return (
    <View style={appStyles.safeAreaView}>
      <View style={appStyles.flex1}>
        <View style={styles.successImage}>
          {wdConfirm?.status === 'PENDING' ? SVG_ICONS.Pending : SVG_ICONS.Success}
        </View>
        <View style={styles.successReportParent}>
          <Text testID="lblTxStatus" variant="xl-bold" style={styles.txtSuccess(wdConfirm.status)}>
            {wdConfirm?.status === 'PENDING' ? t('withdrawalSuccess.pendingText') : t('withdrawalSuccess.successText')}
          </Text>
          <Text testID="lblTxType" variant="lg-bold" style={styles.txtSuccessDetails}>
            {fromTransfer ? t('bankTransfer.successText') : t('withdrawalSuccess.withdrawText')}
          </Text>
          <TextCurrency
            testID="txtTransferAmount"
            variant="xxl-bold"
            style={styles.txtTransferAmount}
            amountCurrency={withdrawalData.transferAmount}
          />
          <Text testID="lblTxAccount" variant="md-bold" style={styles.txtTarikGaji}>
            {fromTransfer ? 'Transfer' : selectedAccount.displayName}
          </Text>
        </View>
      </View>
      <GGTxnReferralBanner
        logParams={{
          [e.attributes.transaction_category_id]: transaction_category_id,
          [e.attributes.requested_amount]: withdrawalData?.requestedAmount,
          [e.attributes.reason]: withdrawalReason?.reason,
          [e.attributes.reason_id]: withdrawalReason?.id,
          [e.attributes.payment_method]: 'accrued_salary',
          [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.success_page,
          [e.attributes.dynamic_account_name]: amplitudeAccountName,
        }}
      />
      <View style={[appStyles.borderWithShadow, styles.bottomView]}>
        <Button
          testID="btnCommonOk"
          style={appStyles.marginBottom(16)}
          onPress={() => {
            logSuccessPageClosed(withdrawalData, withdrawalReason, transaction_category_id);
            goBackToHome(navigation);
          }}>
          {t('common.ok')}
        </Button>
      </View>
    </View>
  );
}

GGWithdrawSuccess.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      transaction_category_id: PropTypes.string,
      validatedReferralCode: PropTypes.string,
      wdConfirm: PropTypes.shape({
        status: PropTypes.string,
      }),
      withdrawalData: PropTypes.shape({
        transferAmount: PropTypes.number,
        requestedAmount: PropTypes.string,
      }),
      fromTransfer: PropTypes.bool,
      withdrawalReason: PropTypes.shape({
        reason: PropTypes.string,
        id: PropTypes.number,
      }),
      logParams: PropTypes.shape({}),
    }),
  }),
};
GGWithdrawSuccess.defaultProps = {
  route: {
    params: {
      source: '',
      transaction_category_id: '',
      validatedReferralCode: '',
      wdConfirm: {
        status: '',
      },
      withdrawalData: {
        transferAmount: 0,
      },
      fromTransfer: false,
      withdrawalReason: {
        reason: '',
        id: 0,
      },
      logParams: {},
    },
  },
};
export default GGWithdrawSuccess;
