import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { FAB } from '@rneui/base';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import React from 'react';
import styles from './styles';

function ChatIcon() {
  const navigation = useNavigation();
  return (
    <FAB
      testID="btnChatBot"
      style={styles.fabStyles}
      loading={false}
      visible
      color={colors.primary.carnation}
      size="large"
      placement="right"
      onPress={() => {
        navigation.navigate(navigationConstants.GG_CHAT_BOT);
      }}>
      {SVG_ICONS.ChatLogo.chatLogo}
    </FAB>
  );
}

ChatIcon.propTypes = {};
ChatIcon.defaultProps = {};

export default ChatIcon;
