import appStyles from '@root/appStyles';
import { scale } from '@utils/normalize';
import { MenuIcons } from '@utils/SvgIcons';
import { extendTheme, Text, View } from 'native-base';
import React from 'react';
import { Dimensions } from 'react-native';
import colors from './colors';
import { getSvgProps, getTabBarComponent } from './themeUtils';

const xxxl = {
  fontSize: scale(32),
  lineHeight: scale(48),
};

const xxl = {
  fontSize: scale(24),
  lineHeight: scale(36),
};

const xl = {
  fontSize: scale(20),
  lineHeight: scale(30),
};

const lg = {
  fontSize: scale(16),
  lineHeight: scale(24),
};

const md = {
  fontSize: scale(14),
  lineHeight: scale(21),
};

const sm = {
  fontSize: scale(12),
  lineHeight: scale(18),
};

const xsm = {
  fontSize: scale(10),
  lineHeight: scale(15),
};

export const regularFont = 'Nunito-Regular';
export const boldFont = 'Nunito-Bold';

function getFocusedRedBar(focused) {
  let focusedBarStyle = { width: 20, height: 4 };
  if (focused) {
    focusedBarStyle = { ...focusedBarStyle, backgroundColor: colors.primary.carnation, borderRadius: 30 };
  }
  return <View style={focusedBarStyle} />;
}

const shouldRenderTabBarIcon = ({ focused }, name, labelName) => {
  const Component = MenuIcons[name];
  const svgProps = getSvgProps(focused);

  const shouldRenderIcon = () => (
    <View style={appStyles.tabStyles}>
      {getFocusedRedBar(focused)}
      <View style={appStyles.marginTop(8)}>
        <Component {...svgProps} />
      </View>
      <Text testID="txtTabLbl" size="xsm" style={appStyles.tabText(focused)}>
        {labelName}
      </Text>
    </View>
  );
  return (
    <View
    // style={getFocusedBarStyles(focused)}
    >
      {shouldRenderIcon()}
    </View>
  );
};

export const tabIconOptions = (name, labelName, headerShown = false) => ({
  tabBarIcon: props => shouldRenderTabBarIcon(props, name, labelName),
  tabBarLabel: '',
  headerShown,
  tabBarButtonComponent: getTabBarComponent(labelName),
  headerShadowVisible: false,
  headerStyle: { backgroundColor: colors.secondary.orchid },
  headerBackTitleStyle: { color: colors.neutral.cotton, fontFamily: regularFont },
  headerTitle: labelName,
  headerTintColor: colors.neutral.cotton,
  headerTitleStyle: {
    color: colors.neutral.cotton,
    fontFamily: boldFont,
    fontSize: 16,
    fontStyle: 'bold',
    lineHeight: 24,
  },
});

export const theme = extendTheme({
  colors,
  fontConfig: {
    Nunito: {
      100: {
        normal: regularFont,
      },
      200: {
        normal: boldFont,
      },
    },
  },
  fonts: {
    heading: 'Nunito',
    body: 'Nunito',
    mono: 'Nunito',
  },
  components: {
    Toast: {
      baseStyle: {
        width: Dimensions.get('window').width,
      },
      variants: {
        error: {
          bg: colors.error.rose,
          width: Dimensions.get('window').width,
        },
      },
    },
    Input: {
      baseStyle: {
        borderColor: colors.disabled.smoke,
        backgroundColor: colors.neutral.cotton,
      },
      defaultProps: {
        _focus: { backgroundColor: colors.neutral.cotton },
        borderColor: colors.neutral.charcoal,
        borderWidth: '1px',
        borderRadius: '8px',
        height: '48px',
      },
      variants: {
        focusedNormal: () => ({
          borderColor: colors.secondary.orchid,
        }),
        focusedError: () => ({
          borderColor: colors.error.rose,
        }),
      },
    },
    Text: {
      variants: {
        /* NORMAL VARIANTS */
        'xxxl-normal': {
          ...xxxl,
          fontFamily: regularFont,
        },
        'xxl-normal': {
          ...xxl,
          fontFamily: regularFont,
        },
        'xl-normal': {
          ...xl,
          fontFamily: regularFont,
        },
        'lg-normal': {
          ...lg,
          fontFamily: regularFont,
        },
        'md-normal': {
          ...md,
          fontFamily: regularFont,
        },
        'sm-normal': {
          ...sm,
          fontFamily: regularFont,
        },
        'xsm-normal': {
          ...xsm,
          fontFamily: regularFont,
        },
        /* BOLD VARIANTS */
        'xxxl-bold': {
          ...xxxl,
          fontFamily: boldFont,
        },
        'xxl-bold': {
          ...xxl,
          fontFamily: boldFont,
        },
        'xl-bold': {
          ...xl,
          fontFamily: boldFont,
        },
        'lg-bold': {
          ...lg,
          fontFamily: boldFont,
        },
        'md-bold': {
          ...md,
          fontFamily: boldFont,
        },
        'sm-bold': {
          ...sm,
          fontFamily: boldFont,
        },
        'xsm-bold': {
          ...xsm,
          fontFamily: boldFont,
        },
        disabled: () => ({
          color: colors.neutral.cotton,
        }),
      },
      sizes: {
        xxxl,
        xxl,
        xl,
        lg,
        md,
        sm,
        xsm,
      },
    },
    Modal: {
      defaultProps: {
        size: 'full',
        closeOnOverlayClick: true,
        _backdrop: {
          bg: colors.black,
          opacity: '0.8',
        },
      },
    },
    Button: {
      variants: {
        ghost: () => ({
          android_ripple: colors.disabled.smoke,
          borderColor: colors.primary.carnation,
          borderWidth: 1,
          _text: {
            color: colors.primary.carnation,
            fontWeight: '200',
          },
          _disabled: {
            _text: { color: colors.primary.carnation, fontWeight: '200' },
            backgroundColor: colors.disabled.smoke,
            opacity: 0.9,
          },
          fontWeight: '200',
          _pressed: { backgroundColor: colors.neutral.mist },
          bg: colors.neutral.cotton,
          borderRadius: '100px',
          opacity: 1,
        }),
        solid: () => ({
          android_ripple: colors.disabled.smoke,
          colorScheme: '',
          _text: {
            color: colors.neutral.cotton,
            fontWeight: '200',
          },
          _disabled: {
            _text: { color: colors.neutral.cotton, fontWeight: '200' },
            backgroundColor: colors.disabled.smoke,
            opacity: 0.9,
          },
          fontWeight: '200',
          _pressed: { backgroundColor: colors.primary.darkCarnation },
          bg: colors.primary.carnation,
          borderRadius: '100px',
          opacity: 1,
        }),
        group: () => ({
          opacity: 0.9,
          _pressed: { backgroundColor: colors.neutral.charcoal },
          bg: colors.neutral.cotton,
          _text: {
            color: colors.neutral.charcoal,
            size: 'sm',
            fontWeight: '200',
          },
        }),
      },
    },
  },
});
