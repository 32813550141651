// import PropTypes from 'prop-types';
import { apiLogout } from '@api/apiCall';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import { forceLogout, logoutOnError } from '@organisms/ProfileTab/profileUtils';
import { useNavigation } from '@react-navigation/native';
import { LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import colors from '@theme/colors';
import { returnBottomSheetContent } from '@utils/utils';
import { Button, Text, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logoutConfirmedClicked } from './analyticsLogout';
import bottomSheetHandlers from './profileLogoutUtils';
import styles from './styles';

function ProfileLogoutButton() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setLoading, setInitialData } = getStore();
  const navigation = useNavigation();
  const toast = useToast();
  const [loadingLogout, setLoadingLogout] = useState(false);
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.LOGOUT,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.LOGOUT],
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.LOGOUT,
  });
  const {
    isLoading,
    mutate: performLogout,
    isSuccess,
    data: LogoutData,
    isError,
    error,
  } = useMutation({ mutationFn: () => apiLogout() });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  useEffect(() => {
    setLoadingLogout(false);
    (async () => {
      await forceLogout(isSuccess, LogoutData, navigation, setInitialData, queryClient);
    })();
  }, [isSuccess, LogoutData]);

  useEffect(() => {
    setLoadingLogout(false);
    logoutOnError(isError, error, navigation, setInitialData, toast, t);
  }, [isError, error]);

  useEffect(() => {
    if (infoModal?.fromLogout && infoModal.errorCode === LOGIN_INFO_SHEET_KEYS.LOGOUT) {
      try {
        setLoading(true);
        logoutConfirmedClicked();
        setLoadingLogout(true);
        performLogout();
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  }, [infoModal]);

  const { onOpenLogoutModal, onPressPrimaryButton, onPressSecondaryBottomSheet, onCloseModalHandler } =
    bottomSheetHandlers(setInfoModal);

  return (
    <>
      <Button
        testID="btnLogout"
        sentry-label="btnLogout"
        fontFamily="body"
        marginY="12px"
        variant="ghost"
        style={styles.buttonStyle}
        isLoading={loadingLogout}
        backgroundColor={colors.primary.softCarnation}
        borderColor={colors.primary.softCarnation}
        onPress={onOpenLogoutModal}>
        <Text variant="sm-bold" color={colors.primary.carnation}>
          {t('profile.logout')}
        </Text>
      </Button>
      <LoginInfoSheet
        onPressPrimary={onPressPrimaryButton}
        onPressSecondary={() => onPressSecondaryBottomSheet()}
        loginInfo={infoModal}
        hasTwoButtons
        // metaComponent={shouldRenderSecurityMetaInfo()}
        onClose={() => onCloseModalHandler('close')}
      />
    </>
  );
}

ProfileLogoutButton.propTypes = {};
ProfileLogoutButton.defaultProps = {};
export default ProfileLogoutButton;
