import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

function getState(listingData, hasPendingData, isTransacted) {
  if (listingData.length > 0) {
    if (!isTransacted) return 'registered';
    if (hasPendingData) return 'pending';
    return 'approved';
  }
  return 'empty';
}

function logCommonPhonePage(e, eventSource, data, hasPendings, isTransact, transactionCategoryId) {
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: eventSource,
    [e.attributes.state]: getState(data, hasPendings, isTransact),
    [e.attributes.transaction_category_id]: transactionCategoryId ?? null,
  });
}
function logPhonePage(eventSource, data, isTransact, transactionCategoryId) {
  const hasPendings = data?.filter(item => item.status === 'PENDING')?.length > 0;

  logCommonPhonePage(
    AMPLITUDE_CONSTANTS.PhoneEnroll.phone_list_page,
    eventSource,
    data,
    hasPendings,
    isTransact,
    transactionCategoryId,
  );

  if (isTransact)
    logCommonPhonePage(
      AMPLITUDE_CONSTANTS.PhoneEnroll.select_phone_page,
      eventSource,
      data,
      hasPendings,
      isTransact,
      transactionCategoryId,
    );
}

export default logPhonePage;
