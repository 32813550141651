import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function ReferralDoneButton() {
  const { t } = useTranslation('common');
  return (
    <View style={styles.buttonDone}>
      <Text testID="txtReferralValid" variant="md-bold" color={colors.variants.clover}>
        {t('referral.validReferral')}
      </Text>
      <AntDesign name="check" size={scale(24)} color={colors.variants.clover} style={styles.checkIconStyles} />
    </View>
  );
}

ReferralDoneButton.propTypes = {};
ReferralDoneButton.defaultProps = {};

export default ReferralDoneButton;
