import MyModal from '@atoms/MyModal';
import Close from '@expo/vector-icons/AntDesign';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { shouldRenderButtons } from './loginSheetUtils';
import styles from './style';

const LoginInfoSheet = ({ loginInfo, onPressPrimary, onPressSecondary, onClose }) => {
  const { t } = useTranslation('common');
  const title = t(`login.${loginInfo?.data?.title}`);
  const description = t(`login.${loginInfo?.data?.description}`);
  const { phoneNumber } = loginInfo.data;
  const description2 = loginInfo?.data?.description2 ? t(`login.${loginInfo?.data?.description2}`) : null;

  const buttonText = t(`login.${loginInfo?.data?.buttonText}`);
  const buttonText2 = t(`login.${loginInfo?.data?.buttonText2}`);
  return (
    <MyModal
      testID="loginLogoutBottomSheet"
      onPress={onClose}
      containerStyle={styles.modal}
      isVisible={loginInfo?.visible}>
      <View style={styles.innerContainer}>
        <TouchableOpacity testID="btnCloseBottomSheet" onPress={onClose} style={styles.closeButton}>
          <Close name="close" size={20} color={colors.neutral.charcoal} />
        </TouchableOpacity>
        <View style={styles.icon}>{loginInfo?.data?.icon}</View>
        <View style={styles.textContainer}>
          <Text testID="txtInfoSheetTitle" variant="xl-bold" marginY="8px">
            {title}
          </Text>
          <View stlyle={[appStyles.flexRow, { marginTop: 5 }]}>
            <Text variant="md-normal" color={colors.neutral.charcoal}>
              <Text
                testID="txtInfoSheetDescription1"
                color={colors.neutral.darkMist}
                variant="md-normal"
                marginTop="8px"
                alignSelf="stretch">
                {description}{' '}
              </Text>
              <Text testID="txtInfoSheetNumber" color={colors.neutral.charcoal} fontFamily="Nunito-Bold" flex={1}>
                {phoneNumber}
              </Text>
              <Text
                testID="txtInfoSheetDescription2"
                color={colors.neutral.darkMist}
                variant="md-normal"
                marginTop="8px"
                alignSelf="stretch">
                {description2 || ''}
              </Text>
            </Text>
          </View>
        </View>

        {shouldRenderButtons(loginInfo, onPressPrimary, buttonText, onPressSecondary, buttonText2)}
      </View>
    </MyModal>
  );
};

LoginInfoSheet.propTypes = {
  loginInfo: PropTypes.shape({
    visible: PropTypes.bool,
    data: PropTypes.shape({
      title: PropTypes.string,
      buttonText: PropTypes.string,
      hasTwoButtons: PropTypes.bool,
      description: PropTypes.string,
      description2: PropTypes.string,
      icon: PropTypes.element,
      buttonText2: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    errorCode: PropTypes.string,
  }),
  onPressPrimary: PropTypes.func,
  onPressSecondary: PropTypes.func,
  onClose: PropTypes.func,
};

LoginInfoSheet.defaultProps = {
  loginInfo: {
    visible: false,
    data: {
      title: '',
      buttonText: '',
      hasTwoButtons: false,
      description: '',
      description2: '',
      icon: null,
      buttonText2: '',
      phoneNumber: '',
    },
  },
  onPressPrimary: () => {},
  onPressSecondary: () => {},
  onClose: () => {},
};

export default React.memo(LoginInfoSheet);
