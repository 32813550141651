import Baru from '@atoms/Loan/Baru';
import RemoteImage from '@atoms/RemoteImage';
import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Text, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable, View } from 'react-native';
import styles from './styles';

/**
 *
 * @param {object} selectedAccountDetails
 * @param {array} withdrawlAccounts
 * @param {function} setWithdrawlAccounts
 * @param {function} setShowAccountSelector
 */
function setSelectedAccountOnPress(selectedAccountDetails, setSelectedAccount, setShowAccountSelector) {
  setSelectedAccount(selectedAccountDetails);
  setShowAccountSelector(false);
}

export function shouldRenderRightCheck(show) {
  if (show)
    return (
      <View style={styles.checkStyle}>
        <Icon
          testID="account-selector-check"
          as={AntDesign}
          name="check"
          color={colors.error.rose}
          marginRight={0}
          size="24px"
        />
      </View>
    );

  return null;
}
function AccountRow(props) {
  const { item, setSelectedAccount, setShowAccountSelector, excludeNewTag, isSelected } = props;
  return (
    <Pressable
      sentry-level={`${item?.accountTypeId}_account`}
      testID={`${item?.accountTypeId}_account`}
      sentry-label={`${item?.accountTypeId}_account`}
      style={styles.accountRow}
      onPress={() => setSelectedAccountOnPress(item, setSelectedAccount, setShowAccountSelector)}>
      <View>
        <RemoteImage uri={item.icon} width={15} height={15} />
      </View>
      <Text testID="txtItemDisplayName" variant="md-normal" marginLeft="16px">
        {item.displayName}
      </Text>

      <Baru showBaru={item.canShowNew && !excludeNewTag} customStyles={styles.baruStyle} />

      {shouldRenderRightCheck(isSelected)}
    </Pressable>
  );
}

AccountRow.propTypes = {
  item: PropTypes.shape({
    accountTypeId: PropTypes.number,
    icon: PropTypes.string,
    displayName: PropTypes.string,
    canShowNew: PropTypes.bool,
  }),
  setSelectedAccount: PropTypes.func,
  setShowAccountSelector: PropTypes.func,
  excludeNewTag: PropTypes.bool,
  isSelected: PropTypes.bool,
};

AccountRow.defaultProps = {
  item: {
    accountTypeId: 0,
    icon: '',
    displayName: '',
    canShowNew: false,
  },
  setSelectedAccount: () => {},
  setShowAccountSelector: () => {},
  excludeNewTag: false,
  isSelected: false,
};

export default AccountRow;
