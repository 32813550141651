import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  addPhoneText: {
    color: colors.neutral.cotton,
    // marginHorizontal: 8,
    // marginRight: 8,
    marginVertical: 2,
  },
  addPhoneTextContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  btnAddPhoneStyles: {
    borderColor: colors.disabled.smoke,
    borderRadius: 100,
    borderWidth: 1,
    flexDirection: 'row',
    marginHorizontal: 16,
    marginTop: 12,
  },
  btnAddPhoneTextContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  btnAddScreenStyles: {
    borderRadius: 100,
    flex: 1,
    marginLeft: 8,
    marginRight: 16,
  },
});
export default styles;
