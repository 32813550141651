import RefreshControl from '@atoms/RefreshControl/index.web';
import colors from '@theme/colors';
import React from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import styles from './styles';

export default function shouldShowFlatListOrLoader({
  loadingList,
  listEmpty,
  refresh,
  onRefresh,
  transactionList,
  renderItem,
  onEndReached,
  footer,
  itemSeperatorComponent,
  searchHeaderComponent,
}) {
  if (loadingList) return <ActivityIndicator testID="loading-indicator" color={colors.secondary.orchid} size="large" />;
  return (
    <FlatList
      testID="transaction-list"
      ListEmptyComponent={listEmpty}
      refreshControl={<RefreshControl colors={[colors.secondary.orchid]} refreshing={refresh} onRefresh={onRefresh} />}
      data={transactionList}
      renderItem={renderItem}
      onEndReached={onEndReached}
      ListFooterComponent={footer}
      onEndReachedThreshold={0.6}
      keyExtractor={(item, index) => index.toString()}
      contentContainerStyle={styles.contentContainerStyle}
      ItemSeparatorComponent={itemSeperatorComponent}
      ListHeaderComponent={searchHeaderComponent}
    />
  );
}
