import { deleteEmployeeBeneficiary } from '@api/apiCall';
import useFetchBanks from '@customHooks/useFetchBanks';
import BankList from '@molecules/BankList';
import DefaultBankHeader from '@molecules/DefaultBankHeader';
import BottomSheet from '@molecules/LoginInfoSheet';
import PinWithAddBank from '@molecules/PinWithAddBank';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { BOTTOMSHEET_CONTENT, BOTTONSHEET_KEYS, COMMON_CONSTANTS, addBankTemplate3 } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { returnBottomSheetContent } from '@utils/utils';
import { View, useToast, Button } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, ScrollView } from 'react-native';
import bankListBottomSheetHandlers from './bankListWithHeaderUtils';

function shouldShowFooter(t, fromTransfer, bankList, source) {
  if (fromTransfer) {
    return null;
  }

  const pendingList = bankList?.filter(item => item.status === 'PENDING') ?? [];
  const hasPendingBanks = pendingList.length > 0;
  const openWhatsappOnPress = () => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration.help_clicked;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
    });
    Linking.openURL(COMMON_CONSTANTS.support);
  };
  return (
    <View
      flexDirection="row"
      justifyContent="space-evenly"
      style={[
        appStyles.borderWithShadow,
        appStyles.marginHorizontal(0),
        appStyles.paddingHorizontal(16),
        appStyles.borderBottomLeftRadius(0),
        appStyles.borderBottomRightRadius(0),
        appStyles.paddingVertical(16),
      ]}>
      {hasPendingBanks ? (
        <Button
          variant="ghost"
          flex={1}
          borderColor={colors.variants.clover}
          style={[appStyles.borderWidth(1), appStyles.marginRight(8)]}
          onPress={openWhatsappOnPress}
          _text={{ color: colors.variants.clover }}>
          {t('phoneTransfer.phoneTransferSupport')}
        </Button>
      ) : null}
      <PinWithAddBank
        type={addBankTemplate3}
        userBanks={bankList}
        fromTransfer
        source={AMPLITUDE_EVENT_SOURCES.bank_page}
      />
    </View>
  );
}

function GGBankListWithDefaultBank() {
  const initialState = {
    visible: false,
    data: returnBottomSheetContent(BOTTONSHEET_KEYS.BANK_DELETE, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.BANK_DELETE]),
    errorCode: BOTTONSHEET_KEYS.BANK_DELETE,
  };
  const [infoModal, setInfoModal] = useState(initialState);
  const route = useRoute();
  const { t } = useTranslation('common');
  const { fromTransfer, setBankAccountNumber, source } = route.params;
  const { setLoading, setInitialData } = getStore();
  const toast = useToast();
  const { bankList, refetch } = useFetchBanks();
  const isFocused = useIsFocused();

  const [bankRowPressed, setBankRowPressed] = useState(null);
  const navigation = useNavigation();

  const {
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
    error: errorDelete,
    data: dataDelete,
    mutate: beneficiaryDeleteMutate,
  } = useMutation({
    mutationFn: payload => deleteEmployeeBeneficiary(payload),
  });

  useEffect(() => {
    if (fromTransfer) {
      navigation.setOptions({ title: t('addBank.bankHeaderFromTransfer') });
    } else {
      navigation.setOptions({ title: t('addBank.bankList') });
    }
  }, []);

  useEffect(() => {
    setLoading(isLoadingDelete);
  }, [isLoadingDelete]);

  useEffect(() => {
    shouldShowError(isErrorDelete, errorDelete, toast, t, navigation, setInitialData);
  }, [isErrorDelete, errorDelete]);

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);

  useEffect(() => {
    // refetch banks when deleted
    if (isSuccessDelete && dataDelete) {
      refetch();
    }
  }, [isSuccessDelete, dataDelete]);

  const { onPressDelete, onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onCloseModalHandler } =
    bankListBottomSheetHandlers(infoModal, setInfoModal, beneficiaryDeleteMutate, setBankRowPressed, bankRowPressed);

  return (
    <View style={appStyles.safeAreaView}>
      <ScrollView styles={appStyles.safeAreaView}>
        <DefaultBankHeader />
        <BankList
          bankList={bankList ?? []}
          onPressDelete={onPressDelete}
          fromTransfer={fromTransfer}
          setBankForTransfer={setBankAccountNumber}
          source={source}
        />
      </ScrollView>
      {shouldShowFooter(t, fromTransfer, bankList, AMPLITUDE_EVENT_SOURCES.bank_page)}
      <BottomSheet
        onPressPrimary={() => onPressPrimaryBottomSHeet(bankRowPressed)}
        onPressSecondary={onPressSecondaryBottomSheet}
        loginInfo={infoModal}
        hasTwoButtons={infoModal.data.hasTwoButtons}
        onClose={onCloseModalHandler}
      />
    </View>
  );
}

GGBankListWithDefaultBank.propTypes = {};
GGBankListWithDefaultBank.defaultProps = {};
export default GGBankListWithDefaultBank;
