import SearchListHeaderComponent from '@atoms/SearchListHeaderComponent';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase } from '@utils/utils';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, FlatList } from 'react-native';
import styles from './styles';

function getItemSeperatorComponent() {
  return () => <Divider style={styles.dividerStyle} color={colors.neutral.mist} />;
}

function GGRegions(props) {
  const navigation = useNavigation();
  const { t } = useTranslation('common');

  useEffect(() => {
    navigation.setOptions({ title: t('bp.selectRegion') });
  }, []);
  const { route } = props;
  const { params } = route;
  const { regionsData, setSelectedRegion } = params;
  const [searchData] = useState(regionsData);
  const [filteredData, setFilteredData] = useState(regionsData);
  return (
    <View style={appStyles.safeAreaView}>
      <FlatList
        testID="fltLstRegions"
        style={styles.flRegionsStyles}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          <SearchListHeaderComponent
            searchKey="name"
            searchData={searchData}
            setFilteredData={setFilteredData}
            searchPlaceholder={t('bp.regionSelect')}
          />
        }
        ItemSeparatorComponent={getItemSeperatorComponent()}
        data={filteredData}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            testID={`btnRegionSelect${index}`}
            onPress={() => {
              const e = AMPLITUDE_CONSTANTS.BillPayment.PostPaid.pdamAreaSelected;
              const { name, attributes } = e;
              AmplitudeHelper.logEvent(name, {
                [attributes.product_code]: formatUnderScoreLowerCase(item?.code),
              });
              setSelectedRegion(item);
              navigation.goBack();
            }}>
            <Text variant="md-normal" style={styles.txtRegionsStlyles}>
              {item.name}
            </Text>
          </TouchableOpacity>
        )}
      />
    </View>
  );
}

GGRegions.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      regionsData: PropTypes.arrayOf({ id: PropTypes.number, name: PropTypes.string }),
      setSelectedRegion: PropTypes.func,
    }),
  }),
};
GGRegions.defaultProps = {
  route: {
    params: {
      regionsData: [{ id: 1, name: '' }],
      setSelectedRegion: () => {},
    },
  },
};

export default GGRegions;
