import PinWithAddBank from '@molecules/PinWithAddBank';
import appStyles from '@root/appStyles';
import { addBankTemplate2 } from '@root/constants';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function GGEmptyBankPage(props) {
  const { t } = useTranslation('common');
  const { fromTransfer } = props;
  return (
    <View alignItems="center" style={appStyles.safeAreaView}>
      <View style={[appStyles.marginHorizontal(16), appStyles.marginTop(64)]}>
        {SVG_ICONS.bottomSheet.unregistered}
        <View alignItems="center" style={(appStyles.marginHorizontal(19), appStyles.marginTop(8))}>
          <Text variant="lg-bold">{t('bankEmptyPage.emptyBanksTitle')}</Text>
          <Text color={colors.neutral.darkMist} textAlign="center" variant="md-normal" style={appStyles.marginTop(4)}>
            {t('bankEmptyPage.useBankAdd')}
          </Text>
          <PinWithAddBank
            type={addBankTemplate2}
            fromTransfer={fromTransfer}
            source={AMPLITUDE_EVENT_SOURCES.transfer_page}
          />
        </View>
      </View>
    </View>
  );
}

GGEmptyBankPage.propTypes = {
  fromTransfer: PropTypes.bool,
};

GGEmptyBankPage.defaultProps = {
  fromTransfer: true,
};

export default GGEmptyBankPage;
