import { ERROR_CODES, LOGIN_INFO_SHEET_KEYS } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatPhoneNumber, formatUnderScoreLowerCase, removeNonNumeric, showErrorBottomSheet } from '@utils/utils';

const authAmplitude = AMPLITUDE_CONSTANTS.authentication;

export function phoneChangeHandler(isError, setError, setPhoneNumber, setIsDisabled, t, countryCode) {
  return text => {
    if (isError) setError('');
    setPhoneNumber(removeNonNumeric(text));

    if (removeNonNumeric(text).substring(0, 2) === '00') {
      setIsDisabled(true);
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_input_error;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.error_message]: formatUnderScoreLowerCase(t('login.invalidNumber')),
      });
      setError(t('login.invalidNumber'));
    }

    if (formatPhoneNumber(removeNonNumeric(text), countryCode).length >= 8) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
}

export function showLoginErrors(isError, error, setIsLoadingSMS, setIsLoadingWhatsapp, phoneNumber, setInfoModal) {
  if (isError && error) {
    setIsLoadingSMS(false);
    setIsLoadingWhatsapp(false);
    showErrorBottomSheet(setIsLoadingSMS, setIsLoadingWhatsapp, phoneNumber, error[0], setInfoModal);
  }
}

export function shouldNavigateOnSuccess(
  LoginData,
  isSuccess,
  setLoginData,
  setIsLoadingSMS,
  setIsLoadingWhatsapp,
  navigation,
  phoneNumber,
) {
  if (LoginData && isSuccess) {
    const data = LoginData;
    setLoginData(data);
    setIsLoadingSMS(false);
    setIsLoadingWhatsapp(false);
    const e1 = authAmplitude.otp_successful;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
    navigation.navigate('OTP');
  }
}

export function onPressPrimaryFunction(infoModal, phoneNumber, setInfoModal) {
  if (infoModal.errorCode === LOGIN_INFO_SHEET_KEYS[ERROR_CODES['GG-OTP-01']]) {
    const e = authAmplitude.login_retry;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
  }
  setInfoModal({
    visible: false,
    data: { ...infoModal.data },
    errorCode: infoModal.errorCode,
  });
}

export function onPressSecondaryFunction(infoModal, phoneNumber, setInfoModal, setPhoneNumber, navigation) {
  if (infoModal.errorCode === LOGIN_INFO_SHEET_KEYS[ERROR_CODES['GG-OTP-01']]) {
    const e = authAmplitude.login_check_data_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
  }

  setInfoModal({
    visible: false,
    data: { ...infoModal.data },
    errorCode: infoModal.errorCode,
  });
  setPhoneNumber('');

  // Navigate to check data page
  navigation.navigate('Signup', { phoneNumber });
}

export function onCloseFunction(infoModal, phoneNumber, setInfoModal) {
  if (infoModal.errorCode === LOGIN_INFO_SHEET_KEYS[ERROR_CODES['GG-OTP-01']]) {
    const e = authAmplitude.login_modal_closed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
  }
  setInfoModal({ ...infoModal, visible: false });
}
