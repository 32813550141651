import { addPhoneForUser } from '@api/apiCall';
import { fetchAllPhones } from '@api/useQueryCalls';
import CommonTextInput from '@atoms/CommonTextInput';
import InfoTextComponent from '@atoms/InfoTextComponent';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import BottomSheet from '@molecules/LoginInfoSheet';
import LoginPhoneInput from '@molecules/LoginPhoneInput';
import ScreenNames from '@navigation/navigationConstants';
import { useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import {
  BOTTONSHEET_PHONE_CONTENT,
  BOTTONSHEET_PHONE_ENROLLMENT,
  FEATURE_FLAGS,
  SECURITY_PIN_INFO_MODAL,
} from '@root/constants';
import { phoneChangeHandler } from '@screens/GGLogin/loginUtils';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import {
  addPhnoCompleteCallBack,
  formatPhoneNumber,
  formatUnderScoreLowerCase,
  returnBottomSheetContent,
} from '@utils/utils';
import { View, Text, useToast, Button } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bottomSheetHandlers, errorHandler } from './addPhoneUtils';
import styles from './styles';

function GGAddPhoneScreen() {
  const navigation = useNavigation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [sError, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const { t } = useTranslation('common');
  const route = useRoute();
  const params = route?.params;
  const pageSource = AMPLITUDE_EVENT_SOURCES.add_phonenumber_page;
  const txnCategoryId = params?.txnCategoryId ?? null;
  const toast = useToast();
  const { tenantSettings, setLoading, pinRegister, setInitialData, loginData } = getStore();
  const [phoneList, setPhoneList] = useState([]);
  const { isSuccess: isPhoneListSuccess, data: phoneListData, refetch: refetchPhoneList } = fetchAllPhones();
  const { mobileNumber } = loginData;
  const variables = {
    mobileNumber: formatPhoneNumber(phoneNumber),
    ownerName: name.trim(),
  };
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
      BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY],
    ),
    errorCode: BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
  });

  const {
    isLoading,
    isSuccess,
    mutate: addPhoneNumber,
    data,
    isError,
    error,
  } = useMutation({ mutationFn: payload => addPhoneForUser(payload) });

  const { countryCode } = tenantSettings;
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.SECURITY_PIN],
  });

  const onChangePhoneNumber = phoneChangeHandler(sError, setError, setPhoneNumber, setIsDisabled, t, countryCode);

  useEffect(() => {
    refetchPhoneList();
  }, []);

  useEffect(() => {
    if (isPhoneListSuccess && phoneListData && phoneListData?.list && phoneListData.list.length > 0) {
      setPhoneList(phoneListData.list);
    }
  }, [isPhoneListSuccess, phoneListData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    errorHandler(isError, error, navigation, setInfoModal, toast, t, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess && data) {
      addPhnoCompleteCallBack(navigation, toast, data, t, params);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.add_phonenumber_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(txnCategoryId),
    });
  }, []);

  function addPhoneNumberCallBack() {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.phone_number]: variables.mobileNumber,
      [e.attributes.phone_name]: formatUnderScoreLowerCase(variables.ownerName),
    });

    if (
      mobileNumber === formatPhoneNumber(phoneNumber) ||
      phoneList.filter(item => item.mobileNumber === formatPhoneNumber(phoneNumber)).length > 0
    ) {
      const e1 = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_error;
      AmplitudeHelper.logEvent(e1.name, {
        [e1.attributes.error_message]: 'number_duplicated',
        [e1.attributes.source]: pageSource,
      });

      return setInfoModal(prevState => ({ ...prevState, visible: true }));
    }

    if (isFeatureAvailale?.[FEATURE_FLAGS.SECURITY_PIN]?.show && pinRegister.isActive) {
      if (pinRegister.invalidAttempts >= 3)
        return setInfoModal({
          visible: true,
          data: returnBottomSheetContent(
            SECURITY_PIN_INFO_MODAL.THREE_RETRY_VERIFICATION,
            SECURITY_PIN_INFO_MODAL.THREE_RETRY_VERIFICATION,
          ),
          errorCode: 'THREE_RETRY_VERIFICATION',
        });
      return navigation.navigate(ScreenNames.GG_VERIFY_PIN, {
        source: 'pin_phone_number',
        pin_failed_modal: 'pin_failed_modal_phonenumber',
        onVerifyCallBack: addPhoneNumber,
        onVerifyValues: variables,
      });
    }
    return addPhoneNumber({ ...variables, securityPin: null });
  }

  const { onPressPrimary, onPressSecondaryHandler, onCloseHandler } = bottomSheetHandlers(
    setInfoModal,
    infoModal,
    navigation,
    addPhoneNumber,
    variables,
  );

  return (
    <View style={appStyles.safeAreaView}>
      <View style={styles.addPhoneParent}>
        <Text variant="sm-normal" style={styles.phoneTextStyles}>
          {t('addPhone.addPhoneText')}
        </Text>
        <LoginPhoneInput
          sError={sError}
          phoneNumber={phoneNumber}
          setPhoneNumber={onChangePhoneNumber}
          onPropsFocus={() => {
            const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_input_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: pageSource,
              [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
            });
          }}
          onPropsBlur={() => {
            const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_filled;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: pageSource,
              [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
            });
          }}
        />
        <Text variant="sm-normal" style={styles.nameTextStyles}>
          {t('addPhone.addPhoneNameText')}
        </Text>
        <CommonTextInput
          testID="txtPhoneName"
          placeholder={t('addPhone.phonePlaceHolderText')}
          value={name}
          onChangeText={val => setName(val)}
          amplitudeCallBackOnFocus={() => {
            const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_name_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: pageSource,
              [e.attributes.input]: name,
            });
          }}
          amplitudeCallBackOnBlur={() => {
            const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_name_filled;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: pageSource,
              [e.attributes.input]: name,
            });
          }}
        />
        <Button
          testID="btnSavePhone"
          isDisabled={isDisabled || !name}
          style={appStyles.marginTop(24)}
          onPress={() => addPhoneNumberCallBack()}>
          {t('addPhone.addPhoneButtonText')}
        </Button>
        {/* <HStack style={styles.infoParent}>
          <Feather name="info" size={24} color={colors.neutral.charcoal} style={appStyles.marginRight(8)} />
          <VStack style={appStyles.paddingRight(12)}>
            <Text maxWidth="90%" variant="xsm-bold">
              {t('addPhone.addPhoneInfo1')}
            </Text>
            <Text variant="xsm-bold">{t('addPhone.addPhoneInfo2')}</Text>
          </VStack>
        </HStack> */}
        <InfoTextComponent
          containerStyle={styles.infoParent}
          show
          iconName="info-outline"
          iconColor="black"
          textBucket={[t('addPhone.addPhoneInfo1'), t('addPhone.addPhoneInfo2')]}
          customTextStyle={styles.infoContainer}
        />
      </View>
      <BottomSheet
        onPressPrimary={onPressPrimary}
        onPressSecondary={onPressSecondaryHandler}
        loginInfo={infoModal}
        onClose={onCloseHandler}
      />
    </View>
  );
}

GGAddPhoneScreen.propTypes = {};
GGAddPhoneScreen.defaultProps = {};
export default GGAddPhoneScreen;
