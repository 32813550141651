/* eslint-disable global-require */
import BtnLanguage from '@atoms/BtnLanguage';
import config from '@config';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Box, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Image } from 'react-native';
import styles from './style';

function shouldShowLanguageSelector() {
  if (config.ENVIRONMENT !== 'production') {
    return (
      <Box marginTop={10} top={5} right={5} position="absolute">
        <BtnLanguage />
      </Box>
    );
  }
  return null;
}

function shouldShowWelcomText(show, textContext) {
  if (show)
    return (
      <Text
        testID="txtWelcome"
        marginX={26}
        paddingBottom={10}
        variant="md-bold"
        color={colors.neutral.cotton}
        style={styles.textStyle}>
        {textContext}
      </Text>
    );
  return null;
}

const WelcomCards = ({ item, isKeyboardOpen }) => (
  <View style={[styles.cardContainer, { backgroundColor: item.cardColor }]}>
    <View style={styles.logoStyle}>{SVG_ICONS.welcome.whiteGGLogo}</View>
    <View>
      <Image source={require('@assets/welcome.gif')} resizeMode="center" style={styles.artwork} />
    </View>
    {shouldShowWelcomText(!isKeyboardOpen, item.textContext)}
    {shouldShowLanguageSelector()}
  </View>
);

WelcomCards.propTypes = {
  item: PropTypes.shape({
    cardColor: PropTypes.string,
    textContext: PropTypes.string,
  }),
  isKeyboardOpen: PropTypes.bool,
};

WelcomCards.defaultProps = {
  item: {
    cardColor: '',
    textContext: '',
  },
  isKeyboardOpen: false,
};

export default WelcomCards;
