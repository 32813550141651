export function onSliderChange(setInputValue) {
  return value => setInputValue(value);
}

export function getSliderStepValue(balance, sliderStep) {
  return balance === 0 ? 0 : sliderStep;
}

export function getMinimumBalanceValue(balance, minTxnValue) {
  if (balance < minTxnValue) {
    return balance;
  }
  return balance === 0 ? 0 : minTxnValue;
}

export function isValidNumberWithDot(input) {
  const regex = /^[0-9]*\.?[0-9]*$/;
  return regex.test(input);
}

// export function disableCheck(isDisabled, sInputAmountValue, minTxnValue, balance) {
//   if (balance === 0 || isDisabled || !sInputAmountValue) {
//     return true;
//   }
//   const changedInput = parseInt(sInputAmountValue, 10);
//   return changedInput < minTxnValue || changedInput > balance;
// }

export const isWithdrawButtonDisabled = (amountValue, minValue, maxValue, isEnabled) => {
  const parsedInt = parseInt(amountValue, 10);
  return parsedInt < minValue || parsedInt > maxValue || !isEnabled;
};
