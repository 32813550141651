import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function getReferralImageBGStyles(bgColor) {
  return {
    backgroundColor: bgColor,
    paddingHorizontal: 4,
    paddingVertical: 4,
    borderRadius: 12,
  };
}

function ReferralTrail() {
  const { t } = useTranslation('common');
  return (
    <View style={[appStyles.borderWithShadow, appStyles.marginHorizontal(0), appStyles.paddingHorizontal(16)]}>
      <View flexDirection="row" justifyContent="space-between">
        <Text variant="xsm-normal" color={colors.secondary.orchid} style={styles.txtYouStyles}>
          {t('referral.txtYou')}
        </Text>
        <View style={styles.emptyViewStyles} />
        <View style={appStyles.flex1}>
          <Text
            variant="xsm-normal"
            textAlign="center"
            color={colors.primary.carnation}
            style={styles.yourFriendStyles}>
            {t('referral.txtYourFriend')}
          </Text>
        </View>
      </View>
      <View style={styles.trailArrowImagesContainer}>
        <View style={getReferralImageBGStyles(colors.secondary.softOrchid)}>{SVG_ICONS.referral.refProgOne}</View>
        <View>{SVG_ICONS.referral.pointingArrow}</View>

        <View style={getReferralImageBGStyles(colors.primary.softCarnation)}>{SVG_ICONS.referral.refProgTwo}</View>
        {SVG_ICONS.referral.pointingArrowRed}
        <View style={getReferralImageBGStyles(colors.primary.softCarnation)}>{SVG_ICONS.referral.refProgThree}</View>
      </View>
      <View style={styles.trailTextContainer}>
        <Text variant="sm-bold">{t('referral.txtShareCode')}</Text>
        <Text variant="sm-bold">{t('referral.txtUseCode')}</Text>
        <Text variant="sm-bold">{t('referral.txtTransaction')}</Text>
      </View>
    </View>
  );
}

export default ReferralTrail;
