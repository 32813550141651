import LoanMetaItem from '@atoms/Loan/LoanMetaItem';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FlatList } from 'react-native';
import styles from './style';

export const LOAN_META_INFO = [
  'loanMetaInfo1',
  'loanMetaInfo2',
  'loanMetaInfo3',
  'loanMetaInfo4',
  'loanMetaInfo5',
  'loanMetaInfo6',
];

export const keyExtract = (item, index) => index.toString();

const LoanMetaList = ({ t }) => {
  const renderItem = useCallback(
    ({ item, index }) => <LoanMetaItem key={index} item={item} index={index} t={t} />,
    [t],
  );

  return (
    <FlatList
      testID="loanMetalist"
      contentContainerStyle={styles.contentContainerStyle}
      data={LOAN_META_INFO}
      keyExtractor={keyExtract}
      renderItem={renderItem}
    />
  );
};

LoanMetaList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default LoanMetaList;
