import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { changeAmountWithFormatting, numberWithDots } from '@utils/utils';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import styles from './styles';

export function onFocusHandler(source) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.transfer.transfer_manual_input;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
      [attributes.transaction_category_id]: 'wd-02',
    });
  };
}

function AmountForWithdrawal(props) {
  const { t } = useTranslation('common');
  const { tenantSettings } = getStore();
  const { currencySymbol, currencySeperatorRegEx } = tenantSettings;
  const { handleChange, errors, amount, source } = props;
  const [inputValue, setInputValue] = useState(amount);
  const changeAmountCallBack = val => {
    changeAmountWithFormatting(currencySeperatorRegEx, () => {}, setInputValue)(val);
    handleChange('amount')(val.toString().replace(/\./g, ''));
  };
  return (
    <View style={appStyles.marginTop(16)}>
      <Text variant="sm-normal" color={colors.neutral.darkMist}>
        {t('bankTransfer.amountForTransfer')}
      </Text>
      <View style={styles.amountInputStyles(errors)} _web={{ paddingY: scale(13) }}>
        <Text variant="md-normal" style={appStyles.marginLeft(16)}>
          {currencySymbol}
        </Text>
        <TextInput
          keyboardType="numeric"
          testID="txtInputAmount"
          value={numberWithDots(inputValue)}
          onChangeText={changeAmountCallBack}
          onFocus={onFocusHandler(source)}
          style={[appStyles.flex1, { fontFamily: regularFont, fontSize: scale(14), lineHeight: scale(21) }]}
        />
      </View>
    </View>
  );
}

AmountForWithdrawal.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.shape({}),
  amount: PropTypes.string,
  source: PropTypes.string,
};
AmountForWithdrawal.defaultProps = {
  handleChange: () => {},
  errors: {},
  amount: '',
  source: '',
};

export default AmountForWithdrawal;
