import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fabStyles: {
    alignContent: 'center',
    alignItems: 'center',
    bottom: 10,
    flex: 1,
    justifyContent: 'center',
    position: 'absolute', // since tabStyle shifted by 10 above
  },
});
export default styles;
