import { redPic } from '@assets/png/imagesPng';
import HiUserName from '@atoms/HiUserName';
import MaskedPhoneNumber from '@atoms/MaskedPhoneNumber';
import SkeletonComponent from '@atoms/SkeletonComponent';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import NormalDeptAlert from '@molecules/NormalDeptAlert';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Button } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImageBackground } from 'react-native';
import styles from './styles';

function ProfileHeaderComponent(props) {
  const { loginData, employeeProfile } = getStore();
  const firstName = employeeProfile?.firstName ?? '';

  const { mobileNumber } = loginData;

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  const { t } = useTranslation('common');
  const HappyFace = SVG_ICONS.profilePage.profileAvatar;
  const { profileData, onLihatPressHandler, isPICReferral } = props;
  if (profileData) {
    if (profileData.influencerRole === 'PIC') {
      return (
        <View backgroundColor={colors.secondary.orchid}>
          <View marginX="16px">
            <ImageBackground
              borderTopLeftRadius={31}
              borderTopRightRadius={31}
              source={redPic}
              imageStyle={styles.profileImage}
              style={styles.profileImage}>
              <View style={styles.cardDesign} flexDirection="row">
                <MaterialCommunityIcons name="account-circle" size={32} color="white" />
                <View flex={1}>
                  <HiUserName userName={firstName} t={t} commonColor={colors.neutral.cotton} />
                  <MaskedPhoneNumber mobileNumber={mobileNumber} commonColor={colors.neutral.cotton} />
                </View>
                <Button
                  onPress={onLihatPressHandler}
                  testID="lihatButtonProfilePagePic"
                  backgroundColor={colors.neutral.cotton}
                  style={styles.lihatButtonStyle}>
                  <Text testID="lihatButtonProfilePageText" variant="sm-bold" style={styles.textStylesPic}>
                    {t('profile.lihat')}
                  </Text>
                </Button>
              </View>
            </ImageBackground>
          </View>
        </View>
      );
    }
    return (
      <View marginTop="16px">
        <View flexDirection="row" marginBottom="12px">
          <View marginLeft="16px" width="32px" height="32px">
            {HappyFace}
          </View>
          <View alignSelf="stretch" flex={1}>
            <HiUserName userName={firstName} t={t} commonColor={colors.neutral.charcoal} />
            <MaskedPhoneNumber mobileNumber={mobileNumber} commonColor={colors.neutral.darkMist} />
          </View>

          <Button
            testID="lihatButtonProfilePage"
            backgroundColor={colors.neutral.cotton}
            style={styles.lihatButtonStyle}
            onPress={onLihatPressHandler}
            shadow={6}>
            <Text testID="lihatButtonProfilePageText" variant="sm-bold" style={styles.textStyles}>
              {t('profile.lihat')}
            </Text>
          </Button>
        </View>
        <NormalDeptAlert
          show={isPICReferral && (!employeeProfile.departmentName || !employeeProfile?.designationName) && !isPIC}
          icon={<MaterialIcons style={styles.iconContainer} size={24} name="query-builder" />}
          title="Lengkapi Data Karyawanmu"
          customContainer={styles.barContainer}
          iconRight={<MaterialIcons style={styles.arrowStyle} size={20} name="arrow-right" />}
          onPressHandler={onLihatPressHandler}
        />
      </View>
    );
  }
  return <SkeletonComponent />;
}

ProfileHeaderComponent.propTypes = {
  profileData: PropTypes.shape({
    influencerRole: PropTypes.string,
  }),
  onLihatPressHandler: PropTypes.func,
  isPICReferral: PropTypes.string,
};
ProfileHeaderComponent.defaultProps = {
  profileData: {
    influencerRole: '',
  },
  onLihatPressHandler: () => {},
  isPICReferral: false,
};
export default ProfileHeaderComponent;
