import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  modalParent: containerStyle => ({
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    ...containerStyle,
  }),
});

export default styles;
