import appStyles from '@root/appStyles';
import GGGoldLanding from '@screens/GGGold/GGGoldLanding';
import { ScrollView } from 'native-base';
import React from 'react';
// import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native';

function FinansialTab() {
  return (
    <SafeAreaView style={appStyles.tabParent}>
      <ScrollView>
        <GGGoldLanding />
      </ScrollView>
    </SafeAreaView>
  );
}

// FinansialTab.propTypes = {};
// FinansialTab.defaultProps = {};
export default FinansialTab;
