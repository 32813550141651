import colors from '@theme/colors';
import { Platform } from 'react-native';

export function getFocusedTextForTab(focused) {
  return focused ? colors.primary.carnation : colors.disabled.smoke;
}

export function getWebStyles() {
  return Platform.OS === 'web' ? { justifyContent: 'flex-end', alignSelf: 'stretch' } : {};
}
