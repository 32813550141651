import React from 'react';
import { Pressable } from 'react-native';
import colors from './colors';

export function getSvgProps(focused) {
  return focused ? { fill: colors.primary.carnation } : null;
}

export function getTabBarComponent(labelName) {
  return props => <Pressable testID={`tab_${labelName}`} {...props} />;
}
