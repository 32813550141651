import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  phoneBookStyles: {
    alignSelf: 'center',
    height: 172,
    marginTop: 32,
    resizeMode: 'contain',
    width: '100%',
  },
});

export default styles;
