import logEyeIconClicked from '@atoms/BankRow/analyticsBankRow';
import InfoTextComponent from '@atoms/InfoTextComponent';
import { Ionicons } from '@expo/vector-icons';
import PinWithAddBank from '@molecules/PinWithAddBank';
import {
  getMaskedOrUnmaskedData,
  maskIconInit,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import { useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { addBankTemplate1 } from '@root/constants';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { View, Text, Divider } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function DefaultBankHeader() {
  const route = useRoute();
  const { t } = useTranslation('common');
  const { defaultBank } = getStore();
  const { fromTransfer, source } = route.params;
  const [maskData, setMaskData] = useState(false);
  const [maskIcon, setMaskIcon] = useState(maskIconInit(maskData));

  if (fromTransfer) {
    return (
      <View style={[appStyles.marginHorizontal(16), appStyles.marginTop(24)]}>
        <View flexDirection="row">
          <Text flex={1} variant="md-bold">
            {t('bankList.accountListForTransfer')}
          </Text>
          <PinWithAddBank type={addBankTemplate1} source={AMPLITUDE_EVENT_SOURCES.transfer_page} fromTransfer />
        </View>
        <InfoTextComponent
          containerStyle={[styles.infoContainer, appStyles.paddingHorizontal(12)]}
          show
          customTextStyle={appStyles.flex1}
          infoText={t('bankList.hanya')}
          iconContainer={appStyles.marginRight(8)}
          variant="xsm-bold"
          size={scale(24)}
        />
      </View>
    );
  }
  return (
    <View>
      <View>
        <Text
          testID="txtPrimaryBankHeader"
          style={[appStyles.marginHorizontal(16), appStyles.marginTop(22)]}
          variant="md-normal">
          {t('profile.primaryBankHeader')}
        </Text>
      </View>
      <View style={[appStyles.marginHorizontal(16), appStyles.marginTop(20)]}>
        <Text testID="txtBankName" variant="md-bold" style={{}}>
          {defaultBank?.bankName}
        </Text>
        <View flexDirection="row" alignItems="center">
          <Text testID="txtMaskedAccountNumber" variant="sm-normal" style={{}}>
            {getMaskedOrUnmaskedData(maskData, defaultBank?.number)}
          </Text>
          <Ionicons
            name={maskIcon}
            size={18}
            color="black"
            testID="btnMaskUnmaskImage"
            style={appStyles.marginLeft(8)}
            onPress={() => {
              logEyeIconClicked(source, maskData, { accountNumber: defaultBank?.number });
              setMaskIcon(setMaskIconValue(maskData));
              setMaskData(!maskData);
            }}
          />
        </View>
        <Text variant="sm-normal" color={colors.neutral.darkMist} style={appStyles.marginBottom(4)}>
          {defaultBank?.bankAccountName}
        </Text>
        <InfoTextComponent
          containerStyle={styles.infoContainerTwo}
          customTextStyle={[appStyles.marginLeft(8), appStyles.marginRight(12), appStyles.flex1]}
          iconColor={colors.secondary.orchid}
          iconContainer={[appStyles.marginLeft(12)]}
          infoText={t('profile.bankListInfo')}
          variant="xsm-bold"
          size={scale(24)}
          show
        />
        <Divider height="4px" color={colors.neutral.mist} style={appStyles.marginTop(16)} />
        <Text variant="md-normal" style={appStyles.marginTop(12)}>
          {t('profile.bankOtherList')}
        </Text>
        <Text variant="xsm-normal" color={colors.neutral.darkMist}>
          {t('profile.bankMaxLimit')}
        </Text>
      </View>
    </View>
  );
}

export default DefaultBankHeader;
