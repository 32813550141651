import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { getAmplitudeKeyValue, formatUnderScoreLowerCase, getAmplitudeYesNo, removeNonNumeric } from '@utils/utils';

export function withdrawNowFailedAmplitude(
  source,
  transaction_category_id,
  withdrawalAmount,
  failureMessage,
  validatedReferralCode,
  amplitudeAccountName,
) {
  const e = AMPLITUDE_CONSTANTS.transfer.withdrawnow_failed;

  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.transaction_category_id]: transaction_category_id,
    [e.attributes.source]: source,
    [e.attributes.requested_amount]: removeNonNumeric(withdrawalAmount),
    [e.attributes.failure_message]: formatUnderScoreLowerCase(failureMessage),
    [e.attributes.referral_code]: validatedReferralCode || null,
    [e.attributes.dynamic_account_name]: amplitudeAccountName,
  });
}

export function logReasonSelector(
  transaction_category_id,
  fromTransfer,
  source,
  validatedReferralCode,
  amplitudeAccountName,
  withdrawalAmount,
) {
  const e = AMPLITUDE_CONSTANTS.transfer.withdrawnow_successful;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.transaction_category_id]: transaction_category_id,
    [e.attributes.source]: fromTransfer ? AMPLITUDE_EVENT_SOURCES.transfer_page : source,
    [e.attributes.referral_code]: validatedReferralCode || null,
    [e.attributes.dynamic_account_name]: amplitudeAccountName,
    [e.attributes.requested_amount]: removeNonNumeric(withdrawalAmount),
  });
}

export function logWithdrawPressed(
  withdrawalAmount,
  source,
  transaction_category_id,
  validatedReferralCode,
  amplitudeAccountName,
  meAmplitudeContext,
  isInLockPeriod,
  status,
) {
  const e = AMPLITUDE_CONSTANTS.transfer.withdrawnow_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.requested_amount]: removeNonNumeric(withdrawalAmount),
    [e.attributes.source]: source,
    [e.attributes.transaction_category_id]: transaction_category_id,
    [e.attributes.referral_code]: validatedReferralCode || null,
    [e.attributes.dynamic_account_name]: amplitudeAccountName,
    [e.attributes.locK_period]: getAmplitudeYesNo(isInLockPeriod),
    [e.attributes.status]: status ?? null,
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}
