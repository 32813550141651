import ScreenNames from '@navigation/navigationConstants';
import {
  BOTTONSHEET_PHONE_CONTENT,
  BOTTONSHEET_PHONE_ENROLLMENT,
  FEATURE_FLAGS,
  SECURITY_PIN_INFO_MODAL,
} from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { returnBottomSheetContent } from '@utils/utils';

export default function phoneWithPinHandlers(phonePinParams) {
  const {
    listData,
    navigation,
    setInfoModal,
    isFeatureAvailale,
    pinRegister,
    infoModal,
    initalState,
    eventState,
    eventSource,
    transactionCategoryId,
  } = phonePinParams;
  const onLegacyPressAddPhoneNo = buttonPosition => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.add_phonenumber_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: eventSource,
      [e.attributes.state]: eventState,
      [e.attributes.button]: buttonPosition,
      [e.attributes.transaction_category_id]: transactionCategoryId ?? null,
    });
    if (listData.length < 10) {
      navigation.navigate(ScreenNames.GG_ADD_PHONE_SCREEN, {
        source: eventSource,
        txnCategoryId: transactionCategoryId ?? null,
      });
      return;
    }
    const e1 = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_error;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.error_message]: 'max_reached',
      [e1.attributes.source]: eventSource,
    });

    setInfoModal(prevState => ({
      ...prevState,
      data: returnBottomSheetContent(
        BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
        BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT],
      ),
      errorCode: BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
      visible: true,
      currentId: 0,
    }));
  };

  const onPressAddPhoneNo = buttonPosition => {
    if (isFeatureAvailale[FEATURE_FLAGS.SECURITY_PIN]?.show && !pinRegister.isActive) {
      return setInfoModal({
        data: returnBottomSheetContent(
          SECURITY_PIN_INFO_MODAL.BEFORE_CREATE_PIN,
          SECURITY_PIN_INFO_MODAL.BEFORE_CREATE_PIN,
        ),
        errorCode: 'BEFORE_CREATE_PIN',
        visible: true,
        currentId: 0,
        buttonPosition,
      });
    }

    return onLegacyPressAddPhoneNo(buttonPosition);
  };

  const onPressPrimaryBottomSHeet = () => {
    if (infoModal.errorCode === 'BEFORE_CREATE_PIN') {
      const e = AMPLITUDE_CONSTANTS.securityPin.create_pin_later;

      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_phonenumber_page,
      });

      setInfoModal(initalState);
      onLegacyPressAddPhoneNo(infoModal.buttonPosition);
      // infoModal.buttonPosition
      return;
    }
    setInfoModal(initalState);
  };

  const onPressSecondaryBottomSheet = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === 'BEFORE_CREATE_PIN') {
      const e = AMPLITUDE_CONSTANTS.securityPin.create_pin_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_phonenumber_page,
      });
      return navigation.navigate(ScreenNames.GG_CREATE_PIN, { type: 'create', source: 'pin_phone_number' });
    }
  };

  const onBottomSheetCloseHandler = () => {
    if (infoModal.errorCode === 'BEFORE_CREATE_PIN') {
      const e = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.add_phonenumber_page,
      });
    }
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_cancelled;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.number]: infoModal.selectedNumber,
        [e.attributes.name]: infoModal.selectedName,
        [e.attributes.button]: 'close',
      });
    }
    setInfoModal(initalState);
  };
  return { onPressAddPhoneNo, onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onBottomSheetCloseHandler };
}
