import TNCitem from '@atoms/TNCitem';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './style';

const AttentionBoard = ({ show, dataList, t }) => {
  if (!show) return null;

  return (
    <View style={styles.outerContainer}>
      <View style={styles.row}>
        <View style={styles.iconStyle}>{SVG_ICONS.loan.verifiedUser}</View>
        <View style={styles.textContainer}>
          <Text variant="md-bold">{t('loan.harapa')}</Text>
          <Text variant="sm-normal">{t('loan.baca')}</Text>
        </View>
      </View>

      <View style={styles.listContainer}>
        {dataList.map((o, i) => (
          <TNCitem key={o} item={o} index={i} />
        ))}
      </View>
    </View>
  );
};

AttentionBoard.propTypes = {
  show: PropTypes.bool,
  dataList: PropTypes.shape([]),
  t: PropTypes.func.isRequired,
};

AttentionBoard.defaultProps = {
  show: false,
  dataList: [],
};
export default AttentionBoard;
