import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Pressable, ActivityIndicator } from 'react-native';
import style from './style';

const GoldBanner = ({
  content,
  buttonTitle,
  onPressHandler,
  show,
  bannerContainerStyle,
  contentAdjStyle,
  buttonStyle,
  buttonTextStyle,
  icon,
  contentTextStyle,
  loading,
}) => {
  if (!show) return null;

  function shouldRenderLoader(isLoading) {
    if (isLoading)
      return <ActivityIndicator color={colors.primary.carnation} size={25} style={appStyles.marginRight(5)} />;

    return (
      <Pressable onPress={onPressHandler} style={[style.buttonStyle, buttonStyle]}>
        <Text variant="sm-bold" style={buttonTextStyle}>
          {buttonTitle}
        </Text>
      </Pressable>
    );
  }

  return (
    <View style={[style.bannerContainer, bannerContainerStyle]}>
      <View style={[style.contentAdj, contentAdjStyle]}>
        <View style={style.coinStyle}>{icon}</View>
        <Text variant="sm-bold" style={contentTextStyle}>
          {content}
        </Text>
      </View>
      {shouldRenderLoader(loading)}
    </View>
  );
};

GoldBanner.propTypes = {
  content: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  onPressHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  bannerContainerStyle: PropTypes.shape({}),
  contentAdjStyle: PropTypes.shape({}),
  buttonStyle: PropTypes.shape({}),
  buttonTextStyle: PropTypes.shape({}),
  icon: PropTypes.element,
  contentTextStyle: PropTypes.shape({}),
  loading: PropTypes.bool,
};

GoldBanner.defaultProps = {
  bannerContainerStyle: {},
  contentAdjStyle: {},
  buttonStyle: {},
  buttonTextStyle: {},
  icon: null,
  contentTextStyle: {},
  loading: false,
};

export default GoldBanner;
