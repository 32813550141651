import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logHeaderLink() {
  const e = AMPLITUDE_CONSTANTS.Loans.additional_loan_info_clicked;
  AmplitudeHelper.logEvent(e.name, {});
}

export function logProductPress(item) {
  const e = AMPLITUDE_CONSTANTS.Loans.loan_selection_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.loan_product_code]: item.loanCode,
  });
}
