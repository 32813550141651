import { Skeleton } from '@rneui/base';
import { Stack } from 'native-base';
import React from 'react';
import styles from './styles';

function SkeletonComponent(props) {
  return (
    <Stack testID="skeletonComponent" row align="center" spacing={4} {...props}>
      <Skeleton animation="wave" style={styles.skeletonParent} />
    </Stack>
  );
}

// SkeletonComponent.propTypes = {};
// SkeletonComponent.defaultProps = {};
export default SkeletonComponent;
