import AmountForWithdrawal from '@atoms/AmountForWithdrawal';
import BankSelectionButton from '@atoms/BankSelectionButton';
import InfoTextComponent from '@atoms/InfoTextComponent';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import WithdrawButton from '@molecules/WithdrawButton';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { shouldShowErrorForAmounts } from '@screens/GGBankTransfer/bankTransferUtils';
import { logPageLaunch } from '@screens/GGBillPayments/GGNominals/analyticsNominals';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { bankTransferSchema } from '@utils/schemas';
import { Formik } from 'formik';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getDisabledCheck from './bankTransferUtils';
import styles from './styles';
import productConfig from '../../../productConfig/31bankTransfer.json';

const transactionCategoryId = 'wd-02';
function GGBankTransferPage(props) {
  const { accounts, tenantSettings, setBankForTransfer, validatedReferralCode } = getStore();
  const { currencySymbol } = tenantSettings;
  const transferAccount = accounts.filter(accountItem => accountItem.accountTypeId === 0)[0];
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const [bankAccountNumber, setBankAccountNumber] = useState(null);
  const [amountForTransfer] = useState('');
  const { route } = props;
  const { params } = route;

  const { source } = params;
  const navigateToBankListOnPress = () => {
    const e = AMPLITUDE_CONSTANTS.transfer.select_bank_account_clicked;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: AMPLITUDE_EVENT_SOURCES.transfer_page,
      [attributes.transaction_category_id]: transactionCategoryId,
    });
    navigation.navigate(navigationConstants.BANK_LIST, {
      defaultBank: null,
      fromTransfer: true,
      setBankAccountNumber,
      source: AMPLITUDE_EVENT_SOURCES.transfer_page,
    });
  };

  useEffect(() => {
    logPageLaunch(productConfig, source, validatedReferralCode);
  }, []);

  useEffect(() => {
    if (bankAccountNumber) {
      const e = AMPLITUDE_CONSTANTS.transfer.bank_account_selected;
      const { name, attributes } = e;
      AmplitudeHelper.logEvent(name, {
        [attributes.source]: AMPLITUDE_EVENT_SOURCES.transfer_page,
        [attributes.transaction_category_id]: transactionCategoryId,
      });
    }
    setBankForTransfer(bankAccountNumber);
  }, [bankAccountNumber]);
  return (
    <View style={appStyles.safeAreaView}>
      <Formik
        enableReinitialize
        validateOnChange
        validationSchema={bankTransferSchema(transferAccount.minTxnValue, transferAccount.balance, t, currencySymbol)}
        initialValues={{
          amount: amountForTransfer,
          bankAccountNumber,
        }}>
        {({ handleChange, values, errors, setFieldValue }) => (
          <View flex={1}>
            <View style={[appStyles.flex1, appStyles.marginHorizontal(16), appStyles.paddingVertical(16)]}>
              <Text variant="md-normal">{t('bankTransfer.sourceOfFunds')}</Text>
              <View
                flexDirection="row"
                borderColor={colors.disabled.smoke}
                alignItems="center"
                style={[
                  appStyles.borderRadius(12),
                  appStyles.borderWidth(1),
                  appStyles.paddingVertical(8),
                  appStyles.marginTop(8),
                ]}>
                <View style={[appStyles.marginLeft(16), appStyles.marginRight(12)]}>
                  <RemoteImage uri={transferAccount.icon} width={24} height={24} />
                </View>
                <View>
                  <Text testID="txtDisplayName" variant="sm-bold" color={colors.secondary.orchid}>
                    {transferAccount.displayName}
                  </Text>
                  <TextCurrency variant="md-bold" amountCurrency={transferAccount.balance} />
                </View>
              </View>
              <Divider color={colors.neutral.mist} height="4px" style={appStyles.marginTop(14)} />
              <View style={appStyles.marginTop(16)}>
                <Text testID="txtTransferDay" variant="md-normal">
                  {t('bankTransfer.transferOfDay')}
                </Text>
                <View style={appStyles.marginTop(8)}>
                  <Text textAlign="txtAccountName" variant="sm-normal" color={colors.neutral.darkMist}>
                    {t('bankTransfer.accountName')}
                  </Text>
                  <BankSelectionButton
                    bankAccountNumber={bankAccountNumber}
                    navigateToBankListOnPress={navigateToBankListOnPress}
                  />
                </View>
                <AmountForWithdrawal
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  amount={values.amount}
                  errors={errors.amount}
                  source={AMPLITUDE_EVENT_SOURCES.transfer_page}
                />
                {shouldShowErrorForAmounts(errors)}
                <InfoTextComponent
                  show
                  containerStyle={styles.infoContainer}
                  customTextStyle={appStyles.paddingHorizontal(8)}
                  iconColor={colors.neutral.charcoal}
                  infoText={t('bankTransfer.transferInfo')}
                  variant="xsm-bold"
                  size={scale(24)}
                />
              </View>
            </View>
            <View
              style={[
                appStyles.borderWithShadow,
                appStyles.marginHorizontal(0),
                appStyles.borderBottomLeftRadius(0),
                appStyles.borderBottomRightRadius(0),
                appStyles.paddingBottom(16),
              ]}>
              <WithdrawButton
                isDisabled={getDisabledCheck(values, errors)}
                withdrawalAmount={values.amount}
                buttonTitle={t('common.continue')}
                fromTransfer
                source={AMPLITUDE_EVENT_SOURCES.transfer_page}
                transaction_category_id={transactionCategoryId}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
}

GGBankTransferPage.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      productConfig: PropTypes.shape({}),
      source: PropTypes.string,
    }),
  }),
};
GGBankTransferPage.defaultProps = {
  route: {
    params: {
      productConfig: {},
      source: '',
    },
  },
};
export default GGBankTransferPage;
