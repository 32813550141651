import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { View, Icon } from 'native-base';
import React from 'react';
import styles from '../ArrowDown/styles';

function ArrowUp() {
  return (
    <View style={styles.arrowStyle}>
      <Icon as={AntDesign} name="up" color={colors.neutral.charcoal} />
    </View>
  );
}

ArrowUp.propTypes = {};
ArrowUp.defaultProps = {};
export default ArrowUp;
