import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import bulletRowStyle from './style';

const RenderBulletRow = ({ desc }) => (
  <View style={bulletRowStyle.outerRow}>
    <Text variant="sm-normal" style={bulletRowStyle.bulletStyle}>
      {'\u2022'}
    </Text>
    <Text variant="sm-normal" style={bulletRowStyle.bulletStyleTwo}>
      {desc}
    </Text>
  </View>
);

RenderBulletRow.propTypes = {
  desc: PropTypes.string,
};

RenderBulletRow.defaultProps = {
  desc: '',
};

export default RenderBulletRow;
