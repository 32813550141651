import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getDateInFormatLocale } from '@utils/dateUtils';
import { getBoundary } from './paymentCyclesUtils';

export function logCycles(paymentCycles, selectedPCIdx) {
  if (getBoundary(selectedPCIdx, paymentCycles)) return;
  const e = AMPLITUDE_CONSTANTS.TransactionPage.cycle_changed;
  const { startDate = '', endDate = '' } = paymentCycles[selectedPCIdx];
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'transaction_page',
    [e.attributes.start_cycle_date]: getDateInFormatLocale(startDate, 'DD MMM YYYY'),
    [e.attributes.end_cycle_date]: getDateInFormatLocale(endDate, 'DD MMM YYYY'),
  });
}

export function test() {}
