import { apiReferralValidation } from '@api/apiCall';
import ControlledTooltip from '@atoms/CustomToolTip';
import ShimmerReferralInput from '@atoms/Shimmers/ShimmerReferralInput';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Icon } from '@rneui/base';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { View, Text, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logReferralApplicable from './analyticsReferral';
import {
  shouldShowToastOnSuccess,
  shouldShowValidatedButton,
  shouldShowValidatedReferralInput,
} from './referralInputUtils';
import styles from './styles';

/*
  This component is responsible for rendering the referral input section.
*/

// Function to determine whether to show the validated button or the check button
function ReferralInput(props) {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const toast = useToast();

  // Getting necessary data and functions from the store
  const {
    validatedReferralCode,
    canShowReferral,
    referralValidated,
    setReferralValidated,
    setLoading,
    setInitialData,
    setValidatedReferralCode,
    setDeepLinkData,
    deepLinkData,
  } = getStore();
  const { selectedAccount, isLoading: isMeLoading, pageSource } = props;
  const { t } = useTranslation('common');

  // State to store the referral code entered by the user
  const [referralCode, setReferralCode] = useState('');

  // Call the validateReferral function that returns isLoading, isError, error, isSuccess, data, refetch and isFetching
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    data,
    mutate: validateReferralCodeApi,
  } = useMutation({
    mutationFn: referralCodeInput => apiReferralValidation(referralCodeInput),
  });
  // validateReferral(referralCode);

  useEffect(() => {
    if (canShowReferral && selectedAccount.accountTypeId === 0 && isFocused) {
      logReferralApplicable(pageSource);
    }
  }, [canShowReferral, selectedAccount.accountTypeId, pageSource, isFocused]);

  // Set loading based on isLoading and isFetching
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  // Handle server error and display appropriate message
  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData, referralCode, pageSource);
  }, [isError, error]);

  // Handle successful validation of referral code
  useEffect(() => {
    shouldShowToastOnSuccess({
      isSuccess,
      data,
      toast,
      setReferralValidated,
      setValidatedReferralCode,
      referralCode,
      pageSource,
      t,
    });
  }, [isSuccess, data]);

  useEffect(() => {
    // Read deeplink code from Zustand and appl
    if (canShowReferral && deepLinkData && deepLinkData !== validatedReferralCode) {
      setReferralCode(deepLinkData);
      validateReferralCodeApi(deepLinkData);
      setDeepLinkData('');
    }
  }, [canShowReferral, deepLinkData, validatedReferralCode]);

  if (isMeLoading) {
    return <ShimmerReferralInput />;
  }

  // If canShowReferral is false, render the referral input section
  if (canShowReferral && selectedAccount.accountTypeId === 0) {
    return (
      <View style={styles.referralParent}>
        <View flexDirection="row" alignItems="center">
          <Text testID="txtReferralHeader" variant="md-bold" color={colors.neutral.darkMist} marginRight="5px">
            {t('referral.referralHeader')}
          </Text>
          <ControlledTooltip
            translateString="referral.toolTip"
            logParams={{
              source: pageSource,
              referral_code: referralCode,
            }}>
            <Icon name="questioncircle" type="antdesign" size={scale(16)} color={colors.neutral.darkMist} />
          </ControlledTooltip>
        </View>
        <View style={styles.referralInputContainer}>
          {shouldShowValidatedReferralInput(t, referralValidated, setReferralCode, validatedReferralCode, pageSource)}
          {shouldShowValidatedButton(referralValidated, referralCode, validateReferralCodeApi, pageSource)}
        </View>
      </View>
    );
  }
  // If canShowReferral is true, don't render anything
  return null;
}

ReferralInput.propTypes = {
  isLoading: PropTypes.bool,
  selectedAccount: PropTypes.shape({
    accountTypeId: PropTypes.number,
  }),
  pageSource: PropTypes.string.isRequired,
};

ReferralInput.defaultProps = {
  isLoading: false,
  selectedAccount: {
    accountTypeId: 0,
  },
};

// Export the ReferralInput component as the default export of this module
export default ReferralInput;
