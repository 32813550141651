import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  infoContainer: {
    alignItems: 'center',

    // marginHorizontal: 16,
    marginTop: scale(12),
  },
  infoContainerTwo: { alignItems: 'center', borderRadius: scale(8), marginTop: scale(8), paddingHorizontal: 0 },
});
export default styles;
