export const calculateTotalPoints = data => {
  let totalPoints = 0;

  data.employeesReferred.forEach(employee => {
    if (employee.tnxPoint !== null) {
      totalPoints += employee.tnxPoint;
    }

    if (employee.loginPoint !== null) {
      totalPoints += employee.loginPoint;
    }
  });

  return totalPoints;
};

export const searchEmployees = (employees, searchTerm) => {
  const normalizedSearchTerm = searchTerm.toLowerCase();
  const searchTermsArray = normalizedSearchTerm.split(' ');

  return employees.filter(employee => {
    const fullName = employee.employeeName.toLowerCase();
    const hasValidTnxPoint = employee.tnxPoint !== null;
    const hasValidLoginPoint = employee.loginPoint !== null;
    const hasCombinedShowLoginPoint = employee.showLoginPoint && (hasValidTnxPoint || hasValidLoginPoint);

    return (
      searchTermsArray.every(term => fullName.includes(term)) &&
      (hasCombinedShowLoginPoint || (hasValidTnxPoint && !employee.showLoginPoint)) &&
      fullName.trim() !== ''
    );
  });
};

export function showWhatsappShareForPic(isStausAjakanVar, item) {
  if (isStausAjakanVar) {
    if (item.tnxPoint !== null && item.tnxPoint > 0 && item.loginPoint !== null && item.loginPoint > 0) return false;

    if (item.tnxPoint !== null && item?.tnxPoint > 0 && item.showLoginPoint === false) return false;

    if (item.showLoginPoint && item.tnxPoint === null) return false;
  }
  return true;
}

export function getSelectedNames(departmentName, designationName, selectedDepartmentName, selectedDesignationName) {
  if (!departmentName && !designationName) {
    return selectedDepartmentName && selectedDesignationName;
  }
  if (!departmentName) {
    return selectedDepartmentName;
  }
  if (!designationName) {
    return selectedDesignationName;
  }

  return false;
}

export function formatDepartmentAndDesignation(department, designation) {
  const formattedDepartment = department || '';
  const formattedDesignation = designation || '';

  return `${formattedDepartment}${formattedDepartment && formattedDesignation ? ' - ' : ''}${formattedDesignation}`;
}
