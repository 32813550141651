import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const statusRowStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.neutral.mist,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: scale(12),
    marginHorizontal: scale(-16),
    paddingHorizontal: scale(19),
    paddingVertical: scale(4),
  },
  title: {
    color: colors.neutral.darkMist,
  },
  value: {
    color: colors.neutral.darkMist,
  },
});

export default statusRowStyles;
