import { fetchProfile } from '@api/useQueryCalls';
import ProfileAppVersion from '@atoms/ProfileAppVersion';
import ShimmerProfile from '@atoms/Shimmers/ShimmerProfile';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import Header from '@molecules/Header';
import ProfileBankDetails from '@molecules/ProfileBankDetails';
import ProfileBonus from '@molecules/ProfileBonus';
import ProfileHeader from '@molecules/ProfileHeader';
import ProfileInformation from '@molecules/ProfileInformation';
import ProfileLogoutButton from '@molecules/ProfileLogoutButton';
import ProfileReferral from '@molecules/ProfileReferral';
import ProfileSecurityPin from '@molecules/ProfileSecurityPin';
import { useIsFocused } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { FEATURE_FLAGS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { ScrollView } from 'native-base';
import React, { useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';
import logProfileTab from './analyticsProfieltab';

function ProfileTab() {
  const isFocused = useIsFocused();
  const { setDefaultBank, defaultBank, employeeProfile } = getStore();
  const [promotionBanner, setPromotionBanner] = useState([]);
  // const [defaultEmployeeBank, setDefaultEmployeeBank] = useState(null);
  const [isFeatureAvailale, getFeatureFlags] = useFeatureFlag({
    featureName: [
      FEATURE_FLAGS.GOLD_SAVINGS,
      FEATURE_FLAGS.MUL_BANK_ACC,
      FEATURE_FLAGS.BANK_SEL_TRNSF,
      FEATURE_FLAGS.PHONE_ENROLL,
      FEATURE_FLAGS.SALARY_INFO,
      FEATURE_FLAGS.SECURITY_PIN,
      FEATURE_FLAGS.PIC_REFERRAL,
    ],
  });

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  useEffect(() => {
    if (isFocused) {
      getFeatureFlags();
    }
  }, [isFocused]);

  useEffect(() => {
    if (isFocused) logProfileTab(isPIC);
  }, [isFocused, isPIC]);

  const { isLoading, isError, error, data, isSuccess, refetch } = fetchProfile();

  useEffect(() => {}, [isError, error]);

  useEffect(() => {
    if (isSuccess && data) {
      setDefaultBank(data.account);
      setPromotionBanner(data.bannerSection);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isFocused) refetch();
  }, [isFocused]);

  return (
    <SafeAreaView style={appStyles.tabParent}>
      <Header />
      {isLoading ? (
        <ShimmerProfile />
      ) : (
        <ScrollView>
          <ProfileHeader isPICReferral={isFeatureAvailale[FEATURE_FLAGS.PIC_REFERRAL]?.show} />
          {isFeatureAvailale[FEATURE_FLAGS.MUL_BANK_ACC]?.show ? (
            <ProfileBankDetails
              showBankDetails={isFeatureAvailale[FEATURE_FLAGS.MUL_BANK_ACC]?.show}
              defaultBank={defaultBank}
            />
          ) : null}
          {isFeatureAvailale[FEATURE_FLAGS.PIC_REFERRAL]?.show ? (
            <ProfileBonus showBonus={isFeatureAvailale[FEATURE_FLAGS.PIC_REFERRAL]?.show} />
          ) : null}
          <ProfileInformation
            showPhoneEnroll={isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL]?.show}
            showSalaryInfo={isFeatureAvailale[FEATURE_FLAGS.SALARY_INFO]?.show}
          />
          {isFeatureAvailale[FEATURE_FLAGS.SECURITY_PIN]?.show ? (
            <ProfileSecurityPin showSecurityPin={isFeatureAvailale[FEATURE_FLAGS.SECURITY_PIN]?.show} />
          ) : null}
          <ProfileReferral promotionData={promotionBanner?.banners} headerText={promotionBanner?.header} />
          {/* <ProfileWhatsapp isPIC={isPIC} /> */}
          <ProfileLogoutButton />
          <ProfileAppVersion />
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

export default ProfileTab;
