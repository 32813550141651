import MICon from '@expo/vector-icons/MaterialIcons';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable } from 'react-native';
import styles from './style';

const LihatPICGateway = ({ show, title, onLihatBenifitHandler }) => {
  if (show) {
    return (
      <Pressable onPress={onLihatBenifitHandler} style={styles.lihatButtonContainer} testID="lihat-button">
        <Text variant="sm-bold" style={styles.lihatTextStyle}>
          {title}
        </Text>
        <MICon name="arrow-forward" style={styles.iconStyle} />
      </Pressable>
    );
  }
  return null;
};

LihatPICGateway.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onLihatBenifitHandler: PropTypes.func,
};

LihatPICGateway.defaultProps = {
  show: false,
  title: '',
  onLihatBenifitHandler: () => {},
};

export default LihatPICGateway;
