import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  arrowIconStyles: { marginLeft: 4, paddingRight: 10.5 },
  buttonStyle: {
    borderRadius: scale(12),
    paddingBottom: scale(4.5),
    paddingHorizontal: scale(4),
    paddingTop: scale(3.5),
  },
  coinContainer: {
    alignItems: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: scale(15),
    flexDirection: 'row',
    marginRight: scale(12),
    paddingHorizontal: scale(8),
    paddingVertical: scale(5),
  },
  textStyle: { marginLeft: scale(4) },
});
export default styles;
