import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonContainer: {
    width: '100%',
  },
  headerDescription: {
    marginTop: 5,
  },
  headerTitle: {
    marginVertical: 10,
  },
  item: {
    marginStart: 10,
  },
  lastUpdate: {
    marginBottom: 15,
  },
  mainContainer: {
    alignItems: 'center',
    borderColor: colors.gainsboro,
    borderRadius: 8,
    borderWidth: 0.7,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 10,
  },
  pressableStyle: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  safeAreaParent: { backgroundColor: colors.neutral.cotton, flex: 1 },
  sectionList: {
    // paddingBottom: Dimensions.get('screen').width / 3,
    // flex: 1,
  },
  textContainer: {
    marginBottom: 5,
    marginHorizontal: 15,
    paddingTop: 15,
    width: '90%',
  },
  textStyle: {
    color: colors.black,
  },
  title: {
    marginBottom: 10,
  },
  tncItem: { marginVertical: 5 },
});

export default styles;
