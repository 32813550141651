import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const bulletRowStyle = StyleSheet.create({
  bulletStyle: {
    color: colors.neutral.charcoal,
    lineHeight: scale(13),
    marginStart: scale(5),
  },
  bulletStyleTwo: {
    color: colors.neutral.charcoal,
    lineHeight: scale(13),
    marginStart: scale(5),
  },
  outerRow: {
    flexDirection: 'row',
    marginTop: scale(8),
    paddingHorizontal: scale(16),
  },
});

export default bulletRowStyle;
