import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  addPhoneParent: { marginHorizontal: 16, marginTop: 24 },
  infoContainer: { flex: 1 },
  infoParent: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(8),
    flexDirection: 'row',
    marginTop: 30,
    paddingHorizontal: scale(12),
    paddingVertical: scale(8),
  },
  nameTextStyles: { color: colors.neutral.darkMist, marginBottom: 4, marginTop: 16 },
  phoneTextStyles: { color: colors.neutral.darkMist, marginBottom: 4 },
});
export default styles;
