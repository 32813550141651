import { fetchLoanDetails, updateUserNotified } from '@api/useQueryCalls';
import useBeforeUnload from '@customHooks/useBeforeUnload';
import BottomSheet from '@molecules/LoginInfoSheet';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { returnBottomSheetContent } from '@utils/utils';
import { useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { onShowModalHandler } from './ggLoanUtils';

export function onPrimaryLoanPressHandler(
  setInfoModalSheet,
  showInfoModalSheet,
  navigation,
  updateLoanStatus,
  loanData,
) {
  return () => {
    setInfoModalSheet(prevState => ({ ...prevState, visible: false, type: null }));
    if (showInfoModalSheet.data?.hasTwoButtons) return navigation.goBack();
    return updateLoanStatus({ applicationId: loanData.id });
  };
}

export function onCloseLoanPressHandler(setInfoModalSheet, navigation) {
  return () => {
    setInfoModalSheet(prevState => ({ ...prevState, visible: false, type: null }));
    navigation.goBack();
  };
}

export function onPressSecondaryLoanHandler(setInfoModalSheet, updateLoanStatus, loanData) {
  return () => {
    setInfoModalSheet(prevState => ({ ...prevState, visible: false, type: null }));
    updateLoanStatus({ applicationId: loanData.id });
  };
}

export function handleUnload(navigation) {
  return () => navigation.goBack();
}

function GGLoan() {
  const { setLoading, setInitialData } = getStore();
  const navigation = useNavigation();
  const route = useRoute();
  const { t } = useTranslation('common');
  const toast = useToast('common');

  const [showInfoModalSheet, setInfoModalSheet] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.RESEND_OTP],
      6282114299848,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
  });

  const {
    isLoading: loadingLoanDetails,
    isError: isLoanDetailsError,
    refetch: refetchLoanDetails,
    error: loanError,
    data: loanData,
  } = fetchLoanDetails(onShowModalHandler(setInfoModalSheet, route?.params?.source, navigation));

  const {
    isLoading: loadingUserNotified,
    isError: isUserNotifiedError,
    error: userNotifiedError,
    mutate: updateLoanStatus,
  } = updateUserNotified(() => {
    refetchLoanDetails();
  });

  useEffect(() => {
    refetchLoanDetails();
  }, []);

  useEffect(() => {
    if (isLoanDetailsError) navigation.goBack();
    shouldShowError(isLoanDetailsError, loanError, toast, t, navigation, setInitialData);
  }, [isLoanDetailsError, loanError]);

  useEffect(() => {
    if (isUserNotifiedError) navigation.goBack();
    shouldShowError(isUserNotifiedError, userNotifiedError, toast, t, navigation, setInitialData);
  }, [isUserNotifiedError, userNotifiedError]);

  useEffect(() => {
    setLoading(loadingLoanDetails || loadingUserNotified);
  }, [loadingLoanDetails, loadingUserNotified]);

  useBeforeUnload(handleUnload(navigation));

  useEffect(() => {
    // Since the  screen is transluencent card lay
    navigation.setOptions({
      headerShown: false,
      cardStyle: { backgroundColor: 'transparent' },
      cardOverlayEnabled: true,
    });
  }, []);

  return (
    <BottomSheet
      loginInfo={showInfoModalSheet}
      onPressPrimary={onPrimaryLoanPressHandler(
        setInfoModalSheet,
        showInfoModalSheet,
        navigation,
        updateLoanStatus,
        loanData,
      )}
      onClose={onCloseLoanPressHandler(setInfoModalSheet, navigation)}
      onPressSecondary={onPressSecondaryLoanHandler(setInfoModalSheet, updateLoanStatus, loanData)}
    />
  );
}

GGLoan.propTypes = {};
GGLoan.defaultProps = {};

export default GGLoan;
