import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { formatUnderScoreLowerCase, getAmplitudeKeyValue } from '@utils/utils';

export function logAccountSelected(source, accountDetails, meAmplitudeContext) {
  const e = AMPLITUDE_CONSTANTS.account_drop_down_selected;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: source,
    [e.attributes.dynamic_account_name]: formatUnderScoreLowerCase(accountDetails?.amplitudeAccountName),
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}

export function logAccountClick(source, selectedAccount, meAmplitudeContext) {
  const e = AMPLITUDE_CONSTANTS.account_drop_down_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: source,
    [e.attributes.dynamic_account_name]: formatUnderScoreLowerCase(selectedAccount?.amplitudeAccountName),
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}
