import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  dashedBorder: {
    borderColor: colors.neutral.darkMist,
    borderRadius: 12,
    borderStyle: 'dashed',
    borderWidth: 1.5,
    marginTop: 12,
  },
});
export default styles;
