import InfoTextComponent from '@atoms/InfoTextComponent';
import Row from '@atoms/SalaryInfo/Row';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Pressable } from 'react-native';
import Collapsible from 'react-native-collapsible';
import styles from './style';

const AbsoluteCard = ({
  isCollapsed,
  handleColllapsePress,
  ewaBalance,
  totalAccuredSalary,
  workingDays,
  daySalary,
  totalExpense,
  handleLimitPress,
  t,
}) => (
  <View style={styles.absoluteContainer}>
    <View style={styles.topCotainerRow}>
      <MaterialCommunityIcons name="wallet" size={scale(15)} color="black" style={styles.iconStyle} />
      <Text variant="md-bold">{t('salaryInfo.textOne')}</Text>
    </View>

    <View style={styles.textContainer}>
      <Text variant="xxl-bold" style={styles.ewaBalance}>
        {ewaBalance}
      </Text>
    </View>

    <Pressable onPress={handleLimitPress} style={styles.limitRowStyle}>
      <Text variant="sm-normal" style={styles.limitTextStyle}>
        {t('salaryInfo.textTwo')}
      </Text>
      <View style={styles.collapsibleSubValueRow}>
        <MaterialIcons name="keyboard-arrow-right" size={scale(15)} color={colors.black} />
      </View>
    </Pressable>

    <InfoTextComponent
      iconContainer={styles.infoAdj}
      iconName="info-outline"
      infoText={t('salaryInfo.textThree')}
      customTextStyle={styles.infoTextContainer}
      size={scale(24)}
      containerStyle={appStyles.paddingLeft(8)}
      iconColor={colors.secondary.darkOrchid}
      variant="xsm-bold"
      show
    />
    <Pressable style={styles.collapsibleContainer} onPress={handleColllapsePress}>
      <Text variant="sm-bold">{t('salaryInfo.textFour')}</Text>

      <View style={styles.collapsibleSubValueRow}>
        <Text variant="sm-normal">{totalAccuredSalary}</Text>
        <MaterialIcons name={isCollapsed ? 'keyboard-arrow-down' : 'keyboard-arrow-up'} size={scale(20)} />
      </View>
    </Pressable>
    <Collapsible collapsed={isCollapsed}>
      <Row title={t('salaryInfo.harja')} value={`${workingDays} Hari`} />
      <Row title={t('salaryInfo.xGaji')} value={daySalary} containerStyle={styles.rowAdj} />
    </Collapsible>

    <View style={styles.totalExpsenseRowContainer}>
      <Text variant="sm-bold" style={styles.totalExpenseText}>
        {t('salaryInfo.textFive')}
      </Text>
      <Text variant="sm-normal" style={styles.totalExpenseValue}>
        {totalExpense}
      </Text>
    </View>
  </View>
);

AbsoluteCard.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  handleColllapsePress: PropTypes.func.isRequired,
  ewaBalance: PropTypes.string.isRequired,
  totalAccuredSalary: PropTypes.string.isRequired,
  workingDays: PropTypes.number.isRequired,
  daySalary: PropTypes.string.isRequired,
  totalExpense: PropTypes.string.isRequired,
  handleLimitPress: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AbsoluteCard;
