import { analyticsTimeStampFormat } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getNowInFormat } from '@utils/dateUtils';
import { getAmplitudeKeyValue } from '@utils/utils';

export function logTxConfirmation(
  productConfig,
  bpReqData,
  source,
  validatedReferralCode,
  selectedBPAccount,
  meAmplitudeContext,
) {
  const e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
  const { name, attributes } = e;

  AmplitudeHelper.logEvent(name, {
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
    [attributes.product_price]: bpReqData?.subtotal,
    [attributes.reason]: productConfig?.reason,
    [attributes.reason_id]: productConfig?.reasonId,
    [attributes.fee_charged]: bpReqData?.fee,
    [attributes.source]: source,
    [attributes.product_code]: bpReqData?.productCode,
    [attributes.referral_code]: validatedReferralCode,
    [attributes.payment_method]: selectedBPAccount.amplitudeAccountName,
    ...getAmplitudeKeyValue(name, meAmplitudeContext),
  });
}

export function logTermsPage(productConfig, source, validatedReferralCode) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.termsPage;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.source]: source,
    [attributes.referral_code]: validatedReferralCode,
    [attributes.timestamp]: getNowInFormat(analyticsTimeStampFormat),
    [attributes.transaction_category_id]: productConfig?.transaction_category_id,
  });
}
