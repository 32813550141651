import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 40,
    marginTop: 10,
    paddingHorizontal: 10,
    width: '95%',
  },
  closeButton: {
    alignSelf: 'flex-end',
    padding: 15,
  },
  cta: {
    alignSelf: 'center',
    borderRadius: 30,
    marginVertical: 15,
    width: '95%',
  },
  icon: { alignSelf: 'center' },
  innerContainer: {
    backgroundColor: colors.neutral.cotton,
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: 5,
    width: '100%',
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  textContainer: {
    alignSelf: 'center',
    marginTop: 8,
    width: '95%',
  },
  title: { marginBottom: 10 },
});

export default styles;
