import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const Item = ({ icon, title, t }) => (
  <View style={styles.itemContainer}>
    <View>{icon}</View>
    <Text variant="xsm-normal" style={styles.itemStyle}>
      {t(`salaryInfo.${title}`)}
    </Text>
  </View>
);

const ItemPropTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

Item.propTypes = ItemPropTypes;

export default Item;
