import SkeletonComponent from '@atoms/SkeletonComponent';

import React from 'react';
import { View } from 'react-native';

function ShimmerProfile() {
  return (
    <View>
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
      <SkeletonComponent />
    </View>
  );
}

ShimmerProfile.propTypes = {};
ShimmerProfile.defaultProps = {};
export default ShimmerProfile;
