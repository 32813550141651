import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mobileNumberWithTick: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  tooltipBannerContainerStyle: {
    backgroundColor: colors.neutral.mist,
    //   marginHorizontal: scale(15),
    marginTop: scale(11),
  },
  tooltipBannerIconContainerStyle: { justifyContent: 'center' },
  tooltipBannerTextStyle: { flex: 1, paddingLeft: scale(10) },
});

export default styles;
