import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  lowerBorderStyle: {
    alignSelf: 'center',
    borderColor: colors.neutral.mist,
    borderWidth: 1,
    width: '80%',
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: scale(16),
    paddingVertical: scale(16),
  },
  textStyle: {
    color: colors.neutral.charcoal,
    textAlign: 'right',
    width: '50%',
  },
  titleStyle: {
    color: colors.neutral.charcoal,
  },
});

export default styles;
