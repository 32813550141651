import SkeletonComponent from '@atoms/SkeletonComponent';
import appStyles from '@root/appStyles';
import React from 'react';
import { View } from 'react-native';

function ShimmerSingleColumnNominal() {
  return (
    <View>
      <SkeletonComponent style={appStyles.flex1} />
      <SkeletonComponent style={appStyles.flex1} />
      <SkeletonComponent style={appStyles.flex1} />
      <SkeletonComponent style={appStyles.flex1} />
    </View>
  );
}

ShimmerSingleColumnNominal.propTypes = {};
ShimmerSingleColumnNominal.defaultProps = {};
export default ShimmerSingleColumnNominal;
