import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bannerStyle: {
    borderRadius: scale(12),
    height: scale(120),
    marginBottom: scale(4),
    marginHorizontal: scale(16),
    overflow: 'hidden',
  },
});

export default styles;
