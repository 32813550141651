import AccountCard from '@atoms/AccountCard';
import ShimmerAccountList from '@atoms/Shimmers/ShimmerAccountList';
import SkeletonComponent from '@atoms/SkeletonComponent';
import { getStore } from '@store/storeUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { FlatList } from 'react-native';
import styles from './styles';

function AccountList(props) {
  const { accounts } = getStore();
  const { isLoading } = props;
  if (isLoading) {
    return <ShimmerAccountList />;
  }
  if (accounts && accounts.length > 0) {
    return (
      <FlatList
        testID="LstAccounts"
        sentry-label="LstAccounts"
        contentContainerStyle={styles.flatListParent}
        data={accounts}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => <AccountCard account={item} />}
      />
    );
  }
  return <SkeletonComponent />;
}

AccountList.propTypes = {
  isLoading: PropTypes.bool,
};
AccountList.defaultProps = {
  isLoading: false,
};

export default AccountList;
