import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  avatarContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: scale(16),
    height: scale(32),
    justifyContent: 'center',
    marginRight: scale(9),
    width: scale(32),
  },
  closeIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: scale(7),
  },
  container: {
    alignSelf: 'center',
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(25),
    bottom: 26,
    elevation: 5,
    justifyContent: 'center',
    marginLeft: scale(16),
    paddingHorizontal: scale(12),
    paddingVertical: scale(9),
    position: 'absolute',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  imageStyle: { height: '95%', width: '95%' },
  textStyle: { textDecorationLine: 'underline' },
});

export default styles;
