import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  accountAmount: { alignSelf: 'stretch' },
  accountCardContainer: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: 11,
    flex: 1,
    marginRight: 10,
    paddingLeft: 8,
    paddingRight: 8,
  },
  iconAndAccountName: { alignItems: 'center', flexDirection: 'row', marginRight: 8, marginTop: 8 },
  iconStyles: { marginRight: 4 },
});
export default styles;
