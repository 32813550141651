import SkeletonComponent from '@atoms/SkeletonComponent';
import PhoneEmptyView from '@molecules/PhoneEmptyView';
import PhoneList from '@molecules/PhoneList';
import PropTypes from 'prop-types';
import React from 'react';

function PhoneListOrEmptyView(props) {
  const { loading, phoneList, getPhoneNumbers, isTransact, transactionCategoryId } = props;
  if (loading) {
    return <SkeletonComponent />;
  }
  if (phoneList && phoneList.length === 0) {
    return <PhoneEmptyView isTransact={isTransact} transactionCategoryId={transactionCategoryId} />;
  }
  if (phoneList && phoneList.length > 0) {
    return (
      <PhoneList
        phoneList={phoneList}
        getPhoneNumbers={getPhoneNumbers}
        isTransact={isTransact}
        transactionCategoryId={transactionCategoryId}
      />
    );
  }
}

PhoneListOrEmptyView.propTypes = {
  loading: PropTypes.bool,
  phoneList: PropTypes.arrayOf(PropTypes.shape({})),
  getPhoneNumbers: PropTypes.func,
  isTransact: PropTypes.bool,
  transactionCategoryId: PropTypes.string,
};
PhoneListOrEmptyView.defaultProps = {
  loading: false,
  phoneList: [{}],
  getPhoneNumbers: () => {},
  isTransact: false,
  transactionCategoryId: '',
};
export default PhoneListOrEmptyView;
