const subTitle01 = 'subTitle01';
const subTitle02 = 'subTitle02';
const subTitle03 = 'subTitle03';
const subTitle04 = 'subTitle04';
const subTitle05 = 'subTitle05';
const subTitle06 = 'subTitle06';
const subTitle07 = 'subTitle07';
const subTitle08 = 'subTitle08';
const subTitle09 = 'subTitle09';
const subTitle10 = 'subTitle10';
const subTitle11 = 'subTitle11';
const subTitle12 = 'subTitle12';
const subTitle13 = 'subTitle13';
const subTitle14 = 'subTitle14';

const dataSubTitle1Data0Text = 'dataSubTitle1Data0Text';
const dataSubTitle1List0 = 'dataSubTitle1List0';
const dataSubTitle1List1 = 'dataSubTitle1List1';
const dataSubTitle1List2 = 'dataSubTitle1List2';
const dataSubTitle1List3 = 'dataSubTitle1List3';
const dataSubTitle1List4 = 'dataSubTitle1List4';
const dataSubTitle1List5 = 'dataSubTitle1List5';

const dataSubTitle1Data0 = {
  text: dataSubTitle1Data0Text,
  list: [
    dataSubTitle1List0,
    dataSubTitle1List1,
    dataSubTitle1List2,
    dataSubTitle1List3,
    dataSubTitle1List4,
    dataSubTitle1List5,
  ],
};

const dataSubTitle1Data1Text = 'dataSubTitle1Data1Text';
const dataSubTitle1Data2Text = 'dataSubTitle1Data2Text';
const dataSubTitle1Data3Text = 'dataSubTitle1Data3Text';

const dataSubTitle1Data1 = {
  text: dataSubTitle1Data1Text,
};
const dataSubTitle1Data2 = {
  text: dataSubTitle1Data2Text,
};
const dataSubTitle1Data3 = {
  text: dataSubTitle1Data3Text,
};

const dataSubTitle2FooterDescription = 'dataSubTitle2FooterDescription';
const dataSubTitle2Data0Text = 'dataSubTitle2Data0Text';
const dataSubTitle2Data1Text = 'dataSubTitle2Data1Text';
const dataSubTitle2Data2Text = 'dataSubTitle2Data2Text';
const dataSubTitle2Data3Text = 'dataSubTitle2Data3Text';
const dataSubTitle2Data4Text = 'dataSubTitle2Data4Text';
const dataSubTitle2Data5Text = 'dataSubTitle2Data5Text';
const dataSubTitle2Data6Text = 'dataSubTitle2Data6Text';

const dataSubTitle2Data6List0 = 'dataSubTitle2Data6List0';
const dataSubTitle2Data6List1 = 'dataSubTitle2Data6List1';
const dataSubTitle2Data6List2 = 'dataSubTitle2Data6List2';
const dataSubTitle2Data6List3 = 'dataSubTitle2Data6List3';
const dataSubTitle2Data6List4 = 'dataSubTitle2Data6List4';
const dataSubTitle2Data6List5 = 'dataSubTitle2Data6List5';
const dataSubTitle2Data6List6 = 'dataSubTitle2Data6List6';
const dataSubTitle2Data6List7 = 'dataSubTitle2Data6List7';

const dataSubTitle3Data0Text = 'dataSubTitle3Data0Text';
const dataSubTitle3Data1Text = 'dataSubTitle3Data1Text';
const dataSubTitle3Data2Text = 'dataSubTitle3Data2Text';
const dataSubTitle4Data0Text = 'dataSubTitle4Data0Text';
const dataSubTitle4Data1Text = 'dataSubTitle4Data1Text';
const dataSubTitle4Data2Text = 'dataSubTitle4Data2Text';
const dataSubTitle5Data0Text = 'dataSubTitle5Data0Text';
const dataSubTitle5Data1Text = 'dataSubTitle5Data1Text';
const dataSubTitle5Data2Text = 'dataSubTitle5Data2Text';
const dataSubTitle5Data3Text = 'dataSubTitle5Data3Text';
const dataSubTitle6Data0Text = 'dataSubTitle6Data0Text';
const dataSubTitle6Data1Text = 'dataSubTitle6Data1Text';
const dataSubTitle7Data0Text = 'dataSubTitle7Data0Text';
const dataSubTitle7Data1Text = 'dataSubTitle7Data1Text';
const dataSubTitle7Data2Text = 'dataSubTitle7Data2Text';
const dataSubTitle8Data0Text = 'dataSubTitle8Data0Text';
const dataSubTitle8Data1Text = 'dataSubTitle8Data1Text';
const dataSubTitle8Data2Text = 'dataSubTitle8Data2Text';
const dataSubTitle9Data0Text = 'dataSubTitle9Data0Text';
const dataSubTitle9Data1Text = 'dataSubTitle9Data1Text';
const dataSubTitle9Data2Text = 'dataSubTitle9Data2Text';
const dataSubTitle9Data3Text = 'dataSubTitle9Data3Text';
const dataSubTitle9Data4Text = 'dataSubTitle9Data4Text';
const dataSubTitle9Data1List0 = 'dataSubTitle9Data1List0';
const dataSubTitle9Data1List1 = 'dataSubTitle9Data1List1';
const dataSubTitle9Data1List2 = 'dataSubTitle9Data1List2';
const dataSubTitle9Data1List3 = 'dataSubTitle9Data1List3';
const dataSubTitle9Data1List4 = 'dataSubTitle9Data1List4';
const dataSubTitle9Data1List5 = 'dataSubTitle9Data1List5';
const dataSubTitle9Data1List6 = 'dataSubTitle9Data1List6';
const dataSubTitle10Data0Text = 'dataSubTitle10Data0Text';
const dataSubTitle10Data1Text = 'dataSubTitle10Data1Text';
const dataSubTitle10Data2Text = 'dataSubTitle10Data2Text';
const dataSubTitle10Data3Text = 'dataSubTitle10Data3Text';
const dataSubTitle10Data4Text = 'dataSubTitle10Data4Text';
const dataSubTitle11FooterText = 'dataSubTitle11FooterText';
const dataSubTitle12FooterText = 'dataSubTitle12FooterText';
const dataSubTitle13Data0Text = 'dataSubTitle13Data0Text';
const dataSubTitle13Data1Text = 'dataSubTitle13Data1Text';
const dataSubTitle13Data2Text = 'dataSubTitle13Data2Text';
const dataSubTitle14Data0Text = 'dataSubTitle14Data0Text';
const dataSubTitle14Data1Text = 'dataSubTitle14Data1Text';

const dataSubTitle1 = {
  title: {
    subTitle: subTitle01,
  },
  data: [dataSubTitle1Data0, dataSubTitle1Data1, dataSubTitle1Data2, dataSubTitle1Data3],
};

const dataSubTitle2 = {
  title: {
    subTitle: subTitle02,
    footerDescription: dataSubTitle2FooterDescription,
  },
  data: [
    {
      text: dataSubTitle2Data0Text,
    },
    {
      text: dataSubTitle2Data1Text,
    },
    {
      text: dataSubTitle2Data2Text,
    },
    {
      text: dataSubTitle2Data3Text,
    },
    {
      text: dataSubTitle2Data4Text,
    },
    {
      text: dataSubTitle2Data5Text,
    },
    {
      text: dataSubTitle2Data6Text,
      list: [
        dataSubTitle2Data6List0,
        dataSubTitle2Data6List1,
        dataSubTitle2Data6List2,
        dataSubTitle2Data6List3,
        dataSubTitle2Data6List4,
        dataSubTitle2Data6List5,
        dataSubTitle2Data6List6,
        dataSubTitle2Data6List7,
      ],
    },
  ],
};

const dataSubTitle3 = {
  title: {
    subTitle: subTitle03,
  },
  data: [
    {
      text: dataSubTitle3Data0Text,
    },
    {
      text: dataSubTitle3Data1Text,
    },
    {
      text: dataSubTitle3Data2Text,
    },
  ],
};

const dataSubTitle4 = {
  title: {
    subTitle: subTitle04,
  },
  data: [
    {
      text: dataSubTitle4Data0Text,
    },
    {
      text: dataSubTitle4Data1Text,
    },
    {
      text: dataSubTitle4Data2Text,
    },
  ],
};

const dataSubTitle5 = {
  title: {
    subTitle: subTitle05,
  },
  data: [
    {
      text: dataSubTitle5Data0Text,
    },
    {
      text: dataSubTitle5Data1Text,
    },
    {
      text: dataSubTitle5Data2Text,
    },
    {
      text: dataSubTitle5Data3Text,
    },
  ],
};

const dataSubTitle6 = {
  title: {
    subTitle: subTitle06,
  },
  data: [
    {
      text: dataSubTitle6Data0Text,
    },
    {
      text: dataSubTitle6Data1Text,
    },
  ],
};

const dataSubTitle7 = {
  title: {
    subTitle: subTitle07,
  },
  data: [
    {
      text: dataSubTitle7Data0Text,
    },
    {
      text: dataSubTitle7Data1Text,
    },
    {
      text: dataSubTitle7Data2Text,
    },
  ],
};

const dataSubTitle8 = {
  title: {
    subTitle: subTitle08,
  },
  data: [
    {
      text: dataSubTitle8Data0Text,
    },
    {
      text: dataSubTitle8Data1Text,
    },
    {
      text: dataSubTitle8Data2Text,
    },
  ],
};

const dataSubTitle9 = {
  title: {
    subTitle: subTitle09,
  },
  data: [
    {
      text: dataSubTitle9Data0Text,
    },
    {
      text: dataSubTitle9Data1Text,
      list: [
        dataSubTitle9Data1List0,
        dataSubTitle9Data1List1,
        dataSubTitle9Data1List2,
        dataSubTitle9Data1List3,
        dataSubTitle9Data1List4,
        dataSubTitle9Data1List5,
        dataSubTitle9Data1List6,
      ],
    },
    {
      text: dataSubTitle9Data2Text,
    },
    {
      text: dataSubTitle9Data3Text,
    },
    {
      text: dataSubTitle9Data4Text,
    },
  ],
};

const dataSubTitle10 = {
  title: {
    subTitle: subTitle10,
  },
  data: [
    {
      text: dataSubTitle10Data0Text,
    },
    {
      text: dataSubTitle10Data1Text,
    },
    {
      text: dataSubTitle10Data2Text,
    },
    {
      text: dataSubTitle10Data3Text,
    },
    {
      text: dataSubTitle10Data4Text,
    },
  ],
};

const dataSubTitle11 = {
  title: {
    subTitle: subTitle11,
  },
  data: [{ text: dataSubTitle11FooterText }],
};

const dataSubTitle12 = {
  title: {
    subTitle: subTitle12,
  },
  data: [{ text: dataSubTitle12FooterText }],
};

const dataSubTitle13 = {
  title: {
    subTitle: subTitle13,
  },
  data: [
    {
      text: dataSubTitle13Data0Text,
    },
    {
      text: dataSubTitle13Data1Text,
    },
    {
      text: dataSubTitle13Data2Text,
    },
  ],
};

const dataSubTitle14 = {
  title: {
    subTitle: subTitle14,
  },
  data: [
    {
      text: dataSubTitle14Data0Text,
    },
    {
      text: dataSubTitle14Data1Text,
    },
  ],
};

const tncDataDescription = 'tncDataDescription';

const tncData = {
  lastupdatedDate: '26 Oct 2022',
  description: tncDataDescription,
  data: [
    dataSubTitle1,
    dataSubTitle2,
    dataSubTitle3,
    dataSubTitle4,
    dataSubTitle5,
    dataSubTitle6,
    dataSubTitle7,
    dataSubTitle8,
    dataSubTitle9,
    dataSubTitle10,
    dataSubTitle11,
    dataSubTitle12,
    dataSubTitle13,
    dataSubTitle14,
  ],
};

export default tncData;
