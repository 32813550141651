import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Linking, Pressable } from 'react-native';
import styles from './style';

function VoucherCode(props) {
  const { redeemUrl } = props;

  if (redeemUrl)
    return (
      <Pressable testID="voucherCodeBtn" onPress={() => Linking.openURL(redeemUrl)} style={styles.container}>
        <Text variant="sm-bold">{redeemUrl}</Text>
        <MaterialIcons name="arrow-forward" size={scale(24)} color={colors.secondary.orchid} />
      </Pressable>
    );
  return null;
}

VoucherCode.propTypes = {
  redeemUrl: PropTypes.string,
};

VoucherCode.defaultProps = {
  redeemUrl: '',
};

export default VoucherCode;
