import colors from '@theme/colors';

const getStatusAndColor = status => {
  switch (status) {
    case 'PENDING':
      return {
        bgColor: colors.warning.softSunflower,
        color: colors.warning.sunflower,
        text: 'transStatus.wait',
      };

    case 'COMPLETED':
    case 'CREDITED':
    case 'DEBITED':
      return {
        color: colors.success.cactus,
        bgColor: colors.success.softCactus,
        text: 'transStatus.success',
      };

    case 'FAILED':
      return {
        bgColor: colors.error.softRose,
        color: colors.error.rose,
        text: 'transStatus.failed',
      };
    case 'REJECTED':
      return {
        bgColor: colors.error.softRose,
        color: colors.error.rose,
        text: 'transStatus.rejected',
      };

    default:
      return {
        color: colors.neutral.cotton,
        text: '',
      };
  }
};

export default getStatusAndColor;
