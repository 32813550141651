import { useNavigation } from '@react-navigation/native';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback } from 'react-native';

function ProfileRejectedPinRow(props) {
  const navigation = useNavigation();
  const { onPressSecurityPin, childRef } = props;
  const { t } = useTranslation('common');
  return (
    <TouchableWithoutFeedback ref={childRef} testID="btnPressSecurityPin" onPress={onPressSecurityPin(navigation)}>
      <View backgroundColor={colors.error.softRose} borderRadius="12px" marginRight="8px">
        <Text
          testID="txtSecurityPinRejected"
          marginX="8px"
          marginY="5px"
          variant="xsm-normal"
          color={colors.error.darkRose}>
          {t('profile.securityPinRejected')}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

ProfileRejectedPinRow.propTypes = {
  onPressSecurityPin: PropTypes.func,
  childRef: PropTypes.func.isRequired,
};
ProfileRejectedPinRow.defaultProps = {
  onPressSecurityPin: () => {},
};
export default ProfileRejectedPinRow;
