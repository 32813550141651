import TextCurrency from '@atoms/TextCurrency';
import Icon from '@expo/vector-icons/MaterialIcons';
import { scale } from '@utils/normalize';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Text, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Pressable } from 'react-native';
import styles from './style';

export function onPressHandler(onPressItem, toast) {
  return () => onPressItem(toast);
}

const LoanItem = ({ principalAmt, receivable, tenure, installmentAmount, onPressItem, t }) => {
  const toast = useToast();

  return (
    <Pressable style={styles.container} onPress={onPressHandler(onPressItem, toast)}>
      <View>
        <View style={styles.principleRow}>
          {SVG_ICONS.loan.cash}
          <TextCurrency
            showCurrencySymbol={false}
            amountCurrency={principalAmt}
            variant="md-bold"
            style={styles.principleAmtStyle}
          />
        </View>

        <View style={styles.metaRow}>
          <View>
            <Text variant="xsm-normal" style={styles.metaTitleStyle}>
              {t('loan.loanItemText1')}
            </Text>

            <TextCurrency
              variant="sm-bold"
              style={styles.metaInfoStyle}
              amountCurrency={receivable}
              showCurrencySymbol
            />
          </View>
          <View style={styles.containerAdj}>
            <Text variant="xsm-normal"> {t('loan.loanItemText2')}</Text>

            <View style={styles.tenureBubble}>
              <Text variant="sm-bold" style={styles.tenureTxtStyle}>
                {`${tenure}x`}
              </Text>
            </View>
          </View>
          <View style={styles.containerAdj}>
            <Text variant="xsm-normal" style={styles.metaTitleStyle}>
              {t('loan.loanItemText3')}
            </Text>
            <TextCurrency
              variant="sm-bold"
              style={styles.installAmtTxt}
              amountCurrency={installmentAmount}
              showCurrencySymbol
            />
          </View>
        </View>
      </View>

      <View style={styles.rightArrowContainer}>
        <Icon name="keyboard-arrow-right" size={scale(22)} />
      </View>
    </Pressable>
  );
};

LoanItem.propTypes = {
  principalAmt: PropTypes.number,
  receivable: PropTypes.number,
  tenure: PropTypes.number,
  installmentAmount: PropTypes.number,
  onPressItem: PropTypes.func,
  t: PropTypes.func.isRequired,
};

LoanItem.defaultProps = {
  principalAmt: 0,
  receivable: 0,
  tenure: 0,
  installmentAmount: 0,
  onPressItem: () => {},
};

export default LoanItem;
