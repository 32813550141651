import { gql } from '@apollo/client';

const UPDATE_DEPARTMENT_DESIGNATION = gql`
  mutation MyMutation($employeeId: Int, $departmentName: String, $designationName: String) {
    update_gg_employee(
      _set: { departmentName: $departmentName, designationName: $designationName }
      where: { id: { _eq: $employeeId } }
    ) {
      returning {
        departmentName
        designationName
      }
    }
  }
`;

const FETCH_DEPARTMENTS = gql`
  query MyQuery {
    departments {
      department_name
    }
  }
`;

const FETCH_ROLES = gql`
  query MyQuery {
    roles {
      role_name
    }
  }
`;

export { UPDATE_DEPARTMENT_DESIGNATION, FETCH_DEPARTMENTS, FETCH_ROLES };
