// import { fetchReasonsUseQuery } from '@api/useQueryCalls';
import ModalInactiveWAS from '@atoms/ModalInactiveWAS';
import useWithdrawalRequest from '@customHooks/useWithdrawalRequest';
import ReasonsModal from '@molecules/ReasonsModal';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import {
  Button,
  Text,
  // , useToast
} from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logReasonSelector, logWithdrawPressed, withdrawNowFailedAmplitude } from './analyticsWithdrawButton';
/**
 *
 * @param {boolean} showReasonSelector
 * @param {function} setShowReasonSelector
 * @param {array} reasons
 * @returns JSX
 */
export function shouldShowReasonsModal(
  showReasonSelector,
  setShowReasonSelector,
  reasons,
  withdrawalAmount,
  withdrawRequest,
  reasonData,
  setReason,
  selectedAccount,
  fromTransfer,
  source,
  transaction_category_id,
) {
  if (showReasonSelector) {
    return (
      <ReasonsModal
        showReasonSelector={showReasonSelector}
        reasons={reasons}
        setShowReasonSelector={setShowReasonSelector}
        withdrawalAmount={withdrawalAmount}
        withdrawRequest={withdrawRequest}
        reasonData={reasonData}
        setReason={setReason}
        account={selectedAccount}
        isEditReasons={false}
        fromTransfer={fromTransfer}
        source={source}
        transaction_category_id={transaction_category_id}
      />
    );
  }
  return null;
}

/**
 *
 * @param {boolean} isStale
 * @param {array} reasons
 * @param {function} refetch
 * @param {function} setShowReasonSelector
 */
// function fetchReasonsAndShowModal(refetch, isStale) {
//   if (isStale) {
//     refetch();
//   }
// }

function WithdrawButton(props) {
  const { isDisabled, withdrawalAmount, buttonTitle, fromTransfer, source, transaction_category_id } = props;

  const { t } = useTranslation('common');
  const [reasons] = useState(null);
  const {
    // setLoading, selectedAccount, setInitialData,
    selectedAccount,
    withdrawalReason,
    setWithdrawalReason,
    validatedReferralCode,
    meAmplitudeContext,
    status,
    bankForTransfer,
    isInLockPeriod,
    isSuspended,
  } = getStore();
  const { buttonText, amplitudeAccountName } = selectedAccount;
  const [showReasonSelector, setShowReasonSelector] = useState(false);
  const [showWDModal, setShowWDModal] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [wdModalText, setWDModalText] = useState('');
  const [imgStatusFile, setImgStatusFile] = useState(null);
  const navigation = useNavigation();
  const onSuccessCallBack = (isWithdrawalSuccess, withdrawalData) => {
    if (isWithdrawalSuccess && withdrawalData) {
      navigation.navigate(navigationConstants.GG_WITHDRAW_DETAILS, {
        withdrawalData,
        fromTransfer,
        source,
        transaction_category_id,
      });
    }
  };
  const hookProps = { selectedBank: bankForTransfer, onSuccessCallBack };
  const { withdrawRequest, isWithdrawalError, withdrawalError } = useWithdrawalRequest(hookProps);
  useEffect(() => {
    if (isWithdrawalError && withdrawalError) {
      let failureMessage = '';
      if (withdrawalError && withdrawalError.length > 0) {
        failureMessage = t(`errorCodes.${withdrawalError[0]}`);
      }
      withdrawNowFailedAmplitude(
        source,
        transaction_category_id,
        withdrawalAmount,
        failureMessage,
        validatedReferralCode,
        amplitudeAccountName,
      );
    }
  }, [isWithdrawalError, withdrawalError]);
  useEffect(() => {
    if (showReasonSelector) {
      logReasonSelector(
        transaction_category_id,
        fromTransfer,
        source,
        validatedReferralCode,
        amplitudeAccountName,
        withdrawalAmount,
      );
    }
  }, [showReasonSelector]);
  const closeModalWDStatus = () => setShowWDModal(false);
  return (
    <>
      <Button
        isDisabled={isDisabled}
        testID="btnWithdraw"
        sentry-label="btnWithdraw"
        marginY="12px"
        paddingY="4px"
        // size=""
        _text={{ marginX: 16, marginY: 12 }}
        // isLoading={loadingWithdrawalButton}
        onPress={() => {
          setWithdrawalReason(null);
          logWithdrawPressed(
            withdrawalAmount,
            source,
            transaction_category_id,
            validatedReferralCode,
            amplitudeAccountName,
            meAmplitudeContext,
            isInLockPeriod,
            status,
          );
          if (selectedAccount.accountTypeId === 0 && isInLockPeriod) {
            const failureMessage = t('common.lockPeriod');
            withdrawNowFailedAmplitude(
              source,
              transaction_category_id,
              withdrawalAmount,
              failureMessage,
              validatedReferralCode,
              amplitudeAccountName,
            );
            setModalHeader('withdrawal.status.inactive');
            setWDModalText(t('common.lockPeriodModalText'));
            setImgStatusFile('LockPeriodImg');
            setShowWDModal(true);
            return;
          }
          if (selectedAccount.accountTypeId === 0 && isSuspended) {
            const failureMessage = t('common.suspendedError');
            withdrawNowFailedAmplitude(
              source,
              transaction_category_id,
              withdrawalAmount,
              failureMessage,
              validatedReferralCode,
              amplitudeAccountName,
            );
            setModalHeader('withdrawal.status.withdrawalSuspended');
            setWDModalText(t('common.suspendedModalText'));
            setImgStatusFile('AccountSuspended');
            setShowWDModal(true);
            return;
          }
          setShowReasonSelector(true);
        }}>
        <Text variant="lg-bold" testID="textBtnWithdraw" color={colors.neutral.cotton}>
          {buttonTitle.length > 0 ? buttonTitle : buttonText}
        </Text>
      </Button>
      <ModalInactiveWAS
        showWDModal={showWDModal}
        modalHeader={modalHeader}
        wdModalText={wdModalText}
        imgStatusFile={SVG_ICONS.bottomSheet[imgStatusFile]}
        closeModalWDStatus={closeModalWDStatus}
      />
      {shouldShowReasonsModal(
        showReasonSelector,
        setShowReasonSelector,
        reasons,
        withdrawalAmount,
        withdrawRequest,
        withdrawalReason,
        setWithdrawalReason,
        selectedAccount,
        fromTransfer,
        source,
        transaction_category_id,
      )}
    </>
  );
}

WithdrawButton.propTypes = {
  isDisabled: PropTypes.bool,
  withdrawalAmount: PropTypes.number,
  buttonTitle: PropTypes.string,
  fromTransfer: PropTypes.bool,
  source: PropTypes.string,
  transaction_category_id: PropTypes.string,
};

WithdrawButton.defaultProps = {
  isDisabled: false,
  withdrawalAmount: 0,
  buttonTitle: '',
  fromTransfer: false,
  source: '',
  transaction_category_id: '',
};

export default WithdrawButton;
