import { fetchBanksEnrolled } from '@api/useQueryCalls';
// import { shouldShowError } from '@organisms/HomeTab/homeUtils';
// import { useNavigation } from '@react-navigation/native';
// import { getStore } from '@store/storeUtils';
// import { useToast } from 'native-base';
import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

const useFetchBanks = () => {
  const [bankList, setBankList] = useState(null);
  // const { setInitialData } = getStore();
  const {
    isLoading,
    // , isError, error
    refetch,
    isSuccess,
    data,
    isFetching,
    isFetched,
    status,
  } = fetchBanksEnrolled();
  // const toast = useToast();
  // const { t } = useTranslation('common');
  // const navigation = useNavigation();

  // useEffect(() => {
  //   setLoading(isLoading);
  // }, [isLoading]);
  // useEffect(() => {
  //   shouldShowError(isError, error, toast, t, navigation, setInitialData);
  // }, [isError, error]);
  useEffect(() => {
    if (isSuccess && data && data?.list) {
      setBankList(data.list);
    }
  }, [isSuccess, data]);
  return { bankList, isSuccess, isLoading, isFetching, isFetched, status, refetch };
};

export default useFetchBanks;
