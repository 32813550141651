import { fetchSubProductsOrNominals } from '@api/useQueryCalls';
import useBPMutation from '@customHooks/useBPRequest';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { Button, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import logPdamFailure from './analyticsPdam';
import styles from './styles';
import { disabledButtonForPDAM, refetchBPIfStale, shouldShowAccountInput, showErrorOnAPI } from '../BPUtils';
import { logPageBack, logPageLaunch, logRequestBP } from '../GGNominals/analyticsNominals';

export function getInitialSelectedAccNum(productConfig, mobileNumber) {
  return productConfig.hasAccountInput ? null : mobileNumber;
}

export function onPressHandler(
  selectedAccountNumber,
  selectedBPAccount,
  productApiData,
  selectedPDAMRegion,
  validatedReferralCode,
  requestBP,
  setAccountInputError,
  t,
  productConfig,
  source,
  meAmplitudeContext,
) {
  return () => {
    const { amplitudeAccountName } = selectedBPAccount;
    if (selectedAccountNumber) {
      logRequestBP(
        selectedAccountNumber,
        productConfig,
        selectedPDAMRegion,
        source,
        validatedReferralCode,
        meAmplitudeContext,
        amplitudeAccountName,
      );
      const request = {
        accountNumber: selectedAccountNumber,
        employeeAccountNumber: selectedBPAccount.id,
        transCategoryPrefix: productApiData.prefix,
        productCode: selectedPDAMRegion.code,
        referralCode: validatedReferralCode,
        zoneId: null,
        month: null,
      };
      requestBP(request);
    } else {
      setAccountInputError(t('bp.selectOrInputAccountNumber'));
    }
  };
}

function GGPdam(props) {
  const { setLoading, setInitialData, selectedBPAccount, validatedReferralCode, loginData, meAmplitudeContext } =
    getStore();
  const { accountTypeId } = selectedBPAccount;
  const { route } = props;
  const { params } = route;
  const { productConfig, productApiData, source } = params;
  const { productId, productName } = productConfig;
  const [sAccountInputError, setAccountInputError] = useState('');
  const [regionsData, setRegionsData] = useState([]);
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  useEffect(() => {
    navigation.setOptions({ title: productName });
  }, [productName]);
  const [openRTS, setOpenRTS] = useState(false);
  const { mobileNumber } = loginData;
  const [selectedAccountNumber, setSelectedAccountNumber] = useState(
    getInitialSelectedAccNum(productConfig, mobileNumber),
  );
  const [selectedPDAMRegion, setSelectedPDAMRegion] = useState(null);

  const [bpReqData, setBpReqData] = useState({});
  const { requestBP, isBPReqError, bpError, apiPayload } = useBPMutation({ setOpenRTS, setBpReqData });
  const { isLoading, isSuccess, data, isError, error, isStale, refetch } = fetchSubProductsOrNominals({
    productId,
    accountTypeId,
  });

  useEffect(() => {
    logPageLaunch(productConfig, source, validatedReferralCode);
    refetchBPIfStale(isStale, refetch);
    return () => logPageBack(productConfig, source, validatedReferralCode);
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    logPdamFailure(isBPReqError, selectedAccountNumber, productConfig, apiPayload, t, bpError, source);
  }, [isBPReqError, bpError]);

  useEffect(() => {
    if (isSuccess && data) {
      const regionKeys = Object.keys(data);
      const regData = [];
      regionKeys.forEach(rKey => {
        regData.push(data[rKey][0]);
      });
      setRegionsData(regData);
    }
  }, [isSuccess, data]);
  return (
    <View style={appStyles.safeAreaView}>
      <View style={appStyles.flex1}>
        {shouldShowAccountInput({
          selectedAccountNumber,
          setSelectedAccountNumber,
          setSelectedPDAMRegion,
          regionsData,
          sAccountInputError,
          setAccountInputError,
          ...params,
        })}
      </View>
      <View style={[appStyles.borderWithShadow, styles.bottomButton]}>
        <Button
          testID="btnConfirm"
          isDisabled={disabledButtonForPDAM(selectedAccountNumber, selectedPDAMRegion)}
          onPress={onPressHandler(
            selectedAccountNumber,
            selectedBPAccount,
            productApiData,
            selectedPDAMRegion,
            validatedReferralCode,
            requestBP,
            setAccountInputError,
            t,
            productConfig,
            source,
            meAmplitudeContext,
          )}>
          {t('common.confirmed')}
        </Button>
      </View>
      <RequestTransactionSheet
        openRTS={openRTS}
        setOpenRTS={setOpenRTS}
        productConfig={productConfig}
        productApiData={productApiData}
        selectedAccountNumber={selectedAccountNumber}
        nominalSelected={selectedPDAMRegion}
        bpReqData={bpReqData}
      />
    </View>
  );
}

GGPdam.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      productConfig: PropTypes.shape({
        productId: PropTypes.number,
        noOfColumns: PropTypes.number,
        productName: PropTypes.string,
        hasAccountInput: PropTypes.bool,
      }),
      productApiData: PropTypes.shape({
        prefix: PropTypes.string,
      }),
    }),
  }),
};

GGPdam.defaultProps = {
  route: {
    params: {
      source: '',
      productConfig: {
        hasAccountInput: false,
        productId: 1,
        noOfColumns: 1,
        productName: '',
      },
      productApiData: {
        prefix: '',
      },
    },
  },
};
export default GGPdam;
