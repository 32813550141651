import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Dimensions, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  textStyle: { flex: 1, paddingLeft: scale(8) },
  toastContainer: {
    alignItems: 'center',
    backgroundColor: colors.error.rose,
    borderRadius: scale(8),
    flexDirection: 'row',
    marginLeft: scale(16),
    marginRight: scale(16),
    paddingHorizontal: scale(12),
    paddingVertical: scale(8),
    width: Dimensions.get('window').width - scale(32),
  },
});
export default styles;
