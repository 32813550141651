export default function getDisabledCheck(values, errors) {
  let result = false;
  if (values.bankAccountNumber === null || errors?.bankAccountNumber?.length > 0) {
    result = true;
  }
  if (values.amount === '' || errors?.amount?.length > 0) {
    result = true;
  }
  return result;
}
