export default function checkIfSDModalNeedsToBeShown(
  accounts,
  setSelectedSDAccountWallet,
  showSDModal,
  isInLockPeriod,
  isFocused,
  setSDModalVisible,
) {
  let sdAccount = [];
  if (accounts && accounts.length > 0) sdAccount = accounts.filter(item => item.accountTypeId === 3);

  if (sdAccount.length > 0) {
    setSelectedSDAccountWallet(sdAccount[0]);
    const sdBalance = sdAccount[0].balance;

    if (showSDModal && sdBalance > 0 && isInLockPeriod && isFocused) {
      setSDModalVisible(true);
    }
  }
}
