import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

export function getNominalColorBasedOnAvailability(selectedNominal) {
  return selectedNominal.availability ? colors.neutral.charcoal : colors.neutral.darkMist;
}

export function getPriceColorBasedOnAvailability(selectedNominal) {
  return selectedNominal.availability ? colors.success.cactus : colors.neutral.darkMist;
}

function BPSubProdSelectedNominal(props) {
  const { t } = useTranslation('common');
  const { selectedNominal } = props;
  return (
    <View style={appStyles.flex1}>
      <Text variant="md-normal" color={getNominalColorBasedOnAvailability(selectedNominal)}>
        {selectedNominal.name}
      </Text>
      <View style={styles.nominalStyles}>
        <Text variant="lg-bold" color={getPriceColorBasedOnAvailability(selectedNominal)}>
          {t('bp.price')}{' '}
        </Text>
        <TextCurrency
          variant="md-bold"
          color={getPriceColorBasedOnAvailability(selectedNominal)}
          amountCurrency={selectedNominal.nominal.amount}
        />
      </View>
      {selectedNominal.availability ? null : (
        <Text color={colors.error.rose} variant="xsm-bold">
          {t('bankTransfer.notEnoughBalance')}
        </Text>
      )}
    </View>
  );
}

BPSubProdSelectedNominal.propTypes = {
  selectedNominal: PropTypes.shape({
    availability: PropTypes.bool,
    name: PropTypes.string,
    nominal: PropTypes.shape({
      amount: PropTypes.number,
      value: PropTypes.number,
    }),
  }),
};
BPSubProdSelectedNominal.defaultProps = {
  selectedNominal: {
    availability: true,
    name: '',
    nominal: {
      amount: 0,
      value: 0,
    },
  },
};

export default BPSubProdSelectedNominal;
