import ArrowDown from '@atoms/ArrowDown';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import { AntDesign } from '@expo/vector-icons';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import styles from './styles';

function shouldShowCurrencyForPoints(item) {
  return item?.accountTypeId !== 1;
}

function shouldShowCheckedOrDown(item, selectedEligibleAccount, showChecked) {
  if (item === selectedEligibleAccount && showChecked) {
    return (
      <AntDesign
        testID={`selectedAccount${selectedEligibleAccount?.id ?? ''}`}
        name="checkcircle"
        size={24}
        color={colors.secondary.orchid}
      />
    );
  }
  if (item === selectedEligibleAccount && !showChecked && Platform.OS !== 'web') {
    return <ArrowDown />;
  }
  return <View />;
}

function BPReqAccountWithBalance(props) {
  const { t } = useTranslation('common');
  const { selectedEligibleAccount, item, showChecked } = props;
  const customStyles = showChecked ? { flex: 1 } : {};
  const enabledDisabledColor = item.isDisabled ? colors.neutral.darkMist : colors.neutral.charcoal;
  return (
    <View testID={`dd_${item.id}`} style={styles.accountSelectorContainer(item, selectedEligibleAccount, showChecked)}>
      <RemoteImage uri={item.iconLink} width={18} height={18} />
      <View flex={1}>
        <View style={styles.accountSelectorRow}>
          {/* <View alignContent={'center'} alignItems={'center'}> */}
          <Text
            testID={`dropSelected_${item.accountTypeId}`}
            variant="sm-bold"
            flex={1}
            style={styles.txtAccountName}
            color={enabledDisabledColor}>
            {item.name}
          </Text>
          {/* {item.isDisabled && showChecked ? <Text color={colors.error.rose}>{item.disabledReason}</Text> : null} */}
          {/* </View> */}
          <View style={[styles.txtAccountBalance(item, selectedEligibleAccount), customStyles]}>
            <Text variant="sm-bold" color={enabledDisabledColor}>
              {' | '}
            </Text>
            <TextCurrency
              variant="sm-bold"
              testID="selectedTxtCurrency"
              amountCurrency={item.balance}
              showCurrencySymbol={shouldShowCurrencyForPoints(item)}
              color={enabledDisabledColor}
            />
          </View>
          {shouldShowCheckedOrDown(item, selectedEligibleAccount, showChecked)}
        </View>
        {item.isDisabled ? (
          <Text variant="xsm-bold" style={appStyles.marginLeft(9)} color={colors.error.rose}>
            {t(`errorCodes.${item.disabledReason}`)}
          </Text>
        ) : null}
      </View>
    </View>
  );
}

BPReqAccountWithBalance.propTypes = {
  selectedEligibleAccount: PropTypes.shape({}),
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    accountTypeId: PropTypes.number,
    balance: PropTypes.number,
    iconLink: PropTypes.string,
    isDisabled: PropTypes.bool,
    disabledReason: PropTypes.string,
  }),
  showChecked: PropTypes.bool,
};
BPReqAccountWithBalance.defaultProps = {
  selectedEligibleAccount: {},
  item: {
    accountTypeId: 0,
    name: '',
    id: 0,
    balance: 0,
    iconLink: '',
    isDisabled: false,
    disabledReason: '',
  },
  showChecked: false,
};

export default BPReqAccountWithBalance;
