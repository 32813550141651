import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logHelpClicked() {
  const e = AMPLITUDE_CONSTANTS.authentication.help_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pin_phone_number',
  });
}

export function logOnPage(getPageNumber, route) {
  const e = AMPLITUDE_CONSTANTS.securityPin.pin_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.page_number]: getPageNumber(),
    [e.attributes.source]: route.params?.source,
  });
}

export function logHelpCheckCreatePin(route) {
  const e2 = AMPLITUDE_CONSTANTS.securityPin.help_clicked;
  AmplitudeHelper.logEvent(e2.name, {
    [e2.attributes.source]: route.params?.source,
  });
}

export function logCloseModal(route) {
  const e = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: route.params?.pin_failed_modal,
  });
}

export function logPrimaryPress(route) {
  const e = AMPLITUDE_CONSTANTS.securityPin.reset_pin_later;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: route.params?.pin_failed_modal,
  });
}

export function logReCreatePin(route) {
  const e2 = AMPLITUDE_CONSTANTS.securityPin.recreate_pin_clicked;
  AmplitudeHelper.logEvent(e2.name, {
    [e2.attributes.source]: `${route.params?.source}_modal`,
  });
}

export function logResetPinClicked(route) {
  const e = AMPLITUDE_CONSTANTS.securityPin.reset_pin_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: route.params?.pin_failed_modal,
  });
}

export function logBankClicked(source) {
  const e4 = AMPLITUDE_CONSTANTS.securityPin.add_bank_account_clicked;
  AmplitudeHelper.logEvent(e4.name, {
    [e4.attributes.source]: source,
  });
}

export function logAddPhoneClicked(source) {
  const e4 = AMPLITUDE_CONSTANTS.securityPin.add_phonenumber_clicked;
  AmplitudeHelper.logEvent(e4.name, {
    [e4.attributes.source]: source,
  });
}

export function logOkCreate(route) {
  const e4 = AMPLITUDE_CONSTANTS.securityPin.ok_button_clicked;
  AmplitudeHelper.logEvent(e4.name, {
    [e4.attributes.source]: `${route.params?.source}_submitted_modal`,
  });
}

export function logPinCreationFailedFinal(route, getPageNumber) {
  const e3 = AMPLITUDE_CONSTANTS.securityPin.pin_creation_failed;
  AmplitudeHelper.logEvent(e3.name, {
    [e3.attributes.source]: route?.params?.source,
    [e3.attributes.page_number]: getPageNumber(),
    [e3.attributes.failure_message]: 'pin_repeated',
  });
}

export function logPinSubmitted(route) {
  const e2 = AMPLITUDE_CONSTANTS.securityPin.pin_submitted;
  AmplitudeHelper.logEvent(e2.name, {
    [e2.attributes.source]: route.params?.source,
  });
}

export function logContinueButtonClicked(route) {
  const e = AMPLITUDE_CONSTANTS.securityPin.continue_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: route.params?.source,
  });
}

export function logPinCreationFailed(route, getPageNumber, invalidConfirms) {
  const e6 = AMPLITUDE_CONSTANTS.securityPin.pin_creation_failed;
  AmplitudeHelper.logEvent(e6.name, {
    [e6.attributes.source]: route.params?.source,
    [e6.attributes.page_number]: getPageNumber(),
    [e6.attributes.failure_message]: `wrong_pin${invalidConfirms + 1}`,
  });
}

export function logPinInputted(route) {
  const e7 = AMPLITUDE_CONSTANTS.securityPin.pin_inputted;
  AmplitudeHelper.logEvent(e7.name, {
    [e7.attributes.source]: route.params?.source,
  });
}

export function logPinCreationInputted(getPageNumber, route) {
  const e5 = AMPLITUDE_CONSTANTS.securityPin.pin_creation_inputted;
  AmplitudeHelper.logEvent(e5.name, {
    [e5.attributes.page_number]: getPageNumber(),
    [e5.attributes.source]: route.params?.source,
  });
}

export function logPinInputFailed(route, securityPin) {
  const e = AMPLITUDE_CONSTANTS.securityPin.pin_inputted_failed;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: route.params?.source,
    [e.attributes.attempt]: `${securityPin.invalidAttempts + 1}`,
  });
}

export function logXButtonClickedSubmitted(route) {
  const e4 = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
  AmplitudeHelper.logEvent(e4.name, {
    [e4.attributes.source]: `${route.params?.source}_submitted_modal`,
  });
}

export function logCloseModalSource(route) {
  const e2 = AMPLITUDE_CONSTANTS.securityPin.x_button_clicked;
  AmplitudeHelper.logEvent(e2.name, {
    [e2.attributes.source]: `${route.params?.source}_modal`,
  });
}

export function logPinInputSuccessfully(route) {
  const e = AMPLITUDE_CONSTANTS.securityPin.pin_inputted_successfully;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: route.params?.source,
  });
}
