import RenderBulletRow from '@atoms/UserProfile/RenderBulletRow';
import TNCitem from '@atoms/UserProfile/TNCitem';
import { id } from '@root/i18n/supportedLanguages';
import { getStore } from '@store/storeUtils';
import { Text } from 'native-base';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Linking, ScrollView } from 'react-native';
import logPicTerms from './analyticsPicTerms';
import styles from './style';

const PicTerms = () => {
  const { t } = useTranslation('common');
  const { employeeProfile } = getStore();

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  useEffect(() => {
    logPicTerms(isPIC);
  }, []);

  const PIC_TERMS = [
    <>
      <Text variant="md-normal" style={styles.termsTextStyle}>
        {t('userProfile.PIC_TERMS_TEXT.0')}
      </Text>
      <View style={styles.subtermsContainerStyle}>
        {id.userProfile.SUB_TERMS.map((d, i) => (
          <RenderBulletRow key={d} desc={t(`userProfile.SUB_TERMS.${i}`)} />
        ))}
      </View>
    </>,

    <Text variant="md-normal" style={styles.termsTextStyle}>
      {t('userProfile.PIC_TERMS_TEXT.1')}
      <Text
        variant="md-normal"
        testID="linkOne"
        onPress={() => Linking.openURL('https://gajigesa.com/gajigesa-referral')}
        style={styles.termsLinkStyle}>
        {' '}
        {t('userProfile.PIC_TERMS_TEXT.5')}
      </Text>
    </Text>,
    <Text variant="md-normal" style={styles.termsTextStyle}>
      {t('userProfile.PIC_TERMS_TEXT.2')}
    </Text>,
    <Text variant="md-normal" style={styles.termsTextStyle}>
      {t('userProfile.PIC_TERMS_TEXT.3')}
    </Text>,
    <Text variant="md-normal" style={styles.termsTextStyle}>
      {t('userProfile.PIC_TERMS_TEXT.4')}{' '}
      <Text
        variant="sm-normal"
        testID="linkTwo"
        onPress={() => Linking.openURL('https://gajigesa.com/rekan-hr')}
        style={styles.termsLinkStyle}>
        {t('userProfile.PIC_TERMS_TEXT.6')}
      </Text>
    </Text>,
  ];

  return (
    <View style={styles.mainContainer}>
      <ScrollView contentContainerStyle={styles.scrollableContainerStyle} style={styles.mainContentStyle}>
        <View style={styles.collapsibleContainer}>
          {PIC_TERMS.map((o, i) => (
            <TNCitem key={o} item={o} component={o} index={i} />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

export default React.memo(PicTerms);
