import { MaterialIcons } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import styles from './style';

const UploadMessage = ({ onImagePick, onCloseModal, t }) => (
  <View style={styles.container}>
    <TouchableOpacity onPress={onCloseModal} style={styles.closeButton}>
      <MaterialIcons name="close" size={20} color={colors.black} />
    </TouchableOpacity>
    <Text variant="lg-bold" style={styles.title}>
      {t('transactionDetailsScreen.uploadBuktiPembayaranShort')}
    </Text>
    <Text variant="md-regular" style={styles.description}>
      {t('transactionDetailsScreen.limitDescription')}
    </Text>
    <Pressable
      testID="image-btn-upload"
      onPress={onImagePick}
      android_ripple={{ color: colors.neutral.mist }}
      style={styles.fromGalleryButton}>
      <MaterialIcons name="photo" size={20} style={styles.fromGalleryIcon} />
      <Text variant="md-bold" style={styles.fromGalleryText}>
        {t('transactionDetailsScreen.dariGaleri')}
      </Text>
    </Pressable>
  </View>
);

UploadMessage.propTypes = {
  onImagePick: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default UploadMessage;
