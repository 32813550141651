import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function WithdrawalAdminFee(props) {
  const { t } = useTranslation('common');
  const { fee } = props;
  return (
    <View style={appStyles.amountContainer}>
      <Text testID="lblAdminFee" flex={1} variant="lg-normal">
        {t('withdrawalPage.adminFee')}
      </Text>
      <TextCurrency variant="sm-bold" color={colors.error.rose} amountCurrency={fee} showCurrencySymbol />
    </View>
  );
}

WithdrawalAdminFee.propTypes = {
  fee: PropTypes.number,
};

WithdrawalAdminFee.defaultProps = {
  fee: 0,
};

export default WithdrawalAdminFee;
