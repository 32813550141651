import config from '@config';
import { openProdOrSubProdOrNominals } from '@molecules/BPProductTile';
import ScreenNames from '@navigation/navigationConstants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { initialiseUserAttributes } from '@utils/engagement/engagementUtils';
import { setUserProperties } from '@utils/performance';
import { formatUnderScoreLowerCase, serverError } from '@utils/utils';
import products from '../../../product';

export function shouldRenderHomeOnSuccess(renderProps) {
  const {
    meApiData,
    isSuccess,
    setAccounts,
    setSelectedAccount,
    setCanShowReferral,
    setEmployeeId,
    setIsInLockPeriod,
    setReferralApplicable,
    setStatus,
    setUserId,
    setEmployerId,
    mobileNumber,
    setBPAccounts,
    setSelectedBPAccounts,
    userName,
    setBPPhone,
    setIsSuspended,
    setHeaderText,
    setReferralHeaderText,
    setAppliedReferral,
    setTxnSuccessReferral,
    setAutoDisburseData,
  } = renderProps;
  if (meApiData && isSuccess) {
    const {
      accounts,
      canShowReferral,
      employeeId,
      isInLockPeriod,
      referralApplicable,
      status,
      userId,
      employerId,
      firstName,
      lastName,
      isSuspended,
      headerText,
      referralButtonText,
      appliedReferralCode,
      successPageBanners,
      lockPeriodDate,
      allowEwaAutoDisburse,
    } = meApiData;
    setAccounts(accounts);
    setSelectedAccount({ ...(accounts?.filter(item => item.isWithdrawalEligible)[0] || []) });
    setBPAccounts(accounts?.filter(item => item.isBillPaymentEligible));
    setSelectedBPAccounts({ ...(accounts?.filter(item => item.isBillPaymentEligible)[0] || []) });
    setCanShowReferral(canShowReferral);
    setEmployeeId(employeeId);
    setIsInLockPeriod(isInLockPeriod);
    setReferralApplicable(referralApplicable);
    setStatus(status);
    setUserId(userId);
    setEmployerId(employerId);
    setHeaderText(headerText);
    setReferralHeaderText(referralButtonText);
    setUserProperties(employeeId, employerId, `${firstName} ${lastName}`);
    if (employeeId && employeeId?.toString()?.length > 0) {
      AmplitudeHelper.setUserId(employeeId?.toString(), employerId?.toString());
      AmplitudeHelper.setUserProperties({
        userId: employeeId?.toString(),
        employeeId: employeeId?.toString(),
        employerId: employerId?.toString(),
        environment: config.ENVIRONMENT,
      });
    }
    initialiseUserAttributes({ userId, phoneNumber: mobileNumber, is_gajigea: true, employeeId, userName });
    setBPPhone({ mobileNumber, name: userName });
    setIsSuspended(isSuspended);
    setAppliedReferral(appliedReferralCode);
    setTxnSuccessReferral(successPageBanners);
    setAutoDisburseData({ lockPeriodDate, allowEwaAutoDisburse });
  }
}

export function shouldShowError(isError, error, toast, t, navigation, setInitialData) {
  if (isError && error) {
    serverError(error, toast, t, navigation, setInitialData);
  }
}

export function getScreenNameFromLink(link, path = 'link/') {
  return link?.url?.split(path)?.reverse()[0];
}

export function logUTMCampagin(campName) {
  if (campName) {
    const e = AMPLITUDE_CONSTANTS.utm_detected;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.utm_campaign]: campName,
    });
  }
}

export function logReferralDeepLink(link) {
  const e = AMPLITUDE_CONSTANTS.app_open_via_referral;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.link]: link?.url,
  });
}

export function logHandleLink(link) {
  const e = AMPLITUDE_CONSTANTS.eng_diag_interruption;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.event]: formatUnderScoreLowerCase('App opened from Notifications'),
    [e.attributes.link]: link?.url,
  });
}

export function parseAndLogUTMCampaign(url) {
  try {
    const queryStart = url.indexOf('?');

    if (queryStart === -1) return false;

    const queryString = url.substring(queryStart + 1);

    const queryParams = queryString.split('&');

    const params = {};

    queryParams.forEach(param => {
      const [key, value] = param.split('=');

      params[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    if (params.utm_campaign) return params.utm_campaign;
    return false;
  } catch (error) {
    return false;
    /// console.error('Error parsing URL:', error);
  }
}

export function handleLink(link, navigation, data, store) {
  if (link?.url && link?.url?.length > 0) {
    logHandleLink(link);
    logUTMCampagin(parseAndLogUTMCampaign(link?.url));
    const pathName = getScreenNameFromLink(link);
    // Define actions based on screen names
    const screenActions = {
      [ScreenNames.REFERRAL_PAGE]: () => navigation.navigate(ScreenNames.REFERRAL_PAGE),
      [ScreenNames.TransactionsTab]: () =>
        navigation.navigate(ScreenNames.HOME, { screen: ScreenNames.TransactionsTab }),
      [ScreenNames.Finansial]: () => navigation.navigate(ScreenNames.HOME, { screen: ScreenNames.Finansial }),
      [ScreenNames.PembayaranTab]: () => navigation.navigate(ScreenNames.HOME, { screen: ScreenNames.PembayaranTab }),
      [ScreenNames.PIC_LISTING]: () => navigation.navigate(ScreenNames.PIC_LISTING),
      [ScreenNames.USER_PROFILE]: () => navigation.navigate(ScreenNames.USER_PROFILE),
      [ScreenNames.GG_CHAT_BOT]: () => navigation.navigate(ScreenNames.GG_CHAT_BOT),
    };

    // Use the screenAction if it exists for the current screenName
    if (screenActions[pathName]) {
      screenActions[pathName]();
    } else if (pathName.includes('referral')) {
      logReferralDeepLink(link);
      const pathPayload = getScreenNameFromLink(link, 'referral/');
      store.setDeepLinkData(pathPayload);
    } else if (pathName.includes('share')) {
      store.share();
    } else {
      // Create a dmapping from screen names to product indices
      const screenToProductIndex = {
        'BP-05': 5, // TOKEN PLN
        'BP-06': 6, // PDAM
        'BP-07': 7, // PAKET DATA
        'BP-08': 8, // PASCABAYAR
        'BP-10': 10, // PULSA
        'BP-13': 13, // VOUCHERS
        'BP-15': 15, // EMoney
        'BP-17': 17, // TAGIHAN PLN
        31: 31, // BANK TRANSFER
      };

      // Retrieve the product index based on the screen name
      const productIndex = screenToProductIndex[pathName];
      // If the product index exists, call the function with corresponding product
      if (productIndex !== undefined) {
        openProdOrSubProdOrNominals(products[productIndex], navigation, data);
      }
    }
  }
}

export function checkLinkAndHandle(link, navigation, store) {
  const screenName = getScreenNameFromLink(link);
  const apiDataMap = {
    'BP-05': {
      id: 5,
      prefix: 'BP-05',
      name: 'Listrik prepaid',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/tokenPLN.png`,
      screenName: 'Token PLN',
      canChangeNumber: true,
      availability: true,
      hasMoreProducts: true,
    },
    'BP-06': {
      id: 6,
      prefix: 'BP-06',
      name: 'PDAM postpaid',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/pdam3.png`,
      screenName: 'PDAM',
      canChangeNumber: true,
      availability: true,
      hasMoreProducts: true,
    },
    'BP-07': {
      id: 7,
      prefix: 'BP-07',
      name: 'Paket Data prepaid',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/paket-data.png`,
      screenName: 'Paket Data',
      canChangeNumber: true,
      availability: true,
      hasMoreProducts: true,
    },
    'BP-08': {
      id: 8,
      prefix: 'BP-08',
      name: 'Pascabayar postpaid',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/pascabayar.png`,
      screenName: 'Pascabayar',
      canChangeNumber: true,
      availability: true,
      hasMoreProducts: true,
    },
    'BP-10': {
      id: 10,
      prefix: 'BP-10',
      name: 'Pulsa prepaid',
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/pulsa.png`,
      screenName: 'Pulsa',
    },
    // ... other mappings
    'BP-13': {
      id: 13,
      prefix: 'BP-13',
      name: 'Voucher Digital prepaid',
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/voucher.png`,
      screenName: 'Voucher',
      canChangeNumber: false,
    },
    'BP-15': {
      id: 15,
      prefix: 'BP-15',
      name: 'eMoney prepaid',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/e-money.png`,
      screenName: 'eMoney',
      canChangeNumber: true,
      availability: true,
      hasMoreProducts: true,
    },
    'BP-17': {
      id: 17,
      prefix: 'BP-17',
      name: 'Listrik postpaid',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/listrik.png`,
      screenName: 'Tagihan PLN',
      canChangeNumber: true,
      availability: true,
      hasMoreProducts: true,
    },
    31: {
      id: 31,
      prefix: 'WD-02',
      name: 'Bank Transfer',
      canShowNew: false,
      iconLink: `${config.MEDIA_STORAGE}bill-payment-category-icons/bank-transfer.png`,
      screenName: 'Transfer',
      canChangeNumber: false,
      availability: true,
      hasMoreProducts: true,
    },
  };
  // Set common properties for all payloads.
  const commonProperties = {
    canShowNew: false,
    canChangeNumber: true,
    availability: true,
    hasMoreProducts: true,
  };
  const payload = apiDataMap[screenName];
  if (payload) {
    // Merge common properties into the selected payload.
    const fullPayload = { ...commonProperties, ...payload };
    handleLink(link, navigation, fullPayload);
  } else {
    handleLink(link, navigation, null, store);
  }
}
