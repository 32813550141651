import { Skeleton } from '@rneui/base';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { View } from 'native-base';
import React from 'react';

const shimmerStyles = [
  appStyles.flex1,
  appStyles.borderRadius(10),
  appStyles.marginRight(10),
  appStyles.width('40%'),
  appStyles.height(40),
];

const ShimmerAccountList = () => (
  <View
    backgroundColor={colors.secondary.orchid}
    flexDirection="row"
    style={[appStyles.paddingHorizontal(10), appStyles.paddingVertical(10)]}>
    <Skeleton testID="shimmer-element-1" animation="wave" style={shimmerStyles} />
    <Skeleton testID="shimmer-element-2" animation="wave" style={shimmerStyles} />
  </View>
);

export default ShimmerAccountList;
