import colors from '@theme/colors';
import { boldFont, regularFont } from '@theme/theme';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnGrpContainer: {
    backgroundColor: colors.neutral.mist,
    borderRadius: 8,
    marginHorizontal: 0,
    marginTop: verticalScale(16),
    paddingLeft: scale(4),
    paddingVertical: verticalScale(4),
  },
  btnSelectedStyle: { backgroundColor: colors.primary.carnation, borderRadius: 8, fontFamily: regularFont },
  btnTxtStyle: {
    fontFamily: boldFont,
    fontSize: verticalScale(14),
    lineHeight: verticalScale(21),
    // marginVertical: verticalScale(7),
  },
  customerIdTextStyles: {
    color: colors.neutral.darkMist,
    marginBottom: verticalScale(4),
    marginTop: verticalScale(16),
  },
  dividerStyles: { height: verticalScale(4), marginTop: verticalScale(16) },
  selectedTextStyles: {
    fontFamily: boldFont,
    fontSize: verticalScale(14),
    lineHeight: verticalScale(21),
    // marginVertical: verticalScale(7),
  },
});
export default styles;
