import { FETCH_DEPARTMENTS, FETCH_ROLES } from '@api/queries/UserProfile';
import { useLazyQuery } from '@apollo/client';
import CommonTextInput from '@atoms/CommonTextInput';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StatusBar, View, Pressable, FlatList, ScrollView } from 'react-native';
import { logRoleChoosen, logisDeparmentChoose } from './analyticsDepDesign';
import styles from './style';

export function searchDepartments(listData, query) {
  const sanitizedQuery = query.toLowerCase();
  return listData.filter(d => d.toLowerCase().includes(sanitizedQuery));
}

export function shouldRenderClose(show, setSearchText) {
  if (show)
    return (
      <Pressable testID="closeBtnIcon" onPress={() => setSearchText('')}>
        <Ionicons name="close-circle" color={colors.neutral.charcoal} size={scale(18)} style={styles.closeIconStyle} />
      </Pressable>
    );
  return null;
}

export function shouldProcessRolesResult(setDeptOrDesign) {
  return data => {
    const roleNames = data?.roles?.map(role => role.role_name) || [];
    setDeptOrDesign(roleNames);
  };
}

export function shouldRenderDepartments(setDeptOrDesign) {
  return data => {
    const deparmentNames = data?.departments?.map(department => department.department_name) || [];
    setDeptOrDesign(deparmentNames);
  };
}

export const onPressHandler = (
  item,
  isDepartment,
  setEmployeeProfile,
  employeeProfile,
  employeeId,
  employerId,
  navigation,
) => {
  if (isDepartment) {
    setEmployeeProfile({ ...employeeProfile, selectedDepartmentName: item });
    logisDeparmentChoose(employeeId, employerId);
  } else {
    setEmployeeProfile({ ...employeeProfile, selectedDesignationName: item });
    logRoleChoosen(employeeId, employerId);
  }
  navigation.goBack();
};

const DeptDesignList = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const isDepartment = route?.params?.isDepartment;

  const { t } = useTranslation('common');

  const { employeeId, employerId, setEmployeeProfile, employeeProfile } = getStore();

  const [deptOrDesign, setDeptOrDesign] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!isDepartment) navigation.setOptions({ headerTitle: t('userProfile.designTitle') });
  }, [isDepartment]);

  const [fetchRoles] = useLazyQuery(FETCH_ROLES, {
    onCompleted: shouldProcessRolesResult(setDeptOrDesign),
    fetchPolicy: 'no-cache',
  });

  const [fetchDepartments] = useLazyQuery(FETCH_DEPARTMENTS, {
    onCompleted: shouldRenderDepartments(setDeptOrDesign),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (isDepartment) fetchDepartments();
    else fetchRoles();
  }, [isDepartment]);

  const onChangeSearchText = txt => setSearchText(txt);

  const renderItem = ({ item }) => (
    <TouchableOpacity
      testID="itemBtn"
      onPress={() =>
        onPressHandler(item, isDepartment, setEmployeeProfile, employeeProfile, employeeId, employerId, navigation)
      }
      style={styles.itemContainer}>
      <Text variant="md-normal" style={styles.itemTextStyle}>
        {item}
      </Text>
    </TouchableOpacity>
  );

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <StatusBar translucent={false} backgroundColor={colors.secondary.orchid} />

      <View style={styles.containerStyle}>
        <View style={styles.inputTextContainer}>
          <CommonTextInput
            value={searchText}
            testID="textInput"
            style={styles.inputSearch}
            customBorderStyle={styles.borderStyle}
            onChangeText={onChangeSearchText}
            placeholder={t('userProfile.departmentSearch')}
            placeholderTextColor={colors.neutral.darkMist}
          />
          {shouldRenderClose(searchText?.length > 0, setSearchText)}
          <View testID="search">
            <MaterialIcons name="search" color={colors.neutral.charcoal} size={scale(25)} />
          </View>
        </View>
      </View>

      <FlatList
        data={searchDepartments(deptOrDesign, searchText)}
        keyExtractor={item => item.key}
        contentContainerStyle={styles.contentContainerStyle}
        testID="depDesignFlatList"
        renderItem={renderItem}
      />
    </ScrollView>
  );
};

export default DeptDesignList;
