import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  baruStyle: { left: 15, marginTop: scale(5) },
  enabledDisabledOpacity: bpCategoryProducts => ({ opacity: bpCategoryProducts.availability ? 1.0 : 0.5 }),
  enabledDisabledStyles: bpCategoryProducts => ({
    backgroundColor: bpCategoryProducts.availability ? colors.neutral.cotton : colors.neutral.mist,
    elevation: bpCategoryProducts.availability ? 6 : 0,
    shadowOffset: bpCategoryProducts.availability
      ? {
          width: 0,
          height: 3,
        }
      : {},
    shadowOpacity: bpCategoryProducts.availability ? 0.27 : 0,
    shadowRadius: bpCategoryProducts.availability ? 4.65 : 0,
  }),
  productNameText: { marginTop: verticalScale(8) },
  productTile: {
    alignItems: 'center',
  },
  productTileButton: {
    alignItems: 'center',
    marginHorizontal: 0,
    marginTop: verticalScale(12),
    paddingHorizontal: scale(15),
    paddingVertical: verticalScale(15),
  },
});
export default styles;
