import { addEmployeeBeneficiary } from '@api/apiCall';
import { fetchProfile } from '@api/useQueryCalls';
import InfoTextComponent from '@atoms/InfoTextComponent';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import useFetchBanks from '@customHooks/useFetchBanks';
import ScreenNames from '@navigation/navigationConstants';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { BOTTOMSHEET_CONTENT, BOTTONSHEET_KEYS, FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { bankAddSchema } from '@utils/schemas';
import { formatUnderScoreLowerCase, returnBottomSheetContent } from '@utils/utils';
import { Formik } from 'formik';
import { View, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addBankHandlers, renderFormContentFunction, shouldRenderSuccessfulBankAdd } from './addBankUtils';
import { logBankAccountAddPress, logCloseHandler } from './analyticsBankScreen';
import styles from './styles';

export function onSelectedBankHandler(selectedBank, source) {
  if (selectedBank) {
    const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_name_chosen;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
    });
  }
}

export function onSubmitHandler(
  source,
  defaultBank,
  bankList,
  t,
  setInfoModal,
  isFeatureAvailale,
  pinRegister,
  navigation,
  addBankMutate,
) {
  return values => {
    logBankAccountAddPress(source, values);
    const defaultBankEntered = values.accountNumber === defaultBank.number;
    const bankExists = bankList?.filter(bank => bank.accountNumber === values.accountNumber) ?? [];
    if (bankExists.length > 0 || defaultBankEntered) {
      const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_failed;
      const { name, attributes } = e;
      AmplitudeHelper.logEvent(name, {
        [attributes.source]: source,
        [attributes.failure_message]: formatUnderScoreLowerCase(
          t(`login.${BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.DUPLICATE_ENTRY].description}`),
        ),
      });
      return setInfoModal({
        visible: true,
        data: returnBottomSheetContent(
          BOTTONSHEET_KEYS.DUPLICATE_ENTRY,
          BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.DUPLICATE_ENTRY],
        ),
        errorCode: BOTTONSHEET_KEYS.DUPLICATE_ENTRY,
      });
    }
    if (isFeatureAvailale?.[FEATURE_FLAGS.SECURITY_PIN]?.show && pinRegister.isActive) {
      // log 3 retry verifications
      if (pinRegister.invalidAttempts >= 3)
        return setInfoModal({
          visible: true,
          data: returnBottomSheetContent(
            SECURITY_PIN_INFO_MODAL.THREE_RETRY_VERIFICATION,
            SECURITY_PIN_INFO_MODAL.THREE_RETRY_VERIFICATION,
          ),
          errorCode: 'THREE_RETRY_VERIFICATION',
        });
      return navigation.navigate(ScreenNames.GG_VERIFY_PIN, {
        source: 'add_bank_account',
        pin_failed_modal: 'pin_failed_modal_bankaccount',
        onVerifyCallBack: addBankMutate,
        onVerifyValues: {
          accountNumber: values.accountNumber,
          bankId: values.selectedBank.id,
          beneficiaryName: values.accountName,
          nickName: values.accountNickName,
        },
      });
    }
    addBankMutate({
      accountNumber: values.accountNumber,
      bankId: values.selectedBank.id,
      beneficiaryName: values.accountName,
      nickName: values.accountNickName,
      securityPin: null,
    });
  };
}

export function shouldHandleClose(setInfoModal, infoModal, addBankMutate) {
  return values => {
    setInfoModal({ ...infoModal, visible: false });
    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      addBankMutate({
        accountNumber: values.accountNumber,
        bankId: values.selectedBank.id,
        beneficiaryName: values.accountName,
        nickName: values.accountNickName,
        securityPin: null,
      });
      logCloseHandler();
    }
  };
}
function GGAddBankScreen() {
  const { bankList, refetch } = useFetchBanks();
  const [selectedBank, setSelectedBank] = useState(null);
  const navigation = useNavigation();
  const [defaultBank, setDefaultBank] = useState(null);
  const { isSuccess: isSuccessProfile, data: profileData, refetch: refetchProfile } = fetchProfile();
  const { setLoading, setInitialData, pinRegister, setDefaultBank: storeDefaultBank } = getStore();
  const { t } = useTranslation('common');
  const toast = useToast();
  const route = useRoute();
  const { params } = route;
  const { source } = params;

  useEffect(() => {
    // fetch banks
    refetch();
    // fetch profile
    refetchProfile();
  }, []);

  useEffect(() => {
    setDefaultBank(profileData?.account);
    storeDefaultBank(profileData?.account);
  }, [isSuccessProfile, profileData]);

  useEffect(() => {
    onSelectedBankHandler(selectedBank, source);
  }, [selectedBank]);

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
    mutate: addBankMutate,
  } = useMutation({
    mutationFn: payload => addEmployeeBeneficiary(payload),
  });

  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.SECURITY_PIN],
  });
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_KEYS.DUPLICATE_ENTRY,
      BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.DUPLICATE_ENTRY],
    ),
    errorCode: BOTTONSHEET_KEYS.DUPLICATE_ENTRY,
  });
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  useEffect(() => {
    shouldRenderSuccessfulBankAdd(isSuccess, data, toast, t, navigation, params);
  }, [isSuccess, data]);
  useEffect(() => {
    shouldShowError(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  const onCloseHandler = shouldHandleClose(setInfoModal, infoModal, addBankMutate);

  const { openBankList, onPressSecondaryHandler } = addBankHandlers({
    setInfoModal,
    infoModal,
    navigation,
    setSelectedBank,
    source,
  });
  const renderFormContent = renderFormContentFunction(
    t,
    openBankList,
    selectedBank,
    setInfoModal,
    infoModal,
    addBankMutate,
    onPressSecondaryHandler,
    onCloseHandler,
    source,
  );
  return (
    <View style={appStyles.safeAreaView}>
      <Formik
        enableReinitialize
        validationSchema={bankAddSchema(t)}
        initialValues={{
          selectedBank,
          accountNumber: '',
          accountName: '',
          accountNickName: '',
        }}
        onSubmit={onSubmitHandler(
          source,
          defaultBank,
          bankList,
          t,
          setInfoModal,
          isFeatureAvailale,
          pinRegister,
          navigation,
          addBankMutate,
        )}>
        {renderFormContent}
      </Formik>

      <InfoTextComponent
        containerStyle={styles.infoContainer}
        customTextStyle={styles.textStyle}
        iconColor={colors.neutral.charcoal}
        infoText={t('addBank.bankAccountAddInfo')}
        show
        variant="xsm-bold"
        size={scale(24)}
      />
    </View>
  );
}

GGAddBankScreen.propTypes = {};
GGAddBankScreen.defaultProps = {};
export default GGAddBankScreen;
