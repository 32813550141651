import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, ActivityIndicator } from 'react-native';
import styles from './styles';

export const getImagePickerItemIcon = (imageUri, MaterialIcons, ActivityIndicators) => {
  if (imageUri?.uploaded) return <MaterialIcons name="remove-red-eye" size={20} color={colors.neutral.cotton} />;

  if (imageUri?.failed) return <MaterialIcons name="refresh" size={20} color={colors.secondary.orchid} />;

  if (imageUri?.uploading) {
    return <ActivityIndicators color={colors.orange} />;
  }
  return <MaterialIcon name="file-upload" size={20} color={colors.black} />;
};

const ImagePickerItem = ({ onPress, title, imageUri, onPressCancel }) => {
  const componentTheme = useMemo(() => {
    const theme = {
      pressableStyle: styles.button,
      titleStyle: null,
    };
    if (imageUri?.failed) {
      theme.pressableStyle = styles.failedButton;
    }
    if (imageUri?.uploaded) {
      theme.pressableStyle = styles.completedButton;
      theme.titleStyle = styles.name;
    }
    return theme;
  }, [imageUri]);

  const showCloseButton = imageUri?.uploading || imageUri?.uploaded || imageUri?.failed;
  const { t } = useTranslation('common');

  return (
    <View style={styles.container}>
      <Pressable
        testID="uploadBtn"
        onPress={onPress}
        style={componentTheme.pressableStyle}
        android_ripple={{ color: colors.neutral.charcoal }}>
        <Text testID="textTile" variant="sm-bold" color={colors.neutral.darkMist} style={componentTheme.titleStyle}>
          {imageUri?.uploaded ? title : t('transactionDetailsScreen.uploadBuktiPembayaran')}
          {imageUri?.failed && (
            <Text variant="sm-bold" style={styles.failedText}>
              {t('editTransactions.failedToUpload')}
            </Text>
          )}
        </Text>
        {getImagePickerItemIcon(imageUri, MaterialIcon, ActivityIndicator)}
      </Pressable>
      {showCloseButton && (
        <Pressable testID="close-button">
          <MaterialIcon
            size={20}
            onPress={onPressCancel}
            style={styles.cancel}
            name="close"
            color={colors.error.rose}
          />
        </Pressable>
      )}
    </View>
  );
};

ImagePickerItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  imageUri: PropTypes.shape({
    failed: PropTypes.bool,
    uploaded: PropTypes.bool,
    uploading: PropTypes.bool,
    uri: PropTypes.string,
  }),
  onPressCancel: PropTypes.func.isRequired,
};

ImagePickerItem.defaultProps = {
  imageUri: undefined,
};

export default ImagePickerItem;
