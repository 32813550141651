import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const TitleText = ({ title }) => (
  <View style={styles.titleTextContainer}>
    <Text variant="md-bold">{title}</Text>
  </View>
);

TitleText.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleText;
