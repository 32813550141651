// import secureLocalStorage from 'react-secure-storage';

const secureStore = {
  setItemAsync: (key, value) => localStorage.setItem(key, value),
  getItemAsync: key => localStorage.getItem(key),
  remoteItemAsync: key => localStorage.removeItem(key),
  clearAll: () => localStorage.clear(),
};

export default secureStore;
