import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonActions } from '@react-navigation/native';
import { FEATURE_FLAGS } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { SentryHelper } from '@utils/performance/SentryHelper';
import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';
import { getUserBanksFromQuery, serverError } from '@utils/utils';

export async function forceLogout(isSuccess, LogoutData, navigation, setInitialData, queryClient) {
  if (isSuccess && LogoutData) {
    queryClient.clear();
    await secureStore.clearAll();
    tokenManager.clearAccessToken();
    tokenManager.clearRefreshToken();
    await AsyncStorage.clear();
    AmplitudeHelper.clearUserProperties();
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Login' }],
      }),
    );
    setInitialData();
    SentryHelper.clearUser();
  }
}

export function logoutOnError(isError, error, navigation, setInitialData, toast, t) {
  (async () => {
    if (isError && error) {
      if (error[0] === 'GG-GEN-05') {
        await forceLogout(true, {}, navigation, setInitialData);
      }
      serverError(error, toast, t, navigation, setInitialData);
    }
  })();
}

export function onGetUserPropertiesCallBack(setEmployeeProfile) {
  return data => {
    AmplitudeHelper.setUserProperties({
      // [AMPLITUDE_CONSTANTS.userProperties.salary_amount]: data?.gg_salary_profile[0]?.salaryAmount,
      // [AMPLITUDE_CONSTANTS.userProperties.salary_type]: data?.gg_salary_profile[0].salaryTypeInfo.salaryType,
      [AMPLITUDE_CONSTANTS.userProperties.gender]: data?.gg_employee[0].gender,
      // [AMPLITUDE_CONSTANTS.userProperties.fee_type]: data?.gg_salary_profile[0].feeType,
      // [AMPLITUDE_CONSTANTS.userProperties.fee_value]: data?.gg_salary_profile[0].fee,
    });
    setEmployeeProfile({ ...data?.gg_employee[0], ...data?.gg_user?.[0] });
  };
}

export function onGetUserBankCallBank(dataList, setEmployeeBank) {
  if (dataList.length > 0) {
    const dispatchData = getUserBanksFromQuery(dataList);
    setEmployeeBank(dispatchData);
    // dispatch(setUserBankData(dispatchData.defaultBank, dispatchData.userBanks, dispatchData.commonUserReadStatus));
  }
}

export function onCheckSecurityPinCallBack(setShowPinRegister) {
  return data => {
    if (data?.employee_security_pin?.length > 0) {
      setShowPinRegister({
        showPinRegister: false,
        isActive: true,
        invalidAttempts: data.employee_security_pin[0].invalidAttempts,
        verifiedPin: false,
      });
    }
    if (data?.employee_security_pin?.length === 0)
      setShowPinRegister({ showPinRegister: true, isActive: false, invalidAttempts: 0, verifiedPin: false });
  };
}

export function onCheckSecurityErrorCallBack(setShowPinRegister) {
  return () => {
    setShowPinRegister({ showPinRegister: false, isActive: false, invalidAttempts: 0, verifiedPin: false });
  };
}

export function shouldRunOnFocused(isFeatureAvailale, getUserBanks, checkSecurityPin) {
  if (isFeatureAvailale[FEATURE_FLAGS.MUL_BANK_ACC]?.show) getUserBanks();

  checkSecurityPin();
}
