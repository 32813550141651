import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: scale(12),
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: scale(16),
    marginTop: scale(10),
    paddingBottom: scale(10),
    paddingHorizontal: scale(12),
    paddingTop: scale(13),
  },
  textContainer: {
    marginRight: scale(8),
  },
  tokenStyle: { marginTop: scale(4) },
});

export default styles;
