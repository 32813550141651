import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable, View } from 'react-native';
import styles from './style';

const NormalDeptAlert = ({ show, customContainer, icon, title, iconRight, onPressHandler }) => {
  if (show)
    return (
      <Pressable onPress={onPressHandler} style={[styles.container, customContainer]}>
        <View style={styles.styleAdj}>
          {icon}
          <Text variant="sm-bold" style={styles.textStyle}>
            {title}
          </Text>
        </View>
        {iconRight}
      </Pressable>
    );
  return null;
};

NormalDeptAlert.propTypes = {
  show: PropTypes.bool.isRequired,
  customContainer: PropTypes.shape({}),
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  iconRight: PropTypes.element.isRequired,
  onPressHandler: PropTypes.func.isRequired,
};

NormalDeptAlert.defaultProps = {
  customContainer: {},
};

export default NormalDeptAlert;
