import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: scale(7),
    paddingLeft: scale(12),
    paddingVertical: scale(8),
  },
});

export default styles;
