import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function WithdrawalAmount(props) {
  const { requestedAmount } = props;
  const { t } = useTranslation('common');
  return (
    <View style={appStyles.amountContainer}>
      <Text testID="lblAmountOfWithdrawal" variant="lg-normal" flex={1}>
        {t('withdrawalPage.amountOfWithdrawal')}
      </Text>
      <TextCurrency variant="sm-bold" amountCurrency={requestedAmount} showCurrencySymbol />
    </View>
  );
}

WithdrawalAmount.propTypes = {
  requestedAmount: PropTypes.number,
};
WithdrawalAmount.defaultProps = {
  requestedAmount: 0,
};
export default WithdrawalAmount;
