import colors from '@theme/colors';
import { scale } from '@utils/normalize';

import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonStyle: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(12),
    elevation: 6,
    height: scale(24),
    justifyContent: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    width: scale(24),
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: scale(14),
    paddingVertical: scale(8),
  },
  dateText: {
    lineHeight: scale(21),
    marginHorizontal: scale(38),
    textAlign: 'center',
  },
  disabledBtn: {
    backgroundColor: colors.disabled.smoke,
  },

  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});
export default styles;
