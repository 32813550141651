import colors from '@theme/colors';
import { regularFont } from '@theme/theme';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  inputStyles: {
    borderColor: colors.neutral.darkMist,
    borderRadius: scale(8),
    borderWidth: 1,
    color: colors.neutral.charcoal,
    fontFamily: regularFont,
    fontSize: scale(14),
    lineHeight: scale(21),
    marginTop: scale(4),
    outlineStyle: 'none',
    paddingHorizontal: scale(16),
    paddingVertical: scale(13),
  },
});

export default styles;
