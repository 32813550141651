import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tooltipContainerStyle: {
    backgroundColor: colors.neutral.charcoal,
    borderRadius: scale(8),
    height: 'auto',
    marginTop: 4,
    padding: 0,
    // marginLeft: 10,

    // alignSelf: 'center',

    // paddingHorizontal: scale(16),
    // paddingVertical: scale(22),
  },
});
export default styles;
