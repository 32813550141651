import colors from '@theme/colors';
import { verticalScale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bankContainer: {
    alignItems: 'center',
    backgroundColor: colors.success.cactus,
    borderRadius: 50,
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  bankIconStyles: { marginHorizontal: 9, marginVertical: 10 },
  bankParent: { flexDirection: 'row', marginTop: verticalScale(8) },
  maskUnmaskContainer: { alignItems: 'center', flexDirection: 'row' },
});
export default styles;
