import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  dropDownContainerStyles: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: 12,
    borderWidth: 1,
    marginTop: Platform.OS === 'web' ? 0 : -20,
  },
  dropDownStyles: {
    borderColor: colors.neutral.charcoal,
    borderRadius: 12,
    borderWidth: 1,
    overflow: 'hidden',
    paddingVertical: scale(8),
  },
});
export default styles;
