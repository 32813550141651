import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bottomButtonContainer: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    bottom: 0,
    elevation: 0.5,
    flex: 1,
    justifyContent: 'space-evenly',
    paddingBottom: scale(5),
    paddingVertical: scale(2),
    position: 'absolute',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: '100%',
  },
  containerAdj: { marginLeft: scale(18) },
  contentStyle: {
    backgroundColor: colors.neutral.cotton,
    flexGrow: 1,
    paddingBottom: 200,
    paddingTop: 0,
  },
  copyButtonStyle: {
    alignSelf: 'center',
    marginTop: scale(20),
    width: '90%',
  },
  installAmtTxt: { fontSize: scale(12), marginTop: scale(3) },
  metaInfoStyle: { fontSize: scale(12), marginTop: scale(3) },
  metaRow: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginHorizontal: scale(10),
    marginTop: scale(2),
    marginVertical: scale(8),
    paddingVertical: scale(8),
  },
  metaTagStyle: { color: colors.warning.darkSunflower, fontSize: scale(9) },
  metaTitleStyle: { fontSize: scale(12), marginBottom: scale(5) },
  pressableLinkContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: scale(3),
    paddingHorizontal: scale(15),
    paddingTop: scale(19),
    width: '100%',
  },
  pressableStyleContainer: { alignItems: 'center', flex: 1, justifyContent: 'center' },
  pressableTitle: {
    color: colors.secondary.orchid,
    marginLeft: scale(8),
    textDecorationLine: 'underline',
  },
  principleAmtStyle: { color: colors.success.cactus, marginLeft: scale(12) },
  principleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: scale(12),
    paddingVertical: scale(10),
  },
  tenureBubble: {
    alignItems: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: scale(38),
    height: scale(20),
    justifyContent: 'center',
    width: scale(20),
  },
  tenureTxtStyle: {
    color: colors.black,
    fontSize: scale(12),
  },
});

export default styles;
