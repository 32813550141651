import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logPointsRow(employeeId, pointsAccount) {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.UserProfile.poin_clicked.name, {
    employee_id: employeeId,
    poin_balance: pointsAccount?.balance,
    source: AMPLITUDE_EVENT_SOURCES.profile_page,
  });
}

export default logPointsRow;
