const helpClicked = {
  name: 'help_clicked',
  attributes: {
    source: 'source',
  },
};

const referralCode = 'referral code';

export const AMPLITUDE_CONSTANTS = {
  userProperties: {
    salary_amount: 'salary_amount',
    salary_type: 'salary_type',
    gender: 'gender',
    fee_type: 'fee_type',
    fee_value: 'fee_value',
  },
  Withdrawal: {
    ewaModal: {
      name: 'ewa_modal',
      attributes: {
        employee_id: 'employee_id',
        referral_code: 'referral_code',
      },
    },

    withdrawSliderEvent: {
      name: 'withdrawal_slider_input',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        referral_code: 'referral_code',
      },
    },
    transactionSummaryPage: {
      name: 'payment_summary_page',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        fee_requested: 'fee_requested',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    transactionFailure: {
      name: 'transaction_failed',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        source: 'source',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        failure_message: 'failure_message',
        payment_method: 'payment_method',
        fee: 'fee',
        referral_code: 'referral_code',
      },
    },

    transactionRequuested: {
      name: 'transaction_requested',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_requested: 'fee_requested',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    transactionSuccess: {
      name: 'transaction_successful',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_requested: 'fee_requested',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    manualWithdrawal: {
      name: 'withdrawal_manual_input',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        referral_code: 'referral_code',
      },
    },

    verifyWithdrawal: {
      name: 'withdrawnow_button_clicked',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    verifyTransaction: {
      name: 'user_clicked_withdraw_button',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
      },
    },

    verifyTransactionFailed: {
      name: 'withdrawnow_failed',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        failure_message: 'failure_message',
      },
    },
    verifyWithdrawalSuccess: {
      name: 'withdrawnow_successful',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        failure_message: 'failure_message',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    verifyWithdrawalFailed: {
      name: 'withdrawnow_failed',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        failure_message: 'failure_message',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    reasonsModal: {
      name: 'reasons_modal',
      attributes: {
        source: 'soruce',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    userSwipesLeftOrRight: {
      name: 'reasons_modal_swiped',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    selectedReason: {
      name: 'reasons_selected',
      attributes: {
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    kebutuhanLainnya: {
      name: 'reasons_selected',
      attributes: {
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    customReason: {
      name: 'reasons_description_filled',
      attributes: {
        reason: 'reason',
        reason_id: 'reasons_id',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    reasonsModalClose: {
      name: 'reasons_modal_closed',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    applyForWithdrawal: {
      name: 'payment_summary_requested',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        fee_requested: 'fee_requested',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    paymentSummaryPage: {
      name: 'payment_summary_requested',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        fee: 'fee',
        source: 'source',
        referral_code: 'referral_code',
        account_number: 'account_number',
      },
    },
    payment_summary_successful: {
      name: 'payment_summary_successful',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
        requested_amount: 'requested_amount',
      },
    },
    userClickedTermsCheckbox: {
      name: 'terms_checkbox_checked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    userClickedTermsCheckboxUnchecked: {
      name: 'terms_checkbox_unchecked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    termsOfServiceClickEvent: {
      name: 'terms_checkbox_checked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    userAcceptedWithdrawalTerms: {
      name: 'terms_accepted',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    userCanceledTerms: {
      name: 'terms_declined',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    userClickedBackWithdrawalTerms: {
      name: 'terms_page_closed',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    termsCheckboxUnchecked: {
      name: 'terms_checkbox_unchecked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    transactionCancelled: {
      name: 'transaction_cancelled',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    termsAndConditionsPage: {
      name: 'terms_page',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    userAcceptedTerms: {
      name: 'terms_accepted',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    userCanceledWithdrawalTerms: {
      name: 'terms_declined',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    userChangedReason: {
      name: 'reason_change_clicked',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    TransactionFailure: {
      name: 'transaction_failed',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        source: 'source',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        failure_message: 'failure_message',
        payment_method: 'payment_method',
        fee: 'fee',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    success_page_closed: {
      name: 'success_page_closed',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_price: 'product_price',
        reason: 'reason',
        reason_id: 'reason_id',
        payment_method: 'payment_method',
        source: 'source',
        fee_charged: 'fee_charged',
        account_number: 'account_number',
        requested_amount: 'requested_amount',
      },
    },
    selamatPage: {
      name: 'success_page',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        payment_method: 'payment_method',
        source: 'source',
        fee_requested: 'fee_requested',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
        account_number: 'account_number',
        fee_charged: 'fee_charged',
        product_code: 'product_code',
        product_price: 'product_price',
      },
    },

    salary_disbursement_account_clicked: {
      name: 'salary_disbursement_account_clicked',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    salaryAccountClicked: {
      name: 'salary_account_clicked',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    salaryInformationExpand: {
      name: 'salary_information_expand',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    salaryInformationHide: {
      name: 'salary_information_hide',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    CancelButtonClicked: {
      name: 'cancel_button_clicked',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    AmountCardClicked: {
      name: 'amount_card_clicked',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        ppercentage_amount: 'percentage_amount',
        referral_code: 'referral_code',
      },
    },
    ShortCutEWA: {
      name: 'withdrawal_navbar_button_clicked',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },
  },
  Banner: {
    BannerAppeared: {
      name: 'banner_appeared',
      attributes: {
        banner_name: 'banner_name',
      },
    },
    banner_clicked: {
      name: 'banner_clicked',
      attributes: {
        source: 'source',
        banner_name: 'banner_name',
        point_balance: 'point_balance',
      },
    },
  },

  Savings: {
    savingsClicked: {
      name: 'savings_clicked',
      attributes: {
        source: 'source',
      },
    },
    savingsInsightPage: {
      name: 'savings_insight_page',
      attributes: {
        tab: 'tab',
        source: 'source',
      },
    },

    savingsListClicked: {
      name: 'savings_list_clicked',
      attributes: {
        offer_id: 'offer_id',
        source: 'source',
        transaction_id: 'transaction_id',
      },
    },

    CampaignButtonClicked: {
      name: 'campaign_button_clicked',

      attributes: {
        offer_id: 'offer_id',
        source: 'source',
        transaction_id: 'transaction_id',
      },
    },

    click_X: {
      name: 'cancel_button_clicked',
      attributes: {
        offer_id: 'offer_id',
        source: 'source',
        transaction_id: 'transaction_id',
        button: 'button',
      },
    },

    productSuggestionScrolled: {
      name: 'product_suggestion_scrolled',

      attributes: {
        source: 'source',
      },
    },
    ProductSuggestionClicked: {
      name: 'product_suggestion_clicked',

      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
  },

  Poin: {
    productTile: {
      name: 'product_clicked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        source: 'source',
      },
    },
    PoinClicked: {
      name: 'point_clicked',

      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        poin_balance: 'poin_balance',
      },
    },

    PoinPage: {
      name: 'point_page',
      attributes: {
        poin_balance: 'poin_balance',
        source: 'source',
      },
    },
    ProductSuggestionClicked: {
      name: 'product_suggestion_clicked',

      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    ///
    RedeemPoinClicked: {
      name: 'redeem_point_clicked',
      attributes: {
        source: 'source',
        point_balance: 'point_balance',
        employee_id: 'employee_id',
      },
    },

    RedeemNowClicked: {
      name: 'redeem_now_clicked',
      attributes: {
        source: 'source',
        point_balance: 'point_balance',
        employee_id: 'employee_id',
      },
    },

    CancelButtonClicked: {
      name: 'cancel_button_clicked',

      attributes: {
        source: 'source',
        point_balance: 'point_balance',
        employee_id: 'employee_id',
      },
    },

    BannerClicked: {
      name: 'banner_clicked',

      attributes: {
        source: 'source',
        point_balance: 'point_balance',
        employee_id: 'employee_id',
      },
    },
    PoinPaymentTicked: {
      name: 'point_payment_ticked',

      attributes: {
        source: 'source',
        point_balance: 'point_balance',
        employee_id: 'employee_id',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        point_used: 'point_used',
      },
    },

    PoinPaymentFailed: {
      name: 'point_payment_failed',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        point_balance: 'point_balance',
        employee_id: 'employee_id',
        failure_message: 'failure_message',
        point_used: 'point_used',
        referral_code: 'referral_code',
      },
    },
    userConfirmedBillPaymentTransaction: {
      name: 'transaction_requested',
      attributes: {
        account_number: 'account_number',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        product_price: 'product_price',
        reason_id: 'reason_id',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        product_code: 'product_code',
        reason: 'reason',
        referral_code: 'referral_code',
      },
    },
    selamatPage: {
      name: 'success_page',

      attributes: {
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        payment_method: 'payment_method',
        source: 'source',
        fee_requested: 'fee_requested',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
        account_number: 'account_number',
        fee_charged: 'fee_charged',
        product_code: 'product_code',
        product_price: 'product_price',
        fee: 'fee',
      },
    },
  },

  Stories: {
    storyClicked: {
      name: 'story_clicked',
      attributes: {
        source: 'source',
        story_name: 'story_name',
      },
    },
    storyView: {
      name: 'story_view',
      attributes: {
        source: 'source',
        story_name: 'story_name',
        story_set_sequence: 'story_set_sequence',
        number_of_views: 'number_of_views',
      },
    },
    backButtonClicked: {
      name: 'back_button_clicked',
      attributes: {
        source: 'source',
        story_name: 'story_name',
      },
    },
    story_playagain_clicked: {
      name: 'story_playagain_clicked',
      attributes: {
        source: 'source',
        story_name: 'story_name',
      },
    },
    ctaButtonClicked: {
      name: 'cta_button_clicked',
      attributes: {
        source: 'source',
        story_name: 'story_name',
        story_set_sequence: 'story_set_sequence',
        url: 'url',
      },
    },
    userClickedProduct: {
      name: 'product_clicked',
      attributes: {
        employee_id: 'employee_id',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    pembayaranPage: {
      name: 'other_product_page',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },
  },

  TransactionPage: {
    transaction_page: {
      name: 'transaction_page',
      attributes: {
        source: 'source',
      },
    },

    transactionHistoryPage: {
      name: 'transactionHistoryPage',
    },
    transactionSummaryPage: {
      name: 'transactionSummaryPage',
    },

    tooltip_clicked: {
      name: 'tooltip_clicked',
      attributes: {
        source: 'source',
        section: 'section',
      },
    },
    filterRemoved: {
      name: 'filter_reset',
      attributes: {
        source: 'source',
      },
    },
    FilterClicked: {
      name: 'filter_clicked',
      attributes: {
        source: 'source',
      },
    },

    filterSaved: {
      name: 'filter_saved',
      attributes: {
        source: 'source',
        filter_parameter: 'filter_parameter',
        filter_input: 'filter_input',
      },
    },

    filterReset: {
      name: 'filter_reset',
      attributes: {
        source: 'source',
        filter_parameter: 'filter_parameter',
        filter_input: 'filter_input',
      },
    },

    userAddedFilter: {
      name: 'user_added_filter',
    },

    userCanceledFilters: {
      name: 'filter_cancelled',
    },

    userClickedFilter: {
      name: 'user_clicked_filter',
    },
    userRemovedFilter: {
      name: 'user_removed_filter',
    },

    userResetFilters: {
      name: 'user_reset_filter',
    },
    cycle_changed: {
      name: 'cycle_changed',
      attributes: {
        source: 'source',
        start_cycle_date: 'start_cycle_date',
        end_cycle_date: 'end_cycle_date',
      },
    },

    list_transaction_clicked: {
      name: 'list_transaction_clicked',
      attributes: {
        source: 'source',
        transaction_id: 'transaction_id',
      },
    },

    detail_transaction_page: {
      name: 'detail_transaction_page',
      attributes: {
        source: 'source',
        transaction_id: 'transaction_id',
      },
    },

    field_clicked: {
      name: 'field_clicked',
      attributes: {
        source: 'source',
        field_name: 'field_name',
      },
    },

    transaction_edit_saved: {
      name: 'transaction_edit_saved',
      attributes: {
        source: 'source',
        transaction_id: 'transaction_id',
        parameter_changed: 'parameter_changed',
        old_reason_input: 'old_reason_input',
        new_reason_input: 'new_reason_input',
        old_description_input: 'old_description_input',
        new_description_input: 'new_description_input',
      },
    },
  },

  AppTour: {
    AppTourSavings1: {
      name: 'app_tour_savings1',
      attributes: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        click_next: 'click_next',
        click_x: 'click_x',
        click_back: 'click_back',
        click_finish: 'click_finish',
        page: 'page',
        action: 'action',
      },
    },
  },
  Treasury: {
    financial_page: {
      name: 'financial_page',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    gold_id_bottom_sheet: {
      name: 'gold_id_bottom_sheet',
    },
    gold_entry_clicked: {
      name: 'gold_entry_clicked',
      attributes: {
        source: 'source',
      },
    },
    treasury_webview_opened: {
      name: 'treasury_webview_opened',
      attributes: {
        source: 'source',
      },
    },
    x_button_clicked: {
      name: 'x_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    continue_button_clicked: {
      name: 'continue_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    name_clicked: {
      name: 'name_clicked',
      attributes: {
        source: 'source',
      },
    },
    name_inputted: {
      name: 'name_inputted',
      attributes: {
        source: 'source',
        name: 'name',
      },
    },
    ktp_number_clicked: {
      name: 'ktp_number_clicked',
      attributes: {
        source: 'source',
      },
    },
    ktp_number_inputted: {
      name: 'ktp_number_inputted',
      attributes: {
        source: 'source',
        ktp_number: 'ktp_number',
      },
    },
    ktp_number_failed: {
      name: 'ktp_number_failed',
      attributes: {
        source: 'source',
        failure_message: 'failure_message',
      },
    },
    promo_code_clicked: {
      name: 'promo_code_clicked',
      attributes: {
        source: 'source',
      },
    },
    promo_code_inputted: {
      name: 'promo_code_inputted',
      attributes: {
        source: 'source',
        promo_code: 'promo_code',
      },
    },
    ktp_number_submitted: {
      name: 'ktp_number_submitted',
      attributes: {
        source: 'source',
        promo_code: 'promo_code',
      },
    },
    tooltip_clicked: {
      name: 'tooltip_clicked',
      attributes: {
        source: 'source',
      },
    },
    promo_code_submitted: {
      name: 'promo_code_submitted',
      attributes: {
        source: 'source',
        promo_code: 'promo_code',
      },
    },
  },
  NavBar: {
    financial_menu_clicked: {
      name: 'financial_menu_clicked',
      attributes: {
        source: 'source',
      },
    },
    transaction_menu_clicked: {
      name: 'transaction_menu_clicked',
      attributes: {
        source: 'source',
      },
    },
    othersPage: {
      name: 'others_page',
    },

    logoutClicked: {
      name: 'logout_clicked',
    },

    home_menu_clicked: {
      name: 'home_menu_clicked',
    },

    other_product_menu_clicked: {
      name: 'other_product_menu_clicked',
      attributes: {
        source: 'source',
      },
    },

    others_menu_clicked: {
      name: 'others_menu_clicked',
      attributes: {
        source: 'source',
      },
    },

    home_page: {
      name: 'home_page',
    },
    pembayaranPageFromHome: {
      name: 'home_page',
    },
  },

  BillPayment: {
    tabs: {
      name: 'tab_clicked',
      attributes: {
        accrued_salary: 'accrued_salary',
        point: 'point',
        tab: 'tab',
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    transactionSummary: {
      paymentMethod: {
        name: 'payment_method_clicked',
        attributes: {
          employee_id: 'employee_id',
          product_code: 'product_code',
          transaction_category_id: 'transaction_category_id',
          source: 'source',
          referral_code: 'referral_code',
        },
      },
      pickPaymentMethod: {
        name: 'payment_method_picked',
        attributes: {
          employee_id: 'employee_id',
          transaction_category_id: 'transaction_category_id',
          point_balance: 'point_balance',
          point_used: 'point_used',
          product_code: 'product_code',
          payment_method: 'payment_method',
          source: 'source',
          referral_code: 'referral_code',
        },
      },
    },
    pembayaranPage: {
      name: 'other_product_page',
      attributes: {
        source: 'source',
        tab: 'tab',
        referral_code: 'referral_code',
      },
    },

    userClickedProduct: {
      name: 'product_clicked',
      attributes: {
        employee_id: 'employee_id',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        referral_code: 'referral_code',
      },
    },

    ProductPage: {
      name: 'product_page',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        referral_code: 'referral_code',
      },
    },

    userClickedBack: {
      name: 'back_arrow_clicked',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        referral_code: 'referral_code',
      },
    },

    AccountInput: {
      newCustomerIdTabClicked: {
        name: 'new_customer_id_tab_clicked',
        attributes: {
          account_number: 'account_number',
          transaction_category_id: 'transaction_category_id',
          source: 'source',
          referral_code: 'referral_code',
        },
      },

      newCustomerIdBoxClicked: {
        name: 'new_customer_id_box_clicked',
        attributes: {
          transaction_category_id: 'transaction_category_id',
          source: 'source',
          referral_code: 'referral_code',
        },
      },

      userClickedInputBaru: {
        name: 'new_customer_id_filled',
        attributes: {
          account_number: 'account_number',
          transaction_category_id: 'transaction_category_id',
          source: 'source',
          referral_code: 'referral_code',
        },
      },

      registeredIdTabClicked: {
        name: 'registered_id_tab_clicked',
        attributes: {
          transaction_category_id: 'transaction_category_id',
          source: 'source',
          referral_code: 'referral_code',
        },
      },

      userClickedIdTerdaftar: {
        name: 'registered_id_selected',
        attributes: {
          account_number: 'account_number',
          transaction_category_id: 'transaction_category_id',
          source: 'source',
          referral_code: 'referral_code',
        },
      },
    },

    verifyingBillPaymentTransaction: {
      name: 'payment_summary_requested',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    verifyTransactionSuccess: {
      name: 'payment_summary_successful',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    verifyTransactionFailed: {
      name: 'payment_summary_failed',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        failure_message: 'failure_message',
        referral_code: 'referral_code',
      },
    },

    BillPaymentSuccess: {
      name: 'transaction_successful',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        product_price: 'product_price',
        reason: 'reason',
        reason_id: 'reason_id',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    BillPaymentFailed: {
      name: 'transaction_failed',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        product_price: 'product_price',
        reason: 'reason',
        reason_id: 'reason_id',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        source: 'source',
        referral_code: 'referral_code',
        failure_message: 'failure_message',
      },
    },

    billPaymentTransactionSummary: {
      name: 'payment_summary_page',
      attributes: {
        account_number: 'account_number',
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    // InquiryBillPaymentError: {
    //   name: "payment_summary_failed",
    //   attributes: {
    //     account_number: "account_number",
    //     transaction_category_id: "transaction_category_id",
    //     product_code: "product_code",
    //     reason: "reason",
    //     reason_id: "reason_id",
    //     failure_message: "failure_message",
    //     source: "source",
    //   },
    // },

    userClickedPembayaranTermsCheckbox: {
      name: 'terms_checkbox_checked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    termsPage: {
      name: 'terms_page',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    userCancelledBillPaymentTerms: {
      name: 'terms_declined',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
      },
    },

    userClickedLihatDetail: {
      name: 'details_modal',
      attributes: {
        account_number: 'account_number',
        product_price: 'product_price',
        product_code: 'product_code',
        transaction_category_id: 'transaction_category_id',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
      },
    },

    detailsModal: {
      name: 'details_clicked',
      attributes: {
        account_number: 'account_number',
        product_price: 'product_price',
        product_code: 'product_code',
        transaction_category_id: 'transaction_category_id',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
      },
    },

    detailsModalClosed: {
      name: 'details_modal_closed',
    },

    userAcceptedBillPaymentTerms: {
      name: 'terms_accepted',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
      },
    },

    termsPageClosed: {
      name: 'terms_page_closed',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
      },
    },

    termsCheckboxUnchecked: {
      name: 'terms_checkbox_unchecked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    userClosedBillPaymentModal: {
      name: 'transaction_cancelled',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        button: 'button',
        source: 'source',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    userCanceledBillPaymentTransaction: {
      name: 'transaction_cancelled',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        button: 'button',
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },

    providerSelected: {
      name: 'provider_selected',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        provider: 'provider',
      },
    },

    dropdownSelected: {
      name: 'dropdown_selected',
      attributes: {
        transaction_category_id: 'transaction_category_id',
      },
    },

    poinPaymentTicked: {
      name: 'poin_payment_ticked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        timestamp: 'timestamp',
        source: 'source',
      },
    },

    poinPaymentTickedWithPointsUsed: {
      name: 'point_payment_ticked',
      attributes: {
        employee_id: 'employee_id',
        transaction_category_id: 'transaction_category_id',
        point_balance: 'point_balance',
        point_used: 'point_used',
        product_code: 'product_code',
        source: 'source',
      },
    },

    userChoosesNominal: {
      name: 'nominal_selected',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        product_price: 'product_price',
        account_number: 'account_number',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    selamatPage: {
      name: 'success_page',

      attributes: {
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        reason: 'reason',
        reason_id: 'reason_id',
        payment_method: 'payment_method',
        source: 'source',
        fee_requested: 'fee_requested',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
        account_number: 'account_number',
        fee_charged: 'fee_charged',
        product_code: 'product_code',
        product_price: 'product_price',
        fee: 'fee',
      },
    },

    PostPaid: {
      pdamAreaSelected: {
        name: 'pdam_area_selected',
        attributes: {
          product_code: 'product_code',
        },
      },
    },

    Asuransi: {
      qoalaBannerClick: {
        name: 'banner_clicked',
        attributes: {
          transaction_category_id: 'transaction_category_id',
          banner_name: 'banner_name',
        },
      },
    },

    Voucher: {
      detailsProductPage: {
        name: 'details_product_page',
        attributes: {
          transaction_category_id: 'transaction_category_id',
          product_code: 'product_code',
          product_price: 'product_price',
          account_number: 'account_number',
          reason: 'reason',
          reason_id: 'reason_id',
        },
      },

      seeMoreClicked: {
        name: 'see_more_clicked',
        attributes: {
          transaction_category_id: 'transaction_category_id',
          product_code: 'product_code',
          product_price: 'product_price',
          account_number: 'account_number',
          reason: 'reason',
          reason_id: 'reason_id',
          section: 'section',
        },
      },

      seeLessClicked: {
        name: 'see_less_clicked',
        attributes: {
          transaction_category_id: 'transaction_category_id',
          product_code: 'product_code',
          product_price: 'product_price',
          account_number: 'account_number',
          reason: 'reason',
          reason_id: 'reason_id',
          section: 'section',
        },
      },
    },
  },

  Login: {
    loginPage: {
      name: 'login_page',
    },
    phoneNumberClicked: {
      name: 'phone_number_clicked',
    },

    phoneNumberFilled: {
      name: 'phone_number_filled',
    },
    otpRequested: {
      name: 'otp_requested',
      attributes: {
        phone_number: 'phone_number',
        source: 'source',
      },
    },
    otpFailed: {
      name: 'otp_failed',
      attributes: {
        phone_number: 'phone_number',
        failure_message: 'failure_message',
      },
    },
    otpSuccessful: {
      name: 'otp_successful',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    otpPage: {
      name: 'otp_page',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    otpResendClicked: {
      name: 'otp_resend_clicked',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    otpInput: {
      name: 'otp_input',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    loginAttempted: {
      name: 'login_attempted',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    loginFailed: {
      name: 'login_failed',
      attributes: {
        phone_number: 'phone_number',
        failure_message: 'failure_message',
      },
    },
    loginSuccessful: {
      name: 'login_successful',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    dashboardPage: {
      name: 'home_page',
    },
    termsClicked: {
      name: 'terms_clicked',
      attributes: {
        phone_number: 'phone_number',
        source: 'source',
      },
    },
    productInfoClicked: {
      name: 'product_info_clicked',
      attributes: {
        source: 'source',
      },
    },
    helpClicked,
    registrationClicked: {
      name: 'registration_clicked',
      attributes: {
        source: 'source',
      },
    },
    registrationPage: {
      name: 'registration_page',
    },
    registrationRegisteredEmployer: {
      name: 'registration_registered_employer',
    },
    registrationUnregisteredEmployer: {
      name: 'registration_unregistered_employer',
    },
    registrationContinued: {
      name: 'registration_continued',
    },
  },

  MenuBar: {
    Home: {
      name: 'home_menu_clicked',
      source: 'source',
    },
    Transaksi: {
      name: 'transaction_menu_clicked',
      source: 'source',
    },

    Pembayaran: {
      name: 'other_product_menu_clicked',
      source: 'source',
    },
    Lainnya: {
      name: 'others_menu_clicked',
      source: 'source',
    },
  },

  Referral: {
    referral_button_clicked: {
      name: 'referral_button_clicked',
    },

    Banner: {
      name: 'banner_clicked',
      attributes: {
        source: 'source',
        point_balance: 'point_balance',
      },
    },

    TNC: {
      name: 'referral_tnc_page',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
      },
    },

    CopySuccess: {
      name: 'copy_code_clicked',
      attributes: {
        referral_code: 'referral_code',
      },
    },
    CopyFailed: {
      name: 'copy_code_failed',
      attributes: {
        failure_message: 'failure_message',
        referral_code: 'referral_code',
      },
    },
    Share: {
      name: 'share_code_clicked',
      attributes: {
        referral_code: 'referral_code',
      },
    },
    Select: {
      name: 'channel_code_clicked',
      attributes: {
        channel: 'channel',
      },
    },

    Dropdown: {
      name: 'tnc_dropdown_clicked',
      attributes: {
        action: 'action',
      },
    },
  },
  appTourSavings: {
    name: 'app_tour_savings0',
    attributes: {
      page: 'page',
      action: 'action',
    },
  },
  redeemptionFlow: {
    referral_code_inputted: {
      name: 'referral_code_inputted',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
      },
    },
    referral_code_submit_clicked: {
      name: 'referral_code_submit_clicked',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
      },
    },
    referral_code_tooltip: {
      name: 'referral_code_tooltip',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
      },
    },
    referral_code_successful: {
      name: 'referral_code_successful',
      attributes: {
        referral_code: 'referral_code',
        action: 'action',
        failure_message: 'failure_message',
        source: 'source',
      },
    },
    referral_code_applicable: {
      name: 'referral_code_applicable',
      attributes: {
        source: 'source',
      },
    },
    referral_code_reminder: {
      name: 'referral_code_reminder',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        action: 'action',
      },
    },
    referral_code_failed: {
      name: 'referral_code_failed',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        failure_message: 'failure_message',
      },
    },
    referral_transaction_page_failed: {
      name: 'referral_transaction_page_failed',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        action: 'action',
        failure_message: 'failure_message',
      },
    },
    referral_app_tour: {
      name: 'referral_app_tour',
      attributes: {
        source: 'source',
        action: 'action',
      },
    },
  },
  attendancePaySlip: {
    attendance: {
      name: 'attendance_page',
      attributes: {
        end_cycle_date: 'end_cycle_date',
        start_cycle_date: 'start_cycle_date',
        employee_id: 'employee_id',
      },
    },
    check_in_clicked: {
      name: 'check_in_clicked',
      attributes: {
        data: 'data',
        employee_id: 'employee_id',
        timestamp: 'timestamp',
      },
    },
    check_out_clicked: {
      name: 'check_out_clicked',
      attributes: {
        data: 'data',
        employee_id: 'employee_id',
        timestamp: 'timestamp',
      },
    },
    check_in_confirmed: {
      name: 'check_in_confirmed',
      attributes: {
        employee_id: 'employee_id',
        timestamp: 'timestamp',
      },
    },
    check_out_confirmed: {
      name: 'check_out_confirmed',
      attributes: {
        employee_id: 'employee_id',
        timestamp: 'timestamp',
      },
    },
    skip_break_clicked: {
      name: 'skip_break_clicked',
      attributes: {
        employee_id: 'employee_id',
        timestamp: 'timestamp',
      },
    },
    salary_clicked: {
      name: 'salary_clicked',
      attributes: {
        employee_id: 'employee_id',
      },
    },
    salary_page: {
      name: 'salary_page',
      attributes: {
        employee_id: 'employee_id',
      },
    },
    detailed_salary_clicked: {
      name: 'detailed_salary_clicked',
      attributes: {
        employee_id: 'employee_id',
        cycle_date: 'cycle_date',
      },
    },
    detailed_salary_page: {
      name: 'detailed_salary_page',
      attributes: {
        employee_id: 'employee_id',
        cycle_date: 'cycle_date',
      },
    },
    payslip_downloaded: {
      name: 'payslip_downloaded',
      attributes: {
        employee_id: 'employee_id',
        cycle_date: 'cycle_date',
      },
    },
    payslip_shared: {
      name: 'payslip_shared',
      attributes: {
        employee_id: 'employee_id',
        cycle_date: 'cycle_date',
      },
    },
    reasons_description_cancelled: {
      name: 'reasons_description_cancelled',
      attributes: {
        source: 'source',
      },
    },
  },

  AppTourInsider: {
    referral: {
      name: 'referrer_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    homepage: {
      name: 'homepage_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },

    tagihanpln: {
      name: 'tagihan_pln_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    pdam: {
      name: 'pdam_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    pascabayar: {
      name: 'pascabayar_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    pulsa: {
      name: 'pulsa_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    paketdata: {
      name: 'paket_data_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    referral_code_apply: {
      name: 'referral_code_apply_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    tokenpln: {
      name: 'tokenpln',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    profile_page_app_tour: {
      name: 'profile_page_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
  },
  AutoDisburse: {
    opt_in_autowd_clicked: {
      name: 'opt_in_autowd_clicked',
      attributes: {
        source: 'source',
      },
    },
    autowd_tnc_page: {
      name: 'autowd_tnc_page',
    },
    autowd_tnc_clicked: {
      name: 'autowd_tnc_clicked',
    },
    back_button_clicked: {
      name: 'back_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    continue_button_clicked: {
      name: 'continue_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    opt_out_autowd_clicked: {
      name: 'opt_out_autowd_clicked',
      attributes: {
        source: 'source',
      },
    },
  },
  authentication: {
    login_page: {
      name: 'login_page',
      attributes: {},
    },
    phone_number_clicked: {
      name: 'phone_number_clicked',
      attributes: {},
    },
    phone_number_filled: {
      name: 'phone_number_filled',
      attributes: {},
    },
    otp_requested: {
      name: 'otp_requested',
      attributes: {
        phone_number: 'phone_number',
        source: 'source',
      },
    },
    otp_failed: {
      name: 'otp_failed',
      attributes: {
        phone_number: 'phone_number',
        failure_message: 'failure_message',
        source: 'source',
      },
    },
    otp_successful: {
      name: 'otp_successful',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    otp_page: {
      name: 'otp_page',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    otp_resend_clicked: {
      name: 'otp_resend_clicked',
      attributes: {
        phone_number: 'phone_number',
        source: 'source',
      },
    },
    otp_input: {
      name: 'otp_input',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    login_attempted: {
      name: 'login_attempted',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    login_failed: {
      name: 'login_failed',
      attributes: {
        phone_number: 'phone_number',
        failure_message: 'failure_message',
      },
    },
    login_retry: {
      name: 'login_retry',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    login_check_data_clicked: {
      name: 'login_check_data_clicked',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    login_modal_closed: {
      name: 'login_modal_closed',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    login_check_data_page: {
      name: 'login_check_data_page',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    id_type_clicked: {
      name: 'id_type_clicked',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    id_type_chosen: {
      name: 'id_type_chosen',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
      },
    },
    id_number_clicked: {
      name: 'id_number_clicked',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
      },
    },
    id_number_filled: {
      name: 'id_number_filled',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
        id_number: 'id_number',
      },
    },
    employee_name_clicked: {
      name: 'employee_name_clicked',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
        id_number: 'id_number',
      },
    },
    employee_name_filled: {
      name: 'employee_name_filled',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
        id_number: 'id_number',
        employee_name: 'employee_name',
      },
    },
    employer_name_clicked: {
      name: 'employer_name_clicked',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
        id_number: 'id_number',
        employee_name: 'employee_name',
      },
    },
    employer_name_filled: {
      name: 'employer_name_filled',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
        id_number: 'id_number',
        employee_name: 'employee_name',
        employer_name: 'employer_name',
      },
    },
    check_data_attributes: {
      phone_number: 'phone_number',
      id_type: 'id_type',
      id_number: 'id_number',
      employee_name: 'employee_name',
      employer_name: 'employer_name',
    },

    login_check_data_closed: {
      name: 'login_check_data_closed',
      attributes: {
        phone_number: 'phone_number',
      },
    },
    login_check_data_requested: {
      name: 'login_check_data_requested',
      attributes: {
        phone_number: 'phone_number',
        id_type: 'id_type',
        id_number: 'id_number',
        employee_name: 'employee_name',
        employer_name: 'employer_name',
      },
    },
    terms_clicked: {
      name: 'terms_clicked',
      attributes: {
        phone_number: 'phone_number',
        source: 'source',
      },
    },
    login_terms_page: {
      name: 'login_terms_page',
      attributes: {
        source: 'source',
      },
    },
    back_arrow_clicked: {
      name: 'back_arrow_clicked',
      attributes: {
        source: 'source',
      },
    },
    help_clicked: {
      name: 'help_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    registration_clicked: {
      name: 'registration_clicked',
      attributes: {
        source: 'source',
      },
    },
    banner_swiped: {
      name: 'banner_swiped',
      attributes: {
        phone_number: 'phone_number',
      },
    },
  },
  missions: {
    bannerClicked: {
      name: 'banner_clicked',
      attributes: {
        banner_name: 'banner_name',
        source: 'source',
      },
    },
    mission_card_click: {
      name: 'mission_card_click',
      attributes: {
        source: 'source',
      },
    },
    mission_page: {
      name: 'mission_page',
      attributes: {
        tier_name: 'tier_name',
        source: 'source',
      },
    },
    information_click: {
      name: 'information_click',
      attributes: {
        source: 'source',
      },
    },
    cancel_button_clicked: {
      name: 'cancel_button_clicked',
      attributes: {
        employee_id: 'employee_id',
        source: 'source',
      },
    },
    check_mission_clicked: {
      name: 'check_mission_clicked',
      attributes: {
        employee_id: 'employee_id',
        source: 'source',
      },
    },
    mission_clicked: {
      name: 'mission_clicked',
      attributes: {
        mission_id: 'mission_id',
      },
    },
  },
  VoucherInventory: {
    milvik_product_tab_clicked: {
      name: 'milvik_product_tab_clicked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        source: 'source',
      },
    },
    product_oos: {
      name: 'product_oos',
      attributes: {
        transaction_category_id: 'transaction_category_id',
      },
    },
    milvik_vouchers_tab_clicked: {
      name: 'milvik_vouchers_tab_clicked',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    back_arrow_clicked: {
      name: 'back_arrow_clicked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        source: 'source',
      },
    },
    success_page_cta: {
      name: 'success_page_cta',
      attributes: {
        cta: 'cta',
        transaction_category_id: 'transaction_category_id',
      },
    },
    copy_code_clicked: {
      name: 'milvik_copy_code_clicked',
      attributes: {
        source: 'source',
        code: 'code',
        status: 'status',
        transaction_category_id: 'transaction_category_id',
        feature: 'feature',
      },
    },
    copy_code_failed: {
      name: 'milvik_copy_code_failed',
      attributes: {
        source: 'source',
        code: 'code',
        status: 'status',
        transaction_category_id: 'transaction_category_id',
        feature: 'feature',
      },
    },
    consult_now_clicked: {
      name: 'consult_now_clicked',
      attributes: {
        source: 'source',
        status: 'status',
        transaction_category_id: 'transaction_category_id',
      },
    },
    consult_now_failed: {
      name: 'consult_now_failed',
      attributes: {
        source: 'source',
        status: 'status',
        failure_reason: 'failure_reason',
      },
    },
    user_leaves_success_page: {
      name: 'user_leaves_success_page',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    see_more_clicked: {
      name: 'see_more_clicked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        product_price: 'product_price',
        account_number: 'account_number',
        reason: 'reason',
        reason_id: 'reason_id',
        section: 'section',
      },
    },
    see_less_clicked: {
      name: 'see_less_clicked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        product_price: 'product_price',
        account_number: 'account_number',
        reason: 'reason',
        reason_id: 'reason_id',
        section: 'section',
      },
    },
    consult_popup_closed: {
      name: 'consult_popup_closed',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
  },
  account_drop_down_selected: {
    name: 'account_drop_down_selected',
    attributes: {
      source: 'source',
      dynamic_account_name: 'dynamic_account_name',
    },
  },
  account_drop_down_clicked: {
    name: 'account_drop_down_clicked',
    attributes: {
      source: 'source',
      dynamic_account_name: 'dynamic_account_name',
    },
  },
  sd_period_started: {
    name: 'sd_period_started',
  },
  cancel_button_clicked: {
    name: 'cancel_button_clicked',
    attributes: {
      source: 'source',
    },
  },
  workAllowance: {
    upload_clicked: {
      name: 'upload_clicked',
      attributes: {
        transaction_id: 'transaction_id',
      },
    },
    image_chosen: {
      name: 'image_chosen',
      attributes: {
        status: 'status',
        failure_message: 'failure_message',
        transaction_id: 'transaction_id',
      },
    },
    image_viewed: {
      name: 'image_viewed',
      attributes: {
        transaction_id: 'transaction_id',
        image_id: 'image_id',
      },
    },
    image_deleted: {
      name: 'image_deleted',
      attributes: {
        transaction_id: 'transaction_id',
        image_id: 'image_id',
      },
    },
  },

  ProfilePage: {
    referral_banner_clicked: {
      name: 'referral_banner_clicked',
      attributes: {
        source: 'source',
      },
    },
    logout_clicked: {
      name: 'logout_clicked',
      attributes: {
        source: 'source',
      },
    },
    logout_cancelled_clicked: {
      name: 'logout_cancelled_clicked',
      attributes: {
        source: 'source',
        button: 'button',
      },
    },
    logout_confirm_clicked: {
      name: 'logout_confirm_clicked',
      attributes: {
        source: 'source',
      },
    },
  },
  permissionsUi: {
    permission_page_appear: {
      name: 'permission_page_appear',
      attributes: {},
    },
    permission_page: {
      name: 'permission_page',
      attributes: {
        action: 'action',
        source: 'source',
      },
    },
    phonebook_permission: {
      name: 'phonebook_permission',
      attributes: { action: 'action', source: 'source' },
    },
    location_permission: {
      name: 'location_permission',
      attributes: { action: 'action', source: 'source' },
    },
  },
  bankRegistration: {
    bank_account_app_tour: {
      name: 'bank_account_app_tour',
      attributes: {
        page: 'page',
        action: 'action',
      },
    },
    add_bank_account_clicked: {
      name: 'add_bank_account_clicked',
      attributes: {
        source: 'source',
      },
    },
    add_bank_account_failed: {
      name: 'add_bank_account_failed',
      attributes: {
        source: 'source',
        failure_message: 'failure_message',
      },
    },
    add_bank_account_page: {
      name: 'add_bank_account_page',
      attributes: {
        source: 'source',
      },
    },
    bank_name_clicked: {
      name: 'bank_name_clicked',
      attributes: {
        source: 'source',
      },
    },
    bank_name_searched: {
      name: 'bank_name_searched',
      attributes: {
        source: 'source',
      },
    },
    bank_name_chosen: {
      name: 'bank_name_chosen',
      attributes: {
        source: 'source',
      },
    },
    account_number_clicked: {
      name: 'account_number_clicked',
      attributes: {
        source: 'source',
      },
    },
    account_number_filled: {
      name: 'account_number_filled',
      attributes: {
        source: 'source',
      },
    },
    beneficiary_name_clicked: {
      name: 'beneficiary_name_clicked',
      attributes: {
        source: 'source',
      },
    },
    beneficiary_name_filled: {
      name: 'beneficiary_name_filled',
      attributes: {
        source: 'source',
      },
    },
    nickname_clicked: {
      name: 'nickname_clicked',
      attributes: {
        source: 'source',
      },
    },
    nickname_filled: {
      name: 'nickname_filled',
      attributes: {
        source: 'source',
      },
    },
    add_bank_account_saved: {
      name: 'add_bank_account_saved',
      attributes: {
        source: 'source',
        bank_name: 'bank_name',
        account_number: 'account_number',
        beneficiary_name: 'beneficiary_name',
        nickname: 'nickname',
      },
    },
    bank_page_clicked: {
      name: 'bank_page_clicked',
      attributes: {
        source: 'source',
      },
    },
    bank_page: {
      name: 'bank_page',
      attributes: {
        source: 'source',
      },
    },
    eye_icon_clicked: {
      name: 'eye_icon_clicked',
      attributes: {
        source: 'source',
        bank_account_id: 'bank_account_id',
        action: 'action',
      },
    },
    help_clicked: {
      name: 'help_clicked',
      attributes: {
        source: 'source',
      },
    },
    bank_account_delete_clicked: {
      name: 'bank_account_delete_clicked',
      attributes: {
        source: 'source',
      },
    },
  },
  Loans: {
    loan_tile_clicked: {
      name: 'loan_tile_clicked',
      attributes: {
        status: 'status',
      },
    },

    loan_offers_page: {
      name: 'loan_offers_page',
      attributes: {
        source: 'source',
      },
    },

    additional_loan_info_clicked: {
      name: 'additional_loan_info_clicked',
    },
    loan_selection_clicked: {
      name: 'loan_selection_clicked',
      attributes: {
        loan_product_code: 'loan_product_code',
      },
    },

    loan_tnc_view_clicked: {
      name: 'loan_tnc_view_clicked',
      attributes: {
        loan_product_code: 'loan_product_code',
      },
    },
    loan_tnc_checkbox_clicked: {
      name: 'loan_tnc_checkbox_clicked',
      attributes: {
        loan_product_code: 'loan_product_code',
      },
    },

    loan_requested: {
      name: 'loan_requested',
      attributes: {
        loan_product_code: 'loan_product_code',
      },
    },

    loan_verification: {
      name: 'loan_verification',
      attributes: {
        loan_product_code: 'loan_product_code',
      },
    },

    loan_schedule_page: {
      name: 'loan_schedule_page',
      attributes: {
        loan_product_code: 'loan_product_code',
      },
    },
  },

  PhoneEnroll: {
    phone_list_clicked: {
      name: 'phone_list_clicked',
      attributes: {
        source: 'source',
      },
    },

    phone_list_page: {
      name: 'phone_list_page',
      attributes: {
        source: 'source',
        state: 'state',
        transaction_category_id: 'transaction_category_id',
      },
    },

    add_phonenumber_clicked: {
      name: 'add_phonenumber_clicked',
      attributes: {
        source: 'source',
        state: 'state',
        button: 'button',
        transaction_category_id: 'transaction_category_id',
      },
    },

    add_phonenumber_page: {
      name: 'add_phonenumber_page',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },

    phonenumber_input: {
      name: 'add_phonenumber_page',
      attributes: {
        source: 'source',
        phone_number: 'phone_number',
      },
    },

    phonenumber_input_error: {
      name: 'phonenumber_input_error',
      attributes: {
        error_message: 'error_message',
      },
    },

    phonenumber_name_input: {
      name: 'phonenumber_name_input',
      attributes: {
        source: 'source',
        input: 'input',
      },
    },
    help_clicked: {
      name: 'help_clicked',
      attributes: {
        source: 'source',
      },
    },
    phonenumber_delete_clicked: {
      name: 'phonenumber_delete_clicked',
      attributes: {
        number: 'number',
        name: 'name',
        source: 'source',
      },
    },

    phonenumber_delete_cancelled: {
      name: 'phonenumber_delete_cancelled',
      attributes: {
        number: 'number',
        name: 'name',
        button: 'button',
      },
    },

    phonenumber_delete_confirmed: {
      name: 'phonenumber_delete_confirmed',
      attributes: {
        number: 'number',
        name: 'name',
      },
    },

    change_number_clicked: {
      name: 'change_number_clicked',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },

    select_phone_page: {
      name: 'select_phone_page',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        state: 'state',
      },
    },

    phonenumber_input_clicked: {
      name: 'phonenumber_input_clicked',
      attributes: {
        source: 'source',
        phone_number: 'phone_number',
      },
    },
    phonenumber_filled: {
      name: 'phonenumber_filled',
      attributes: {
        source: 'source',
        phone_number: 'phone_number',
      },
    },

    phonenumber_name_clicked: {
      name: 'phonenumber_name_clicked',
      attributes: {
        source: 'source',
        input: 'input',
      },
    },

    phonenumber_name_filled: {
      name: 'phonenumber_name_filled',
      attributes: {
        source: 'source',
        input: 'input',
      },
    },

    save_phonenumber_clicked: {
      name: 'save_phonenumber_clicked',
      attributes: {
        source: 'source',
        phone_number: 'phone_number',
        phone_name: 'phone_name',
      },
    },
    save_phonenumber_error: {
      name: 'save_phonenumber_error',
      attributes: {
        source: 'source',
        error_message: 'error_message',
      },
    },
    transact_now_clicked: {
      name: 'transact_now_clicked',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    phonenumber_selected: {
      name: 'phonenumber_selected',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        selected_phone_number: 'selected_phone_number',
      },
    },
  },

  transfer: {
    product_clicked: {
      name: 'product_clicked',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    product_page: {
      name: 'product_page',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    select_bank_account_clicked: {
      name: 'select_bank_account_clicked',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    select_bank_account_page: {
      name: 'select_bank_account_page',
    },
    bank_account_selected: {
      name: 'bank_account_selected',
      attributes: {
        source: 'source',
        transaction_category_id: 'transaction_category_id',
      },
    },
    transfer_manual_input: {
      name: 'transfer_manual_input',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
      },
    },
    withdrawnow_button_clicked: {
      name: 'withdrawnow_button_clicked',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        dynamic_account_name: 'dynamic_account_name',
        referral_code: referralCode,
        locK_period: 'locK_period',
        status: 'status',
      },
    },
    withdrawnow_successful: {
      name: 'withdrawnow_successful',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        dynamic_account_name: 'dynamic_account_name',
        referral_code: referralCode,
      },
    },
    withdrawnow_failed: {
      name: 'withdrawnow_failed',
      attributes: {
        source: 'source',
        requested_amount: 'requested_amount',
        transaction_category_id: 'transaction_category_id',
        dynamic_account_name: 'dynamic_account_name',
        referral_code: 'referral code',
        failure_message: 'failure_message',
      },
    },
    reasons_modal: {
      name: 'reasons_modal',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    reasons_modal_swiped: {
      name: 'reasons_modal_swiped',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
    reasons_selected: {
      name: 'reasons_modal_swiped',
      attributes: {
        source: 'source',
        referral_code: 'referral_code',
        dynamic_account_name: 'dynamic_account_name',
        reason_id: 'reason_id',
        reason: 'reason',
      },
    },
    reasons_description_filled: {
      name: 'reasons_description_filled',
      attributes: {
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    reasons_modal_closed: {
      name: 'reasons_modal_closed',
      attributes: {
        dynamic_account_name: 'dynamic_account_name',
        source: 'source',
        referral_code: 'referral_code',
      },
    },
    payment_summary_requested: {
      name: 'payment_summary_requested',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
      },
    },
    payment_summary_page: {
      name: 'payment_summary_page',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
      },
    },
    payment_summary_successful: {
      name: 'payment_summary_successful',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
      },
    },
    payment_summary_failed: {
      name: 'payment_summary_failed',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        product_code: 'product_code',
        reason: 'reason',
        reason_id: 'reason_id',
        source: 'source',
        fee_charged: 'fee_charged',
        payment_method: 'payment_method',
        referral_code: 'referral_code',
        failure_message: 'failure_message',
      },
    },
  },

  Zakat: {
    product_clicked: {
      name: 'product_clicked',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        phone_number: 'phone_number',
      },
    },

    product_page: {
      name: 'product_page',
      attributes: {
        referral_code: 'referral_code',
        source: 'source',
        transaction_category_id: 'transaction_category_id',
        phone_number: 'phone_number',
      },
    },

    add_zakat_clicked: {
      name: 'add_zakat_clicked',
      attributes: {
        value: 'value',
      },
    },

    deduct_zakat_clicked: {
      name: 'deduct_zakat_clicked',
      attributes: {
        value: 'value',
      },
    },

    information_clicked: {
      name: 'information_clicked',
      attributes: {
        source: 'source',
      },
    },

    zakat_name_clicked: {
      name: 'zakat_name_clicked',
      attributes: {
        source: 'source',
      },
    },

    zakat_name_inputted: {
      name: 'zakat_name_inputted',
      attributes: {
        source: 'source',
        name: 'name',
      },
    },

    zakat_email_inputted: {
      name: 'zakat_email_inputted',
      attributes: {
        source: 'source',
        email: 'email',
      },
    },

    zakat_email_clicked: {
      name: 'zakat_email_clicked',
      attributes: {
        source: 'source',
        name: 'name',
      },
    },
  },
  codepush: {
    code_push_available: {
      name: 'code_push_available',
    },
    code_push_availed: {
      name: 'code_push_availed',
    },
    code_push_rejected: {
      name: 'code_push_rejected',
    },
    code_push_failed: {
      name: 'code_push_failed',
    },
    code_push_completed: {
      name: 'code_push_completed',
    },
  },
  errorPage: {
    app_error_page: {
      name: 'app_error_page',
      attributes: {
        event_triggers: 'event_triggers',
        source: 'source',
      },
    },
    error_page_restart_button_clicked: {
      name: 'error_page_restart_button_clicked',
      attributes: {
        event_triggers: 'event_triggers',
      },
    },
  },
  securityPin: {
    add_bank_account_clicked: {
      name: 'add_bank_account_clicked',
      attributes: {
        source: 'source',
      },
    },
    add_phonenumber_clicked: {
      name: 'add_phonenumber_clicked',
      attributes: {
        source: 'source',
      },
    },

    security_pin_clicked: {
      name: 'security_pin_clicked',
      attributes: {
        source: 'source',
      },
    },

    create_pin_clicked: {
      name: 'create_pin_clicked',
      attributes: {
        source: 'source',
      },
    },
    pin_page: {
      name: 'pin_page',
      attributes: {
        page_number: 'page_number',
        source: 'source',
      },
    },
    pin_creation_inputted: {
      name: 'pin_creation_inputted',
      attributes: {
        page_number: 'page_number',
        source: 'source',
      },
    },
    pin_creation_failed: {
      name: 'pin_creation_failed',
      attributes: {
        page_number: 'page_number',
        source: 'source',
        failure_message: 'failure_message',
      },
    },

    recreate_pin_clicked: {
      name: 'recreate_pin_clicked',
      attributes: {
        source: 'source',
      },
    },

    back_button_clicked: {
      name: 'back_button_clicked',
      attributes: {
        source: 'source',
      },
    },

    cancel_button_clicked: {
      name: 'cancel_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    continue_button_clicked: {
      name: 'continue_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    pin_submitted: {
      name: 'pin_submitted',
      attributes: {
        source: 'source',
      },
    },
    ok_button_clicked: {
      name: 'ok_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    reset_pin_later: {
      name: 'reset_pin_later',
      attributes: {
        source: 'source',
      },
    },
    reset_pin_clicked: {
      name: 'reset_pin_clicked',
      attributes: {
        source: 'source',
      },
    },

    help_clicked: {
      name: 'help_clicked',
      attributes: {
        source: 'source',
      },
    },

    x_button_clicked: {
      name: 'x_button_clicked',
      attributes: {
        source: 'source',
      },
    },
    pin_inputted: {
      name: 'pin_inputted',
      attributes: {
        source: 'source',
      },
    },

    create_pin_later: {
      name: 'create_pin_later',
      attributes: {
        source: 'source',
      },
    },

    pin_inputted_failed: {
      name: 'pin_inputted_failed',
      attributes: {
        source: 'source',
        attempt: 'attempt',
      },
    },

    pin_inputted_successfully: {
      name: 'pin_inputted_successfully',
      attributes: {
        source: 'source',
      },
    },
  },
  internalServerErrorToast: {
    internal_server_error_toast: {
      name: 'internal_server_error_toast',
      attributes: {
        failure_message: 'failure_message',
      },
    },
  },
  UserProfile: {
    department_options_appear: {
      name: 'department_options_appear',
      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        employer_id: 'employer_id',
      },
    },
    department_option_chosen: {
      name: 'department_option_chosen',
      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        employer_id: 'employer_id',
      },
    },
    role_options_appear: {
      name: 'role_options_appear',
      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        employer_id: 'employer_id',
      },
    },
    role_option_chosen: {
      name: 'role_option_chosen',
      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        employer_id: 'employer_id',
      },
    },
    department_role_submitted: {
      name: 'department_role_submitted',
      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        employer_id: 'employer_id',
      },
    },
    department_role_saved: {
      name: 'department_role_saved',
      attributes: {
        source: 'source',
        employee_id: 'employee_id',
        employer_id: 'employer_id',
      },
    },

    tooltip_clicked: {
      name: 'tooltip_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    pic_zone_page: {
      name: 'pic_zone_page',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    type_search_bar: {
      name: 'type_search_bar',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    pic_tnc_clicked: {
      name: 'pic_tnc_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    pic_tnc_page: {
      name: 'pic_tnc_page',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    employee_list_clicked: {
      name: 'employee_list_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    invite_status_clicked: {
      name: 'invite_status_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    wa_icon_clicked: {
      name: 'wa_icon_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
        referred_emp_id: 'referred_emp_id',
      },
    },
    user_info_lihat_button_clicked: {
      name: 'user_info_lihat_button_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    promo_code_inputted: {
      name: 'promo_code_inputted',
      attributes: {
        source: 'source',
        promo_code: 'promo_code',
      },
    },
    poin_clicked: {
      name: 'poin_clicked',
      attributes: {
        employee_id: 'employee_id',
        source: 'source',
        poin_balance: 'poin_balance',
      },
    },
    user_info_page: {
      name: 'user_info_page',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    eye_icon_phonenumber_clicked: {
      name: 'eye_icon_phonenumber_clicked',
      attributes: {
        source: 'source',
        influencer_role: 'influencer_role',
      },
    },
    pic_zone_clicked: {
      name: 'pic_zone_clicked',
      attributes: {
        source: 'source',
      },
    },
  },
  salaryInfo: {
    salary_info_clicked: {
      name: 'salary_info_clicked',
      attributes: {
        source: 'source',
      },
    },
    salary_info_page: {
      name: 'salary_info_page',
      attributes: {
        source: 'source',
      },
    },
    limit_clicked: {
      name: 'limit_clicked',
      attributes: {
        source: 'source',
      },
    },
    limit_popup_appear: {
      name: 'limit_popup_appear',
      attributes: {
        source: 'source',
      },
    },
    limit_popup_closed: {
      name: 'limit_popup_closed',
      attributes: {
        source: 'source',
      },
    },
    faq_clicked: {
      name: 'faq_clicked',
      attributes: {
        question: 'question',
        action: 'action',
      },
    },
    back_button_clicked: {
      name: 'back_button_clicked',
      attributes: {
        source: 'source',
      },
    },
  },
  interactionBanner: {
    floatingtext_clicked: {
      name: 'floatingtext_clicked',
      attributes: {
        source: 'source',
        banner_name: 'banner_name',
      },
    },
    floatingtext_closed: {
      name: 'floatingtext_closed',
      attributes: {
        source: 'source',
        banner_name: 'banner_name',
      },
    },
    banner_clicked: {
      name: 'banner_clicked',
      attributes: {
        transaction_category_id: 'transaction_category_id',
        requested_amount: 'requested_amount',
        source: 'source',
        banner_name: 'banner_name',
        reason: 'reason',
        reason_id: 'reason_id',
        payment_method: 'payment_method',
        dynamic_account_name: 'dynamic_account_name',
      },
    },
  },

  home_page_detail: {
    name: 'home_page_detail',
    attributes: {
      lock_period: 'lock_period',
    },
  },
  poin_card_clicked: {
    name: 'poin_card_clicked',
    attributes: {
      source: 'source',
    },
  },
  other_product_page: {
    name: 'other_product_page',
    attributes: {
      source: 'source',
      referral_code: 'referral_code',
      dynamic_account_name: 'dynamic_account_name',
    },
  },
  utm_detected: {
    name: 'utm_detected',
    attributes: {
      utm_campaign: 'utm_campaign',
    },
  },
  app_open_via_referral: {
    name: 'app_open_via_referral',
    attributes: {
      link: 'link',
    },
  },
  eng_diag_interruption: {
    name: 'eng_diag_interruption',
    attributes: {
      link: 'link',
      event: 'event',
    },
  },
};

export const AMPLITUDE_EVENT_SOURCES = {
  navbar: 'navbar',
  success_page: 'success_page',
  savings_page: 'savings_page',
  home_page: 'home_page',
  poin_card_clicked: 'poin_card_clicked',
  add_phonenumber_page: 'add_phonenumber_page',
  other_product_page: 'other_product_page',
  autowd_tnc_page: 'autowd_tnc_page',
  poin_page: 'poin_page',
  others_page: 'others_page',
  profile_page: 'profile_page',
  bank_page: 'bank_page',
  transfer_page: 'transfer_page',
  add_bank_account_page: 'add_bank_account_page',
  transfer_product_page: 'transfer_product_page',
  salary_information_page: 'salary_information_page',
  select_bank_account_page: 'select_bank_account_page',
  header: 'header',
  transaction_page: 'transaction_page',
  withdrawal_card: 'withdrawal_card',
  login_check_data_page: 'login_check_data_page',
  mission_information_page: 'mission_information_page',
  phone_list_page: 'phone_list_page',
  select_phone_page: 'select_phone_page',
  mission_page: 'mission_page',
  login_page_source: {
    login_page: 'login_page',
    otp_page: 'otp_page',
    login_terms_page: 'login_terms_page',
  },
  pin_modal_others_page: 'pin_modal_others_page',
  login_error: {
    'GG-OTP-07': 'account_is_not_registered',
    'GG-4031': 'account_is_suspended_case1',
    'GG-4032': 'account_is_suspended_case2',
    'GG-EMP-02': 'account_is_suspended_case2',
    'GG-4033': 'account_is_suspended_case3',
    'GG-429': 'request_exceeds_limit',
    'GG-OTP-08': 'request_exceeds_limit',
    invalid_number: 'invalid_number',
    'GG-OTP-02': 'invalid_number',
    'GG-500': 'system_error',
    'GG-CON-OTP-07': 'otp_invalid',
    'GG-RESEND-OTP-03': 'otp_expired',
  },
  otp_error: {
    'GG-401': 'otp_invalid',
    'GG-410': 'expired_otp',
  },
  dynamicAccounts: {
    salary_disbursement: 'salary_disbursement',
    salary_account: 'salary_account',
    work_allowance: 'work_allowance',
    uang_kas: 'uang_kas',
    poins: 'poins',
  },
  cancelButtonSource: {
    account_drop_down_withdrawal_card: 'account_drop_down_withdrawal_card',
    account_drop_down_transaction_page: 'account_drop_down_transaction_page',
  },
  permissionUiAttributes: {
    action: {
      close: 'close',
      deny: 'deny',
      later: 'later',
      allow: 'allow',
    },
  },
  bankRegistration: {
    failureMessage: {
      bank_account_limit_exceeded: 'bank_account_limit_exceeded',
      bank_account_already_stored: 'bank_account_already_stored',
    },
    action: {
      to_show: 'to_show',
      to_hide: 'to_hide',
    },
  },
};
