import { fetchSubProductsOrNominals } from '@api/useQueryCalls';
import BPNoDataFound from '@atoms/BPNoDataFound';
import useBPMutation from '@customHooks/useBPRequest';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { Button, View, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchAvailableProviderFromResponse,
  getDisabledCheckForTagihanPLN,
  refetchBPIfStale,
  requestBPForTagihanPLN,
  shouldShowAccountInput,
  showErrorOnAPI,
} from '../BPUtils';
import { logErrorOnAnalytics, logPageBack, logPageLaunch } from '../GGNominals/analyticsNominals';

export function shouldShowEmptyView(showEmptyView) {
  return showEmptyView ? <BPNoDataFound /> : null;
}

function GGTagihanPLN(props) {
  const {
    setLoading,
    setInitialData,
    // , loginData
    selectedBPAccount,
    validatedReferralCode,
    meAmplitudeContext,
  } = getStore();
  const { accountTypeId } = selectedBPAccount;
  const { route } = props;
  const { params } = route;
  const [openRTS, setOpenRTS] = useState(false);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [sAccountInputError, setAccountInputError] = useState('');
  const [bpReqData, setBpReqData] = useState({});

  const { productConfig, productApiData, source } = params;
  const { productId, productName } = productConfig;

  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  const { requestBP, isBPReqLoading, isBPReqError, bpError, apiPayload } = useBPMutation({ setOpenRTS, setBpReqData });
  const { isLoading, isSuccess, data, isError, error, isStale, refetch } = fetchSubProductsOrNominals({
    productId,
    accountTypeId,
  });

  const [selectedAccountNumber, setSelectedAccountNumber] = useState(null);
  const [availablePostPaidProvider, setAvailablePostPaidProvider] = useState({});

  useEffect(() => {
    navigation.setOptions({ title: productName });
  }, [productName]);
  useEffect(() => {
    logPageLaunch(productConfig, source, validatedReferralCode);
    refetchBPIfStale(isStale, refetch);
    return () => logPageBack(productConfig, source, validatedReferralCode);
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    fetchAvailableProviderFromResponse(isSuccess, data, setAvailablePostPaidProvider, setShowEmptyView);
  }, [isSuccess, data]);

  useEffect(() => {
    logErrorOnAnalytics(
      isBPReqError,
      selectedAccountNumber,
      productConfig,
      apiPayload,
      t,
      bpError,
      source,
      validatedReferralCode,
    );
  }, [isBPReqError, bpError]);

  return (
    <View style={appStyles.safeAreaView}>
      {shouldShowAccountInput({
        productConfig,
        productApiData,
        selectedAccountNumber,
        setSelectedAccountNumber,
        sAccountInputError,
        setAccountInputError,
      })}
      <View style={appStyles.flex1} />
      {shouldShowEmptyView(showEmptyView)}
      <View
        style={[
          appStyles.borderWithShadow,
          appStyles.marginHorizontal(0),
          appStyles.marginTop(0),
          appStyles.paddingHorizontal(0),
          appStyles.paddingTop(0),
          appStyles.borderTopLeftRadius(0),
          appStyles.borderTopRightRadius(0),
          appStyles.borderBottomLeftRadius(0),
          appStyles.borderBottomRightRadius(0),
        ]}>
        <Button
          testID="btnConfirm"
          isDisabled={getDisabledCheckForTagihanPLN(selectedAccountNumber, showEmptyView)}
          isLoading={isBPReqLoading}
          onPress={requestBPForTagihanPLN({
            selectedAccountNumber,
            selectedBPAccount,
            productApiData,
            availablePostPaidProvider,
            validatedReferralCode,
            requestBP,
            setAccountInputError,
            t,
            productConfig,
            source,
            meAmplitudeContext,
          })}
          style={[appStyles.marginHorizontal(16), appStyles.marginVertical(16)]}>
          {t('common.confirmed')}
        </Button>
        <RequestTransactionSheet
          openRTS={openRTS}
          setOpenRTS={setOpenRTS}
          productConfig={productConfig}
          productApiData={productApiData}
          selectedAccountNumber={selectedAccountNumber}
          nominalSelected={availablePostPaidProvider}
          bpReqData={bpReqData}
        />
      </View>
    </View>
  );
}

GGTagihanPLN.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      productConfig: PropTypes.shape({
        productId: PropTypes.string,
        productName: PropTypes.string,
        noOfColumns: PropTypes.number,
        hasAccountInput: PropTypes.bool,
      }),
      productApiData: PropTypes.shape({
        prefix: PropTypes.string,
      }),
    }),
  }),
};
GGTagihanPLN.defaultProps = {
  route: {
    params: {
      source: '',
      productConfig: {
        productId: '',
        productName: '',
        noOfColumns: 1,
        hasAccountInput: true,
      },
      productApiData: {
        prefix: '',
      },
    },
  },
};
export default GGTagihanPLN;
