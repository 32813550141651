import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const listingStyles = StyleSheet.create({
  container: {
    paddingHorizontal: scale(16),
    paddingTop: scale(3),
  },
  desigDepartTextStyle: { color: colors.neutral.darkMist },
  listContentStyle: { paddingBottom: scale(100) },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: scale(10),
    minHeight: scale(40),
  },
  separator: {
    alignSelf: 'center',
    borderColor: colors.neutral.mist,
    borderWidth: scale(1),
    bottom: 0,
    position: 'absolute',
    width: '100%',
  },
  subListingContainer: { flexDirection: 'row', marginTop: scale(5), paddingBottom: scale(12) },
  whatsappProfile: { alignItems: 'center', justifyContent: 'center', paddingBottom: scale(7) },
});

export default listingStyles;
