import colors from '@theme/colors';
import { SentryHelper } from '@utils/performance/SentryHelper';
import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';
import { goBackToHome, serverError } from '@utils/utils';
import { Text, View } from 'native-base';
import React from 'react';
import { logLoginSuccssfull, logOTPFailed, logOtpConfirm } from './analyticOTP';
import styles from './styles';

export async function showErrors(
  isError,
  error,
  setIsOTPLoading,
  setErrTxtMsg,
  t,
  mobileNumber,
  isOTPConfirm,
  setDisableOTP = null,
) {
  if (isError && error) {
    if (isOTPConfirm) {
      // toweards login
      logOtpConfirm(mobileNumber, error, t);
    } else {
      // on wahatsapp or sms GG-OTP-08 condition
      logOTPFailed(mobileNumber, error);
    }
    setIsOTPLoading(false);
    if (setDisableOTP) {
      setDisableOTP(true);
    }

    // this exception is returned from api/request.js
    setErrTxtMsg(t(`errorCodes.${error[0]}`));
  }
}

export async function storeTokensFromOTPData(isSuccess, otpData, setOtpData) {
  const data = otpData;
  if (isSuccess && data) {
    await secureStore.setItemAsync('accessToken', data.accessToken);
    await secureStore.setItemAsync('refreshToken', data.refreshToken);
    tokenManager.setAccessToken(data.accessToken);
    tokenManager.setRefreshToken(data.refreshToken);
  }
  delete data.accessToken;
  delete data.refreshToken;
  setOtpData(data);
  // if (isMeStale) {
  //   refetchMe();
  // }
}

export function getDisabledOTPStyles(disableOTP) {
  if (disableOTP) {
    return styles.otpDisabled;
  }
  return {};
}

export function getErrorOTPStyles(errTxtMsg) {
  if (errTxtMsg.length > 0) {
    return styles.otpFailed;
  }
  return {};
}

export function handleOnCodeChanged(setOtp, setErrTxtMsg) {
  return code => {
    setOtp(code);
    setErrTxtMsg('');
  };
}

export function shouldShowErrorMessage(sErrorMessage) {
  if (sErrorMessage && sErrorMessage.length > 0) {
    return (
      <View style={styles.errorStyles}>
        <Text
          testID="txtErrorToastMsg"
          alignSelf="center"
          textAlign="center"
          color={colors.error.rose}
          variant="sm-normal">
          {sErrorMessage}
        </Text>
      </View>
    );
  }
  return null;
}

export function shouldShowErrorOnOTP(isMeError, errorMe, toast, t, navigation, setInitialData) {
  if (isMeError && errorMe) {
    serverError(errorMe, toast, t, navigation, setInitialData);
  }
}

export function shouldNavigateToHomeOnSuccess(props) {
  const {
    meApiData,
    isSuccessMe,
    setAccounts,
    setSelectedAccount,
    setCanShowReferral,
    setEmployeeId,
    setIsInLockPeriod,
    setReferralApplicable,
    setStatus,
    setUserId,
    setEmployerId,
    navigation,
  } = props;
  if (meApiData && isSuccessMe) {
    const {
      accounts,
      canShowReferral,
      employeeId,
      isInLockPeriod,
      referralApplicable,
      status,
      userId,
      employerId,
      firstName,
      lastName,
    } = meApiData;
    setAccounts(accounts);
    setSelectedAccount({ ...accounts.filter(item => item.isWithdrawalEligible)[0] });
    setCanShowReferral(canShowReferral);
    setEmployeeId(employeeId);
    setIsInLockPeriod(isInLockPeriod);
    setReferralApplicable(referralApplicable);
    setStatus(status);
    setUserId(userId);
    setEmployerId(employerId);
    SentryHelper.setUser(employeeId, employerId, `${firstName} ${lastName}`);
    navigation.navigate('Home');
  }
}

export function shouldStoreTokensOnSuccessOTP(params) {
  const { isSuccess, otpData, setOtp, setOtpData, navigation, mobileNumber } = params;
  if (isSuccess && otpData) {
    setOtp('');
    (async () => {
      await storeTokensFromOTPData(isSuccess, otpData, setOtpData);
      logLoginSuccssfull(mobileNumber);
      goBackToHome(navigation, false);
    })();
  }
}
