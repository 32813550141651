import TextCurrency from '@atoms/TextCurrency';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import colors from '@theme/colors';
import { getDateInFormatLocaleWithInputFormat } from '@utils/dateUtils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

export const getBgColor = (count, idx, total) => {
  if (count === 0 && idx === 0) {
    return { bg: styles.ongoingHighlighter, txt: styles.txtStyle };
  }
  if (count === total) {
    return { bg: styles.paidHighter, txt: styles.txtStyle };
  }
  if (count === idx) {
    return { bg: styles.ongoingHighlighter, txt: styles.txtStyle };
  }
  if (count > idx) {
    return { bg: styles.paidHighter, txt: styles.txtStyle };
  }
  return {};
};

const InstallMentRow = ({ item, index, total, installMentsData }) => {
  const paidCount = installMentsData.filter(e => e.status === 'PAID').length;

  let displayMeta = {
    icon: 'check-circle',
    color: colors.success.darkCactus,
    text: 'Selesai',
  };

  if (item.status !== 'PAID') {
    displayMeta = {
      icon: 'calendar-blank',
      color: colors.neutral.darkMist,
      text: getDateInFormatLocaleWithInputFormat(item.dueDate, 'DD MMM YYYY', 'YYYY-MM-DD'),
    };
  }

  return (
    <View style={[styles.installmentRow, getBgColor(paidCount, index, total)?.bg]}>
      <View>
        <Text style={[styles.srStyles, getBgColor(paidCount, index, total)?.txt]} variant="sm-bold">
          {index + 1}
          <Text variant="sm-bold" style={styles.totalTextColor}>
            {`/${total}`}{' '}
          </Text>
        </Text>
        <TextCurrency
          variant="sm-bold"
          amountCurrency={parseInt(item.installmentValue ?? 0, 10)}
          showCurrencySymbol
          style={[styles.installTextSyle, getBgColor(paidCount, index, total)?.txt]}
        />
      </View>

      <View style={styles.secondPart}>
        <>
          <Icon name={displayMeta.icon} color={displayMeta.color} size={20} />
          <Text variant="sm-bold" style={styles.dueTextStyle}>
            {displayMeta.text}
          </Text>
        </>
      </View>
    </View>
  );
};

InstallMentRow.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string,
    dueDate: PropTypes.string,
    installmentValue: PropTypes.number,
  }),
  index: PropTypes.number,
  total: PropTypes.number,
  installMentsData: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      dueDate: PropTypes.string,
      installmentValue: PropTypes.number,
    }),
  ),
};

InstallMentRow.defaultProps = {
  item: {
    status: '',
    dueDate: '',
    installmentValue: 0,
  },
  index: 0,
  total: 0,
  installMentsData: [],
};

const LoanInstallMents = ({ show, installMentsData, t }) => {
  if (!show || installMentsData.length === 0) return null;

  return (
    <>
      <View style={styles.outerContainer}>
        <Text variant="md-bold">{t('loan.loanText1')}</Text>

        <Text variant="sm-normal" style={styles.descStyle}>
          {t('loan.loanText2')}
        </Text>
      </View>

      <View style={styles.titleContainer}>
        <View style={styles.titleRow}>
          <Text variant="sm-bold"> {t('loan.loanText3')}</Text>
          <Text variant="sm-bold">{t('loan.loanText4')}</Text>
        </View>

        {installMentsData.map((o, i) => (
          <InstallMentRow item={o} index={i} total={installMentsData.length} installMentsData={installMentsData} />
        ))}
      </View>
    </>
  );
};

LoanInstallMents.propTypes = {
  show: PropTypes.bool,
  installMentsData: PropTypes.shape([]),
  t: PropTypes.func.isRequired,
};

LoanInstallMents.defaultProps = {
  show: false,
  installMentsData: [],
};

export default LoanInstallMents;
