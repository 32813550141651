import ReferralCheckButton from '@atoms/ReferralCheckButton';
import ReferralDoneButton from '@atoms/ReferralDoneButton';
import ReferralUserInput from '@atoms/ReferralUserInput';
import ReferralValidatedInput from '@atoms/ReferralValidatedInput';
import ToastSuccess from '@atoms/ToastSuccess';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import React from 'react';

export function shouldShowToastOnSuccess(toastProps) {
  const { isSuccess, data, toast, setReferralValidated, setValidatedReferralCode, referralCode, pageSource, t } =
    toastProps;
  if (isSuccess && data && typeof data === 'string' && data === 'Valid Referral Code') {
    // Display success toast message
    toast.show({
      render: () => <ToastSuccess data={t('referral.validCodeText')} />,
      placement: 'top',
      duration: 5000,
    });
    // Set referral as validated
    setReferralValidated(true);

    const e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_successful;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.referral_code]: referralCode ?? '',
      [e.attributes.source]: pageSource,
    });

    // Set the validated referral code
    setValidatedReferralCode(referralCode);
  }
}

export function shouldShowValidatedButton(referralValidated, referralCode, validateReferralCodeApi, pageSource) {
  if (referralValidated) {
    return <ReferralDoneButton />;
  }
  return (
    <ReferralCheckButton
      referralCode={referralCode}
      validateReferralCodeApi={validateReferralCodeApi}
      pageSource={pageSource}
    />
  );
}

// Function to determine whether to show the validated referral input or the user input
export function shouldShowValidatedReferralInput(
  t,
  referralValidated,
  setReferralCode,
  validatedReferralCode,
  pageSource,
) {
  if (referralValidated) {
    return <ReferralValidatedInput validatedReferralCode={validatedReferralCode} />;
  }
  return <ReferralUserInput setReferralCode={setReferralCode} pageSource={pageSource} />;
}
