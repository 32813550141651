import { apiWithdrawalRequest } from '@api/apiCall';
import { useNavigation } from '@react-navigation/native';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'native-base';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useWithdrawalRequest = ({
  onSuccessCallBack,
  // , onErrorCallBack
}) => {
  const toast = useToast();
  const navigation = useNavigation();
  const { setLoading, setInitialData } = getStore();
  const { t } = useTranslation('common');
  const {
    isLoading: isWithdrawalLoading,
    isSuccess: isWithdrawalSuccess,
    data: withdrawalData,
    isError: isWithdrawalError,
    error: withdrawalError,
    mutate: withdrawRequest,
  } = useMutation({ mutationFn: payload => apiWithdrawalRequest(payload) });

  useEffect(() => {
    setLoading(isWithdrawalLoading);
  }, [isWithdrawalLoading]);

  useEffect(() => {
    onSuccessCallBack(isWithdrawalSuccess, withdrawalData);
  }, [isWithdrawalSuccess, withdrawalData]);

  useEffect(() => {
    showErrorOnAPI(isWithdrawalError, withdrawalError, toast, t, navigation, setInitialData);
  }, [isWithdrawalError, withdrawalError]);
  return { isWithdrawalLoading, withdrawRequest, isWithdrawalError, withdrawalError };
};

export default useWithdrawalRequest;
