import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logTxnClick(item) {
  const e = AMPLITUDE_CONSTANTS.TransactionPage.list_transaction_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
    [e.attributes.transaction_id]: item?.transactionId ?? '',
  });
}

export default logTxnClick;
