import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Linking } from 'react-native';
import styles from './style';

const LOAN_READ_LINK = 'https://gajigesa.com/e-Kasbon';

export const onLinkPress = () => Linking.openURL(LOAN_READ_LINK);

export function shouldRenderLoanLinkItem(item) {
  return (
    item === 'loanMetaInfo6' && (
      <Text variant="sm-normal" style={{ color: colors.secondary.orchid }} onPress={onLinkPress}>
        {' '}
        {LOAN_READ_LINK}
      </Text>
    )
  );
}

const LoanMetaItem = ({ item, index, t }) => (
  <View style={styles.termsRow}>
    <View style={styles.termsIconContainer}>
      <View style={styles.circlePlaceholder}>
        <Text variant="sm-bold" color={colors.neutral.cotton}>
          {index + 1}
        </Text>
      </View>
    </View>
    <View style={styles.termsTextContainer}>
      <Text variant="sm-normal" style={styles.termsTextStyle}>
        {t(`loan.${item}`)}
        {shouldRenderLoanLinkItem(item)}
      </Text>
    </View>
  </View>
);

LoanMetaItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default LoanMetaItem;
