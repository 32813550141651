import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnLeftStyle: {
    borderBottomRightRadius: 1,
    borderColor: colors.disabled.smoke,
    borderRadius: 12,
    borderRightWidth: 0,
    borderTopRightRadius: 1,
    borderWidth: 1,
    paddingVertical: 12,
  },
  btnRightStyle: {
    borderBottomLeftRadius: 1,
    borderColor: colors.disabled.smoke,
    borderLeftWidth: 0,
    borderRadius: 12,
    borderTopLeftRadius: 1,
    borderWidth: 1,
  },
  loginButtonContainer: {
    alignSelf: 'stretch',
    borderColor: colors.disabled.smoke,
    // borderRadius: scale(12),
    //  borderWidth: 1,
    flexDirection: 'row',
    marginHorizontal: scale(52),
    marginTop: 8,
    overflow: 'hidden',
  },
});
export default styles;
