import { boldFont } from '@theme/theme';
import { Modal, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ModalHeader(props) {
  const { headerTranslationString } = props;
  const { t } = useTranslation('common');
  return (
    <Modal.Header w="100%" fontFamily={boldFont}>
      <Text testID="txtHeaderTranslated" variant="lg-bold">
        {t(headerTranslationString)}
      </Text>
    </Modal.Header>
  );
}

ModalHeader.propTypes = {
  headerTranslationString: PropTypes.string,
};
ModalHeader.defaultProps = {
  headerTranslationString: '',
};

export default ModalHeader;
