import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnWhatsappStyles: {
    borderColor: colors.variants.clover,
    borderRadius: 84,
    borderWidth: 1,
    flex: 1,
    marginLeft: 16,
  },
  fltLstStyles: {
    // marginVertical: 16,
  },
  footerContainer: {},
  footerParent: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: scale(16),
    // paddingHorizontal: scale(16),
  },
  phoneListContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  phoneOwnerContainer: {
    flex: 1,
    marginVertical: 8,
  },
});
export default styles;
