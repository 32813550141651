import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bottomButton: {
    borderRadius: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingVertical: 16,
  },
});
export default styles;
