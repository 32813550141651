import { Feather } from '@expo/vector-icons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './styles';

function ToastWarning(props) {
  const { data } = props;
  return (
    <View style={styles.toastContainer}>
      <Feather name="clock" size={scale(24)} color={colors.neutral.charcoal} />
      <Text testID="txtToastWarning" variant="sm-bold" color={colors.neutral.charcoal} style={styles.textStyle}>
        {data}
      </Text>
    </View>
  );
}

ToastWarning.propTypes = {
  data: PropTypes.string,
};
ToastWarning.defaultProps = {
  data: '',
};

export default ToastWarning;
