import ToastSuccess from '@atoms/ToastSuccess';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import * as Clipboard from 'expo-clipboard';
import { Text, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable, View } from 'react-native';
import styles from './style';

const TokenContainer = props => {
  const { title, token } = props;
  const toast = useToast();

  if (token) {
    return (
      <Pressable
        testID="tokenContainerBtn"
        onPress={() => {
          Clipboard.setStringAsync(token);
          toast.show({
            render: () => <ToastSuccess data="Kode voucher tersalin!" />,
            placement: 'top',
          });
        }}
        style={styles.container}>
        <View style={styles.textContainer}>
          <Text variant="xsm-normal">{title}</Text>
          <Text variant="sm-bold" style={styles.tokenStyle}>
            {token}
          </Text>
        </View>
        <MaterialIcons name="file-copy" size={scale(24)} color={colors.secondary.orchid} />
      </Pressable>
    );
  }
  return null;
};

TokenContainer.propTypes = {
  title: PropTypes.string.isRequired,
  token: PropTypes.string,
};

TokenContainer.defaultProps = {
  token: '',
};

export default TokenContainer;
