import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  columnWrapperStyle: {
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginVertical: 12,
  },
  nominalContainer: {
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.disabled.smoke,
    borderRadius: 10,
    borderWidth: 1,
    elevation: 3,
    marginBottom: 16,
    marginHorizontal: 16,
  },
  nominalMultiColContainer: {
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.disabled.smoke,
    borderRadius: 10,
    borderWidth: 1,
    elevation: 3,
  },
  nominalMultiColParent: { marginBottom: 10, marginLeft: 13, marginTop: 9 },
  nominalSingleCol: { marginBottom: 10, marginLeft: 13, marginTop: 9 },
});
export default styles;
