import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  eyeIcon: {
    fontSize: scale(18),
  },
  phoneNumberText: {
    color: colors.neutral.charcoal,
    marginLeft: scale(4),
    textAlign: 'right',
  },
});

export default styles;
