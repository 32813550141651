import CollapsibleTerms from '@atoms/CollapsibleTerms';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import useBPMutation from '@customHooks/useBPRequest';
import { Feather } from '@expo/vector-icons';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { VOUCHER_TEXT_CONSTANTS } from '@root/constants';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale, verticalScale } from '@utils/normalize';
import { View, Text, ScrollView, Button, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native';
import styles from './styles';

function purchaseVoucher(props) {
  const {
    requestBP,
    selectedAccountNumber,
    selectedBPAccount,
    productApiData,
    selectedNominal,
    validatedReferralCode,
  } = props;

  requestBP({
    accountNumber: selectedAccountNumber,
    employeeAccountNumber: selectedBPAccount.id,
    transCategoryPrefix: productApiData.prefix,
    productCode: selectedNominal.code,
    referralCode: validatedReferralCode,
    zoneId: null,
    month: null,
  });
}

function GGVoucherDetails(props) {
  const navigation = useNavigation();
  const { route } = props;
  const { params } = route;
  const {
    selectedNominal,
    selectedVoucher,
    productConfig,
    productApiData,
    selectedAccountNumber,
    nominalSelected,
    selectedProductItem,
    voucherImage,
  } = params;
  const { t } = useTranslation('common');

  const { validatedReferralCode } = getStore();
  const voucherTnC = VOUCHER_TEXT_CONSTANTS[selectedVoucher];
  const [openRTS, setOpenRTS] = useState(false);
  const [bpReqData, setBpReqData] = useState({});

  const { requestBP } = useBPMutation({ setOpenRTS, setBpReqData });
  useEffect(() => {
    navigation.setOptions({ title: selectedVoucher });
  }, [selectedVoucher]);
  return (
    <SafeAreaView style={[appStyles.safeAreaView, { paddingHorizontal: scale(16) }]}>
      <View style={styles.voucherTncContainer}>
        <RemoteImage width={scale(113)} height={verticalScale(40)} resizeMode="cover" uri={voucherImage} />
      </View>
      <View flexDirection="row" style={{ marginTop: verticalScale(42) }}>
        <Text variant="md-bold">{selectedNominal.name} </Text>
        <Text variant="md-bold">(</Text>
        <TextCurrency variant="md-bold" amountCurrency={selectedNominal.nominal.value} />
        <Text variant="md-bold">)</Text>
      </View>
      <View style={styles.voucherValidation}>
        <Feather name="clock" size={scale(16)} color={colors.disabled.smoke} />
        <Text variant="sm-bold" style={{ marginLeft: scale(8) }} color={colors.disabled.smoke}>
          {t('bp.voucherTnC')}
        </Text>
      </View>
      <ScrollView style={{ marginVertical: verticalScale(6) }}>
        <Divider height="1px" style={appStyles.marginTop(16)} />
        <Text style={appStyles.marginTop(16)} variant="lg-bold">
          {t('bp.voucherHeader1')}
        </Text>
        <CollapsibleTerms tncContent={voucherTnC.text1} textKey="text1" />
        <Divider height="1px" style={appStyles.marginTop(16)} />
        <Text style={appStyles.marginTop(16)} variant="lg-bold">
          {t('bp.voucherHeader2')}
        </Text>
        <CollapsibleTerms tncContent={voucherTnC.text2} textKey="text2" />
        <Divider height="1px" style={appStyles.marginTop(18)} />
        <Text style={appStyles.marginTop(16)} variant="lg-bold">
          {t('bp.voucherHeader3')}
        </Text>
        <CollapsibleTerms tncContent={voucherTnC.text3} textKey="text3" />
      </ScrollView>
      <View style={{ marginBottom: verticalScale(17) }}>
        <Button
          onPress={() => {
            purchaseVoucher({ ...params, validatedReferralCode, requestBP });
          }}>
          {t('common.confirmed')}
        </Button>
        <RequestTransactionSheet
          openRTS={openRTS}
          setOpenRTS={setOpenRTS}
          productConfig={productConfig}
          productApiData={productApiData}
          selectedAccountNumber={selectedAccountNumber}
          nominalSelected={nominalSelected}
          selectedProductItem={selectedProductItem}
          bpReqData={bpReqData}
        />
      </View>
    </SafeAreaView>
  );
}

GGVoucherDetails.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      selectedNominal: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        nominal: PropTypes.shape({
          value: PropTypes.number,
        }),
      }),
      selectedVoucher: PropTypes.string,
      productConfig: PropTypes.shape({}),
      productApiData: PropTypes.shape({}),
      selectedAccountNumber: PropTypes.string,
      nominalSelected: PropTypes.shape({}),
      selectedProductItem: PropTypes.shape({}),
      voucherImage: PropTypes.string,
    }),
  }),
};

GGVoucherDetails.defaultProps = {
  route: {
    params: {
      selectedNominal: {
        code: '',
        name: '',
        nominal: {
          value: 0,
        },
      },
      selectedVoucher: {},
      productConfig: {},
      productApiData: {},
      selectedAccountNumber: {},
      nominalSelected: {},
      selectedProductItem: '',
      voucherImage: '',
    },
  },
};

export default GGVoucherDetails;
