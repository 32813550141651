import RemoteImage from '@atoms/RemoteImage';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { onRedirectionHandler } from '@utils/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

function GGTxnReferralBanner({ customStyles, logParams }) {
  const { successPageBanners } = getStore();
  const navigation = useNavigation();
  if (successPageBanners && successPageBanners.length > 0)
    return (
      <TouchableOpacity
        testID="txnReferralBannerBtn"
        onPress={onRedirectionHandler(
          successPageBanners,
          navigation,
          AMPLITUDE_CONSTANTS.interactionBanner.banner_clicked.name,
          {
            ...logParams,
            [AMPLITUDE_CONSTANTS.interactionBanner.banner_clicked.attributes.banner_name]: successPageBanners[0]?.title,
          },
        )}
        style={[styles.bannerStyle, customStyles]}>
        <RemoteImage uri={successPageBanners[0]?.imageLink} isFullSize />
      </TouchableOpacity>
    );
}

GGTxnReferralBanner.propTypes = {
  customStyles: PropTypes.shape({}),
  logParams: PropTypes.shape({}),
};

GGTxnReferralBanner.defaultProps = {
  customStyles: {},
  logParams: {},
};
export default GGTxnReferralBanner;
