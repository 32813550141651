import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withTiming,
  Easing,
  interpolate,
} from 'react-native-reanimated';
import styles from './style';

const Baru = ({ testID, showBaru, customStyles, textStyle, isRegular, animate }) => {
  const { t } = useTranslation('common');
  const tiltValue = useSharedValue(0);
  const DURATION = 700;

  useEffect(() => {
    if (showBaru && animate) {
      tiltValue.value = withRepeat(withTiming(-1, { duration: DURATION, easing: Easing.linear }), -1, true);
    }

    return () => {
      tiltValue.value = 0;
    };
  }, [showBaru, animate]);

  const tiltStyle = useAnimatedStyle(() => {
    const rotate = interpolate(tiltValue.value, [-1, 0, 1], [-30, 0, 20]);
    return {
      transform: [{ rotate: `${rotate}deg` }],
    };
  });

  if (!showBaru) return null;

  return (
    <Animated.View testID={testID} style={[styles.container, customStyles, tiltStyle]}>
      <Text testID="newText" variant={isRegular ? 'sm-normal' : 'sm-bold'} style={[styles.textStyle, textStyle]}>
        {t('new')}
      </Text>
    </Animated.View>
  );
};

Baru.propTypes = {
  testID: PropTypes.string,
  showBaru: PropTypes.bool,
  customStyles: PropTypes.shape({}),
  textStyle: PropTypes.shape({}),
  isRegular: PropTypes.bool,
  animate: PropTypes.bool,
};

Baru.defaultProps = {
  testID: 'newBadge',
  isRegular: false,
  showBaru: false,
  customStyles: {},
  textStyle: {},
  animate: false,
};

export default Baru;
