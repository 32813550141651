import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const ListTitle = ({ t }) => (
  <View style={styles.container}>
    <Text variant="md-bold"> {t('loan.pilih')} </Text>
  </View>
);

ListTitle.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ListTitle;
