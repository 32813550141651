import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logAccountNumberFocus(source, productConfig, validatedReferralCode) {
  return () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked.name, {
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked.attributes.transaction_category_id]:
        productConfig.transaction_category_id,
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked.attributes.referral_code]:
        validatedReferralCode,
    });
  };
}

export function logAccountNumberBlur(source, productConfig, validatedReferralCode) {
  return val => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru.name, {
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru.attributes.account_number]:
        val?.target?.value ?? '',
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru.attributes.transaction_category_id]:
        productConfig.transaction_category_id,
      [AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru.attributes.referral_code]:
        validatedReferralCode,
    });
  };
}
