import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const departmentStyles = StyleSheet.create({
  container: {
    marginTop: scale(12),
    paddingHorizontal: scale(16),
  },
  label: {
    color: colors.neutral.charcoal,
  },
  pickerContainer: {
    alignItems: 'center',
    borderColor: colors.neutral.charcoal,
    borderRadius: scale(8),
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: scale(4),
    paddingHorizontal: scale(13),
    paddingVertical: scale(13),
  },
  placeholder: {
    color: colors.neutral.charcoal,
  },
  selectedstyle: {
    color: colors.neutral.charcoal,
  },
});

export default departmentStyles;
