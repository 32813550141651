import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import createSlice from './createStore';

/**
 * Make sure to enforce type for each slice
 */

export function createSliceParam() {
  return (set, get, api) => ({
    ...createSlice(set, get, api),
  });
}

export function getStorageParam() {
  return {
    name: 'gg-app',
    storage: createJSONStorage(() => AsyncStorage),
  };
}

const persistParameter = persist(createSliceParam(), getStorageParam());

const useStore = create(persistParameter);

export default useStore;
