import { Feather } from '@expo/vector-icons';
import colors from '@theme/colors';
import { Icon, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import styles from './styles';

function SignUpInformation() {
  const { t } = useTranslation('common');
  return (
    <View testID="signUpInfo" sentry-label="signUpInfo" style={styles.informationParent}>
      <Icon as={Feather} name="info" color={colors.secondary.orchid} marginRight={5} size="24px" />
      <Text testID="txtSignUpInformation" flex={1} variant="sm-bold">
        {t('signup.signupInformation')}
      </Text>
    </View>
  );
}

export default SignUpInformation;
