import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.error.rose,
    borderRadius: scale(27),
    height: scale(15),
    justifyContent: 'center',
    left: 0,
    paddingHorizontal: scale(6),
    position: 'absolute',
    zIndex: 10,
  },
  textStyle: {
    color: colors.neutral.cotton,
    fontSize: scale(10),
    lineHeight: scale(15),
  },
});

export default styles;
