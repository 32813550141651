import { fetchAccountNumberHistory } from '@api/useQueryCalls';
import ModalClose from '@atoms/ModalClose';
import ModalHeader from '@atoms/ModalHeader';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { shouldShowErrorForAccountInput, showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { Divider, Modal, Text, View, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, TouchableOpacity } from 'react-native';
import logAccountSelected from './logDropDownAccount';
import styles from './styles';

function getDropDownStyles(sAccountInputError) {
  return sAccountInputError.length > 0
    ? { ...styles.dropDownStyles, borderColor: colors.error.rose, borderWidth: 1 }
    : styles.dropDownStyles;
}

function getItemSeperatorComponent() {
  return () => <Divider />;
}

function getTextOrSelectedNumber(selectedAccountNumber, t) {
  if (selectedAccountNumber) {
    return selectedAccountNumber;
  }
  return t('bp.selectedId');
}

function BPDropdownWithAccountsAndAPI(props) {
  const { t } = useTranslation('common');
  const { setLoading, setInitialData } = getStore();
  const {
    productApiData,
    setSelectedAccountNumber,
    selectedAccountNumber,
    sAccountInputError,
    setAccountInputError,
    productConfig,
    source,
  } = props;
  const { productOrAccountModalTitle } = productConfig;
  const { prefix } = productApiData;
  const { isLoading, isError, error, isSuccess, data, isStale, refetch } = fetchAccountNumberHistory({
    productPrefix: prefix,
  });
  const toast = useToast();
  const navigation = useNavigation();
  const [dropDownModalData, setDropDownModalData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (isStale) {
      refetch();
    }
  }, []);
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  useEffect(() => {
    if (isSuccess && data) {
      setDropDownModalData(data.list);
    }
  }, [isSuccess, data]);
  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);
  const closeBPDropDownWithAccounts = () => setIsModalOpen(false);
  return (
    <View>
      <Text
        testID="txtSelectIdHeader"
        style={[appStyles.marginTop(16), appStyles.marginBottom(4)]}
        variant="sm-normal"
        color={colors.neutral.darkMist}>
        {t('bp.selectIdHeader')}
      </Text>
      <TouchableOpacity
        testID="btnDropDownPress"
        style={getDropDownStyles(sAccountInputError)}
        onPress={() => {
          setIsModalOpen(true);
        }}>
        <Text
          testID="txtSelectId"
          variant="md-normal"
          color={
            selectedAccountNumber && selectedAccountNumber.length > 0
              ? colors.neutral.charcoal
              : colors.neutral.darkMist
          }
          flex={1}>
          {getTextOrSelectedNumber(selectedAccountNumber, t)}
        </Text>
        <AntDesign testID="btnAccordianImage" name="down" size={16} color="black" onPress={() => {}} />
      </TouchableOpacity>
      {shouldShowErrorForAccountInput(sAccountInputError)}
      <Modal
        isOpen={isModalOpen}
        style={appStyles.modalParent}
        closeOnOverlayClick
        size="full"
        onClose={closeBPDropDownWithAccounts}>
        <Modal.Content style={appStyles.modalContainer}>
          <ModalHeader headerTranslationString={productOrAccountModalTitle} />
          <ModalClose testID="modalClose" sentry-label="modalClose" onPress={closeBPDropDownWithAccounts} />
          <FlatList
            testID="fltLstNumbers"
            contentContainerStyle={appStyles.paddingHorizontal(16)}
            ItemSeparatorComponent={getItemSeperatorComponent()}
            data={dropDownModalData}
            renderItem={({ item }) => (
              <TouchableOpacity
                testID={item}
                onPress={() => {
                  logAccountSelected(item, productConfig, source);
                  setSelectedAccountNumber(item);
                  setIsModalOpen(false);
                  setAccountInputError('');
                }}>
                <Text variant="md-normal" style={appStyles.marginVertical(16)}>
                  {item}
                </Text>
              </TouchableOpacity>
            )}
          />
        </Modal.Content>
      </Modal>
    </View>
  );
}

BPDropdownWithAccountsAndAPI.propTypes = {
  source: PropTypes.string,
  productApiData: PropTypes.shape({
    prefix: PropTypes.string,
  }),
  productConfig: PropTypes.shape({
    productOrAccountModalTitle: PropTypes.string,
    transaction_category_id: PropTypes.string,
  }),
  setSelectedAccountNumber: PropTypes.func,
  selectedAccountNumber: PropTypes.string,
  sAccountInputError: PropTypes.string,
  setAccountInputError: PropTypes.func,
};

BPDropdownWithAccountsAndAPI.defaultProps = {
  source: '',
  productApiData: {
    prefix: '',
  },
  productConfig: {
    productOrAccountModalTitle: '',
    transaction_category_id: '',
  },
  setSelectedAccountNumber: () => {},
  selectedAccountNumber: '',
  sAccountInputError: '',
  setAccountInputError: () => '',
};

export default BPDropdownWithAccountsAndAPI;
