import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  closeButton: {
    alignSelf: 'flex-end',
    padding: 15,
    position: 'absolute',
    top: 0,
    zIndex: 10,
  },
  container: {
    alignItems: 'center',
    backgroundColor: colors.black,
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingHorizontal: 10,
    width: '100%',
  },
});
export default styles;
