import { analyticsTimeStampFormat } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getNowInFormat } from '@utils/dateUtils';
import { getAmplitudeKeyValue } from '@utils/utils';

export function logTnCOpen(tncOpen, transaction_category_id, amplitudeAccountName, validatedReferralCode, source) {
  if (tncOpen) {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.transaction_category_id]:
        transaction_category_id,
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.timestamp]:
        getNowInFormat(analyticsTimeStampFormat),
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.dynamic_account_name]: amplitudeAccountName,
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.referral_code]: validatedReferralCode,
    });
  }
}

export function logTnCClosedForWithdrawal(transactionCategoryId, src, dynamicAccountName) {
  const e = AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms;
  const { name, attributes } = e;
  const { transaction_category_id, timestamp, source, dynamic_account_name } = attributes;
  AmplitudeHelper.logEvent(name, {
    [transaction_category_id]: transactionCategoryId,
    [source]: src,
    [timestamp]: getNowInFormat(analyticsTimeStampFormat),
    [dynamic_account_name]: dynamicAccountName,
  });
}

export function logConfirmWithdrawalPressed(
  transaction_category_id,
  withdrawalData,
  withdrawalReason,
  source,
  amplitudeAccountName,
  validatedReferralCode,
  meAmplitudeContext,
) {
  const e = AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested;
  // transaction_requested
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.transaction_category_id]: transaction_category_id,
    [e.attributes.requested_amount]: withdrawalData.requestedAmount,
    [e.attributes.reason]: withdrawalReason?.reason,
    [e.attributes.reason_id]: withdrawalReason?.id,
    [e.attributes.payment_method]: 'accrued_salary',
    [e.attributes.source]: source,
    [e.attributes.dynamic_account_name]: amplitudeAccountName,
    [e.attributes.referral_code]: validatedReferralCode,
    [e.attributes.fee_requested]: withdrawalData?.fee,
    ...getAmplitudeKeyValue(e.name, meAmplitudeContext),
  });
}
