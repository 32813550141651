import { apiOTP } from '@api/apiCall';
import LoginButtons from '@molecules/LoginButtons';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import OTPInputView from '@twotalltotems/react-native-otp-input';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { formatPhoneNumber } from '@utils/utils';
import { Button, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, SafeAreaView } from 'react-native';
import {
  getDisabledOTPStyles,
  getErrorOTPStyles,
  handleOnCodeChanged,
  shouldShowErrorMessage,
  shouldStoreTokensOnSuccessOTP,
  showErrors,
} from './otpUtils';
import styles from './styles';

const authenticAmplitude = AMPLITUDE_CONSTANTS.authentication;
function GGOTP() {
  const navigation = useNavigation();
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [otpNew, setOtp] = useState('');
  const [errTxtMsg, setErrTxtMsg] = useState('');
  const [disableOTP, setDisableOTP] = useState(false);
  const { loginData, setLoading, setOtpData } = getStore();
  const { mobileNumber, token } = loginData;
  // const toast = useToast();
  const { t } = useTranslation('common');
  const {
    isLoading,
    mutate: verifyOTP,
    isSuccess,
    data: otpData,
    isError,
    error,
  } = useMutation({
    mutationFn: () => {
      const payload = {
        otpPin: parseInt(otpNew, 10),
      };
      return apiOTP(payload, token);
    },
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    shouldStoreTokensOnSuccessOTP({
      isSuccess,
      otpData,
      setOtp,
      setOtpData,
      setLoading,
      navigation,
      mobileNumber,
    });
  }, [isSuccess, otpData]);

  useEffect(() => {
    setOtp('');
    showErrors(
      isError,
      error,
      setIsOTPLoading,
      setErrTxtMsg,
      t,
      mobileNumber,
      true,
      error?.[0] === 'GG-CON-OTP-08' ? setDisableOTP : null,
    );
  }, [isError, error]);

  useEffect(() => {
    AmplitudeHelper.logEvent(authenticAmplitude.otp_page.name, {
      [AMPLITUDE_CONSTANTS.Login.otpPage.attributes.phone_number]: formatPhoneNumber(mobileNumber),
    });
  }, []);

  const onCodeFilledHandler = () => {
    const e1 = AMPLITUDE_CONSTANTS.authentication.otp_input;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.phone_number]: formatPhoneNumber(mobileNumber),
    });
  };

  const onConfirmHandler = () => {
    const e = AMPLITUDE_CONSTANTS.authentication.login_attempted;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(mobileNumber),
    });
    verifyOTP();
  };

  return (
    <SafeAreaView style={styles.otpParent}>
      <View style={appStyles.flex6}>
        <View style={styles.artContainer}>{SVG_ICONS.otp.messageNoti}</View>
        <View style={styles.otpTextStyles}>
          <Text testID="txtPlain" sentry-label="txtPlain" variant="lg-normal">
            {t('otp.enterOTP')}
            <Text
              testID="txtValue"
              variant="lg-bold"
              sentry-label="txtValue"
              style={styles.subPhoneNumberStyle}
              color={colors.neutral.charcoal}>
              {` +${mobileNumber}`}
            </Text>
          </Text>
        </View>

        <OTPInputView
          pinCount={4}
          code={otpNew}
          style={styles.otpGroup}
          autoFocusOnLoad={false}
          onCodeFilled={onCodeFilledHandler}
          clearInputs={errTxtMsg.length > 0 || otpNew.length === 0}
          codeInputFieldStyle={[styles.otpDefault, getErrorOTPStyles(errTxtMsg), getDisabledOTPStyles(disableOTP)]}
          editable={!disableOTP}
          onCodeChanged={handleOnCodeChanged(setOtp, setErrTxtMsg)}
          codeInputHighlightStyle={[styles.otpHightLight, getDisabledOTPStyles(disableOTP)]}
        />
        {shouldShowErrorMessage(errTxtMsg)}
        <View style={styles.resendText}>
          <Text testID="txtResendText" variant="sm-normal">
            {t('otp.resendText')}
          </Text>
        </View>
        <LoginButtons
          fromLogin={false}
          setErrTxtMsg={setErrTxtMsg}
          setOtp={setOtp}
          disableOTP={disableOTP}
          setDisableOTP={setDisableOTP}
        />
      </View>
      <View style={appStyles.bottomView}>
        <Button
          isLoading={isOTPLoading}
          isDisabled={disableOTP || otpNew.length < 4}
          marginX="16px"
          textAlign="center"
          testID="btnOTP"
          sentry-label="btnOTP"
          onPress={onConfirmHandler}>
          {t('otp.continue')}
        </Button>
      </View>
    </SafeAreaView>
  );
}

export default GGOTP;
