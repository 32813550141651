import colors from '@theme/colors';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const StatusBox = ({ isActive, displayText, timeStamp }) => {
  function shouldRenderClosePeriod(show) {
    if (show) return <Text variant="sm-bold">{timeStamp}</Text>;

    return null;
  }

  return (
    <View style={styles.containerStyle}>
      <View style={styles.rowStyle}>
        <View
          style={[
            styles.circleStyle,
            {
              backgroundColor: isActive ? colors.success.cactus : colors.error.rose,
            },
          ]}
        />
        <Text variant="sm-normal" style={styles.displayText}>
          {displayText}
        </Text>
      </View>

      {shouldRenderClosePeriod(isActive)}
    </View>
  );
};

StatusBox.propTypes = {
  isActive: PropTypes.bool.isRequired,
  displayText: PropTypes.string.isRequired,
  timeStamp: PropTypes.string.isRequired,
};

export default StatusBox;
