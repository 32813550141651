import TNCitem from '@atoms/TNCitem';
import { id } from '@root/i18n/supportedLanguages';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import styles from './style';

function GGGoldTerms() {
  const { t } = useTranslation('common');
  return (
    <ScrollView style={styles.containerStyle}>
      <View style={styles.collapsibleContainer}>
        {id.treasury.goldTerms.map((o, i) => (
          <TNCitem key={o} item={t(`treasury.goldTerms.${i}`)} index={i} />
        ))}
      </View>
    </ScrollView>
  );
}

export default GGGoldTerms;
