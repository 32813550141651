import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonDone: {
    alignItems: 'center',
    backgroundColor: colors.variants.softClover,
    borderRadius: scale(8),
    flexDirection: 'row',
    height: scale(32),
    paddingHorizontal: scale(14),
    paddingVertical: scale(5.5),
  },
  checkIconStyles: {
    marginLeft: 4,
    marginRight: 4,
  },
});
export default styles;
