// import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ActivityIndicator } from 'react-native';
import Modal from 'react-native-modal';
import styles from './styles';

// A basic one for the timebeing
function BackdropLoader(props) {
  const { t } = useTranslation('common');
  const { loading } = props;
  if (loading) {
    return (
      <Modal
        isVisible
        avoidKeyboard
        backdropColor={colors.black}
        style={styles.containerStyle}
        useNativeDriver
        backdropTransitionOutTiming={0}>
        <View style={styles.modal2}>
          <View style={styles.subView}>
            <Text testID="globalLoader" variant="md-bold" style={styles.textStyle} color={colors.neutral.charcoal}>
              {t('common.loader')}
            </Text>
            <ActivityIndicator size={scale(32)} color={colors.primary.carnation} />
          </View>
        </View>
      </Modal>
    );
  }
  return null;
}

BackdropLoader.propTypes = {
  loading: PropTypes.bool,
};

BackdropLoader.defaultProps = {
  loading: false,
};

export default BackdropLoader;
