import ModalClose from '@atoms/ModalClose';
import { useIsFocused } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text, Modal, Button } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import checkIfSDModalNeedsToBeShown from './sdModalUtil';

export function onPressHandler(setSelectedAccount, sdAccountWallet, setSDModalVisible) {
  return () => {
    setSelectedAccount(sdAccountWallet);
    setSDModalVisible(false);
  };
}

export function onCloseHandler(setSDModalVisible) {
  return () => {
    setSDModalVisible(false);
  };
}
function SDModal(props) {
  const { showSDModal } = props;
  const [sdAccountWallet, setSelectedSDAccountWallet] = useState(null);
  const { accounts, isInLockPeriod, setSelectedAccount } = getStore();
  const [sdModalVisible, setSDModalVisible] = useState(false);
  const isFocused = useIsFocused();
  const { t } = useTranslation('common');
  useEffect(() => {
    checkIfSDModalNeedsToBeShown(
      accounts,
      setSelectedSDAccountWallet,
      showSDModal,
      isInLockPeriod,
      isFocused,
      setSDModalVisible,
    );
  }, [accounts, isInLockPeriod, showSDModal, isInLockPeriod, isFocused]);

  return (
    <Modal
      closeOnOverlayClick
      isOpen={sdModalVisible}
      style={appStyles.modalParent}
      onClose={onCloseHandler(setSDModalVisible)}>
      <Modal.Content style={[appStyles.modalContainer, { backgroundColor: colors.neutral.cotton }]}>
        <ModalClose />
        <View alignContent="center" style={appStyles.marginHorizontal(16)}>
          <View alignItems="center">{SVG_ICONS.dynamicAccounts.artInfo}</View>
          <Text color={colors.neutral.charcoal} variant="xl-bold" style={appStyles.marginVertical(8)}>
            {t('sdModal.waitAMinute')}
          </Text>
          <Text color={colors.neutral.darkMist} variant="md-normal" style={appStyles.marginBottom(12)}>
            {t('sdModal.chooseSD')}
          </Text>
          <Button
            testID="btnCloseSDModal"
            variant="solid"
            style={appStyles.marginBottom(16)}
            onPress={onPressHandler(setSelectedAccount, sdAccountWallet, setSDModalVisible)}>
            {t('sdModal.sdBtn')}
          </Button>
        </View>
      </Modal.Content>
    </Modal>
  );
}

SDModal.propTypes = {
  showSDModal: PropTypes.bool,
};
SDModal.defaultProps = {
  showSDModal: false,
};
export default SDModal;
