import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral.cotton,
    flexGrow: 1,
    paddingBottom: scale(80),
    paddingTop: scale(8),
  },

  dividerStyle: {
    backgroundColor: colors.neutral.mist,
    height: scale(4),
    marginTop: scale(20),
  },
  flatlistContainerStyle: { flexGrow: 1 },
  scrollViewContainer: { backgroundColor: colors.neutral.cotton, flex: 1, paddingTop: 0 },
});

export default styles;
