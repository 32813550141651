import { fetchAllPhones } from '@api/useQueryCalls';
import InfoTextComponent from '@atoms/InfoTextComponent';
import { Ionicons } from '@expo/vector-icons';
import PhoneListOrEmptyView from '@molecules/PhoneListOrEmptyView';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { View, Text, Divider } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, TouchableOpacity } from 'react-native';
import { onCompleteGetPhoneCallBack, shouldShowLinkToAddPhone } from './enrolledPhonesUtils';
import styles from './styles';
// import PropTypes from 'prop-types';

function shouldShowTickMarkIfSelected(userPhone, bpPhone) {
  if (userPhone === bpPhone.mobileNumber) {
    return <Ionicons name="checkmark" size={18} color={colors.primary.carnation} />;
  }
  return null;
}

function checkConditionToShowAddLink(isTransact, phoneList, navigation, params, t, transactionCategoryId) {
  const rejectedList = phoneList?.filter(item => item.status === 'REJECTED') ?? [];
  // if the list is empty
  if (isTransact && phoneList && phoneList?.length === 0) {
    return null;
  }
  // if all phones rejected
  if (isTransact && phoneList?.length === rejectedList?.length) {
    return null;
  }
  return shouldShowLinkToAddPhone(isTransact, transactionCategoryId);
}

function GGEnrolledPhones() {
  const { t } = useTranslation('common');
  const { loginData, otpData, bpPhone, setBPPhone } = getStore();
  const { mobileNumber } = loginData;
  const { userName } = otpData;

  const [phoneList, setPhoneList] = useState(null);
  const isFocused = useIsFocused();
  const route = useRoute();
  const { params } = route;
  const isTransact = params?.isTransact;
  const eventSource = params?.eventSource;
  const transactionCategoryId = params?.transactionCategoryId;

  const { isLoading, isFetching, isSuccess, data, refetch } = fetchAllPhones();
  const navigation = useNavigation();
  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);

  // useEffect(() => {
  //   setLoading(isLoading);
  // }, [isLoading]);
  useEffect(() => {
    if (isSuccess && data && data.list) {
      onCompleteGetPhoneCallBack(data.list, setPhoneList, isTransact, eventSource, transactionCategoryId);
    }
  }, [isSuccess, data]);

  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <View style={[appStyles.marginHorizontal(16), appStyles.marginVertical(16)]}>
        <View style={{}}>
          <Text variant="md-bold" color={colors.neutral.charcoal}>
            {t('phoneTransfer.userPhone')}
          </Text>
          <TouchableOpacity
            onPress={() => {
              if (isTransact) {
                setBPPhone({ mobileNumber, name: userName });
                navigation.pop();
              }
            }}>
            <View style={appStyles.paddingVertical(8)}>
              <View style={styles.mobileNumberWithTick}>
                <Text variant="sm-normal" style={appStyles.flex1}>
                  {mobileNumber}
                </Text>
                {shouldShowTickMarkIfSelected(mobileNumber, bpPhone)}
              </View>
              <Text variant="sm-bold" color={colors.neutral.charcoal}>
                {userName}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <Divider color={colors.neutral.mist} height="4px" />
        <View style={appStyles.marginTop(16)}>
          <View flexDirection="row">
            <Text variant="md-bold" flex={1}>
              {t('addPhone.numberHeader')}
            </Text>
            {checkConditionToShowAddLink(isTransact, phoneList, navigation, params, t, transactionCategoryId)}
          </View>
          <Text variant="xsm-normal" color={colors.neutral.darkMist} style={appStyles.marginTop(4)}>
            {t('phoneTransfer.phoneMaxLimit')}
          </Text>
          {isTransact && (phoneList?.filter(item => item.status === 'PENDING')?.length > 0 ?? []) ? (
            <InfoTextComponent
              show
              containerStyle={styles.tooltipBannerContainerStyle}
              customTextStyle={styles.tooltipBannerTextStyle}
              variant="xsm-bold"
              iconContainer={styles.tooltipBannerIconContainerStyle}
              iconColor={colors.neutral.charcoal}
              infoText={t('phoneTransfer.phoneTransferWarning')}
            />
          ) : null}
        </View>
      </View>
      <View style={appStyles.flex1}>
        <PhoneListOrEmptyView
          loading={isLoading || isFetching}
          phoneList={phoneList}
          getPhoneNumbers={refetch}
          isTransact={isTransact}
          transactionCategoryId={transactionCategoryId}
        />
      </View>
    </SafeAreaView>
  );
}

GGEnrolledPhones.propTypes = {};
GGEnrolledPhones.defaultProps = {};
export default GGEnrolledPhones;
