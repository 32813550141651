import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { formatPhoneNumber, formatUnderScoreLowerCase } from '@utils/utils';

export function logOtpConfirm(mobileNumber, error, t) {
  const e = AMPLITUDE_CONSTANTS.authentication.login_failed;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.phone_number]: formatPhoneNumber(mobileNumber),
    [e.attributes.failure_message]:
      AMPLITUDE_EVENT_SOURCES.login_error[error] || formatUnderScoreLowerCase(t(`errorCodes.${error}`)),
  });
}

export function logOTPFailed(mobileNumber, error) {
  const e = AMPLITUDE_CONSTANTS.authentication.otp_failed;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.phone_number]: formatPhoneNumber(mobileNumber),
    [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error[error],
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
  });
}

export function logLoginSuccssfull(mobileNumber) {
  const e = AMPLITUDE_CONSTANTS.Login.loginSuccessful;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.phone_number]: mobileNumber,
  });
}
