import TextCurrency from '@atoms/TextCurrency';
import { SVG_ICONS } from '@utils/SvgIcons';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

export const MetaTag = ({ showTag, t }) => {
  if (showTag)
    return (
      <View style={styles.metaContainer}>
        <Text variant="sm-bold" style={styles.metaTagStyle}>
          {t('loan.menunggu')}
        </Text>
      </View>
    );

  return null;
};

const LoanDetailCard = ({ principalAmt, recievable, tenure, installAmt, showTag, t }) => (
  <View style={styles.outerContainer}>
    <View style={styles.principleRow}>
      <View style={styles.row}>
        {SVG_ICONS.loan.cashTwo}
        <TextCurrency
          amountCurrency={principalAmt}
          variant="xl-bold"
          style={styles.principleAmtStyle}
          showCurrencySymbol
        />
      </View>
      <MetaTag showTag={showTag} t={t} />
    </View>

    <View style={styles.metaRow}>
      <View>
        <Text variant="sm-normal" style={styles.metaTitleStyle}>
          {t('loan.jumlah')}
        </Text>
        <TextCurrency amountCurrency={recievable} variant="sm-bold" style={styles.metaInfoStyle} showCurrencySymbol />
      </View>
      <View style={styles.containerAdj}>
        <Text variant="sm-normal" style={styles.metaTitleStyle}>
          {t('loan.cicilan')}
        </Text>
        <Text variant="sm-bold" style={styles.tenureTxtStyle}>
          {`${tenure}x`}
        </Text>
      </View>
      <View style={styles.containerAdj}>
        <Text variant="sm-normal" style={styles.metaTitleStyle}>
          {t('loan.ciciBulan')}
        </Text>

        <TextCurrency
          amountCurrency={parseInt(installAmt, 10)}
          variant="sm-bold"
          style={styles.installAmtTxt}
          showCurrencySymbol
        />
      </View>
    </View>
  </View>
);

MetaTag.propTypes = {
  showTag: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

LoanDetailCard.propTypes = {
  principalAmt: PropTypes.number,
  recievable: PropTypes.number,
  tenure: PropTypes.number,
  installAmt: PropTypes.string,
  showTag: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

LoanDetailCard.defaultProps = {
  principalAmt: 0,
  recievable: 0,
  tenure: '0',
  installAmt: 0,
  showTag: false,
};

export default LoanDetailCard;
