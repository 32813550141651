import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  accountRow: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 16,
  },
  baruStyle: { marginLeft: scale(12), marginTop: 0, position: 'relative ' },
  checkStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    minWidth: '10%',
  },
});
export default styles;
