import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: scale(7),
    flexDirection: 'row',
    marginHorizontal: scale(14),
    marginTop: scale(10),
    paddingHorizontal: scale(10),
    paddingVertical: scale(10),
  },
  iconContainer: { color: colors.secondary.orchid, fontSize: scale(20) },
  styleAdj: { alignItems: 'center', flexDirection: 'row' },
  textStyle: { marginLeft: scale(10) },
});

export default styles;
