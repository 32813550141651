const tokenPLN = require('./productConfig/05tokenPLN.json');
const pdam = require('./productConfig/06pdam.json');
const paketData = require('./productConfig/07paketData.json');
const pascabayar = require('./productConfig/08pascabayar.json');
const pulsa = require('./productConfig/10pulsa.json');
const vouchers = require('./productConfig/13vouchers.json');
const eMoney = require('./productConfig/15eMoney.json');
const tagihanPLN = require('./productConfig/17tagihanPLN.json');
const bankTransfer = require('./productConfig/31bankTransfer.json');
const gold = require('./productConfig/gold.json');
const loan = require('./productConfig/loan.json');

const products = {
  5: tokenPLN,
  6: pdam,
  7: paketData,
  8: pascabayar,
  10: pulsa,
  13: vouchers,
  15: eMoney,
  17: tagihanPLN,
  31: bankTransfer,
  GG_01: gold,
  30: loan,
};

export default products;
/**
 * token pln: 14234567895
 * pdam: 10091231 / PDAM Kota Bogor
 * pascabayar:88888850003 / TPSBTK
 * postpaid pln: 516070377769
 * emoney: 82260761820
 */
