import { apiWithdrawalConfirm } from '@api/apiCall';
import AppliedReferral from '@atoms/AppliedReferral';
import BPTnc from '@atoms/BPTnc';
import BPTncCheckBox from '@atoms/BPTncCheckBox';
import BPTnCHeader from '@atoms/BPTnCHeader';
import WithdrawalAdminFee from '@atoms/WithdrawalAdminFee';
import WithdrawalAmount from '@atoms/WithdrawalAmount';
import WithdrawalBankDetails from '@atoms/WithdrawalBankDetails';
import WithdrawalReason from '@atoms/WithdrawalReason';
import WithdrawalTotal from '@atoms/WithdrawalTotal';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import {
  logPaymentSummarySuccessfull,
  logScreenBack,
  logScreenLaunch,
} from '@screens/GGWithdrawSuccess/analyticsWithdrawSuccess';
import { getStore } from '@store/storeUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import colors from '@theme/colors';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getAmplitudeKeyValue } from '@utils/utils';
import { View, Text, Divider, Modal, Button, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackHandler } from 'react-native';
import { logConfirmWithdrawalPressed, logTnCClosedForWithdrawal, logTnCOpen } from './analyticsWithdrawDetails';
import styles from './styles';

export function onSuccessHandler(
  isSuccess,
  data,
  queryClient,
  setValidatedReferralCode,
  setReferralValidated,
  navigation,
  withdrawalData,
  fromTransfer,
  withdrawalReason,
  validatedReferralCode,
  source,
  transaction_category_id,
) {
  if (isSuccess && data) {
    queryClient.invalidateQueries({ queryKey: ['transactions_history'] });
    setValidatedReferralCode(null);
    setReferralValidated(false);
    navigation.replace(navigationConstants.GG_WITHDRAW_SUCCESS, {
      wdConfirm: data,
      withdrawalData,
      fromTransfer,
      withdrawalReason,
      validatedReferralCode,
      source,
      transaction_category_id,
      logParams: getAmplitudeKeyValue(
        AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.name,
        data?.amplitudeEventContext,
      ),
    });
  }
}

export function onCloseWithdrawalModal(transaction_category_id, source, amplitudeAccountName, setTncOpen) {
  return () => {
    logTnCClosedForWithdrawal(transaction_category_id, source, amplitudeAccountName);
    setTncOpen(false);
  };
}

export function onBtnConfirmWithdrawalPressHandler(
  isLoading,
  transaction_category_id,
  withdrawalData,
  withdrawalReason,
  source,
  amplitudeAccountName,
  validatedReferralCode,
  confirmWithdrawal,
  meAmplitudeContext,
) {
  return () => {
    if (!isLoading) {
      logConfirmWithdrawalPressed(
        transaction_category_id,
        withdrawalData,
        withdrawalReason,
        source,
        amplitudeAccountName,
        validatedReferralCode,
        meAmplitudeContext,
      );
      confirmWithdrawal();
    }
  };
}

export function onBackActionHandler(transaction_category_id, amplitudeAccountName, source, validatedReferralCode) {
  return () => logScreenBack(transaction_category_id, amplitudeAccountName, source, validatedReferralCode);
}

function GGWithdrawDetails(props) {
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const { route } = props;
  const { params } = route;
  const { withdrawalData, fromTransfer, source, transaction_category_id } = params;
  const toast = useToast();
  const [tncChecked, setTncChecked] = useState(false);
  const [tncOpen, setTncOpen] = useState(false);
  const [showReasonSelector, setShowReasonSelector] = useState(false);
  const queryClient = useQueryClient();
  const {
    withdrawalReason,
    setLoading,
    setInitialData,
    setWithdrawalReason,
    selectedAccount,
    setReferralValidated,
    setValidatedReferralCode,
    validatedReferralCode,
    meAmplitudeContext,
  } = getStore();
  const { amplitudeAccountName } = selectedAccount;
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
    mutate: confirmWithdrawal,
  } = useMutation({
    mutationFn: () => apiWithdrawalConfirm({ transactionId: withdrawalData.transactionId }),
  });

  useEffect(() => {
    logPaymentSummarySuccessfull(
      transaction_category_id,
      withdrawalData,
      withdrawalReason,
      amplitudeAccountName,
      validatedReferralCode,
      source,
    );
    logScreenLaunch(
      withdrawalData,
      withdrawalReason,
      amplitudeAccountName,
      validatedReferralCode,
      source,
      transaction_category_id,
    );
    // return () => {
    //   logScreenBack(transaction_category_id, amplitudeAccountName, source, validatedReferralCode);
    // };
    const backAction = onBackActionHandler(
      transaction_category_id,
      amplitudeAccountName,
      source,
      validatedReferralCode,
    );
    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    onSuccessHandler(
      isSuccess,
      data,
      queryClient,
      setValidatedReferralCode,
      setReferralValidated,
      navigation,
      withdrawalData,
      fromTransfer,
      withdrawalReason,
      validatedReferralCode,
      source,
      transaction_category_id,
    );
  }, [isSuccess, data]);

  const closeWithdrawalModal = onCloseWithdrawalModal(
    transaction_category_id,
    source,
    amplitudeAccountName,
    setTncOpen,
  );

  const btnConfirmWithdrawalPressed = onBtnConfirmWithdrawalPressHandler(
    isLoading,
    transaction_category_id,
    withdrawalData,
    withdrawalReason,
    source,
    amplitudeAccountName,
    validatedReferralCode,
    confirmWithdrawal,
    meAmplitudeContext,
  );

  useEffect(() => {
    logTnCOpen(tncOpen, transaction_category_id, amplitudeAccountName, validatedReferralCode, source);
  }, [tncOpen]);

  return (
    <View style={appStyles.safeAreaView}>
      <View style={appStyles.marginHorizontal(16)}>
        <Text testID="lblWithdrawHeader" variant="lg-bold" style={appStyles.marginTop(16)}>
          {t('withdrawalPage.detailsHeader')}
        </Text>
        <View style={appStyles.marginVertical(8)}>
          <WithdrawalAmount requestedAmount={withdrawalData.requestedAmount} />
          <WithdrawalAdminFee fee={withdrawalData.fee} />
          <Divider color={colors.neutral.mist} height="2px" style={appStyles.marginVertical(12)} />
          <WithdrawalTotal transferAmount={withdrawalData.transferAmount} />
        </View>
      </View>
      <Divider color={colors.neutral.mist} height="4px" style={appStyles.marginVertical(12)} />
      <View style={[appStyles.marginHorizontal(16), appStyles.flex1]}>
        <Text testID="lblBankHead" variant="lg-bold">
          {t('withdrawalPage.destinationAccount')}
        </Text>
        <WithdrawalBankDetails bank={withdrawalData.bank} />
        <WithdrawalReason
          showReasonSelector={showReasonSelector}
          setShowReasonSelector={setShowReasonSelector}
          reason={withdrawalReason?.reason}
          withdrawAmount={withdrawalData.requestedAmount}
          withdrawalReason={withdrawalReason}
          setWithdrawalReason={setWithdrawalReason}
          selectedAccount={selectedAccount}
          fromTransfer={fromTransfer}
          source={source}
          transaction_category_id={transaction_category_id}
        />
        <AppliedReferral />
        <BPTncCheckBox
          tncChecked={tncChecked}
          setTncOpen={setTncOpen}
          setTncChecked={setTncChecked}
          amplitudeAccountName={amplitudeAccountName}
          transaction_category_id={transaction_category_id}
          source={source}
        />
        <Modal
          isOpen={tncOpen}
          closeOnOverlayClick
          placement="bottom"
          style={appStyles.modalParent}
          onClose={closeWithdrawalModal}>
          <Modal.Content style={appStyles.modalContainer}>
            <BPTnCHeader />
            <Modal.CloseButton onPress={closeWithdrawalModal} />
            <BPTnc
              setTncChecked={setTncChecked}
              setTncOpen={setTncOpen}
              fromWithdrawal
              transactionCategoryId={transaction_category_id}
              accountName={amplitudeAccountName}
            />
          </Modal.Content>
        </Modal>
      </View>
      <View style={[appStyles.borderWithShadow, styles.footerView]}>
        <Button
          testID="btnConfirmWithdrawal"
          isLoading={isLoading}
          style={appStyles.marginBottom(16)}
          onPress={btnConfirmWithdrawalPressed}
          isDisabled={!tncChecked}>
          {t('common.confirmed')}
        </Button>
      </View>
    </View>
  );
}

GGWithdrawDetails.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      transaction_category_id: PropTypes.string,
      withdrawalData: PropTypes.shape({
        transactionId: PropTypes.string,
        requestedAmount: PropTypes.number,
        fee: PropTypes.number,
        transferAmount: PropTypes.number,
        bank: PropTypes.shape({
          bankName: PropTypes.string,
          accountNumber: PropTypes.string,
        }),
      }),
      fromTransfer: PropTypes.bool,
    }),
  }),
};
GGWithdrawDetails.defaultProps = {
  route: {
    params: {
      source: '',
      transaction_category_id: '',
      withdrawalData: {
        transactionId: '',
        requestedAmount: 0,
        fee: 0,
        transferAmount: 0,
        bank: {
          bankName: '',
          accountNumber: '',
        },
      },
      fromTransfer: false,
    },
  },
};
export default GGWithdrawDetails;
