import RemoteImage from '@atoms/RemoteImage';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { onRedirectionHandler } from '@utils/utils';
import { View, Text, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Pressable } from 'react-native';
import styles from './styles';

function ProfileReferral({ promotionData, headerText }) {
  const navigation = useNavigation();
  const { tenantSettings } = getStore();

  const { lang } = tenantSettings;
  if (promotionData && promotionData?.length > 0) {
    const e = AMPLITUDE_CONSTANTS.interactionBanner.banner_clicked;
    return (
      <>
        <View marginX="16px" marginY="16px">
          <Text testID="txtReferralHeader" variant="md-bold">
            {headerText[lang]}
          </Text>
          <Pressable
            testID="goldBanner"
            style={styles.goldBanner}
            onPress={onRedirectionHandler(promotionData, navigation, e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
              [e.attributes.banner_name]: promotionData[0]?.title,
            })}>
            <RemoteImage uri={promotionData[0]?.imageLink} style={styles.goldImage} resizeMode="contain" />
          </Pressable>
        </View>
        <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
      </>
    );
  }
  return null;
}

// PropTypes definition
ProfileReferral.propTypes = {
  promotionData: PropTypes.arrayOf(
    PropTypes.shape({
      imageLink: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      // Add other required/optional properties as needed
    }),
  ),
  headerText: PropTypes.string.isRequired,
};

ProfileReferral.defaultProps = {
  promotionData: [],
};
export default ProfileReferral;
