import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  innerContainer: {
    backgroundColor: colors.neutral.mist,
    borderRadius: scale(12),
    marginTop: scale(8),
    paddingHorizontal: scale(12),
    paddingVertical: scale(12),
  },
  mainTitleStyle: { marginLeft: scale(5) },
  outerContainer: {
    marginTop: scale(8),
    paddingHorizontal: scale(15),
    paddingVertical: scale(12),
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: scale(5),
    paddingVertical: scale(4),
  },
  textAdj: { alignItems: 'flex-end', maxWidth: '55%', textAlign: 'right' },
});

export default styles;
