import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  autoDisburseBlueLink: { color: colors.secondary.orchid, marginLeft: scale(4), textDecorationLine: 'underline' },
  autoDisburseRedLink: { color: colors.error.rose, textDecorationLine: 'underline' },
  lockPeriodBannerIconContainerStyle: { justifyContent: 'center' },
  lockPeriodBannerTextStyle: { flex: 1, paddingLeft: scale(10) },
  lockperiodBannerContainerStyle: { backgroundColor: colors.error.softRose, marginBottom: -2 },
});

export default styles;
