import TextCurrency from '@atoms/TextCurrency';
import colors from '@theme/colors';
import { getDateInFormatLocale } from '@utils/dateUtils';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import styles from './styles';
import {
  getStatusAndColor,
  shouldShowItemIcon,
  shouldShowCoinOrExpenseArrow,
  shouldShowRedeemURL,
  shouldShowDescription,
  shouldShowDisplayName,
} from './transactionListUtilties';

const TransactionItem = ({ IS_NOT_POINTS, selectedTxnAccount, item, onItemPress }) => {
  const { t } = useTranslation('common');
  const transaksiStatus = getStatusAndColor(item.transStatus);

  return (
    <Pressable testID="itemPressContainer" onPress={() => onItemPress(item)} style={styles.container}>
      <View style={styles.itemIcon}>{shouldShowItemIcon(item)}</View>
      <View style={styles.itemTextContainer}>
        <View style={styles.itemTextRow}>
          <Text variant="sm-bold" style={styles.itemAmount}>
            {item.position === 'INCREASE' ? '+' : '-'}
            <TextCurrency
              showCurrencySymbol={IS_NOT_POINTS}
              amountCurrency={item.transAmount}
              variant="sm-bold"
              color={colors.neutral.charcoal}
              style={styles.itemCurrency}
              roundOff
            />
          </Text>
          {shouldShowCoinOrExpenseArrow(!IS_NOT_POINTS, selectedTxnAccount)}
          {shouldShowRedeemURL(item, t)}
        </View>
        <Text variant="sm-normal" style={styles.transReasonTextStyle} color={colors.neutral.charcoal}>
          {item?.transReason}
          {shouldShowDescription(item)}
        </Text>
        {shouldShowDisplayName(item)}
      </View>
      <View style={styles.itemDateContainer}>
        <Text testID="transDate" variant="sm-normal" style={styles.itemDateText}>
          {getDateInFormatLocale(item.transDate, 'DD MMM YY')}
        </Text>
        <View style={styles.itemTextRowBottom}>
          <View testID="statusIndicator" style={[styles.statusIndicator, { backgroundColor: transaksiStatus.color }]} />
          <Text variant="xsm-normal" style={styles.statusText}>
            {t(transaksiStatus.text)}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

TransactionItem.propTypes = {
  IS_NOT_POINTS: PropTypes.bool.isRequired,
  selectedTxnAccount: PropTypes.shape({}),
  item: PropTypes.shape({
    transStatus: PropTypes.string,
    position: PropTypes.string,
    transAmount: PropTypes.number,
    transReason: PropTypes.string,
    transDate: PropTypes.string,
  }).isRequired,
  onItemPress: PropTypes.func,
};

TransactionItem.defaultProps = {
  selectedTxnAccount: {},
  onItemPress: () => {},
};
export default TransactionItem;
