import { shareEmployeeReferral } from '@api/useQueryCalls';
import ListingItem from '@atoms/UserProfile/ListingItem';
import StatusRow from '@atoms/UserProfile/StatusRow';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { SVG_ICONS } from '@utils/SvgIcons';
import { numberWithDots } from '@utils/utils';
import { Text, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, Linking, FlatList, Platform } from 'react-native';
import { searchEmployees, showWhatsappShareForPic, formatDepartmentAndDesignation } from './nameListingUtils';
import listingStyles from './style';

export function shouldShowSubListingItem(show, item, t) {
  if (show)
    return (
      <View style={listingStyles.subListingContainer}>
        <ListingItem
          text={t('userProfile.listingItemOne')}
          points={numberWithDots(item.loginPoint || 20000)}
          show={item.showLoginPoint}
          disabled={item.loginPoint === 0}
        />
        <ListingItem
          text={t('userProfile.listingItemTwo')}
          points={numberWithDots(item.tnxPoint || 25000)}
          customContainer={{ marginLeft: item.showLoginPoint ? scale(23) : 0 }}
          show={item.showTxnPoint}
          disabled={item.tnxPoint === 0}
        />
      </View>
    );
  return null;
}

export function shouldShowWhatsappIcon(item, isStausAjakanVar, opWhatsAppChat) {
  if (showWhatsappShareForPic(isStausAjakanVar, item) === true)
    return (
      <Pressable testID="inviteWhatappIcon" onPress={() => opWhatsAppChat(item)} style={listingStyles.whatsappProfile}>
        {SVG_ICONS.userProfile.inviteWhatapp}
      </Pressable>
    );

  return null;
}

export function shouldShowDesigDepart(isNotStatusAjakanVar, item) {
  if (isNotStatusAjakanVar)
    return (
      <Text variant="sm-normal" style={listingStyles.desigDepartTextStyle}>
        {formatDepartmentAndDesignation(item?.department, item?.designation)}
      </Text>
    );
  return null;
}

export function openWhatsAppChatHandler(
  item,
  shortLink,
  isStausAjakan,
  sharedReferralLinkPic,
  employeeId,
  employerId,
  employeeProfile,
  t,
) {
  const content = `👋 ${t('userProfile.whatsappContentOne')} \n👉 ${shortLink} \n\n${t(
    'userProfile.whatsappContentTwo',
  )}`;

  if (((shortLink && Platform.OS === 'android') || Platform.OS === 'web') && item.mobileNumber) {
    const e = AMPLITUDE_CONSTANTS.UserProfile.wa_icon_clicked;

    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: isStausAjakan ? 'pic_page_invite_status' : 'pic_page_employee_list',
      [e.attributes.influencer_role]: 'pic',
      [e.attributes.referred_emp_id]: item.employeeId,
    });

    sharedReferralLinkPic({
      influencerEmployeeId: employeeId,
      influencerUserId: parseInt(employeeProfile?.id ?? 0, 10),
      influencerEmployerId: employerId,
      referredEmployeeId: item.employeeId,
    });

    const url = `https://api.whatsapp.com/send?phone=${item.mobileNumber}&text=${encodeURIComponent(content)}`;
    Linking.openURL(url);
  }
}

/* eslint-disable react/prop-types */
export const shouldRenderItem =
  (isStausAjakan, openWhatsAppChat, t) =>
  ({ item }) =>
    (
      <View style={listingStyles.rowContainer}>
        <View>
          <Text variant="sm-normal" color={colors.neutral.charcoal}>
            {item.employeeName}
          </Text>
          {shouldShowDesigDepart(!isStausAjakan, item)}
          {shouldShowSubListingItem(isStausAjakan, item, t)}
        </View>

        {shouldShowWhatsappIcon(item, isStausAjakan, openWhatsAppChat)}
        <View style={listingStyles.separator} />
      </View>
    );

const NamesListing = ({ route }) => {
  const shortLink = route?.shortLink ?? '';
  const isStausAjakan = route?.key === 'statusAjakan';

  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const toast = useToast();

  const { employeeId, employerId, employeeProfile, employeesToRefer, employeesReferred, searchText, setInitialData } =
    getStore();

  const { mutate: sharedReferralLinkPic, isError: isSharedError, error: sharedError } = shareEmployeeReferral();

  useEffect(() => {
    shouldShowError(isSharedError, sharedError, toast, t, navigation, setInitialData);
  }, [isSharedError, sharedError]);

  const LISTING_DATA = isStausAjakan ? employeesReferred : employeesToRefer;

  const filteredEmployeesToRefer = searchEmployees(LISTING_DATA, searchText);

  const openWhatsAppChat = item =>
    openWhatsAppChatHandler(
      item,
      shortLink,
      isStausAjakan,
      sharedReferralLinkPic,
      employeeId,
      employerId,
      employeeProfile,
      t,
    );

  return (
    <View style={listingStyles.container}>
      <StatusRow title="Satus Undangan" value={`${filteredEmployeesToRefer.length} Orang`} show={isStausAjakan} />

      <FlatList
        testID={`namelisting-${isStausAjakan}`}
        data={filteredEmployeesToRefer}
        renderItem={shouldRenderItem(isStausAjakan, openWhatsAppChat, t)}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={listingStyles.listContentStyle}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

NamesListing.propTypes = {
  route: PropTypes.shape({
    shortLink: PropTypes.string,
    key: PropTypes.string.isRequired,
  }),
};
NamesListing.defaultProps = {
  route: PropTypes.shape({
    shortLink: '',
  }),
};

export default NamesListing;
