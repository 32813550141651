import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnCollapseExpand: {
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 12,
  },
  collapsibleContainer: {
    borderRadius: 12,
    marginBottom: 10,
    marginHorizontal: 16,
  },
  containerStyle: {
    borderRadius: scale(8),
    marginBottom: 0,
    marginTop: scale(16),
    paddingLeft: scale(12),
    paddingRight: scale(12),
  },
  infoAdj: { justifyContent: 'center' },
  infoTextContainer: {
    color: colors.neutral.charcoal,
    flex: 1,
    paddingLeft: scale(8),
  },
});
export default styles;
