const eKasbon = require('./art_e-Kasbon.png');
const generalEmptyState = require('./art_generalEmptyState.png');
const artPhoneBook = require('./art_phonebook.png');
const artPhoneBookError = require('./art_phonebookError.png');
const artPostmail = require('./art_postmail.png');
const artReferral = require('./art_referral.png');
const artWithdrawAll = require('./art_withdrawAll.png');
const bankTransfer = require('./bank-transfer.png');
const blueNormal = require('./blueNormal.png');
const bungkusKado = require('./bungkusKado.png');
const ktpGoldBanner = require('./ktpGoldBanner.png');
const lainnya = require('./lainnya.png');
const redPic = require('./redPic.png');
const securityPin = require('./securityPin.png');

export {
  artPhoneBook,
  artPhoneBookError,
  artPostmail,
  artReferral,
  artWithdrawAll,
  blueNormal,
  eKasbon,
  ktpGoldBanner,
  redPic,
  securityPin,
  generalEmptyState,
  lainnya,
  bankTransfer,
  bungkusKado,
};
