import ToastError from '@atoms/ToastError';
import AccountNumberInput from '@molecules/AccountNumberInput';
import BPNominalTitle from '@molecules/BPNominalTitle';
import BPPhoneSelection from '@molecules/BPPhoneSelection';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { serverError } from '@utils/utils';
import { Text } from 'native-base';
import React from 'react';
import { logRequestBP } from './GGNominals/analyticsNominals';

export function shouldShowPhoneSelection(props) {
  const { productConfig } = props;
  if (productConfig.hasPhoneChange) {
    return <BPPhoneSelection {...props} />;
  }
  return null;
}

export function shouldShowTitle(props) {
  if (props.nominalTitle && props.nominalTitle.length > 0) {
    return <BPNominalTitle nominalTitle={props.nominalTitle} />;
  }
  return null;
}

export function shouldShowAccountInput(props) {
  const { productConfig } = props;
  if (productConfig.hasAccountInput) {
    return <AccountNumberInput {...props} />;
  }
  return null;
}

export function refetchBPIfStale(isStale, refetch) {
  if (isStale) {
    refetch();
  }
}

export function getFailureMessage(errorCode) {
  if (errorCode === 'GG-RF-01') return 'invalid_code';
  if (errorCode === 'GG-RF-02') return 'not_first_transaction';
  if (errorCode === 'GG-RF-03') return 'user_used_their_own_code';
  if (errorCode === 'GG-RF-04') return 'invalid_code_not_exist';
  if (errorCode === 'GG-RF-05' || errorCode === 'GG-RF-06') return 'invalid_code_referral_not_active';
  return 'system_error';
}

export function showErrorOnAPI(isError, error, toast, t, navigation, setInitialData, referralCode, pageSource) {
  if (isError && error) {
    const e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_failed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.referral_code]: referralCode ?? '',
      [e.attributes.source]: pageSource,
      [e.attributes.failure_message]: getFailureMessage(error[0]),
    });
    serverError(error, toast, t, navigation, setInitialData);
  }
}

export function fetchAvailableProviderFromResponse(isSuccess, data, setAvailablePostPaidProvider, setShowEmptyView) {
  if (isSuccess && data) {
    const providerKeys = Object.keys(data);
    // nominals exists
    if (providerKeys.length > 0) {
      const providers = data[providerKeys];
      const availableProviders = providers.filter(item => item.availability);
      if (availableProviders && availableProviders.length === 1) {
        setAvailablePostPaidProvider(availableProviders[0]);
      }
      if (availableProviders && availableProviders.length > 1) {
        setAvailablePostPaidProvider(availableProviders[1]);
      }
    } else {
      // show error
      setShowEmptyView(true);
    }
  }
}

export function shouldShowErrorForAccountInput(sErrorLabel) {
  if (sErrorLabel.length > 0) {
    return (
      <Text testID="textErrorMessage" style={appStyles.marginVertical(4)} variant="sm-normal" color={colors.error.rose}>
        {sErrorLabel}
      </Text>
    );
  }
  return null;
}

export function unavailableToast(toast, t) {
  return toast.show({
    render: () => <ToastError data={t('bp.unavailableProduct')} />,
    placement: 'top',
    duration: 5000,
  });
}

export function withdrawalSuspendedToast(toast, t) {
  return toast.show({
    render: () => <ToastError data={t('common.suspendedError')} />,
    placement: 'top',
    duration: 5000,
  });
}

export function lockPeriodToast(toast, t) {
  return toast.show({
    render: () => <ToastError data={t('common.lockPeriod')} />,
    placement: 'top',
    duration: 5000,
  });
}

export function disabledButtonForPDAM(selectedAccountNumber, selectedPDAMRegion) {
  return selectedAccountNumber === '' || selectedPDAMRegion === null;
}

export function getDisabledCheckForTagihanPLN(selectedAccountNumber, showEmptyView) {
  if (showEmptyView) return showEmptyView;
  return selectedAccountNumber === null || selectedAccountNumber === '';
}

export function requestBPForTagihanPLN(tagihanPLNParams) {
  const {
    selectedAccountNumber,
    selectedBPAccount,
    productApiData,
    availablePostPaidProvider,
    validatedReferralCode,
    requestBP,
    setAccountInputError,
    t,
    productConfig,
    source,
    meAmplitudeContext,
  } = tagihanPLNParams;
  return () => {
    const { amplitudeAccountName } = selectedBPAccount;
    if (selectedAccountNumber) {
      logRequestBP(
        selectedAccountNumber,
        productConfig,
        availablePostPaidProvider,
        source,
        null,
        meAmplitudeContext,
        amplitudeAccountName,
      );
      const request = {
        accountNumber: selectedAccountNumber,
        employeeAccountNumber: selectedBPAccount.id,
        transCategoryPrefix: productApiData.prefix,
        productCode: availablePostPaidProvider.code,
        referralCode: validatedReferralCode,
        zoneId: null,
        month: null,
      };
      requestBP(request);
    } else {
      setAccountInputError(t('bp.selectOrInputAccountNumber'));
    }
  };
}
