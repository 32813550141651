import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

function BPNominalTitle(props) {
  const { nominalTitle } = props;
  return (
    <Text testID="nominalTitle" style={styles.nominalHeaderTxtStyles} variant="lg-bold">
      {nominalTitle}:
    </Text>
  );
}

BPNominalTitle.propTypes = { nominalTitle: PropTypes.string };
BPNominalTitle.defaultProps = { nominalTitle: '' };
export default BPNominalTitle;
