import { analyticsTimeStampFormat } from '@root/constants';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getNowInFormat } from '@utils/dateUtils';
import { formatUnderScoreLowerCase } from '@utils/utils';

function commonEventsForSuccess(
  e1,
  fromTransfer,
  withdrawalData,
  withdrawalReason,
  amplitudeAccountName,
  validatedReferralCode,
  source,
  transaction_category_id,
  logParams = {},
) {
  AmplitudeHelper.logEvent(e1.name, {
    [e1.attributes.transaction_category_id]: transaction_category_id,
    [e1.attributes.requested_amount]: withdrawalData.transferAmount,
    [e1.attributes.fee_requested]: withdrawalData?.fee,
    [e1.attributes.reason]: withdrawalReason?.reason,
    [e1.attributes.reason_id]: withdrawalReason?.id,
    [e1.attributes.payment_method]: 'accrued_salary',
    [e1.attributes.source]: source,
    [e1.attributes.dynamic_account_name]: amplitudeAccountName,
    [e1.attributes.referral_code]: validatedReferralCode,
    ...logParams,
  });
}

export function logPageAppear(
  fromTransfer,
  withdrawalData,
  withdrawalReason,
  amplitudeAccountName,
  validatedReferralCode,
  source,
  transaction_category_id,
  logParams,
) {
  const e1 = AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess;
  const e2 = AMPLITUDE_CONSTANTS.Withdrawal.selamatPage;
  commonEventsForSuccess(
    e1,
    fromTransfer,
    withdrawalData,
    withdrawalReason,
    amplitudeAccountName,
    validatedReferralCode,
    source,
    transaction_category_id,
    logParams,
  );
  commonEventsForSuccess(
    e2,
    fromTransfer,
    withdrawalData,
    withdrawalReason,
    amplitudeAccountName,
    validatedReferralCode,
    source,
    transaction_category_id,
  );
}

export function logSuccessPageClosed(withdrawalData, withdrawalReason, transaction_category_id) {
  const e = AMPLITUDE_CONSTANTS.Withdrawal.success_page_closed;
  const { name, attributes } = e;
  AmplitudeHelper.logEvent(name, {
    [attributes.transaction_category_id]: transaction_category_id,
    [attributes.requested_amount]: withdrawalData.transferAmount,
    [attributes.reason]: withdrawalReason?.reason,
    [attributes.reason_id]: withdrawalReason?.id,
    [attributes.payment_method]: 'accrued_salary',
    [attributes.source]: 'withdrawal_navbar_button',
    [attributes.fee_charged]: withdrawalData?.fee,
  });
}

export function logScreenBack(transaction_category_id, amplitudeAccountName, source, validatedReferralCode) {
  const tc = AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled;
  AmplitudeHelper.logEvent(tc.name, {
    [tc.attributes.transaction_category_id]: transaction_category_id,
    [tc.attributes.timestamp]: getNowInFormat(analyticsTimeStampFormat),
    [tc.attributes.source]: source,
    [tc.attributes.dynamic_account_name]: amplitudeAccountName,
    [tc.attributes.referral_code]: validatedReferralCode,
  });
}

export function logScreenLaunch(
  withdrawalData,
  withdrawalReason,
  amplitudeAccountName,
  validatedReferralCode,
  source,
  transaction_category_id,
) {
  const tsp = AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage;
  AmplitudeHelper.logEvent(tsp.name, {
    [tsp.attributes.transaction_category_id]: transaction_category_id,
    [tsp.attributes.requested_amount]: withdrawalData?.requestedAmount,
    [tsp.attributes.reason]: formatUnderScoreLowerCase(withdrawalReason?.reason),
    [tsp.attributes.reason_id]: withdrawalReason?.id,
    [tsp.attributes.source]: source,
    [tsp.attributes.dynamic_account_name]: amplitudeAccountName,
    [tsp.attributes.referral_code]: validatedReferralCode,
  });
}

export function logPaymentSummarySuccessfull(
  txCatId,
  withdrawalData,
  withdrawalReason,
  amplitudeAccountName,
  validatedReferralCode,
  source,
) {
  const pss = AMPLITUDE_CONSTANTS.Withdrawal.payment_summary_successful;
  AmplitudeHelper.logEvent(pss.name, {
    [pss.attributes.transaction_category_id]: txCatId,
    [pss.attributes.requested_amount]: withdrawalData?.requestedAmount,
    [pss.attributes.reason]: formatUnderScoreLowerCase(withdrawalReason?.reason),
    [pss.attributes.reason_id]: withdrawalReason?.id,
    [pss.attributes.source]: source,
    [pss.attributes.dynamic_account_name]: amplitudeAccountName,
    [pss.attributes.referral_code]: validatedReferralCode,
  });
}
