import ReferralButton from '@atoms/ReferralButton';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Text } from 'native-base';
import React from 'react';
import { StatusBar, View } from 'react-native';
import styles from './styles';

export function referralCtaHandler(navigation) {
  return () => {
    navigation.navigate(navigationConstants.REFERRAL_PAGE, { source: 'tnc_button' });
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.referral_button_clicked.name, {});
  };
}

export function shouldShowReferralView(navigation, referralButtonText) {
  if (referralButtonText) {
    return (
      <View style={styles.referralContainer}>
        <ReferralButton onRefferalCta={referralCtaHandler(navigation)} referralButtonText={referralButtonText} />
      </View>
    );
  }
  return null;
}

function Header() {
  const { headerText, referralButtonText } = getStore();
  const navigation = useNavigation();

  const companyName = headerText;
  return (
    <View style={styles.headerParent}>
      <StatusBar translucent={false} backgroundColor={colors.secondary.orchid} />
      <Text testID="txtCompanyName" flex={1} color={colors.neutral.cotton} variant="xl-bold">
        {companyName}
      </Text>
      {shouldShowReferralView(navigation, referralButtonText)}
    </View>
  );
}

export default Header;
