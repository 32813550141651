import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fullSize: { height: '100%', width: '100%' },
  imageStyles: (width, height) => ({
    height: scale(height),
    width: scale(width),
  }),
});
export default styles;
