import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getPicRole } from '../PicListing/analyticsPIcListing';

function logPicTerms(isPIC) {
  const e = AMPLITUDE_CONSTANTS.UserProfile.pic_tnc_page;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'pic_page',
    [e.attributes.influencer_role]: getPicRole(isPIC),
  });
}

export default logPicTerms;
