import { fetchPaymentCycles } from '@api/useQueryCalls';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { logCycles } from './analyticsPaymentSelector';
import { getBoundary, getPaymentCycleDate } from './paymentCyclesUtils';
import styles from './styles';

function PaymentCycleSelector() {
  const { isError, error, data: paymentCycles = [] } = fetchPaymentCycles({ limit: 10, skip: 0 });
  const toast = useToast();
  const { t } = useTranslation('common');
  const navigation = useNavigation();

  const { setSelectedPCId, setInitialData } = getStore();

  const [selectedPCIdx, setSelectedPCIdx] = useState(0);

  useEffect(() => {
    shouldShowError(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  if (isError) return null;

  const leftDisable = getBoundary(selectedPCIdx + 1, paymentCycles);
  const rightDisable = getBoundary(selectedPCIdx - 1, paymentCycles);

  useEffect(() => {
    setSelectedPCId(paymentCycles[selectedPCIdx]?.id ?? null);
  }, [selectedPCIdx]);

  const onLeftButtonPress = () => {
    logCycles(paymentCycles, selectedPCIdx + 1);
    setSelectedPCIdx(prevState => prevState + 1);
  };

  const onRightButtonPress = () => {
    logCycles(paymentCycles, selectedPCIdx - 1);
    setSelectedPCIdx(prevState => prevState - 1);
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <TouchableOpacity
          testID="leftBtnPaymentSelector"
          disabled={leftDisable}
          onPress={onLeftButtonPress}
          style={[styles.buttonStyle, leftDisable && styles.disabledBtn]}>
          <MaterialCommunityIcons
            size={scale(18)}
            name="chevron-left"
            color={leftDisable ? colors.neutral.darkMist : colors.secondary.orchid}
          />
        </TouchableOpacity>
        <Text testID="selectedPaymentCycle" variant="md-bold" color={colors.neutral.cotton} style={styles.dateText}>
          {getPaymentCycleDate(selectedPCIdx, paymentCycles)}
        </Text>
        <TouchableOpacity
          disabled={getBoundary(selectedPCIdx - 1, paymentCycles)}
          testID="rightBtnPaymentSelector"
          onPress={onRightButtonPress}
          style={[styles.buttonStyle, rightDisable && styles.disabledBtn]}>
          <MaterialCommunityIcons
            size={scale(18)}
            name="chevron-right"
            color={rightDisable ? colors.neutral.darkMist : colors.secondary.orchid}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default PaymentCycleSelector;
