import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  accountListStyle: { alignSelf: 'stretch' },
  fltLstStyles: {
    marginHorizontal: 16,
  },
  idTypeStyles: { alignItems: 'center', flexDirection: 'row' },
  scrollViewContainer: { backgroundColor: colors.neutral.cotton, flexGrow: 1 },
  signUpContainer: { flex: 1, marginHorizontal: 10 },
});
export default styles;
