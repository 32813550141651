import logEyeIconClicked from '@atoms/BankRow/analyticsBankRow';
import DotComponent from '@atoms/DotComponent';
import { Ionicons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { showStatusViewForBank } from '@utils/utils';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

function BankRowStatusApproved(props) {
  const { t } = useTranslation('common');
  const { bankForTransfer } = getStore();
  const {
    item,
    maskData,
    maskIcon,
    setMaskIcon,
    setMaskData,
    onPressDelete,
    fromTransfer,
    setBankForTransfer,
    source,
  } = props;
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      testID="bankRowApproved"
      style={appStyles.marginVertical(8)}
      onPress={() => {
        if (fromTransfer) {
          setBankForTransfer(item);
          navigation.goBack();
        }
      }}>
      <Text variant="md-bold">{item?.bank?.bankName}</Text>
      <View flexDirection="row" alignItems="center">
        <Text variant="sm-normal" style={{}}>
          {getMaskedOrUnmaskedData(maskData, item?.accountNumber)}
        </Text>
        <Ionicons
          name={maskIcon}
          size={18}
          color="black"
          testID="btnMaskUnmaskImage"
          style={appStyles.marginLeft(8)}
          onPress={() => {
            logEyeIconClicked(source, maskData, item);
            setMaskIcon(setMaskIconValue(maskData));
            setMaskData(!maskData);
          }}
        />
        <View flex={1} alignItems="flex-end">
          {showStatusViewForBank(item, onPressDelete, t, fromTransfer, bankForTransfer)}
        </View>
      </View>

      <View flexDirection="row" alignItems="center">
        {item?.nickname ? (
          <View flexDirection="row" alignItems="center">
            <Text color={colors.neutral.darkMist} variant="md-bold">
              {item?.nickname}
            </Text>
            <DotComponent />
          </View>
        ) : null}

        <Text color={colors.neutral.darkMist} variant="sm-normal">
          {item?.beneficiaryName}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

BankRowStatusApproved.propTypes = {
  setBankForTransfer: PropTypes.func,
  onPressDelete: PropTypes.func,
  maskData: PropTypes.bool,
  maskIcon: PropTypes.string,
  setMaskIcon: PropTypes.func,
  setMaskData: PropTypes.func,
  fromTransfer: PropTypes.bool,
  source: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    accountNumber: PropTypes.string,
    beneficiaryName: PropTypes.string,
    nickname: PropTypes.string,
    status: PropTypes.string,
    bank: PropTypes.shape({
      bankName: PropTypes.string,
    }),
  }),
};
BankRowStatusApproved.defaultProps = {
  setBankForTransfer: () => {},
  onPressDelete: () => {},
  maskData: false,
  maskIcon: '',
  setMaskIcon: () => {},
  setMaskData: () => {},
  fromTransfer: false,
  source: '',
  item: {
    id: 0,
    accountNumber: '',
    beneficiaryName: '',
    nickname: '',
    status: '',
    bank: {
      bankName: '',
    },
  },
};
export default BankRowStatusApproved;
