import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  coinIconContainer: {
    height: scale(14),
    marginLeft: scale(4),
    width: scale(14),
  },
  container: {
    flexDirection: 'row',
  },
  itemAmount: {
    color: colors.neutral.charcoal,
  },
  itemCurrency: {
    marginRight: scale(4),
  },
  itemDateContainer: {
    flex: 0.3,
    justifyContent: 'center',
    paddingRight: scale(16),
  },
  itemDateText: {
    color: colors.neutral.darkMist,
    textAlign: 'right',
  },
  itemIcon: {
    padding: scale(16),
  },
  itemIconContainer: {
    height: scale(32),
    width: scale(32),
  },
  itemTextContainer: {
    flex: 0.7,
    justifyContent: 'center',
    paddingBottom: scale(6),
    paddingTop: scale(7),
  },
  itemTextRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  itemTextRowBottom: {
    alignItems: 'center',
    flexDirection: 'row',

    justifyContent: 'flex-end',
  },
  redeemContainer: {
    alignItems: 'center',
    backgroundColor: colors.error.rose,
    borderRadius: scale(5),
    marginBottom: scale(1),
    marginLeft: scale(4),
    paddingHorizontal: scale(5),
  },
  redeemText: {
    color: colors.neutral.cotton,
    fontFamily: 'Nunito-Regular',
    fontSize: scale(7),
    textAlign: 'center',
  },
  statusIndicator: {
    borderRadius: scale(12),
    height: scale(6),
    marginRight: scale(4),
    width: scale(6),
  },
  statusText: {
    color: colors.neutral.darkMist,
    textAlign: 'right',
  },
  transReasonTextStyle: { fontWeight: '400' },
});
export default styles;
