import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  connectionTextStyles: { marginLeft: 8, marginRight: 8 },
  toastParent: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: colors.error.rose,
    borderRadius: 8,
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 16,
    paddingVertical: 8,
    position: 'absolute',
    top: 8,
  },
});

export default styles;
