import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bannerContainerStyle: {
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.disabled.smoke,
    borderWidth: 1,
    bottom: 0,
    marginBottom: scale(10),
    marginVertical: scale(20),
    paddingVertical: 5,
    position: 'relative',
    width: '92%',
  },
  beliButtonStyle: { backgroundColor: colors.neutral.cotton, borderColor: colors.primary.carnation, borderWidth: 1 },
  beliTextStyle: { color: colors.primary.carnation, fontSize: 11 },
  benifitContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: scale(12),
    paddingVertical: scale(12),
  },
  benifitIconContainer: { minHeight: 32 },
  benifitSubContainer: { alignItems: 'center', paddingTop: 5 },

  benifitTextStyle: { marginTop: scale(5), textAlign: 'center' },
  bonusTextStyle: {
    color: colors.neutral.darkMist,
    marginBottom: scale(23),
    textAlign: 'center',
  },
  buttonStyle: { marginHorizontal: scale(16) },
  carouselContentStyle: { height: scale(192) },

  containerStyle: { backgroundColor: colors.neutral.cotton, flex: 1 },
  contentAdjStyle: { flexDirection: 'row', paddingLeft: 0 },
  contentContainerStyle: { flexGrow: 1, paddingBottom: scale(100) },
  contentTextStyle: { fontSize: 11, marginLeft: 10 },
  dotStyle: {
    backgroundColor: colors.secondary.orchid,
    borderRadius: 4,
    height: 8,
    width: scale(16),
  },

  inactiveStyle: {
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 4,
    height: 8,
    opacity: 1,
    width: 8,
  },

  paginationContainer: {
    alignSelf: 'center',
    marginBottom: -10,
    marginTop: -10,
    position: 'relative',
    width: scale(45),
  },
  sponsorContainer: { flexDirection: 'row', justifyContent: 'space-evenly' },
  sponsorTextStyle: { color: colors.neutral.darkMist, marginBottom: scale(8) },
  stickStyle: {
    alignItems: 'center',
    backgroundColor: colors.secondary.orchid,
    borderBottomRightRadius: scale(12),
    borderTopRightRadius: scale(12),
    color: colors.neutral.cotton,
    marginTop: scale(16),
    paddingLeft: scale(16),
    paddingVertical: scale(5.5),
    width: '54%',
  },

  stickTextStyle: {
    color: colors.neutral.cotton,
  },

  subLeftContainer: { alignItems: 'center', justifyContent: 'center' },
  subRightContainer: { alignItems: 'center', justifyContent: 'center' },
  termsContainerStyle: { alignItems: 'center', backgroundColor: colors.neutral.mist, justifyContent: 'center' },
  termsLabelStyle: { color: colors.neutral.charcoal, paddingVertical: scale(10) },
  termsLinkStyle: { color: colors.secondary.orchid, textDecorationLine: 'underline' },
});

export default styles;
