import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function ToolTipPopOver(props) {
  const { translateString, setOpen } = props;
  const { t } = useTranslation('common');
  return (
    <View style={styles.popOverContainer}>
      <View style={styles.textAndCloseParent}>
        <Text testID="txtToolTip" style={styles.adjTooltip} color={colors.neutral.cotton} variant="sm-normal">
          {t(translateString)}
        </Text>
      </View>
      <AntDesign
        testID="adCloseIcon"
        name="close"
        size={scale(16)}
        color={colors.neutral.cotton}
        onPress={() => setOpen(false)}
      />
    </View>
  );
}

ToolTipPopOver.propTypes = {
  translateString: PropTypes.string,
  setOpen: PropTypes.func,
};
ToolTipPopOver.defaultProps = {
  translateString: '',
  setOpen: () => {},
};

export default ToolTipPopOver;
