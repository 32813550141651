import TextCurrency from '@atoms/TextCurrency';
import colors from '@theme/colors';
import { getDateInFormatLocale } from '@utils/dateUtils';
import { verticalScale } from '@utils/normalize';
import { Text, View } from 'native-base';
import React from 'react';
import styles from './styles';

export function shouldFormat(bpReqData, item) {
  if (item?.value && bpReqData[item?.value]) {
    if (item.isGoldUnit) return `${bpReqData[item.value]} gr`;
    if (item.isGoldPurchaseTime) return getDateInFormatLocale(bpReqData[item.value], 'DD MMM, HH:mm');
    return bpReqData[item.value];
  }
  return '';
}

export function getValueFromAPIOrProps(item, props) {
  if (item.valueFromApi && typeof item.value === 'string') {
    const { bpReqData } = props;
    return shouldFormat(bpReqData, item);
  }
  if (item.valueFromApi && typeof item.value === 'object') {
    const { bpReqData } = props;
    const key = item.value;
    if (bpReqData && bpReqData[key?.subKey]) {
      if (bpReqData[key?.subKey][key?.index] && bpReqData[key?.subKey][key?.index][key?.innerKey]) {
        return bpReqData[key?.subKey][key?.index][key?.innerKey];
      }
      return '';
    }
    return '';
  }
  if (!item?.valueFromApi) {
    return props[item.value] ?? '';
  }
  return '';
}

function getKeyFromConfig(t, item, isSummary = false) {
  return (
    <Text testID={`detail_${item.key}`} variant={isSummary ? 'sm-bold' : 'sm-normal'} flex={1}>
      {t(`reqTxSheet.${item.key}`)}
    </Text>
  );
}

function getValuesFromProductConfig(item, props, isSummary = false) {
  return item.isCurrency ? (
    <TextCurrency
      variant="sm-bold"
      testID="txtCurrencyNominal"
      suffix={item.isGoldPrice ? '/gr' : null}
      color={isSummary ? colors.success.cactus : colors.neutral.charcoal}
      amountCurrency={getValueFromAPIOrProps(item, props)}
    />
  ) : (
    <Text
      textAlign="right"
      flex={1}
      testID="txtCurrencyNominal"
      variant="sm-bold"
      color={isSummary ? colors.success.cactus : colors.neutral.charcoal}>
      {getValueFromAPIOrProps(item, props)}
    </Text>
  );
}

export function showProdDetailsFromConfigAndApi(configArray, t, props, isSummary = false) {
  return (
    <View style={{ marginTop: verticalScale(8) }}>
      {configArray.map(item => (
        <View key={item.key} style={styles.detailsStyle}>
          {getKeyFromConfig(t, item, isSummary)}
          {getValuesFromProductConfig(item, props, isSummary)}
        </View>
      ))}
    </View>
  );
}
