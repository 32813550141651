import { SVG_ICONS } from '@utils/SvgIcons';

export const LOAN_APPLICATION_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISBURSED: 'DISBURSED',
  FULLY_PAID: 'FULLY_PAID',
};

export const LOAN_STATUS_TYPES = {
  [LOAN_APPLICATION_STATUS.PENDING_APPROVAL]: {
    title: 'LOAN_APPLICATION_TITLE_1',
    description: 'LOAN_APPLICATION_DESCRIPTION_1',
    buttonText: 'LOAN_BUTTON_TEXT_1',
    icon: SVG_ICONS.loan.verification,
  },
  [LOAN_APPLICATION_STATUS.APPROVED]: {
    title: 'LOAN_APPLICATION_TITLE_2',
    description: 'LOAN_APPLICATION_DESCRIPTION_2',
    buttonText: 'LOAN_BUTTON_TEXT_2',
    icon: SVG_ICONS.loan.approved,
  },
  [LOAN_APPLICATION_STATUS.DISBURSED]: {
    title: 'LOAN_APPLICATION_TITLE_3',
    description: 'LOAN_APPLICATION_DESCRIPTION_3',
    buttonText: 'LOAN_BUTTON_TEXT_3',
    icon: SVG_ICONS.loan.approved,
  },
  [LOAN_APPLICATION_STATUS.REJECTED]: {
    title: 'LOAN_APPLICATION_TITLE_4',
    description: 'LOAN_APPLICATION_DESCRIPTION_4',
    buttonText: 'LOAN_BUTTON_TEXT_4',
    icon: SVG_ICONS.loan.rejected,
  },
  [LOAN_APPLICATION_STATUS.FULLY_PAID]: {
    title: 'LOAN_APPLICATION_TITLE_5',
    description: 'LOAN_APPLICATION_DESCRIPTION_5',
    buttonText: 'LOAN_BUTTON_TEXT_5_1',
    buttonText2: 'LOAN_BUTTON_TEXT_5_2',
    hasTwoButtons: true,
    icon: SVG_ICONS.loan.fullyPaid,
  },
};

export function onShowModalHandler(setInfoModalSheet, source, navigation) {
  return data => {
    if (!data.applicationStatus) {
      return navigation.replace('LoanListing', {
        source,
      });
    }
    if (
      (data.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
        data.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL ||
        data.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED) &&
      data.userNotified
    ) {
      return navigation.replace('LoanDetails', {
        loanProductCode: data.loanCode,
        appId: data.id,
      });
    }
    if (
      (data.applicationStatus === LOAN_APPLICATION_STATUS.REJECTED ||
        data.applicationStatus === LOAN_APPLICATION_STATUS.FULLY_PAID) &&
      data.userNotified
    ) {
      return navigation.replace('LoanListing', {
        loanProductCode: data.loanCode,
        appId: data.id,
        source,
      });
    }

    return setInfoModalSheet(prevState => ({
      ...prevState,
      visible: true,
      data: LOAN_STATUS_TYPES[data.applicationStatus],
      type: 'loan',
    }));
  };
}
