import { isFeatureEnabled } from '@utils/utils';

export function featureFlagOnComplete(setFeatureFlags, data, onCompleted, featureName, onCompleteWithDynamicFeature) {
  setFeatureFlags(data?.app_feature ?? [], false);
  // dispatch(setFeatureFlagError(false));
  // AsyncStorage.setItem('@featureFlag', JSON.stringify(data.app_feature));
  // dispatch(setFeatureFlagLoading(false));
  if (onCompleted) {
    onCompleted(isFeatureEnabled(data?.app_feature ?? [], featureName));
  }
  if (onCompleteWithDynamicFeature) {
    onCompleteWithDynamicFeature(data?.app_feature ?? []);
  }
}

export function featureFlagOnError() {
  // serverError(true, toast, error, navigation);
  // dispatch(setFeatureFlagLoading(false));
  // dispatch(setFeatureFlagError(true));
}
