import IconRightArrow from '@atoms/IconRightArrow';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { View, Text } from 'native-base';
import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

function onPressHandler(navigation) {
  return () => {
    const e = AMPLITUDE_CONSTANTS.UserProfile.pic_zone_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.profile_page,
    });
    navigation.navigate(navigationConstants.PIC_LISTING);
  };
}

function ProfilePICBonusRow() {
  const { t } = useTranslation('common');
  const navigation = useNavigation();
  return (
    <TouchableOpacity testID="btnPICDetails" onPress={onPressHandler(navigation)}>
      <View flexDirection="row" marginTop="8px" alignItems="center">
        <View
          backgroundColor={colors.warning.softSunflower}
          paddingX="4px"
          paddingBottom="4.5px"
          paddingTop="3.5px"
          borderRadius="12px"
          justifyContent="center">
          {SVG_ICONS.userProfile.yellowStarBadge}
        </View>
        <Text testID="txtZonaRekanHR" marginLeft="8px" flex={1} variant="md-normal" alignSelf="center">
          {t('profile.zonaRekanHR')}
        </Text>
        <IconRightArrow />
      </View>
    </TouchableOpacity>
  );
}

ProfilePICBonusRow.propTypes = {};
ProfilePICBonusRow.defaultProps = {};

export default ProfilePICBonusRow;
