import { Text } from 'native-base'; // Updated import
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const SectionTitle = ({ title, show, children }) => {
  if (show) {
    return (
      <>
        <View style={styles.container}>
          <Text variant="md-normal" style={styles.titleText}>
            {title}
          </Text>
        </View>
        {children}
      </>
    );
  }

  return null;
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  children: PropTypes.node,
};

SectionTitle.defaultProps = {
  title: '',
  show: false,
  children: null,
};

export default SectionTitle;
