import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { formatPhoneNumber } from '@utils/utils';
import { Button, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

function BtnSMS(props) {
  const { t } = useTranslation('common');
  const { isLoadingSMS, isDisabled, phoneNumber, setIsLoadingSMS, setIsLoadingWhatsapp, performLogin } = props;
  const { tenantSettings } = getStore();
  const { countryCode } = tenantSettings;
  return (
    <Button
      isLoading={isLoadingSMS}
      isDisabled={isDisabled}
      flex={1}
      variant="solid"
      testID="btnSMS"
      sentry-label="btnSMS"
      style={styles.btnSmsStyles}
      onPress={() => {
        setIsLoadingSMS(true);
        setIsLoadingWhatsapp(false);
        performLogin({
          otpType: 'SMS',
          mobileNumber: formatPhoneNumber(phoneNumber, countryCode),
        });
      }}>
      <Text testID="txtSms" variant="sm-bold" color={colors.neutral.cotton}>
        {t('login.sms')}
      </Text>
    </Button>
  );
}

BtnSMS.propTypes = {
  isLoadingSMS: PropTypes.bool,
  isDisabled: PropTypes.bool,
  phoneNumber: PropTypes.string,
  setIsLoadingSMS: PropTypes.func,
  setIsLoadingWhatsapp: PropTypes.func,
  performLogin: PropTypes.func,
};

BtnSMS.defaultProps = {
  isLoadingSMS: false,
  isDisabled: false,
  phoneNumber: '',
  setIsLoadingSMS: () => {},
  setIsLoadingWhatsapp: () => {},
  performLogin: () => {},
};

export default BtnSMS;
