import NetInfo from '@react-native-community/netinfo';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import connectionComponent, { validateConnectionChange } from './connectivityUtils';

function ConnectivityListener() {
  const [showNoConnection, setShowNoConnection] = useState(false);
  const [connectionState, setConnectionState] = useState(null); // Initialize with null

  const { t } = useTranslation('common');

  const handleNetworkStateChanged = useCallback(
    validateConnectionChange(connectionState, setConnectionState, setShowNoConnection),
    [connectionState],
  );

  useEffect(() => {
    const fetchInitialNetworkState = async () => {
      const initialNetworkState = await NetInfo.fetch(); // Fetch initial network state
      setConnectionState(initialNetworkState); // Set initial state
    };

    fetchInitialNetworkState(); // Fetch initial state when component mounts

    const unsubscribe = NetInfo.addEventListener(handleNetworkStateChanged);

    return () => unsubscribe(); // Cleanup function
  }, [handleNetworkStateChanged]);

  // If initial state is still null, you can render a loading state or handle it as appropriate

  return connectionComponent(showNoConnection, t);
}

export default ConnectivityListener;
