import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './style';

const SecurityRow = ({ title, description, show, featureImage }) => {
  if (show)
    return (
      <View style={styles.outerContainer}>
        <View style={styles.subContainer}>
          <View style={styles.imageContainer}>{featureImage}</View>
        </View>
        <View style={styles.viewRightContainer}>
          <Text variant="md-bold">{title}</Text>
          <Text variant="sm-normal">{description}</Text>
        </View>
      </View>
    );

  return null;
};

SecurityRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  show: PropTypes.bool,
  featureImage: PropTypes.element,
};
SecurityRow.defaultProps = {
  title: '',
  description: '',
  show: false,
  featureImage: null,
};

export default SecurityRow;
