// import { DELETE_PHONE_NUMBER } from '@api/queries/phoneEnrollments';
// import { useMutation } from '@apollo/client';
import { deletePhoneNumber } from '@api/apiCall';
import BottomSheet from '@molecules/LoginInfoSheet';
import PinWithAddPhone from '@molecules/PinWithAddPhone';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import {
  BOTTONSHEET_PHONE_CONTENT,
  BOTTONSHEET_PHONE_ENROLLMENT,
  COMMON_CONSTANTS,
  PHONE_ENROLLMENT_STATUS,
  addPhoneTemplate2,
} from '@root/constants';
import { getStore } from '@store/storeUtils';
import { useMutation } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import {
  formatPhoneNumber,
  formatUnderScoreLowerCase,
  onClickSecondaryPhonNoBottomSheet,
  returnBottomSheetContent,
  showStatusViewForPhone,
} from '@utils/utils';
import { View, Text, Button, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Linking, TouchableOpacity } from 'react-native';
import { getBottomSheetHandlers, onDeletePhoneCompleteCallBack, shouldShowFooterComponent } from './phoneListUtils';
import styles from './styles';

export function onPressHandler(item, setBPPhone, navigation, isTransact, transactionCategoryId, eventSource) {
  return () => {
    if (isTransact) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_selected;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: eventSource,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(transactionCategoryId),
        [e.attributes.selected_phone_number]: formatPhoneNumber(item.mobileNumber),
      });
    }
    if (isTransact && item.status.toUpperCase() === 'APPROVED') {
      setBPPhone({ mobileNumber: item.mobileNumber, name: item.ownerName });
      navigation.pop();
    }
  };
}

const onContactSupport = eventSource => {
  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Login.helpClicked.name, {
    [AMPLITUDE_CONSTANTS.Login.helpClicked.attributes.source]: eventSource,
  });
  Linking.openURL(COMMON_CONSTANTS.support);
};
function PhoneList(props) {
  const { phoneList, getPhoneNumbers, isTransact, transactionCategoryId } = props;
  const { t } = useTranslation('common');
  const { setBPPhone, setInitialData, bpPhone } = getStore();
  const navigation = useNavigation();
  const initalState = {
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
      BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE],
    ),
    errorCode: BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
    currentId: 0,
    selectedNumber: 0,
    selectedName: '',
  };
  const { setLoading } = getStore();
  const toast = useToast();

  const [infoModal, setInfoModal] = useState(initalState);

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data,
    mutate: deletePhoneNumberMutate,
  } = useMutation({
    mutationFn: payload => deletePhoneNumber(payload),
  });

  const eventSource = isTransact ? AMPLITUDE_EVENT_SOURCES.select_phone_page : AMPLITUDE_EVENT_SOURCES.phone_list_page;

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      onDeletePhoneCompleteCallBack(infoModal, getPhoneNumbers, setInfoModal, initalState);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    shouldShowError(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  function onDeletePress(item) {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.number]: item?.mobileNumber,
      [e.attributes.name]: item?.ownerName,
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.phone_list_page,
    });

    if (item.status === PHONE_ENROLLMENT_STATUS.REJECTED) {
      setInfoModal(prevState => ({
        ...prevState,
        selectedNumber: item?.mobileNumber,
        selectedName: item?.ownerName,
      }));
      return onClickSecondaryPhonNoBottomSheet(
        setInfoModal,
        infoModal,
        deletePhoneNumberMutate,
        item.id,
        BOTTONSHEET_PHONE_ENROLLMENT,
      );
    }

    setInfoModal({
      data: returnBottomSheetContent(
        BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
        BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE],
      ),
      errorCode: BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
      visible: true,
      currentId: item.id,
      selectedNumber: item?.mobileNumber,
      selectedName: item?.ownerName,
    });
    return null;
  }

  const { onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onBottomSheetCloseHandler } = getBottomSheetHandlers(
    infoModal,
    setInfoModal,
    deletePhoneNumberMutate,
    initalState,
  );

  const footerComponent = () => {
    if (phoneList && phoneList.length > 0) {
      const pendingList = phoneList.filter(item => item.status.toUpperCase() === 'PENDING');
      const rejectedList = phoneList.filter(item => item.status.toUpperCase() === 'REJECTED');
      const hasPending = pendingList.length > 0;
      const hasAllRejected = rejectedList.length === phoneList.length;
      if (pendingList.length === 10) {
        return null;
      }
      return (
        <View
          style={[
            appStyles.borderWithShadow,
            appStyles.marginHorizontal(0),
            appStyles.marginTop(0),
            appStyles.paddingHorizontal(0),
            appStyles.paddingTop(0),
            appStyles.borderTopLeftRadius(0),
            appStyles.borderTopRightRadius(0),
            appStyles.borderBottomLeftRadius(0),
            appStyles.borderBottomRightRadius(0),
          ]}>
          <View style={styles.footerParent}>
            {hasPending || hasAllRejected ? (
              <Button
                testID="btnWhatsappLink"
                variant="unstyled"
                onPress={() => onContactSupport(eventSource)}
                style={styles.btnWhatsappStyles}
                _text={{ variant: 'md-bold', color: colors.variants.clover }}>
                {t('phoneTransfer.phoneTransferSupport')}
              </Button>
            ) : null}
            <PinWithAddPhone
              type={addPhoneTemplate2}
              listData={phoneList}
              pageSource={eventSource}
              isTransact={isTransact}
              transactionCategoryId={transactionCategoryId}
            />
          </View>
        </View>
      );
    }
    return null;
  };

  return (
    <View style={appStyles.flex1}>
      <View style={[appStyles.flex1, appStyles.marginHorizontal(16)]}>
        <FlatList
          testID="fltLstPhoneNumbers"
          style={styles.fltLstStyles}
          data={phoneList}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              testID={`phoneItem${index}`}
              style={styles.phoneListContainer}
              onPress={onPressHandler(item, setBPPhone, navigation, isTransact, transactionCategoryId, eventSource)}>
              <View style={styles.phoneOwnerContainer}>
                <Text variant="sm-normal">{item.mobileNumber}</Text>
                <Text variant="sm-bold" color={colors.neutral.darkMist}>
                  {item.ownerName}
                </Text>
              </View>
              {showStatusViewForPhone(item, onDeletePress, t, isTransact, bpPhone)}
            </TouchableOpacity>
          )}
        />
      </View>
      {shouldShowFooterComponent(isTransact, footerComponent)}
      <BottomSheet
        onPressPrimary={onPressPrimaryBottomSHeet}
        onPressSecondary={onPressSecondaryBottomSheet}
        loginInfo={infoModal}
        hasTwoButtons={infoModal.data.hasTwoButtons}
        onClose={onBottomSheetCloseHandler}
      />
    </View>
  );
}

PhoneList.propTypes = {
  transactionCategoryId: PropTypes.string,
  phoneList: PropTypes.arrayOf(PropTypes.shape({})),
  getPhoneNumbers: PropTypes.func,
  isTransact: PropTypes.bool,
};
PhoneList.defaultProps = {
  transactionCategoryId: '',
  phoneList: [{}],
  getPhoneNumbers: () => {},
  isTransact: false,
};

export default PhoneList;
