import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';

export function logSumbitHandler(src) {
  const e = AMPLITUDE_CONSTANTS.Treasury.gold_entry_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: src,
  });
}

export function logCloseButton() {
  const e = AMPLITUDE_CONSTANTS.Treasury.x_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'gold_id_bottom_sheet',
  });
}

export function logContinueButton() {
  const e = AMPLITUDE_CONSTANTS.Treasury.continue_button_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: 'gold_id_bottom_sheet',
  });
}
