import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  arrowStyle: {
    alignSelf: 'center',
    color: colors.neutral.charcoal,
    fontSize: scale(20),
    marginRight: scale(10),
  },

  barContainer: { backgroundColor: colors.warning.softSunflower, justifyContent: 'space-between' },
  cardDesign: { marginHorizontal: scale(16), marginTop: scale(26), marginVertical: scale(16) },
  iconContainer: { color: colors.warning.sunflower, fontSize: scale(24) },
  lihatButtonStyle: {
    borderRadius: scale(100),
    marginRight: scale(16),
    padding: 0,
    paddingHorizontal: scale(16),
    paddingVertical: scale(4),
  },
  profileImage: {
    borderTopLeftRadius: scale(18),
    borderTopRightRadius: scale(18),
    marginTop: scale(8),
    width: '100%',
  },
  textStyles: {
    color: colors.secondary.orchid,
    textAlign: 'center',
    width: scale(52),
  },
  textStylesPic: {
    color: colors.primary.carnation,
    textAlign: 'center',
    width: scale(52),
  },
});
export default styles;
