import BPProductTile from '@molecules/BPProductTile';
import appStyles from '@root/appStyles';
import { serverError } from '@utils/utils';
import { Text, View } from 'native-base';
import React from 'react';
import styles from './styles';

function shouldShowProductTile(bpcategory, source) {
  return bpcategory.category.products.map(bpCategoryProducts => (
    <BPProductTile bpCategoryProducts={bpCategoryProducts} source={source} />
  ));
}

export function shouldRenderError(isError, error, toast, t, navigation, setInitialData) {
  if (isError && error) {
    serverError(error, toast, t, navigation, setInitialData);
  }
}

export function shouldShowProductCategoryList(bpProducts, source) {
  return bpProducts.map(bpcategory => (
    <View key={bpcategory.category.title} style={[appStyles.marginHorizontal(15), styles.categoryContainer]}>
      <Text testID="categoryName" variant="lg-bold">
        {bpcategory.category.title}
      </Text>
      <View style={styles.productContainer}>{shouldShowProductTile(bpcategory, source)}</View>
    </View>
  ));
}

export function shouldSetBPListOnSuccess(isSuccess, data, setBPProducts) {
  if (isSuccess && data && data.list) {
    setBPProducts(data.list);
  }
}
