import { bankTransfer } from '@assets/png/imagesPng';
import SecurityRow from '@atoms/SecurityRow';
import { MaterialIcons } from '@expo/vector-icons';
import ScreenNames from '@navigation/navigationConstants';
import { StackActions } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { COMMON_CONSTANTS, FEATURE_FLAGS, SECURITY_PIN_INFO_MODAL } from '@root/constants';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { SVG_ICONS } from '@utils/SvgIcons';
import { goBackToHome, returnBottomSheetContent, validatePin } from '@utils/utils';
import { Text, View } from 'native-base';
import React from 'react';
import { Image, Linking } from 'react-native';
import {
  logAddPhoneClicked,
  logBankClicked,
  logCloseModal,
  logHelpCheckCreatePin,
  logOkCreate,
  logPrimaryPress,
  logReCreatePin,
  logResetPinClicked,
  logPinCreationFailedFinal,
  logPinSubmitted,
  logContinueButtonClicked,
  logPinCreationFailed,
  logPinInputted,
  logPinCreationInputted,
  logPinInputFailed,
  logXButtonClickedSubmitted,
  logCloseModalSource,
  logPinInputSuccessfully,
} from './analyticsCreatePiin';
import styles from './styles';

function commonPinHandlerFunction(type, route) {
  if (type === 'link') {
    logHelpCheckCreatePin(route);
  }
  Linking.openURL(COMMON_CONSTANTS.support);
}

export const getTitleName = (screenType, t) => {
  if (screenType === 'confirm') return t('securityPin.reEnterPin');
  if (screenType === 'create') return t('securityPin.makeNewPin');
  if (screenType === 'verifyPin') return t('securityPin.enterPin');
  return '';
};

export function shouldShowErrorText(errTxtMsg) {
  if (errTxtMsg.length > 0) {
    return (
      <Text variant="md-normal" style={styles.errorTextStyles}>
        {errTxtMsg}
      </Text>
    );
  }
  return null;
}

export function shoulRenderInstructions(show, isFeatureAvailale, t) {
  if (show)
    return (
      <>
        <View style={styles.instructionStyles}>
          <MaterialIcons name="info-outline" color={colors.secondary.orchid} size={24} />
          <Text variant="xsm-bold" style={appStyles.marginHorizontal(8)}>
            {t('securityPin.securityInfo')}
          </Text>
        </View>

        <SecurityRow
          title={t('login.SECURITY_PIN_ACTIVE_PHONE_ENROLL_BUTTON1')}
          featureImage={SVG_ICONS.BPIcons.phoneSelector}
          description={t('securityPin.phoneInfo')}
          show={isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL].show}
        />
        <SecurityRow
          title={t('login.SECURITY_PIN_ACTIVE_BANK_ENROLL_BUTTON')}
          description={t('securityPin.bankInfo')}
          show={isFeatureAvailale[FEATURE_FLAGS.BANK_SEL_TRNSF].show}
          featureImage={
            <Image source={bankTransfer} resizeMode="contain" style={{ width: scale(32), height: scale(32) }} />
          }
        />
      </>
    );

  return null;
}

export function pinHandlerFunctions(params) {
  const {
    setOtp,
    setVerifyInvalid,
    errTxtMsg,
    setErrTxtMsg,
    setClearInputs,
    route,
    navigation,
    setInfoModal,
    infoModal,
    setTypeScreen,
    MODAL_KEYS,
    onVerifyCallBack,
    onVerifyValues,
  } = params;
  const onCodeChangedHandler = code => {
    setOtp(code);
    setVerifyInvalid(false);
    if (errTxtMsg.length > 0) setErrTxtMsg('');
    setClearInputs(false);
  };

  const resetPinHandler = type => {
    commonPinHandlerFunction(type, route);
  };

  const errorHandler = errorMessage => {
    setErrTxtMsg(errorMessage);
    setOtp('');
    setClearInputs(true);
  };
  const markVerifySuccess = (/* param */) => {
    navigation.goBack();
  };

  const onCloseModal = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      logCloseModal(route);
      return markVerifySuccess(/* 'failed' */);
    }
    if (infoModal.errorCode === 'THREE_RETRY') {
      setTypeScreen('create');
      errorHandler('');
      logCloseModalSource(route);
      return;
    }

    logXButtonClickedSubmitted(route);
    goBackToHome(navigation);
    return null;
  };

  const onPrimaryPress = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));
    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      logPrimaryPress(route);
      markVerifySuccess(/* 'failed' */);
      onVerifyCallBack({ ...onVerifyValues, securityPin: null });
    }

    if (infoModal.errorCode === 'THREE_RETRY') {
      setTypeScreen('create');
      errorHandler('');
      logReCreatePin(route);
    }

    if (infoModal.errorCode === MODAL_KEYS.GG_01_FALLBACK) {
      logOkCreate(route);
      goBackToHome(navigation);
    }

    if (infoModal.errorCode === MODAL_KEYS.GG_01) {
      const source = AMPLITUDE_EVENT_SOURCES.pin_modal_others_page;
      logAddPhoneClicked(source);
      navigation.dispatch(StackActions.replace(ScreenNames.GG_ADD_PHONE_SCREEN, { source }));
    }

    if (infoModal.errorCode === MODAL_KEYS.GG_01_PHONE_ENROLL) {
      const source = AMPLITUDE_EVENT_SOURCES.pin_modal_others_page;
      logAddPhoneClicked(source);
      navigation.dispatch(StackActions.replace(ScreenNames.GG_ADD_PHONE_SCREEN, { source }));
    }

    if (infoModal.errorCode === MODAL_KEYS.GG_01_BANK_ENROLL) {
      const source = AMPLITUDE_EVENT_SOURCES.pin_modal_others_page;
      logBankClicked(source);
      navigation.dispatch(StackActions.replace(ScreenNames.GG_ADD_BANK_SCREEN, { source }));
    }
  };

  const onPressSecondaryHandler = () => {
    setInfoModal(prevState => ({ ...prevState, visible: false }));

    if (infoModal.errorCode === MODAL_KEYS.GG_01) {
      const source = AMPLITUDE_EVENT_SOURCES.pin_modal_others_page;
      logBankClicked(source);
      navigation.dispatch(
        StackActions.replace(ScreenNames.GG_ADD_BANK_SCREEN, {
          pop: false,
          source,
        }),
      );
    }

    if (infoModal.errorCode === 'THREE_RETRY_VERIFICATION') {
      // TODO navigate back to home
      // navigation.navigate('HomeScene');
      resetPinHandler();

      logResetPinClicked(route);
    }
  };

  return {
    errorHandler,
    markVerifySuccess,
    onCodeChangedHandler,
    onPrimaryPress,
    onPressSecondaryHandler,
    onCloseModal,
  };
}

export function otherPinHandlers(otherHandlerParams) {
  const {
    typeScreen,
    route,
    verifyPinHandler,
    setDisableOTP,
    confirmCopy,
    invalidConfirms,
    setInfoModal,
    setInvalidConfirms,
    errorHandler,
    setConfirmCopy,
    otpNew,
    setTypeScreen,
    securityPinRegistration,
    t,
  } = otherHandlerParams;
  const getPageNumber = () => {
    if (typeScreen === 'create') return 1;
    if (typeScreen === 'confirm') return 2;

    return null;
  };

  const onCodeFilledHandler = code => {
    if (route.params?.type === 'create') {
      logPinCreationInputted(getPageNumber, route);
    }

    if (typeScreen === 'verifyPin') {
      verifyPinHandler(code);
      logPinInputted(route);
      return;
    }

    if (validatePin(code)) {
      setDisableOTP(false);
      if (typeScreen === 'confirm' && code !== confirmCopy) {
        setDisableOTP(true);
        logPinCreationFailed(route, getPageNumber, invalidConfirms);

        if (invalidConfirms === 2) {
          setInfoModal({
            visible: true,
            data: returnBottomSheetContent(SECURITY_PIN_INFO_MODAL.THREE_RETRY, SECURITY_PIN_INFO_MODAL.THREE_RETRY),
            errorCode: 'THREE_RETRY',
          });
          return;
        }

        setInvalidConfirms(prevState => prevState + 1);

        setTimeout(() => {
          errorHandler(t('securityPin.errorPinNotSame'));
        }, 0);
      }
    } else {
      logPinCreationFailedFinal(route, getPageNumber);
      setDisableOTP(true);
      setTimeout(() => {
        errorHandler(t('securityPin.errorPinFormat'));
      }, 0);
    }
  };

  const onNextPress = async () => {
    if (typeScreen === 'create') {
      logContinueButtonClicked(route);
      setConfirmCopy(otpNew);
      setTypeScreen('confirm');
      errorHandler('');
      return;
    }
    if (typeScreen === 'confirm') {
      logPinSubmitted(route);
    }
    securityPinRegistration(otpNew);
  };
  return { getPageNumber, onNextPress, onCodeFilledHandler };
}

export function shouldRenderOnVerifyPinSuccess(
  isSuccessVerify,
  dataVerify,
  route,
  onVerifyCallBack,
  onVerifyValues,
  otpEntered,
  setShowPinRegister,
) {
  if (isSuccessVerify && dataVerify) {
    setShowPinRegister({
      showPinRegister: false,
      isActive: true,
      invalidAttempts: 0,
    });
    logPinInputSuccessfully(route);
    onVerifyCallBack({ ...onVerifyValues, securityPin: otpEntered });
  }
}

export function shouldRenderOnVerifyPinError(isErrorVerify, errorVerify, verifyPinErrorHandling) {
  if (isErrorVerify && errorVerify) {
    // shouldShowError(isError, error, toast, t, navigation, setInitialData);
    verifyPinErrorHandling();
  }
}

export function shouldRenderSuccessPinCreation(isSuccess, data, setInfoModal, getModalNameKey, setShowPinRegister) {
  if (isSuccess && data) {
    setInfoModal({
      visible: true,
      data: returnBottomSheetContent(
        SECURITY_PIN_INFO_MODAL[getModalNameKey],
        SECURITY_PIN_INFO_MODAL[getModalNameKey],
      ),
      errorCode: getModalNameKey,
    });
    setShowPinRegister({
      showPinRegister: false,
      isActive: true,
      invalidAttempts: 0,
    });
  }
}

export function verifyAndResetPinHandlers(verifyPinParams) {
  const { securityPin, setShowPinRegister, setVerifyInvalid, route, t, errorHandler, setInfoModal, securityPinVerify } =
    verifyPinParams;
  const verifyPinErrorHandling = () => {
    const remainingAttempts = 3 - (securityPin.invalidAttempts + 1);

    setShowPinRegister({ ...securityPin, invalidAttempts: securityPin.invalidAttempts + 1 });

    setVerifyInvalid(true);

    logPinInputFailed(route, securityPin);

    if (remainingAttempts > 0) {
      const errorPart1 = t('securityPin.retry1');
      const errorPart2 = t('securityPin.retry2');
      return errorHandler(`${errorPart1} ${remainingAttempts} ${errorPart2}`);
    }

    return setInfoModal({
      visible: true,
      data: returnBottomSheetContent(
        SECURITY_PIN_INFO_MODAL.THREE_RETRY_VERIFICATION,
        SECURITY_PIN_INFO_MODAL.THREE_RETRY_VERIFICATION,
      ),
      errorCode: 'THREE_RETRY_VERIFICATION',
    });
  };

  const verifyPinHandler = async pin => {
    securityPinVerify(pin);
  };
  const resetPinHandler = type => {
    commonPinHandlerFunction(type, route);
  };
  return { verifyPinHandler, verifyPinErrorHandling, resetPinHandler };
}

export const MODAL_KEYS = {
  GG_01: 'GG_01',
  GG_01_FALLBACK: 'GG_01_FALLBACK',
  GG_01_PHONE_ENROLL: 'GG_01_PHONE_ENROLL',
  GG_01_BANK_ENROLL: 'GG_01_BANK_ENROLL',
};

export function getModalKeyBasedOnFeatureFlags(isFeatureAvailale) {
  return () => {
    if (isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL].show && isFeatureAvailale[FEATURE_FLAGS.BANK_SEL_TRNSF].show)
      return MODAL_KEYS.GG_01;
    if (isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL].show) return MODAL_KEYS.GG_01_PHONE_ENROLL;
    if (isFeatureAvailale[FEATURE_FLAGS.BANK_SEL_TRNSF].show) return MODAL_KEYS.GG_01_BANK_ENROLL;
    return MODAL_KEYS.GG_01_FALLBACK;
  };
}
