import { shouldRenderHomeOnSuccess } from '@organisms/HomeTab/homeUtils';
import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';

export function renderHomePageOnAutoLogin(
  isSuccess,
  meApiData,
  setLoginData,
  setAccounts,
  setSelectedAccount,
  setCanShowReferral,
  setEmployeeId,
  setIsInLockPeriod,
  setReferralApplicable,
  setStatus,
  setUserId,
  setEmployerId,
  setBPAccounts,
  setSelectedBPAccounts,
  setBPPhone,
  setIsSuspended,
  setOtpData,
  setHeaderText,
  setReferralHeaderText,
  setAppliedReferral,
  setTxnSuccessReferral,
  setMeAmplitudeContext,
  setAutoDisburseData,
) {
  if (isSuccess && meApiData) {
    const { phoneNumber } = meApiData;
    const userName = meApiData.firstName + meApiData.lastName;
    setLoginData({ mobileNumber: phoneNumber });
    setOtpData({ userName });
    shouldRenderHomeOnSuccess({
      meApiData,
      isSuccess,
      setAccounts,
      setSelectedAccount,
      setCanShowReferral,
      setEmployeeId,
      setIsInLockPeriod,
      setReferralApplicable,
      setStatus,
      setUserId,
      setEmployerId,
      setBPAccounts,
      setSelectedBPAccounts,
      phoneNumber,
      userName,
      setBPPhone,
      setIsSuspended,
      setHeaderText,
      setReferralHeaderText,
      setAppliedReferral,
      setTxnSuccessReferral,
      setMeAmplitudeContext,
      setAutoDisburseData,
    });
  }
}

export async function autoLoginIfCorrectTokens(search, refetch) {
  if (search) {
    const accessToken = new URLSearchParams(search).get('accessToken');
    const refreshToken = new URLSearchParams(search).get('refreshToken');
    if (accessToken && refreshToken) {
      await secureStore.setItemAsync('accessToken', accessToken);
      await secureStore.setItemAsync('refreshToken', refreshToken);
      tokenManager.setAccessToken(accessToken);
      tokenManager.setRefreshToken(refreshToken);
      refetch();
    }
  }
}
