import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bannerContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.warning.softSunflower,
    borderRadius: 12,
    bottom: 150,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
    paddingHorizontal: 14,
    position: 'absolute',
    width: '90%',
    zIndex: 10,
  },
  buttonStyle: {
    backgroundColor: colors.primary.carnation,
    borderRadius: 100,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },

  buttonText: { color: colors.neutral.cotton, fontSize: 12 },
  coinStyle: { alignItems: 'center', justifyContent: 'center' },
  contentAdj: { padding: 12 },
  loaderAdj: { marginRight: 5 },
});
export default styles;
