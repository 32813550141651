import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  titleTextContainer: {
    backgroundColor: colors.neutral.cotton,
    marginTop: scale(15),
    paddingHorizontal: scale(15),
  },
});

export default styles;
