import ChatIcon from '@atoms/ChatIcon';
import useFeatureFlag from '@customHooks/useFeatureFlag';
import { FEATURE_FLAGS } from '@root/constants';
import React from 'react';

function ChaBot() {
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.CHAT_GPT],
  });

  if (isFeatureAvailale[FEATURE_FLAGS.CHAT_GPT]?.show) {
    return <ChatIcon />;
  }
  return null;
}

// ChaBot.propTypes = {};
// ChaBot.defaultProps = {};

export default ChaBot;
