import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  absoluteContainer: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: scale(12),
    elevation: 5,
    marginHorizontal: scale(15),
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },

  accuredValueText: { marginRight: scale(10) },
  collapsibleContainer: {
    borderBottomWidth: 1,
    borderColor: colors.neutral.mist,
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: scale(11),
    padding: scale(15),
  },
  collapsibleSubValueRow: { alignItems: 'center', flexDirection: 'row' },
  ewaBalance: {
    alignSelf: 'center',
    color: colors.success.cactus,
  },
  iconStyle: { alignSelf: 'center', marginRight: scale(6) },
  infoAdj: { justifyContent: 'center' },
  infoTextContainer: {
    color: colors.neutral.charcoal,

    paddingLeft: scale(8),
  },
  limitRowStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: scale(12),
  },
  limitTextStyle: { marginRight: scale(4) },

  limitValueStyle: { fontSize: scale(11.5), marginRight: scale(4) },
  rowAdj: { marginTop: scale(-13) },
  textContainer: { marginVertical: scale(8) },
  titleText: { fontSize: scale(14) },
  topCotainerRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  totalExpenseText: { fontSize: scale(12) },
  totalExpenseValue: { fontSize: scale(12), marginRight: scale(11) },
  totalExpsenseRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: scale(15),
    paddingRight: scale(23),
    paddingVertical: scale(15),
  },
});

export default styles;
