import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  btnRegionStyles: {
    alignItems: 'center',
    borderColor: colors.neutral.darkMist,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 13,
  },
  txtSelectRegionHeader: { marginBottom: 4, marginTop: 16 },
});
export default styles;
