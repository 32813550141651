import SearchListHeaderComponent from '@atoms/SearchListHeaderComponent';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { Divider, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, FlatList } from 'react-native';

function ListWithFilter(props) {
  const navigation = useNavigation();
  const { allBanks, setSelectedBank, source } = props;
  const [searchData, setSearchData] = useState(allBanks);
  const [filteredData, setFilteredData] = useState(allBanks);
  const { t } = useTranslation('common');
  useEffect(() => {
    setFilteredData(allBanks);
    setSearchData(allBanks);
  }, [allBanks]);
  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={[appStyles.paddingHorizontal(16), appStyles.paddingVertical(16)]}
      onPress={() => {
        setSelectedBank(item);
        navigation.pop();
      }}>
      <Text variant="lg-normal">{item.bankName}</Text>
    </TouchableOpacity>
  );

  const itemSeperatorComponent = () => <Divider color={colors.neutral.mist} />;
  return (
    <FlatList
      data={filteredData}
      stickyHeaderIndices={[0]}
      ListHeaderComponent={
        <SearchListHeaderComponent
          searchKey="bankName"
          searchData={searchData}
          setFilteredData={setFilteredData}
          searchPlaceholder={t('addBank.findBank')}
          logSearchDone={() => {
            const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_name_searched;
            const { name, attributes } = e;
            AmplitudeHelper.logEvent(name, { [attributes.source]: source });
          }}
        />
      }
      renderItem={renderItem}
      ItemSeparatorComponent={itemSeperatorComponent}
    />
  );
}

ListWithFilter.propTypes = {
  allBanks: PropTypes.arrayOf({}),
  setSelectedBank: PropTypes.func,
  source: PropTypes.string,
};
ListWithFilter.defaultProps = {
  allBanks: [],
  setSelectedBank: () => {},
  source: '',
};

export default ListWithFilter;
