function resetNavigationStack(CommonActions, navigation) {
  navigation.dispatch(state => {
    const routes = state.routes.filter(r => r.name !== 'LoanListing');
    return CommonActions.reset({
      ...state,
      routes,
      index: routes.length - 1,
    });
  });
}

export default resetNavigationStack;
