import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
    backgroundColor: colors.neutral.cotton,
    flexDirection: 'row',
    marginVertical: scale(10),
    paddingHorizontal: scale(20),
  },
  itemStyle: {
    marginLeft: scale(15),
    marginRight: scale(40),
  },
});

export default styles;
