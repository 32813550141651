import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

function logProfileTab(isPIC) {
  AmplitudeHelper.logEvent(AMPLITUDE_EVENT_SOURCES.others_page, {
    source: AMPLITUDE_EVENT_SOURCES.others_page,
    influencer_role: isPIC ? 'pic' : 'normal_user',
  });
}

export default logProfileTab;
