import DotComponent from '@atoms/DotComponent';
import { AntDesign, FontAwesome, Ionicons } from '@expo/vector-icons';
import PinWithAddBank from '@molecules/PinWithAddBank';
import appStyles from '@root/appStyles';
import { addBankTemplate2 } from '@root/constants';
import colors from '@theme/colors';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { Divider, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getIconState,
  getMaskedOrUnmaskedData,
  maskIconInit,
  setIconValue,
  setMaskIconValue,
} from './profileDefaultUserBankUtils';
import styles from './style';

function shouldShowAddBankButton(expanded) {
  if (expanded) {
    return (
      <>
        <Divider marginTop="12px" style={{ backgroundColor: colors.neutral.mist }} />
        <PinWithAddBank type={addBankTemplate2} fromTransfer={false} source={AMPLITUDE_EVENT_SOURCES.others_page} />
      </>
    );
  }
  return null;
}

function ProfileDefaultUserBank(props) {
  const [expanded, setExpanded] = useState(true);
  const [maskData, setMaskData] = useState(true);
  const [maskIcon, setMaskIcon] = useState(maskIconInit(maskData));
  const [icon, setIcon] = useState(getIconState(expanded));
  const { t } = useTranslation('common');
  const { bankName, beneficiaryName, bankAccountNo } = props;

  return (
    <View
      marginX="16px"
      borderColor={colors.disabled.smoke}
      borderWidth={1}
      borderRadius="12px"
      style={appStyles.marginTop(12)}>
      <View marginLeft="12px" flexDirection="row">
        <FontAwesome name="bank" style={styles.bankIconStyles} size={18} color="black" />
        <View marginLeft="8px" marginTop="10px" flex={1}>
          <Text testID="txtDefaultBankHeader" variant="xsm-normal">
            {t('profile.defaultBankHeader')}
          </Text>
          <Text testID="txtBankName" variant="sm-bold">
            {bankName}
          </Text>
          <View flexDirection="row" marginBottom={expanded ? 0 : '12px'} alignItems="center">
            <Text testID="txtBeneficiaryName" variant="xsm-normal" color={colors.neutral.darkMist}>
              {beneficiaryName}
            </Text>
            <DotComponent />
            <Text
              testID="txtMaskedData"
              variant="xsm-bold"
              style={appStyles.marginLeft(2)}
              color={colors.neutral.darkMist}>
              {getMaskedOrUnmaskedData(maskData, bankAccountNo)}
            </Text>
            <Ionicons
              name={maskIcon}
              size={16}
              color="black"
              testID="btnMaskUnmaskImage"
              style={styles.maskUnmaskStyles}
              onPress={() => {
                setMaskIcon(setMaskIconValue(maskData));
                setMaskData(!maskData);
              }}
            />
          </View>
        </View>
        <AntDesign
          testID="btnAccordianImage"
          name={icon}
          size={16}
          color="black"
          style={styles.upDownIconStyles}
          onPress={() => {
            setIcon(setIconValue(icon));
            setExpanded(!expanded);
          }}
        />
      </View>
      {shouldShowAddBankButton(expanded)}
    </View>
  );
}

ProfileDefaultUserBank.propTypes = {
  bankName: PropTypes.string,
  beneficiaryName: PropTypes.string,
  bankAccountNo: PropTypes.string,
};

ProfileDefaultUserBank.defaultProps = {
  bankName: '',
  beneficiaryName: '',
  bankAccountNo: '',
};

export default ProfileDefaultUserBank;
