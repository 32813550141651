import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

const Row = ({ title, value, containerStyle }) => (
  <View style={[styles.innerRowStyle, containerStyle]}>
    <Text variant="sm-bold">{title}</Text>
    <Text style={styles.innterRowValue} variant="sm-normal">
      {value}
    </Text>
  </View>
);

Row.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  containerStyle: PropTypes.shape({}),
};

Row.defaultProps = {
  containerStyle: {},
};

export default Row;
