import ShimmerMultiColumnNominal from '@atoms/Shimmers/ShimmerMultiColumnNominal';
import ShimmerSingleColumnNominal from '@atoms/Shimmers/ShimmerSingleColumnNominal';
import TextCurrency from '@atoms/TextCurrency';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { logNominalsPress, logRequestBP } from './analyticsNominals';
import styles from './styles';
import { unavailableToast } from '../BPUtils';

function getBackgroundColorForAvailableItem(item) {
  return item.availability ? colors.neutral.cotton : colors.neutral.mist;
}

function getNameStyleForAvailableitem(item) {
  return item.availability ? colors.neutral.charcoal : colors.disabled.smoke;
}

function getCurrencyStylesForAvailableItem(item) {
  return item.availability ? colors.success.cactus : colors.disabled.smoke;
}

function getValueFromNominal(item) {
  return item?.nominal?.value === null ? 0 : item?.nominal?.value;
}

function getAmountFromNominal(item) {
  return item?.nominal?.amount === null ? 0 : item?.nominal?.amount;
}

export default function showListOfNominals(params) {
  const {
    noOfColumns,
    nominalsData,
    productApiData,
    setNominalSelected,
    selectedAccountNumber,
    selectedBPAccount,
    requestBP,
    validatedReferralCode,
    t,
    setAccountInputError,
    isLoading,
    toast,
    productConfig,
    source,
    meAmplitudeContext,
    amplitudeAccountName,
  } = params;
  const availableNominals = nominalsData; // .filter(item => item.availability);
  function onPressNominal(item) {
    logNominalsPress(selectedAccountNumber, item, productConfig, source, validatedReferralCode);
    if (item.availability) {
      if (selectedAccountNumber) {
        setNominalSelected(item);
        logRequestBP(
          selectedAccountNumber,
          productConfig,
          item,
          source,
          validatedReferralCode,
          meAmplitudeContext,
          amplitudeAccountName,
        );
        requestBP({
          accountNumber: selectedAccountNumber,
          employeeAccountNumber: selectedBPAccount.id,
          transCategoryPrefix: productApiData.prefix,
          productCode: item.code,
          referralCode: validatedReferralCode,
          zoneId: null,
          month: null,
        });
      } else {
        setAccountInputError(t('bp.selectOrInputAccountNumber'));
      }
    } else {
      return unavailableToast(toast, t);
    }
    return null;
  }
  if (noOfColumns > 1) {
    if (isLoading) {
      return <ShimmerMultiColumnNominal />;
    }
    return (
      <FlatList
        testID="fltLstMultiCol"
        key={`multiCol${noOfColumns}`}
        data={availableNominals}
        numColumns={noOfColumns}
        style={appStyles.flex1}
        columnWrapperStyle={styles.columnWrapperStyle}
        renderItem={({ item }) => (
          <View
            w="47.5%"
            style={[styles.nominalMultiColContainer, { backgroundColor: getBackgroundColorForAvailableItem(item) }]}>
            <TouchableOpacity
              testID={item?.code}
              style={styles.nominalMultiColParent}
              onPress={() => onPressNominal(item)}>
              <TextCurrency
                color={getNameStyleForAvailableitem(item)}
                amountCurrency={getValueFromNominal(item)}
                showCurrencySymbol={false}
                variant="md-normal"
              />
              <Text variant="md-bold" color={getCurrencyStylesForAvailableItem(item)}>
                {t('bp.price')}:{' '}
                <TextCurrency
                  amountCurrency={getAmountFromNominal(item)}
                  showCurrencySymbol
                  variant="sm-bold"
                  color={getCurrencyStylesForAvailableItem(item)}
                />
              </Text>
            </TouchableOpacity>
          </View>
        )}
      />
    );
  }
  if (isLoading) {
    return <ShimmerSingleColumnNominal />;
  }
  return (
    <FlatList
      testID="fltLstSingleCol"
      key={`singleCol${noOfColumns}`}
      data={availableNominals}
      numColumns={noOfColumns}
      style={appStyles.flex1}
      contentContainerStyle={appStyles.marginTop(12)}
      renderItem={({ item }) => (
        <View testID={item?.code} style={styles.nominalContainer}>
          <TouchableOpacity style={styles.nominalSingleCol} onPress={() => onPressNominal(item)}>
            <Text variant="md-normal" color={getNameStyleForAvailableitem(item)}>
              {item.name}
            </Text>
            <Text variant="md-bold" color={getCurrencyStylesForAvailableItem(item)} style={appStyles.marginTop(2)}>
              {t('bp.price')}:{' '}
              <TextCurrency
                amountCurrency={getAmountFromNominal(item)}
                showCurrencySymbol
                variant="sm-bold"
                color={getCurrencyStylesForAvailableItem(item)}
              />
            </Text>
          </TouchableOpacity>
        </View>
      )}
    />
  );
}
