import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';

export function logBackButtonTerms() {
  const e2 = AMPLITUDE_CONSTANTS.AutoDisburse.back_button_clicked;
  AmplitudeHelper.logEvent(e2.name, {
    [e2.attributes.source]: 'autowd_tnc_page',
  });
}

export function logAutoDTerms() {
  const e = AMPLITUDE_CONSTANTS.AutoDisburse.autowd_tnc_page;
  AmplitudeHelper.logEvent(e.name, {});
}

export function logChangeBox() {
  const e = AMPLITUDE_CONSTANTS.AutoDisburse.autowd_tnc_clicked;
  AmplitudeHelper.logEvent(e.name, {});
}

export function logContinue() {
  const e = AMPLITUDE_CONSTANTS.AutoDisburse.continue_button_clicked;
  AmplitudeHelper.logEvent(e.name, { [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.autowd_tnc_page });
}
