import colors from '@theme/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bpPhoneIcon: { marginLeft: 15, marginRight: 8 },
  gantiButton: {
    alignItems: 'center',
    backgroundColor: colors.secondary.softOrchid,
    borderRadius: 12,
    flexDirection: 'row',
    marginRight: 16,
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  nameAndNumber: { alignContent: 'center', flex: 1, justifyContent: 'center' },
  phoneContainer: {
    alignItems: 'center',
    borderColor: colors.disabled.smoke,
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    marginBottom: 16,
    marginHorizontal: 15,
    marginTop: 16,
    paddingBottom: 10,
    paddingTop: 8,
  },
});

export default styles;
