import { fetchTopProducts } from '@api/useQueryCalls';
import { lainnya } from '@assets/png/imagesPng';
import ShimmerBPTile from '@atoms/Shimmers/ShimmerBPTile';
import BPProductTile from '@molecules/BPProductTile';
import { useIsFocused } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { View } from 'native-base';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import styles from './styles';

function TopProducts() {
  const [productList, setProductList] = useState([]);
  const { isLoading, isError, data, isSuccess, refetch } = fetchTopProducts();
  const isFocused = useIsFocused();
  const { t } = useTranslation('common');

  const otherProductParams = useMemo(
    () => ({
      icon: lainnya,
      name: 'Layinnya',
      screenName: t('common.Layinnya'),
      id: -1,
      availability: true,
    }),
    [t],
  );

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused, refetch]);

  useEffect(() => {
    if (isSuccess && data) {
      const { list } = data;
      if (list && list.length > 0) {
        const otherTileExists = list.some(listItem => listItem.id === -1);
        if (!otherTileExists) {
          list.push(otherProductParams);
        }
      }
      setProductList(list);
    }
  }, [isSuccess, data, otherProductParams]);

  const renderProductTile = useCallback(
    ({ item }) => <BPProductTile bpCategoryProducts={item} source="home_page" />,
    [],
  );

  if (isLoading) {
    return (
      <View flexDirection="row" style={appStyles.marginHorizontal(16)}>
        <ShimmerBPTile />
        <ShimmerBPTile />
        <ShimmerBPTile />
        <ShimmerBPTile />
      </View>
    );
  }

  if (isError) {
    return null; // or return an error component
  }

  return (
    <View style={appStyles.flexRow}>
      <FlatList
        testID="fltLstTopProducts"
        style={styles.topProductsList}
        contentContainerStyle={styles.fltLstContainerStyle}
        horizontal
        data={productList}
        keyExtractor={item => item?.id?.toString()}
        renderItem={renderProductTile}
      />
    </View>
  );
}

export default React.memo(TopProducts);
